import { DeaPdfDialogComponent } from '../../dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { RingFormsService } from 'src/app/services/ring-forms.service';
import { RingForm } from 'src/app/models/ring-form/ring-form.model';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { API } from 'src/app/constants/api';
import {
  FiltersDialogComponent,
  FiltersDialogConfigInterface,
} from '../filters-dialog/filters-dialog.component';

@Component({
  selector: 'app-forms-listing',
  templateUrl: './forms-listing.component.html',
  styleUrls: ['./forms-listing.component.scss'],
})
export class FormsListingComponent implements OnInit {
  private _ringFormsService: RingFormsService = inject(RingFormsService);
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _dialog: MatDialog = inject(MatDialog);

  @Input() page: number = 0;
  @Input() limit: number = 20;
  @Input() department?: string;
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() cbMainBtn: Function = () => {};

  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public error?: ApiError;

  public formDeaId?: string;
  public forms: RingForm[] = [];

  private _filtersDialogConfigs?: FiltersDialogConfigInterface;

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.formDeaId = params['id'];
      console.info('ID:', params['id']);
      this.getData();
    });
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData(filters?: FiltersDialogConfigInterface) {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._ringFormsService
      .getFormsListingByType({
        deaId: this.formDeaId || 'MD0000',
        page: this.page,
        limit: this.limit,
        filters: filters,
      })
      .subscribe({
        next: (data) => {
          this.forms = (data.data ?? []).map((json: any) => new RingForm(json));
          this.pagination = new ApiPagination(data?.pagination);
          console.info('DATA:', data.data);
          console.info('FORMS:', this.forms);
          console.info('PAGINATION:', this.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Ring Forms Listing Error:', error);
        },
      })
      .add(() => (this.loading = false));
  }

  openPdfPreviewPDF(id?: string): void {
    this._dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: {
        id: id || 'NO-ID',
        url: API.getFormPDF(id || 'ID-ERROR'),
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  documentFormPDF(id?: string): void {
    if (!id) return;
    this._ringFormsService.getFormPDF(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      const selected = this.forms.filter((form) => form.id == id);
      if (selected.length == 1) {
        a.download =
          `${selected[0].getClasses().join(', ') || 'No-Classes'} - ${
            selected[0].getOwnersFullName().join(', ') || 'No-Owners'
          }` + ` - ${selected[0].dea_id || 'MD0000'}.pdf`;
      } else a.download = new Date().toISOString() + '.pdf';
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }

  openFiltersDialog(): void {
    this._dialog
      .open(FiltersDialogComponent, {
        data: this._filtersDialogConfigs,
        autoFocus: false,
        restoreFocus: false,
      })
      .afterClosed()
      .subscribe((config: boolean | FiltersDialogConfigInterface) => {
        if (config == true) {
          this._filtersDialogConfigs = undefined;
          this.getData();
        } else if (config != undefined && typeof config != 'boolean') {
          console.info('CONFIG => ', config);
          this._filtersDialogConfigs = config;
          this.getData(config);
        }
      });
  }
}
