import { GuestsAttendancesParameters } from '../constants/interfaces';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class GuestAttendanceService {
  constructor(private http: HttpClient) {}

  getListing(parameters: GuestsAttendancesParameters) {
    return this.http.get<ApiResponse<any>>(
      API.guestAttendanceListing(parameters),
      {
        withCredentials: true,
      }
    );
  }

  getDetail(id?: string) {
    return this.http.get<ApiResponse<any>>(API.guestAttendance(id), {
      withCredentials: true,
    });
  }

  deleteGuestAttendance(id?: string) {
    return this.http.delete<ApiResponse<any>>(API.guestAttendance(id), {
      withCredentials: true,
    });
  }
}
