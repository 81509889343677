export class Feature {
  id?: string;
  status?: string;
  pinned?: boolean;
  title?: string;
  categories?: string[];
  tags?: string[];
  description?: string;
  created?: Date;
  updated?: Date;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.status = json['status'];
    this.pinned = json['pinned'];
    this.title = json['title'];
    this.categories = json['categories'];
    this.tags = json['tags'];
    this.description = json['description'];
    this.created = json['createdAt'] ? new Date(json['createdAt']) : undefined;
    this.updated = json['updatedAt'] ? new Date(json['updatedAt']) : undefined;
  }

  toJson(): any {
    return {
      _id: this.id,
      status: this.status,
      pinned: this.pinned,
      title: this.title,
      description: this.description,
      categories: (this.categories || []).map((c) => c.toString().trim()),
      tags: (this.tags || []).map((t) => t.toString().trim()),
      createdAt: this.created,
      updatedAt: this.updated,
    };
  }

  isPinned(): boolean {
    return this.pinned
      ? this.pinned.toString() == 'true'
        ? true
        : false
      : false;
  }
}
