import { ScholasticYear } from '../models/scholastic-year.model';
import { Injectable, inject } from '@angular/core';
import { ClassesService } from './classes.service';
import { SubjectService } from './subject.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RingFormsFiltersService {
  private _subjectService: SubjectService = inject(SubjectService);
  private _classService: ClassesService = inject(ClassesService);
  private _userService: UserService = inject(UserService);

  getScholasticYears() {
    return this._classService.getScholasticYears();
  }

  getClasses(year: string = ScholasticYear.getCurrent()) {
    return this._classService.listing({ year });
  }

  getSubjects() {
    return this._subjectService.getListing({ page: 0, limit: 100 });
  }

  getUsers(txt?: string) {
    return this._userService.getListing({ page: 0, limit: 20, txt: txt });
  }
}
