import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-timetable',
  templateUrl: './user-timetable.component.html',
  styleUrl: './user-timetable.component.scss',
})
export class UserTimetableComponent {
  @Input() classId?: string = undefined;
  @Input() teacherId?: string = undefined;
  @Input() startLoading: boolean = false;
}
