export class SpecialEducationalNeeds {
  enable?: boolean;
  dsa?: boolean;
  diagnosi?: String[];
  note?: String;

  constructor() {}

  public static fromJson(json: any): SpecialEducationalNeeds {
    let newSpecialEducationalNeeds = new SpecialEducationalNeeds();
    newSpecialEducationalNeeds.enable =
      json['enable'] === undefined
        ? undefined
        : (json['enable'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    newSpecialEducationalNeeds.dsa =
      json['dsa'] === undefined
        ? undefined
        : (json['dsa'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    newSpecialEducationalNeeds.diagnosi = json['diagnosi'];
    newSpecialEducationalNeeds.note = json['note'];
    return newSpecialEducationalNeeds;
  }

  toJson(defaultUndefined: boolean = true): any {
    const value = defaultUndefined ? undefined : '';
    if (
      (this.enable == undefined || this.enable == false) &&
      (this.dsa == undefined || this.dsa == false) &&
      !this.diagnosi &&
      !this.note
    ) {
      return undefined;
    }
    return {
      enable: this.enable || value,
      dsa: this.dsa || value,
      diagnosi: this.diagnosi || value,
      note: this.note || value,
    };
  }
}
