<div class="row m-0 p-0">
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 81px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-start align-items-center m-0 p-0" style="height: 81px;">
        <button class="me-auto mt-1" mat-stroked-button color="primary" (click)="openUploadDialog()">
          Carica Documento PCTO Corso Sicurezza
        </button>
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="row m-0 p-0" *ngFor="let doc of pctos;">
        <app-details-divider class="m-0 p-0"></app-details-divider>
        <app-user-pcto-row [showHours]="false" [pctoDoc]="doc" (userDocRowEmitter)="getDocumentsData()" class="m-0 p-0">
        </app-user-pcto-row>
      </div>
    </ng-template>
  </ng-template>
</div>