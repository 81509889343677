import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-fast-action-btn',
  template: `<button
    class="bg-transparent dea-border p-1 px-3 rounded-pill fw-lighter fs-075r"
    [ngClass]="{ 'dea-shadow': !disabled }"
    [disabled]="disabled"
    type="button"
  >
    <span
      class="opacity-50 fw-lighter fs-075r"
      [ngClass]="{ 'fast-action-btn': !disabled }"
    >
      <ng-content></ng-content>
    </span>
  </button>`,
  styles: [],
})
export class DeaFastActionBtnComponent {
  @Input() disabled: boolean = false;
}
