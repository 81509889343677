import { UserTypeBackendStr } from 'src/app/models/user/user-type';
import { Component } from '@angular/core';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent {
  type: string = UserTypeBackendStr.STUDENT;
}
