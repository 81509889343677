import { ThumbnailCacheService } from '../services/thumbnail-cache.service';
import { environment } from 'src/environments/environment';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'cache',
})
export class ThumbnailCachePipe implements PipeTransform {
  constructor(private _imgCacheService: ThumbnailCacheService) {}

  transform(url: unknown, ...args: unknown[]): Observable<string> {
    if (typeof url === 'string' && url.includes(environment.apiUrl)) {
      return this._imgCacheService.get(url, true);
    }
    return of(url as string);
  }
}
