import { DeaUserListingDialogComponent } from '../../dea-pack/dea-user-listing-dialog/dea-user-listing-dialog.component';
import { DeaSingleBtnDialogComponent } from '../../dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { SchoolbooksDialogComponent } from './schoolbooks-dialog/schoolbooks-dialog.component';
import { SubjectsDialogComponent } from './subjects-dialog/subjects-dialog.component';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ClassesService } from 'src/app/services/classes.service';
import { DepartmentUtils } from 'src/app/models/department.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Schoolbook } from 'src/app/models/schoolbook.model';
import { Headquarter } from 'src/app/models/headquarter';
import { Class } from 'src/app/models/class/class.model';
import { AppPaths } from 'src/app/constants/app-paths';
import { Subject } from 'src/app/models/subject.model';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  DeaDropdownMenuEventData,
  DeaUserListingDialogComponentOutput,
} from 'src/app/constants/interfaces';

@Component({
  selector: 'app-class-form',
  templateUrl: './class-form.component.html',
  styleUrls: ['./class-form.component.scss'],
})
export class ClassFormComponent {
  public headquarters: string[] = Headquarter.getFrontendHeadqarters();
  public departments: string[] = DepartmentUtils.getDepartments();
  public disableClassHeadquarterDropdownMenu: boolean = false;
  public selectedHeadquarterClassForm: string = '';
  public selectedDepartmentClassForm: string = '';

  public classe: Class = new Class();
  public posting: boolean = false;
  public loading: boolean = false;
  public isError: boolean = false;
  public formTitle: string = '';
  public error?: ApiError;

  @Input() classId?: string;

  isEditingMode: boolean = false;

  classForm = new FormGroup({
    anno_scolastico: new FormControl({
      value: ScholasticYear.getNext(),
      disabled: true,
    }),
    dipartimento: new FormControl(),
    anno: new FormControl(),
    sezione: new FormControl(),
    studenti: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        cognome: new FormControl({ value: '', disabled: true }),
        secondo_nome: new FormControl({ value: '', disabled: true }),
      }),
    ]),
    docenti: new FormArray([
      new FormGroup({
        docente: new FormGroup({
          _id: new FormControl({ value: '', disabled: true }),
          nome: new FormControl({ value: '', disabled: true }),
          cognome: new FormControl({ value: '', disabled: true }),
          secondo_nome: new FormControl({ value: '', disabled: true }),
        }),
        discipline: new FormArray([
          new FormGroup({
            materia: new FormGroup({
              id: new FormControl({ value: '', disabled: true }),
              dea_id: new FormControl({ value: '', disabled: true }),
              nome: new FormControl({ value: '', disabled: true }),
            }),
            libri: new FormArray([
              new FormGroup({
                id: new FormControl({ value: '', disabled: true }),
                dea_id: new FormControl({ value: '', disabled: true }),
                titolo: new FormControl({ value: '', disabled: true }),
                isbn: new FormControl({ value: '', disabled: true }),
              }),
            ]),
            ore: new FormControl(),
          }),
        ]),
      }),
    ]),
  });

  get classFormStudents() {
    return this.classForm.controls.studenti as FormArray;
  }

  get classFormTeachers() {
    return this.classForm.controls.docenti as FormArray;
  }

  get classFormYear(): string {
    return (this.classForm?.controls?.anno?.value || 'NO-YEAR')
      .toString()
      .trim();
  }

  get classFormSection(): string {
    return (this.classForm?.controls?.sezione?.value || 'NO-SECTION')
      .toString()
      .trim();
  }

  classFormTeacherDisciplines(index: number): FormArray {
    const teacher: FormGroup = this.classFormTeachers.at(index) as FormGroup;
    const disciplineArray = teacher.controls['discipline'] as FormArray;
    return disciplineArray;
  }

  classFormTeacherDisciplineBooks(
    teacherIndex: number,
    disciplineIndex: number
  ): FormArray {
    const discipline = this.classFormTeacherDisciplines(teacherIndex).at(
      disciplineIndex
    ) as FormGroup;
    const bookArray = discipline.controls['libri'] as FormArray;
    return bookArray;
  }

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private location: Location,
    private _route: ActivatedRoute,
    private _classService: ClassesService
  ) {}

  ngOnInit(): void {
    // STUDENTS / TEACHERS
    this.resetFormArrays();
    this.setUsersArrayInForm('studenti', this.classe.studenti || []);
    this.setUsersArrayInForm('docenti', this.classe.docenti || []);

    this._route.params.subscribe((params) => {
      if (params['id']) this.classId = params['id'];
      this.formTitle = this.classId ? 'Modifica Classe:' : 'Nuovo Classe:';
      if (this.classId) {
        this.isEditingMode = true;
        this.getClassData();
      }
    });
  }

  getClassData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._classService
      .getDetail(this.classId)
      .subscribe({
        next: (data) => {
          if (data.data != undefined && (data.data as Class)) {
            this.classe = new Class(data?.data);
            this.selectedHeadquarterClassForm =
              this.classe.sede?.frontend || Headquarter.MILANO.frontend;
            this.selectedDepartmentClassForm =
              DepartmentUtils.fromString(this.classe.dipartimento) ||
              this.departments[0];
            this.classForm
              .get('anno_scolastico')
              ?.setValue(this.classe.anno_scolastico ?? '');
            this.classForm
              .get('sezione')
              ?.setValue(this.classe.sezione ?? undefined);
            this.classForm.get('anno')?.setValue(this.classe.anno ?? undefined);
            // STUDENTS
            let studentsFormArray = <FormArray>this.classForm.get('studenti');
            if (studentsFormArray) {
              (this.classe.studenti || []).forEach((s) => {
                studentsFormArray.push(
                  new FormGroup({
                    _id: new FormControl({
                      value: s.id,
                      disabled: true,
                    }),
                    nome: new FormControl({
                      value: s.nome,
                      disabled: true,
                    }),
                    cognome: new FormControl({
                      value: s.cognome,
                      disabled: true,
                    }),
                    secondo_nome: new FormControl({
                      value: s.secondo_nome,
                      disabled: true,
                    }),
                  })
                );
              });
              studentsFormArray.value.forEach((item: any, i: number) => {
                if (item._id === undefined) studentsFormArray.removeAt(i);
              });
            }
            // TEACHERS
            let teachersFormArray = <FormArray>this.classForm.get('docenti');
            if (teachersFormArray) {
              (this.classe.docenti || []).forEach((docente) => {
                const discipline: any = (docente.discipline || []).map(
                  (disciplina) => {
                    const libri: any = (disciplina.libri || []).map((libro) => {
                      return new FormGroup({
                        id: new FormControl({
                          value: libro.id,
                          disabled: true,
                        }),
                        dea_id: new FormControl({
                          value: libro.dea_id,
                          disabled: true,
                        }),
                        titolo: new FormControl({
                          value: libro.titolo,
                          disabled: true,
                        }),
                        isbn: new FormControl({
                          value: libro.isbn,
                          disabled: true,
                        }),
                      });
                    });
                    return new FormGroup({
                      libri: new FormArray(libri || []),
                      ore: new FormControl(disciplina.ore || undefined),
                      materia: new FormGroup({
                        id: new FormControl({
                          value: disciplina.materia?.id,
                          disabled: true,
                        }),
                        dea_id: new FormControl({
                          value: disciplina.materia?.dea_id,
                          disabled: true,
                        }),
                        nome: new FormControl({
                          value: disciplina.materia?.nome,
                          disabled: true,
                        }),
                      }),
                    });
                  }
                );
                teachersFormArray.push(
                  new FormGroup({
                    docente: new FormGroup({
                      _id: new FormControl({
                        value: docente.docente?.id,
                        disabled: true,
                      }),
                      nome: new FormControl({
                        value: docente.docente?.nome,
                        disabled: true,
                      }),
                      cognome: new FormControl({
                        value: docente.docente?.cognome,
                        disabled: true,
                      }),
                      secondo_nome: new FormControl({
                        value: docente.docente?.secondo_nome,
                        disabled: true,
                      }),
                    }),
                    discipline: new FormArray(discipline || []),
                  })
                );
              });
            }
          }
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Detail Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  getClassFromForm(): Class {
    const formClassData: Class = new Class();
    // Imposto la sede
    formClassData.sede = Headquarter.fromString(
      Headquarter.fromString(this.selectedHeadquarterClassForm)?.backend ||
        Headquarter.MILANO.backend
    );
    // Imposto il dipartimento
    formClassData.dipartimento =
      DepartmentUtils.fromString(this.selectedDepartmentClassForm) ||
      DepartmentUtils.getDepartments()[0];
    // Imposto l'anno scolastico, l'anno e la sezione
    formClassData.anno_scolastico =
      this.classForm.controls['anno_scolastico'].value || '';
    formClassData.anno = this.classForm.controls['anno'].value;
    formClassData.sezione = this.classForm.controls['sezione'].value;
    // Imposto gli studenti
    formClassData.studenti = this.classForm.controls['studenti'].value.map(
      (json: any) => new User(json)
    );
    // Imposto i docenti
    const formArrayDocenti: FormArray<FormGroup> = (<FormArray>(
      this.classForm.get('docenti')
    )) as FormArray;
    formClassData.docenti = formArrayDocenti.controls.map((c) => {
      return {
        docente: new User(c.controls['docente'].value),
        discipline: (<FormArray<FormGroup<any>>>(
          c.get('discipline')
        )).controls.map((cd) => {
          return {
            ore: cd.controls['ore'].value,
            materia: new Subject(cd.controls['materia'].value),
            libri: (<FormArray>cd.get('libri')).controls.map(
              (book) => new Schoolbook(book.value)
            ),
          };
        }),
      };
    });
    // Coordinatori e Rappresentanti
    formClassData.coordinatori = this.classe.coordinatori;
    formClassData.rappresentanti = this.classe.rappresentanti;
    return formClassData;
  }

  setSelectedClassHeadquarter() {
    const h = this.classe.sede?.backend || this.headquarters[0];
    this.selectedHeadquarterClassForm =
      Headquarter.fromString(h)?.frontend ||
      Headquarter.fromString(this.headquarters[0])!.frontend;
  }

  setSelectedClassDepartment() {
    const d = this.classe.dipartimento || this.departments[0];
    this.selectedDepartmentClassForm =
      DepartmentUtils.fromString(d) ||
      DepartmentUtils.fromString(this.departments[0])!;
  }

  setClassYear(event: any) {
    this.classForm.get('anno')?.setValue(event?.value);
  }

  setClassSection(event: any) {
    this.classForm.get('sezione')?.setValue(event?.value);
  }

  onClassHeadquarterChange(eventData: DeaDropdownMenuEventData) {
    if (eventData.id === 'classFormHeadquarterDropdownMenu') {
      this.classe.sede = Headquarter.fromString(
        this.headquarters[eventData.index]
      )!;
      this.setSelectedClassHeadquarter();
    }
  }

  onClassDepartmentChange(eventData: DeaDropdownMenuEventData) {
    if (eventData.id === 'classFormDepartmentDropdownMenu') {
      this.classe.dipartimento = DepartmentUtils.fromString(
        this.departments[eventData.index]
      )!;
      this.setSelectedClassDepartment();
    }
  }

  setUsersArrayInForm(forArray: string, items: any[]) {
    if (['studenti'].includes(forArray)) {
      let formArray = <FormArray>this.classForm.get(forArray);
      if (formArray) {
        items.forEach((item) => {
          formArray.push(
            new FormGroup({
              _id: new FormControl({
                value: item.id,
                disabled: true,
              }),
              nome: new FormControl({
                value: item.nome,
                disabled: true,
              }),
              cognome: new FormControl({
                value: item.cognome,
                disabled: true,
              }),
              secondo_nome: new FormControl({
                value: item.secondo_nome,
                disabled: true,
              }),
            })
          );
        });
        formArray.value.forEach((item: any, i: number) => {
          if (item._id === undefined) formArray.removeAt(i);
        });
      }
    } else console.log('Attenzione: chiave non valida!');
  }

  openUsersDialog(type: string, forArray: string) {
    if (
      ['Student', 'Teacher'].includes(type) &&
      ['studenti', 'docenti'].includes(forArray)
    ) {
      const dialogSize = '1000px';
      const dialogRef = this._dialog.open(DeaUserListingDialogComponent, {
        maxWidth: dialogSize,
        minWidth: dialogSize,
        data: {
          type: type.toString().trim(),
        },
        autoFocus: false,
        restoreFocus: false,
      });
      dialogRef
        .afterClosed()
        .subscribe((userDialogResult: DeaUserListingDialogComponentOutput) => {
          if (userDialogResult) {
            let formArray;
            switch (forArray.toString().toLowerCase()) {
              case 'studenti':
                formArray = <FormArray>this.classForm.get('studenti');
                if (formArray) {
                  formArray.push(
                    new FormGroup({
                      _id: new FormControl({
                        value: userDialogResult.id,
                        disabled: true,
                      }),
                      nome: new FormControl({
                        value: userDialogResult.nome,
                        disabled: true,
                      }),
                      cognome: new FormControl({
                        value: userDialogResult.cognome,
                        disabled: true,
                      }),
                      secondo_nome: new FormControl({
                        value: userDialogResult.secondo_nome,
                        disabled: true,
                      }),
                    })
                  );
                }
                break;
              case 'docenti':
                formArray = <FormArray>this.classForm.get('docenti');
                if (formArray) {
                  formArray.push(
                    new FormGroup({
                      docente: new FormGroup({
                        _id: new FormControl({
                          value: userDialogResult.id,
                          disabled: true,
                        }),
                        nome: new FormControl({
                          value: userDialogResult.nome,
                          disabled: true,
                        }),
                        cognome: new FormControl({
                          value: userDialogResult.cognome,
                          disabled: true,
                        }),
                        secondo_nome: new FormControl({
                          value: userDialogResult.secondo_nome,
                          disabled: true,
                        }),
                      }),
                      discipline: new FormArray([]),
                    })
                  );
                }
                break;
            }
          }
        });
    } else console.log('Attenzione: chiave non valida!');
  }

  openSubjectsDialog(indexDocente: number) {
    const dialogSize = '1000px';
    const dialogRef = this._dialog.open(SubjectsDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      data: {},
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((subjectDialogResult: any) => {
      if (subjectDialogResult) {
        const teachers = this.classFormTeachers;
        if (indexDocente < teachers.length) {
          const teacher = teachers.at(indexDocente);
          let teacherDiscipline = <FormArray>teacher.get('discipline');
          teacherDiscipline.push(
            new FormGroup({
              materia: new FormGroup({
                id: new FormControl({
                  value: subjectDialogResult.id,
                  disabled: true,
                }),
                dea_id: new FormControl({
                  value: subjectDialogResult.dea_id,
                  disabled: true,
                }),
                nome: new FormControl({
                  value: subjectDialogResult.nome,
                  disabled: true,
                }),
              }),
              libri: new FormArray([]),
              ore: new FormControl(),
            })
          );
        }
      }
    });
  }

  openSchoolbooksDialog(indexDocente: number, indexDisciplina: number) {
    const dialogSize = '1000px';
    const dialogRef = this._dialog.open(SchoolbooksDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((schoolbookDialogResult: any) => {
      if (schoolbookDialogResult) {
        const teachers = this.classFormTeachers;
        if (indexDocente < teachers.length) {
          const teacher = teachers.at(indexDocente);
          let teacherDiscipline = <FormArray>teacher.get('discipline');
          if (indexDisciplina < teacherDiscipline.length) {
            const disciplina = teacherDiscipline.at(indexDisciplina);
            let disciplinaLibri = <FormArray>disciplina.get('libri');
            disciplinaLibri.push(
              new FormGroup({
                id: new FormControl({
                  value: schoolbookDialogResult.id,
                  disabled: true,
                }),
                dea_id: new FormControl({
                  value: schoolbookDialogResult.dea_id,
                  disabled: true,
                }),
                titolo: new FormControl({
                  value: schoolbookDialogResult.titolo,
                  disabled: true,
                }),
                isbn: new FormControl({
                  value: schoolbookDialogResult.isbn,
                  disabled: true,
                }),
              })
            );
          }
        }
      }
    });
  }

  deleteUserInAt(forArray: string, index: number) {
    if (['studenti', 'docenti'].includes(forArray)) {
      let formArray = <FormArray>this.classForm.get(forArray);
      if (formArray) formArray.removeAt(index);
    } else console.log('Attenzione: chiave non valida!');
  }

  deleteDisciplineAtAt(indexDocente: number, indexDisciplina: number) {
    let formArrayDocenti = <FormArray>this.classForm.get('docenti');
    let docente = formArrayDocenti.at(indexDocente);
    let formArrayDiscipline = <FormArray>docente.get('discipline');
    formArrayDiscipline.removeAt(indexDisciplina);
  }

  deleteBookAtAtAt(
    indexDocente: number,
    indexDisciplina: number,
    indexLibro: number
  ) {
    let formArrayDocenti = <FormArray>this.classForm.get('docenti');
    let docente = formArrayDocenti.at(indexDocente);
    let formArrayDiscipline = <FormArray>docente.get('discipline');
    let disciplina = formArrayDiscipline.at(indexDisciplina);
    let formArrayLibri = <FormArray>disciplina.get('libri');
    formArrayLibri.removeAt(indexLibro);
  }

  resetFormArrays() {
    const keys = ['studenti', 'docenti'];
    keys.forEach((key) => {
      let formArray = <FormArray>this.classForm.get(key);
      const length = formArray.length;
      for (let i = length - 1; i >= 0; i--) formArray.removeAt(i);
    });
  }

  // Coordinatori e Rappresentanti
  isCheckedTeacherAt(index: number): boolean {
    const idDocente = (<FormArray>this.classForm.get('docenti') || [])
      ?.at(index)
      ?.get('docente')
      ?.get('_id')?.value;
    return this.classe.coordinatori?.includes(idDocente || 'NO-ID') || false;
  }

  onCorrdinatoreChangedAt(index: any) {
    const idDocente = (<FormArray>this.classForm.get('docenti') || [])
      ?.at(index)
      ?.get('docente')
      ?.get('_id')?.value;
    if (idDocente) {
      if (this.classe.coordinatori?.includes(idDocente)) {
        let atIndex = this.classe.coordinatori.indexOf(idDocente);
        let newCoordinatori: string[] = [];
        this.classe.coordinatori.map((c, i) => {
          if (i != atIndex) newCoordinatori.push(c);
        });
        this.classe.coordinatori = newCoordinatori;
      } else this.classe.coordinatori = [idDocente];
    } else this.classe.coordinatori = [];
  }

  isCheckedStudentAt(index: number): boolean {
    const idStudente = (<FormArray>this.classForm.get('studenti') || [])
      ?.at(index)
      ?.get('_id')?.value;
    return this.classe.rappresentanti?.includes(idStudente || 'NO-ID') || false;
  }

  onRappresentanteChangedAt(index: number) {
    const idStudente = (<FormArray>this.classForm.get('studenti') || [])
      ?.at(index)
      ?.get('_id')?.value;
    if (idStudente) {
      if (this.classe.rappresentanti?.includes(idStudente)) {
        let atIndex = this.classe.rappresentanti.indexOf(idStudente);
        let newRappresentanti: string[] = [];
        this.classe.rappresentanti.map((r, i) => {
          if (i != atIndex) newRappresentanti.push(r);
        });
        this.classe.rappresentanti = newRappresentanti;
      } else this.classe.rappresentanti?.push(idStudente);
    }
  }

  // POST / PUT DATA
  postData() {
    this.posting = true;
    const newClassData: Class = this.getClassFromForm();
    const method = this.classId
      ? this._classService.putClass(this.classId, newClassData.toJson(false))
      : this._classService.postClass(newClassData.toJson());
    method.subscribe({
      next: (data) => {
        const newClass = new Class(data?.data);
        newClass.id
          ? this._router.navigateByUrl(AppPaths.CLASSE + '/' + newClass.id)
          : this.backOnePage();
      },
      error: (error: any) => {
        this.error = new ApiError(error);
        const errorDialogRef = this._dialog.open(DeaSingleBtnDialogComponent, {
          maxWidth: '450px',
          minWidth: '450px',
          data: {
            error: this.error,
          },
          autoFocus: false,
          restoreFocus: false,
        });
        errorDialogRef.afterClosed().subscribe(() => (this.posting = false));
      },
    });
  }

  backOnePage(): void {
    this.location.back();
  }
}
