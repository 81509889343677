import { ApiResponse } from 'src/app/models/api/api-response.model';
import { Film } from 'src/app/models/items/film.model';
import { HttpClient } from '@angular/common/http';
import { API } from 'src/app/constants/api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilmService {
  constructor(private http: HttpClient) {}

  getListing(txt?: string) {
    return this.http.get<ApiResponse<any>>(API.filmsLisitng(txt), {
      withCredentials: true,
    });
  }

  postFilm(data?: Film) {
    return this.http.post<ApiResponse<any>>(API.postFilm(), data?.toJson(), {
      withCredentials: true,
    });
  }

  putFilm(data?: Film) {
    return this.http.put<ApiResponse<any>>(
      API.putFilm(data?.id),
      data?.toJson(),
      {
        withCredentials: true,
      }
    );
  }

  deleteFilm(id?: string) {
    return this.http.delete<ApiResponse<any>>(API.deleteFilm(id), {
      withCredentials: true,
    });
  }
}
