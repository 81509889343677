import { UserTypeBackendStr } from 'src/app/models/user/user-type';
import { Component } from '@angular/core';

@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss'],
})
export class ParentsComponent {
  type: string = UserTypeBackendStr.PARENT;
}
