import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-dialog-wrapper',
  templateUrl: './dea-dialog-wrapper.component.html',
  styleUrls: ['./dea-dialog-wrapper.component.scss'],
})
export class DeaDialogWrapperComponent {
  @Input() title?: string;
}
