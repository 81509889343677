import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import isMongoId from 'validator/es/lib/isMongoId';

@Component({
  selector: 'app-edit-event',
  template: `<app-event-form [eventId]="eventId"></app-event-form>`,
  styles: [],
})
export class EditEventComponent implements OnInit {
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _location: Location = inject(Location);
  public eventId?: string;

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.eventId = params['id'];
      if (!this.eventId || !isMongoId(this.eventId)) this._location.back();
    });
  }
}
