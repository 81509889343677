import { Tech } from './tech.model';

export class TechBreaking extends Tech {
  type?: String;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    this.type = json['type'];
  }
}
