<app-dea-dialog-wrapper>
  <div class="d-flex flex-column gap-3 justify-content-center">
    <div class="fw-semibold text-center fs-4">Credenziali temporanee per<br>recupero accesso</div>
    <div class="text-center">Indirizzo E-Mail: {{data.email}}</div>
    <div class="d-flex flex-row gap-1 justify-content-center align-items-center">
      @for (c of data.tmpPassword; track $index) {
      <div class="fw-semibold text-center fs-2 character-cell dea-radius d-flex flex-column justify-content-center">
        <div class="font-monospace">{{c}}</div>
      </div>
      }
    </div>
    <div class="text-center fw-lighter fs-075r">
      Attenzione: una volta chiuso questo alert non sarà più possibile recuperare e visualizzare nuovamente le
      credenziali temporanee dell'utente
    </div>
    <div class="text-center fw-lighter fs-065r">
      (L'utente riceverà comunque le credenziali temporanee via e-mail)
    </div>
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="onDismiss()">Ok</button>
  </div>
</app-dea-dialog-wrapper>
