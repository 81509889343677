import { UserDocumentType } from 'src/app/models/user/user-document/user-document-type.enum';
import { UserDocumentsService } from 'src/app/services/user-documents.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-user-document-upload-dialog',
  templateUrl: './user-document-upload-dialog.component.html',
  styleUrls: ['./user-document-upload-dialog.component.scss'],
})
export class UserDocumentUploadDialogComponent {
  userId?: string;
  error?: ApiError;
  errorMessage?: string;
  isUploading: boolean = false;

  userDocumentForm = new FormGroup({
    type: new FormControl(UserDocumentType.DOCUMENT, Validators.required),
    nome: new FormControl(null, Validators.required),
    note: new FormControl(null),
    checkbox: new FormControl(false),
    file: new FormControl(null, Validators.required),
    document: new FormControl(null, Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<UserDocumentUploadDialogComponent>,
    private _userDocumentService: UserDocumentsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userId = this.data.userId;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userDocumentForm.patchValue({
        document: file,
      });
    }
  }

  onConfirm(): void {
    this.isUploading = true;
    this.error = undefined;
    this.errorMessage = undefined;
    // Controllo se c'è il titolo
    if (this.userDocumentForm.get('nome')?.value == null) {
      this.errorMessage = 'Inserisci il titolo del documento';
      this.isUploading = false;
      return;
    }
    // Controllo se c'è il file
    if (this.userDocumentForm.get('document')?.value) {
      const file: any = this.userDocumentForm.get('document')?.value;
      const type: any = this.userDocumentForm.get('type')?.value;
      const nome: any = this.userDocumentForm.get('nome')?.value;
      const note: any = this.userDocumentForm.get('note')?.value;
      const formData = new FormData();
      formData.append('type', type);
      formData.append('nome', nome);
      formData.append('checkbox', 'false');
      formData.append('documento', file, '' + new Date().getTime());
      if (note != undefined && note != null) formData.append('note', note);
      // Preparo le callbacks
      const subscription = {
        next: (_: any) => {
          this.dialogRef.close(true);
        },
        error: (error: any) => {
          this.error = new ApiError(error);
          console.error('User Document Upload Error: ', error);
        },
      };
      // Salvo il file
      this._userDocumentService
        .uploadDocument(formData, this.userId)
        .subscribe(subscription)
        .add(() => (this.isUploading = false));
    } else {
      this.errorMessage = 'Seleziona il file da salvare';
      this.isUploading = false;
      return;
    }
    this.isUploading = false;
    return;
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
