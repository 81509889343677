import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { PctoService } from 'src/app/services/pcto.service';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-user-pcto-upload-dialog',
  templateUrl: './user-pcto-upload-dialog.component.html',
  styleUrls: ['./user-pcto-upload-dialog.component.scss'],
})
export class UserPctoUploadDialogComponent {
  type?: string;
  userId?: string;
  error?: ApiError;
  errorMessage?: string;
  isUploading: boolean = false;

  years: string[] = [
    ScholasticYear.getFromCurrent(-2),
    ScholasticYear.getFromCurrent(-1),
    ScholasticYear.getCurrent(),
    ScholasticYear.getFromCurrent(1),
  ];

  userPctoForm = new FormGroup({
    anno_scolastico: new FormControl(
      ScholasticYear.getCurrent(),
      Validators.required
    ),
    ente: new FormControl(null, Validators.required),
    ore: new FormControl(null, Validators.required),
    note: new FormControl(null),
    file: new FormControl(null, Validators.required),
    document: new FormControl(null, Validators.required),
  });

  constructor(
    private _pctoService: PctoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserPctoUploadDialogComponent>
  ) {
    this.type = this.data.type;
    this.userId = this.data.userId;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userPctoForm.patchValue({ document: file });
    }
  }

  onConfirm(): void {
    this.isUploading = true;
    this.error = undefined;
    this.errorMessage = undefined;
    // Controllo se c'è l'anno scolastico
    if (this.userPctoForm.get('anno_scolastico')?.value == null) {
      this.errorMessage =
        "Inserisci l'anno scolastico di riferimento del documento PCTO";
      this.isUploading = false;
      return;
    }
    // Controllo se c'è il numero di ore
    if (this.type == 'PCTO' && this.userPctoForm.get('ore')?.value == null) {
      this.errorMessage = 'Inserisci il numero di ore';
      this.isUploading = false;
      return;
    }
    // Controllo se c'è l'ente
    if (this.userPctoForm.get('ente')?.value == null) {
      this.errorMessage = "Inserisci l'Ente di riferimento del documento PCTO";
      this.isUploading = false;
      return;
    }
    // Controllo se c'è il file
    if (this.userPctoForm.get('document')?.value) {
      const file: any = this.userPctoForm.get('document')?.value;
      const anno_scolastico: any =
        this.userPctoForm.get('anno_scolastico')?.value;
      const ore: any = this.userPctoForm.get('ore')?.value;
      const ente: any = this.userPctoForm.get('ente')?.value;
      const note: any = this.userPctoForm.get('note')?.value;
      const formData = new FormData();
      if (this.type == 'PCTO') formData.append('ore', ore);
      formData.append('ente', ente);
      formData.append('anno_scolastico', anno_scolastico);
      formData.append('documento', file, '' + new Date().getTime());
      if (note != undefined && note != null) formData.append('note', note);
      // Preparo le callbacks
      const subscription = {
        next: (_: any) => {
          this.dialogRef.close(true);
        },
        error: (error: any) => {
          this.error = new ApiError(error);
          console.error('User PCTO Document Upload Error: ', error);
        },
      };
      // Salvo il file
      this.isUploading = true;
      this._pctoService
        .uploadPcto(formData, this.userId, this.type)
        .subscribe(subscription)
        .add(() => (this.isUploading = false));
    } else {
      this.errorMessage = 'Seleziona il file da salvare';
      this.isUploading = false;
      return;
    }
    return;
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
