import { ApiResponse } from '../models/api/api-response.model';
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private _http: HttpClient = inject(HttpClient);
  limit: number = 25;
  page: number = 0;

  getListing() {
    return this._http.get<ApiResponse<any>>(
      API.eventsListing({ page: this.page, limit: this.limit }),
      { withCredentials: true }
    );
  }

  create(jsonData?: any) {
    return this._http.post<ApiResponse<any>>(API.event(), jsonData, {
      withCredentials: true,
    });
  }

  details(id: string) {
    return this._http.get<ApiResponse<any>>(API.event(id), {
      withCredentials: true,
    });
  }

  patch(id: string, jsonData?: any) {
    return this._http.patch<ApiResponse<any>>(API.event(id), jsonData, {
      withCredentials: true,
    });
  }

  delete(id: string) {
    return this._http.delete<ApiResponse<any>>(API.event(id), {
      withCredentials: true,
    });
  }
}
