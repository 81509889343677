import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { DeviceService } from 'src/app/services/device.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Device } from 'src/app/models/tech/device.model';

@Component({
  selector: 'app-dea-device-listing-dialog',
  templateUrl: './dea-device-listing-dialog.component.html',
  styleUrls: ['./dea-device-listing-dialog.component.scss'],
})
export class DeaDeviceListingDialogComponent implements OnInit {
  private _deviceService: DeviceService = inject(DeviceService);

  private text?: string;
  private page: number = 0;
  public pagination?: ApiPagination;
  public isError: boolean = false;
  public loading: boolean = true;
  public devices: Device[] = [];
  public error?: ApiError;

  constructor(
    public dialogRef: MatDialogRef<DeaDeviceListingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  onSelected(id?: string) {
    const selectedDevice: Device[] = id
      ? this.devices.filter((device) => device.id === id)
      : [];
    if (selectedDevice.length != 0) this.dialogRef.close(selectedDevice[0]);
    else this.dialogRef.close();
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._deviceService
      .getListing({
        limit: 10,
        txt: this.text,
        page: this.page || 0,
      })
      .subscribe({
        next: (data: any) => {
          if (data.data && (data.data as Device[])) {
            this.devices = data.data.map((device: any) => new Device(device));
          }
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error: any) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Device Listing Error => ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
