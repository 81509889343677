import { DeaTmpCredentialsDialogComponent } from '../dea-pack/dea-tmp-credentials-dialog/dea-tmp-credentials-dialog.component';
import { DeaSingleBtnDialogComponent } from '../dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { DeaConfirmDialogComponent } from '../dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

interface UserDetailsTabGroup {
  anagrafica: boolean;
  ritardi: boolean;
  documenti: boolean;
  tech: boolean;
  moduli: boolean;
  pcto: boolean;
  classi: boolean;
  timetable: boolean;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @Output() userIdEmitter = new EventEmitter<string>();
  public userData?: User;
  public userId?: string;

  private _authService: AuthService = inject(AuthService);
  private _dialog: MatDialog = inject(MatDialog);

  tabGroupStatus: UserDetailsTabGroup = {
    anagrafica: true,
    ritardi: false,
    documenti: false,
    tech: false,
    moduli: false,
    pcto: false,
    classi: false,
    timetable: false,
  };

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.userId = params['id'];
    });
  }

  onUserData(user: User) {
    this.userData = user;
  }

  tabClick(event: any) {
    switch (event?.tab?.textLabel?.toString().toLowerCase().trim()) {
      case 'anagrafica':
        if (!this.tabGroupStatus.anagrafica) {
          this.tabGroupStatus.anagrafica = true;
        }
        break;
      case 'ritardi':
        if (!this.tabGroupStatus.ritardi) {
          this.tabGroupStatus.ritardi = true;
        }
        break;
      case 'documenti':
        if (!this.tabGroupStatus.documenti) {
          this.tabGroupStatus.documenti = true;
        }
        break;
      case 'tech':
        if (!this.tabGroupStatus.tech) {
          this.tabGroupStatus.tech = true;
        }
        break;
      case 'moduli':
        if (!this.tabGroupStatus.moduli) {
          this.tabGroupStatus.moduli = true;
        }
        break;
      case 'pcto':
        if (!this.tabGroupStatus.pcto) {
          this.tabGroupStatus.pcto = true;
        }
        break;
      case 'classi':
        if (!this.tabGroupStatus.classi) {
          this.tabGroupStatus.classi = true;
        }
        break;
      case 'quadro orario':
        if (!this.tabGroupStatus.timetable) {
          this.tabGroupStatus.timetable = true;
        }
        break;
      default:
        console.error('Error: Invalid tab name!');
        break;
    }
  }

  async createTempCredentials() {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        message:
          'Confermi di voler creare delle nuove credenziali di accesso temporanee per questo utente?',
        details: "(Sarà comunque inviata la mail all'utente per informarlo)",
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, crea',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe(async (dialogResult: boolean) => {
      if (dialogResult === true) {
        const userEmails: string[] = [];
        for (
          let i = 0;
          i < (this.userData?.contatti?.email ?? []).length;
          i++
        ) {
          if (this.userData?.contatti?.email?.at(i)?.address !== undefined) {
            userEmails.push(
              this.userData?.contatti?.email?.at(i)?.address ?? 'EMAIL-ERROR'
            );
          }
        }

        if (userEmails?.length === 0) {
          this._dialog.open(DeaSingleBtnDialogComponent, {
            maxWidth: '450px',
            minWidth: '450px',
            data: {
              error: new ApiError({
                code: 400,
                title: 'Bad Request',
                message:
                  'Non è possibile richiedere le credenziali temporanee per un utente che non ha nessun indirizzo email associato al proprio profilo',
              }),
            },
            autoFocus: false,
            restoreFocus: false,
          });
        } else {
          const getTmpCredentials = (email: string) => {
            return new Promise(async (resolve) => {
              this._authService.tmpCredentials(email, true).subscribe({
                next: (response) => {
                  if (
                    response.data.email !== undefined &&
                    response.data.tmpPassword !== undefined
                  )
                    return resolve(response.data);
                  else return resolve(undefined);
                },
                error: (err) => {
                  console.error('tmpCredentials Error:', err);
                  return resolve(undefined);
                },
              });
            });
          };
          let tmpCredentials: any = {};
          for (let i = 0; i < userEmails.length; i++) {
            const result = await getTmpCredentials(userEmails[i]);
            if (result !== undefined) {
              tmpCredentials = result;
              break;
            }
          }
          if (tmpCredentials) {
            this._dialog.open(DeaTmpCredentialsDialogComponent, {
              maxWidth: '500px',
              minWidth: '500px',
              data: tmpCredentials,
              autoFocus: false,
              restoreFocus: false,
            });
          }
        }
      }
    });
  }
}
