<div *ngIf="resources.length != 0">
  <app-dea-label [label]="label"></app-dea-label>
  <div class="v-spacer"></div>
  <div class="row row-cols-4 m-0 p-0" style="display: flex; gap: 8px !important;">
    <div *ngFor="let resource of resources; let index = index;" title="{{resource.fullname()}}"
      class="col p-3 bg-white rounded pointer" style="max-width: calc((100.0% - 24.0px) / 4.0) !important;">
      <div class="d-flex flex-row gap-2 w-100">
        <i class=" {{resource.getIconClasses()}}" (click)="onClick(index)"></i>
        <div class="w-100" title="{{resource.fullname()}}" (click)="onClick(index)"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{resource.name}}
        </div>
        <div *ngIf="!resource.isSystemFolder()" class="ms-auto dropdown m-0 p-0">
          <i class="bi bi-three-dots-vertical dea-icon-btn {{resource.id !== downloadingId ? '':'d-none'}}"
            data-bs-toggle="dropdown" title="Apri Menu">
          </i>
          <mat-icon *ngIf="resource.id == downloadingId" class="d-flex justify-content-end align-items-center w-100">
            <mat-spinner diameter="18"></mat-spinner>
          </mat-icon>
          <ul class="dropdown-menu">
            <li *ngIf="!resource.isFolder()" title="Scarica risorsa" (click)="onClick(index)">
              <button class="dropdown-item" type="button"><i class="bi bi-cloud-download me-2"></i>Download</button>
            </li>
            <!-- <li title="Rinomina risorsa">
              <button class="dropdown-item" type="button"><i class="bi bi-pencil me-2"></i>Rinomina</button>
            </li> -->
            <li title="Elimina risorsa" (click)="onDelete(resource.id)">
              <button class="dropdown-item" type="button"><i class="bi bi-trash3 me-2"></i>Elimina</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-1 d-flex flex-row justify-content-between gap-2 w-100" (click)="onClick(index)">
        <div class="fs-055r opacity-50">{{resource.createdTime | toItDateDdMmmmYyyy}}</div>
        <div *ngIf="resource.size" class="fs-055r opacity-50">{{resource.size | filesize}}</div>
      </div>
    </div>
  </div>
</div>