import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class TechService {
  constructor(private http: HttpClient) {}

  getListing(userId?: string) {
    return this.http.get<ApiResponse<any>>(API.techListing(userId), {
      withCredentials: true,
    });
  }

  getAggregateListing(userId?: string) {
    return this.http.get<ApiResponse<any>>(API.techAggregateListing(userId), {
      withCredentials: true,
    });
  }

  getTechBlockDetails(docId?: string) {
    return this.http.get<ApiResponse<any>>(API.techBlock(docId), {
      withCredentials: true,
    });
  }

  deleteTechBlock(docId?: string) {
    return this.http.delete<ApiResponse<any>>(API.techBlock(docId), {
      withCredentials: true,
    });
  }

  getDocument(docId?: string) {
    return this.http.get(API.techDocument(docId), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  deleteDocument(docId?: string) {
    return this.http.delete(API.techDocument(docId), {
      withCredentials: true,
    });
  }

  // ASSIGNMENT
  postAssignment(data: { owner: string; device: string }) {
    return this.http.post<ApiResponse<any>>(
      API.techUserPost(data.owner, 'assignment'),
      data,
      {
        withCredentials: true,
      }
    );
  }

  // INSURANCE
  postInsurance(formData?: FormData, userId?: string) {
    return this.http.post(API.techUserPost(userId, 'insurance'), formData, {
      withCredentials: true,
    });
  }

  patchInsurance(formData?: FormData, blockId?: string) {
    return this.http.patch(API.techPatch(blockId, 'insurance'), formData, {
      withCredentials: true,
    });
  }

  // LOSS
  postLoss(formData?: FormData, userId?: string) {
    return this.http.post(API.techUserPost(userId, 'loss'), formData, {
      withCredentials: true,
    });
  }

  patchLoss(formData?: FormData, blockId?: string) {
    return this.http.patch(API.techPatch(blockId, 'loss'), formData, {
      withCredentials: true,
    });
  }

  // BREAKING
  postBreaking(data?: { owner?: string }) {
    return this.http.post<ApiResponse<any>>(
      API.techUserPost(data?.owner, 'breaking'),
      data,
      {
        withCredentials: true,
      }
    );
  }

  patchBreaking(blockId?: string, data?: { owner?: string }) {
    return this.http.patch<ApiResponse<any>>(
      API.techPatch(blockId, 'breaking'),
      data,
      {
        withCredentials: true,
      }
    );
  }

  // RETURN
  postReturn(data?: { owner?: string }) {
    return this.http.post<ApiResponse<any>>(
      API.techUserPost(data?.owner, 'return'),
      data,
      {
        withCredentials: true,
      }
    );
  }

  patchReturn(blockId?: string, data?: { owner?: string }) {
    return this.http.patch<ApiResponse<any>>(
      API.techPatch(blockId, 'return'),
      data,
      {
        withCredentials: true,
      }
    );
  }

  // RELEASE
  postRelease(formData?: FormData, userId?: string) {
    return this.http.post(API.techUserPost(userId, 'release'), formData, {
      withCredentials: true,
    });
  }

  patchRelease(formData?: FormData, blockId?: string) {
    return this.http.patch(API.techPatch(blockId, 'release'), formData, {
      withCredentials: true,
    });
  }
}
