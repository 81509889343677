import { Feature } from 'src/app/models/feature.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feature-row',
  templateUrl: './feature-row.component.html',
  styleUrls: ['./feature-row.component.scss'],
})
export class FeatureRowComponent {
  @Input() feature?: Feature;
}
