import { TimetableBlocksSlotsDialogComponent } from '../../calendar/timetable-blocks-slots-dialog/timetable-blocks-slots-dialog.component';
import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { CalendarEventTypes } from 'src/app/models/calendar/calendar-event-types.enum';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { ClassesService } from 'src/app/services/classes.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { Class } from 'src/app/models/class/class.model';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import {
  CalendarEventFormInterface,
  CalendarEventFormDialogComponent,
} from 'src/app/components/calendar/calendar-event-form-dialog/calendar-event-form-dialog.component';
import {
  CalendarEvent,
  CalendarEventRecurrence,
} from 'src/app/models/calendar/calendar-event.model';
import {
  CalendarService,
  CalendarTimetableGridConfig,
} from 'src/app/services/calendar.service';
import {
  Input,
  OnInit,
  inject,
  OnChanges,
  Component,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-dea-timetable',
  templateUrl: './dea-timetable.component.html',
  styleUrl: './dea-timetable.component.scss',
})
export class DeaTimetableComponent implements OnInit, OnChanges {
  private _calendarService: CalendarService = inject(CalendarService);
  private _classesService: ClassesService = inject(ClassesService);
  private _userService: UserService = inject(UserService);
  private _dialog: MatDialog = inject(MatDialog);
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() classId?: string = undefined;
  @Input() teacherId?: string = undefined;
  @Input() startLoading: boolean = false;

  public weekDays: string[] = [];

  mainDate: moment.Moment = moment();
  today: moment.Moment = moment();

  timetableEvents: CalendarEvent[] = [];

  public minHeightPxValue: number = 1.5; // Valore in PX di 1 Minuto;

  get timetableEventType() {
    return CalendarEventTypes.TIMETABLE_EVENT;
  }

  get timetableConfigsHeight(): number {
    return (
      (this._calendarService.timetableGridConfigs?.time.minutes ?? 0) *
      this.minHeightPxValue
    );
  }

  get minutesConfigsValue(): number {
    return this._calendarService.timetableGridConfigs?.time.minutes ?? 0;
  }

  get timetableConfigs(): CalendarTimetableGridConfig | undefined {
    return this._calendarService.timetableGridConfigs;
  }

  ngOnInit(): void {
    moment.updateLocale('it', { week: { dow: 1 } });
    this.weekDays = moment.weekdaysShort(true);
    // this.dayHours = Array.from({ length: 24 }, (_, i) => `${i}:00`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading']?.currentValue &&
      !changes['startLoading']?.previousValue
    ) {
      moment.updateLocale('it', { week: { dow: 1 } });
      this.weekDays = moment.weekdaysShort(true);
      this.mainDate = moment();
      this.today = moment();
      this.getData();
    }
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    if (this.teacherId !== undefined && this.classId !== undefined) {
      this.loading = false;
      this.isError = true;
      return;
    }
    this._calendarService
      .timetable({
        classId: this.classId,
        teacherId: this.teacherId,
        from: moment().startOf('isoWeek'),
        to: moment().endOf('isoWeek'),
      })
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.timetableEvents = response.data.map(
              (json: any) => new CalendarEvent(json)
            );
            console.info('TIMETABLE-EVENTS:', this.timetableEvents);
            console.info(
              'REDUCE:',
              this.groupTimetableByDateTime(this.timetableEvents)
            );
          }
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Timetable Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  groupTimetableByDateTime(
    events: CalendarEvent[]
  ): Record<string, CalendarEvent[]> {
    let keys = false;
    const gruppiEventi = events.reduce((acc, evento) => {
      if (!keys) {
        for (let i = 0; i < 7; i++) acc[i] = [];
        keys = true;
      }
      if (evento.start === undefined || evento.tz === undefined) return acc;
      // Converte la data nel fuso orario specificato e ottiene il giorno della settimana
      const giornoSettimana = moment.tz(evento.start, evento.tz).day();

      // Se la chiave non esiste ancora nel gruppo, creala
      if (!acc[giornoSettimana]) {
        acc[giornoSettimana] = [];
      }

      // Aggiungi l'evento al gruppo corrispondente
      acc[giornoSettimana].push(evento);

      return acc;
    }, {} as Record<string, CalendarEvent[]>);

    // Ordina gli eventi cronologicamente all'interno di ogni giorno della settimana
    Object.keys(gruppiEventi).forEach((giorno) => {
      gruppiEventi[giorno].sort((a, b) => {
        return moment(a.start).diff(moment(b.start));
      });
    });

    return gruppiEventi;
  }

  getBlockColumnShift(event: CalendarEvent): number {
    if (event.start === undefined) return -1;
    const dayOfWeek = event.start.day();
    // Mappa i giorni della settimana a quelli richiesti
    const dayMapping: { [key: number]: number } = {
      0: 6, // Domenica diventa 6
      1: 0, // Lunedì diventa 0
      2: 1, // Martedì diventa 1
      3: 2, // Mercoledì diventa 2
      4: 3, // Giovedì diventa 3
      5: 4, // Venerdì diventa 4
      6: 5, // Sabato diventa 5
    };

    return dayMapping[dayOfWeek];
  }

  getBlockTopShift(event: CalendarEvent): number {
    if (!event.start || !event.tz) {
      // Gestisci il caso in cui `event.start` o `event.tz` non siano definiti
      return 0; // Oppure un valore che indica un errore o una condizione speciale
    }

    // Ottieni l'orario dell'evento come momento nel fuso orario specificato
    const eventMoment = moment.tz(event.start, event.tz);

    // Creo una data per la gionata dell'evento ma all'orario minimo della configurazione
    const minDate = eventMoment
      .clone()
      .startOf('day')
      .add(
        this._calendarService.timetableGridConfigs?.time.min.split(':')[0] ??
          '08',
        'hours'
      )
      .add(
        this._calendarService.timetableGridConfigs?.time.min.split(':')[1] ??
          '10',
        'minutes'
      );

    // Calcola la differenza in minuti tra l'orario dell'evento e la minDate
    const diffInMinutes = eventMoment.diff(minDate, 'minutes');

    return diffInMinutes;
  }

  getEventHTMLTitle(event: CalendarEvent) {
    let str = `${event.timetableBlock}`;
    this.teacherId !== undefined &&
    event.classes !== undefined &&
    event.classes.length > 0
      ? (str += ` ${event.classes?.map((cl) => cl.getName()).join(', ')}`)
      : (str += ` ${event.title}`);
    return str.trim();
  }

  showTimetableBlockDialog(data?: CalendarEventFormInterface) {
    const timetableDialog = this._dialog.open(
      CalendarEventFormDialogComponent,
      {
        maxWidth: '600px',
        minWidth: '600px',
        data: data,
        autoFocus: false,
        restoreFocus: false,
      }
    );

    timetableDialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === true) this.getData();
    });
  }

  showTimetableBlocksSlotsDialog() {
    const timetableBlocksSlotsDialog = this._dialog.open(
      TimetableBlocksSlotsDialogComponent,
      {
        maxWidth: '600px',
        minWidth: '600px',
        autoFocus: false,
        restoreFocus: false,
      }
    );

    timetableBlocksSlotsDialog.afterClosed().subscribe((dialogResult: any) => {
      if (
        dialogResult.id !== undefined &&
        dialogResult.from !== undefined &&
        dialogResult.to !== undefined
      ) {
        const defaultDay = {
          0: 6,
          1: 1,
          2: 2,
          3: 3,
          4: 4,
          5: 5,
          6: 0,
        }[moment().day()];
        const daysMap: any = {
          0: 6,
          1: 0,
          2: 1,
          3: 2,
          4: 3,
          5: 4,
          6: 5,
        };
        const day = dialogResult.day ? daysMap[dialogResult.day] : defaultDay;
        this.showPreConfigTimetableBlockDialog(day, dialogResult);
      }
    });
  }

  async showPreConfigTimetableBlockDialog(
    day: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    block: { id: string; from: string; to: string }
  ) {
    // Configs per la ricorrenza
    const daysMap: any = {
      0: 'MO',
      1: 'TU',
      2: 'WE',
      3: 'TH',
      4: 'FR',
      5: 'SA',
      6: 'SU',
    };
    // Configs per le date
    const startOfWeek = moment()
      .startOf('isoWeek')
      .startOf('day')
      .add(day, 'days');
    const startDate = moment.tz(
      startOfWeek.format('YYYY-MM-DD') + 'T' + block.from,
      'YYYY-MM-DDTHH:mm',
      'Europe/Rome'
    );
    const endDate = moment.tz(
      startDate.format('YYYY-MM-DD') + 'T' + block.to,
      'YYYY-MM-DDTHH:mm',
      'Europe/Rome'
    );

    // Recupero i dati necessari per preconfigurare il form (docente o classe);
    const getUserOrClassData = async () => {
      return new Promise<Class | User | undefined>((resolve) => {
        if (this.teacherId) {
          this._userService.getDetail(this.teacherId).subscribe({
            next: (response) => resolve(new User(response.data)),
            error: (err) => console.error('GetData Error:', err),
          });
        } else if (this.classId) {
          this._classesService.getDetail(this.classId).subscribe({
            next: (response) => resolve(new Class(response.data)),
            error: (err) => console.error('GetData Error:', err),
          });
        }
      });
    };
    const data: User | Class | undefined = await getUserOrClassData();

    const diffMinutes: number = endDate.diff(startDate, 'minutes');
    // Assemblo le configurazioni
    let configs: CalendarEventFormInterface = {
      title: diffMinutes <= 15 ? 'Intervallo' : 'Lezione',
      start: startDate,
      end: endDate,
      recurrence: new CalendarEventRecurrence({
        interval: 1,
        frequency: 'WEEKLY',
        byDay: [daysMap[day]],
        endDate: ScholasticYear.getCurrentEndDate(),
      }),
      teachers: data instanceof User ? [data] : undefined,
      classes: data instanceof Class ? [data] : undefined,
      timetableBlock: diffMinutes <= 15 ? 'SORVEGLIANZA' : 'LEZIONE',
      newEventType: CalendarEventTypes.TIMETABLE_EVENT,
    };

    this.showTimetableBlockDialog(configs);
  }

  deleteTimetableBlock(eventId?: string) {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questo blocco?',
        details: 'Attenzione: non sarà più possibile ripristinarlo',
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult && eventId != undefined && eventId != '') {
        this._calendarService.deleteEvent(eventId).subscribe({
          next: (_: any) => this.getData(),
          error: (error: any) =>
            console.error('Delete Timetable-Event Error: ', error),
        });
      }
    });
  }

  public getTextColor(hexColor: string): string {
    // Rimuove il simbolo # se presente
    hexColor = hexColor.replace('#', '');
    // Converte HEX in RGB
    var r = parseInt(hexColor.substring(0, 2), 16);
    var g = parseInt(hexColor.substring(2, 4), 16);
    var b = parseInt(hexColor.substring(4, 6), 16);
    // Converte RGB in valori su scala da 0 a 1
    r /= 255;
    g /= 255;
    b /= 255;
    // Calcola la luminanza relativa
    var luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // Se la luminanza è minore o uguale a 0.5, restituisci il bianco
    return luminance > 0.6 ? '#000000' : '#FFFFFF';
  }

  getLineTopShift(block: { id: string; from: string; to: string }): number {
    if (!block.from) return 0;

    const fromDate = moment()
      .startOf('day')
      .add(block.from.split(':')[0], 'hours')
      .add(block.from.split(':')[1] ?? '10', 'minutes');

    // Creo una data per la gionata dell'evento ma all'orario minimo della configurazione
    const minDate = moment()
      .startOf('day')
      .add(
        this._calendarService.timetableGridConfigs?.time.min.split(':')[0] ??
          '08',
        'hours'
      )
      .add(
        this._calendarService.timetableGridConfigs?.time.min.split(':')[1] ??
          '10',
        'minutes'
      );

    // Calcola la differenza in minuti tra l'orario dell'evento e la minDate
    const diffInMinutes = fromDate.diff(minDate, 'minutes');

    return diffInMinutes;
  }

  getLineHeight(block: { id: string; from: string; to: string }): number {
    if (!block.from || !block.to) return 0;
    const fromDate = moment()
      .startOf('day')
      .add(block.from.split(':')[0], 'hours')
      .add(block.from.split(':')[1], 'minutes');
    const toDate = moment()
      .startOf('day')
      .add(block.to.split(':')[0], 'hours')
      .add(block.to.split(':')[1], 'minutes');
    const diffInMinutes = toDate.diff(fromDate, 'minutes');
    return diffInMinutes * this.minHeightPxValue;
  }
}
