import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeaDropdownMenuEventData } from 'src/app/constants/interfaces';

@Component({
  selector: 'app-dea-dropdown-menu',
  templateUrl: './dea-dropdown-menu.component.html',
  styleUrls: ['./dea-dropdown-menu.component.scss'],
})
export class DeaDropdownMenuComponent {
  @Output() ddmEventEmitter = new EventEmitter<DeaDropdownMenuEventData>();
  @Input() dropdownMenuId!: string;
  @Input() selectedItem: any;
  @Input() items: any[] = [];

  @Input() menuIsDisabled: boolean = false;

  onClick(index: number) {
    this.ddmEventEmitter.emit({ id: this.dropdownMenuId, index });
  }
}
