<mat-card fxLayoutGap class="p-3">
  <div class="fw-semibold fs-4 mb-2">Carica Documento</div>
  <div class="v-spacer"></div>
  <div class="row m-0 p-0">
    <form [formGroup]="userDocumentForm" enctype="multipart/form-data">
      <app-dea-label class="mb-2" [label]="'Tipo:'"></app-dea-label>
      <mat-radio-group aria-label="Seleziona il tipo di documento" color="primary" class="row m-0 p-0"
        formControlName="type">
        <mat-radio-button class="col-6 m-0 p-0 mp-3" value="DOCUMENT">
          Documento
        </mat-radio-button>
        <mat-radio-button class="col-6 m-0 p-0 mp-3" value="BES-ATTACHMENT">
          Allegato BES
        </mat-radio-button>
        <mat-radio-button class="col-6 m-0 p-0 mp-3" value="SPORT-ATTACHMENT">
          Allegato Atleta
        </mat-radio-button>
        <mat-radio-button class="col-6 m-0 p-0 mp-3" value="CERTIFICATE">
          Attestato
        </mat-radio-button>
      </mat-radio-group>
      <input type="text" formControlName="nome" class="form-control dea-form-input mb-3" placeholder="Titolo documento"
        toTitleCase />
      <input type="text" formControlName="note" class="form-control dea-form-input mb-3"
        placeholder="Note per documento" />
      <input type="file" formControlName="file" class="form-control dea-form-input mb-1" placeholder="Seleziona un file"
        accept="image/png,image/jpeg,.pdf" (change)="onFileChange($event)" />
      <div class="fw-lighter mb-3" style="font-size: 0.5rem;">
        Formati accettati: PDF - PNG/JPG/JPEG | Dimensione Masima: 48Mb x 1
      </div>
      <div class="mb-3 text-danger">{{error?.message || errorMessage}}</div>
    </form>
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">Annulla</button>
    <button *ngIf="!isUploading" class="px-5 py-1" mat-flat-button color="primary" (click)="onConfirm()">
      Carica
    </button>
    <button *ngIf="isUploading" class="px-5 py-1" mat-flat-button disabled>
      <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Caricamento
    </button>
  </div>
</mat-card>
