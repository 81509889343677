import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import isMongoId from 'validator/es/lib/isMongoId';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent {
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _location: Location = inject(Location);
  public eventId?: string;

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.eventId = params['id'];
      if (!this.eventId || !isMongoId(this.eventId)) this._location.back();
    });
  }
}
