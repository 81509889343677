import { CalendarService } from 'src/app/services/calendar.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, inject } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-timetable-blocks-slots-dialog',
  templateUrl: './timetable-blocks-slots-dialog.component.html',
  styleUrl: './timetable-blocks-slots-dialog.component.scss',
})
export class TimetableBlocksSlotsDialogComponent {
  private _calendarService: CalendarService = inject(CalendarService);
  dayMaps: any = {
    0: 'SU',
    1: 'MO',
    2: 'TU',
    3: 'WE',
    4: 'TH',
    5: 'FR',
    6: 'SA',
  };

  formGroup: FormGroup = new FormGroup({
    day: new FormControl(moment().day()),
    slotComboId: new FormControl(),
  });

  get timetableConfigs() {
    return this._calendarService.timetableGridConfigs;
  }

  constructor(
    public dialogRef: MatDialogRef<TimetableBlocksSlotsDialogComponent>
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }

  next() {
    const slotComboId = this.formGroup.get('slotComboId')?.value;
    if (slotComboId != undefined) {
      const blocks = this.timetableConfigs?.blocks.filter((b) => {
        return (
          b.id === slotComboId.split('-').at(0) &&
          b.from === slotComboId.split('-').at(1) &&
          b.to === slotComboId.split('-').at(2)
        );
      });
      if (blocks?.length === 1)
        this.dialogRef.close(
          Object.assign({}, blocks.at(0), {
            day: this.formGroup.get('day')?.value,
          })
        );
    }
  }
}
