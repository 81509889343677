import { DeaConfirmDialogData } from 'src/app/constants/dialog-interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dea-confirm-dialog',
  templateUrl: './dea-confirm-dialog.component.html',
  styleUrls: ['./dea-confirm-dialog.component.scss'],
})
export class DeaConfirmDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<DeaConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaConfirmDialogData
  ) {}

  onConfirm(): void {
    this._dialogRef.close(true);
  }

  onDismiss(): void {
    this._dialogRef.close(false);
  }
}
