import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-user-relationships',
  templateUrl: './user-relationships.component.html',
  styleUrls: ['./user-relationships.component.scss'],
})
export class UserRelationshipsComponent {
  @Input() users?: User[];
  @Input() label?: string;
}
