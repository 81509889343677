export class Subject {
  id?: string;
  dea_id?: string;
  nome?: string;
  descrizione?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.dea_id = json['dea_id'];
    this.nome = json['nome'];
    this.descrizione = json['descrizione'];
  }
}
