import { DeaConfirmDialogData } from 'src/app/constants/dialog-interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-dea-user-confirm-dialog',
  templateUrl: './dea-user-confirm-dialog.component.html',
  styleUrls: ['./dea-user-confirm-dialog.component.scss'],
})
export class DeaUserConfirmDialogComponent {
  public avatarUrl = API.thumbnail(1);

  constructor(
    public dialogRef: MatDialogRef<DeaUserConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaConfirmDialogData
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
