<div class="d-flex">
  <div>
    <!-- FULL NAME -->
    <div class="ms-3 hstack mb-4 gap-3">
      <app-data-box [label]="'Cognome'" [data]="user?.cognome" [boldData]="true"></app-data-box>
      <app-data-box [label]="'Nome'" [data]="user?.nome" [boldData]="true"></app-data-box>
      <app-data-box [label]="'Secondo Nome'" [data]="user?.secondo_nome" [boldData]="true"></app-data-box>
    </div>
    <!-- DATA -->
    <div *ngIf="showFullData" class="ms-3 hstack mb-4 gap-3">
      <app-data-box [label]="'Codice Fiscale'" [data]="user?.codice_fiscale"></app-data-box>
      <app-data-box *ngIf="user?.hasClass()" [label]="'Classe'" [data]="user?.classe?.getName()"
        title="Copia: {{user?.classe?.getName()}} • Doppio click: Visualizza la classe"
        (doubleClick)="goToClass(user?.classe?.id)">
        <i *ngIf="isRappresentanteDiClasse()" class="bi bi-patch-check-fill ms-2 opacity-50"
          title="Rappresentante di Classe"></i>
      </app-data-box>
    </div>
    <!-- CONTACTS -->
    <div class="ms-3 hstack gap-3 d-flex align-items-start">
      <div>
        <div class="mb-4" *ngFor="let phoneContact of user?.contatti?.phone;">
          <app-phone-box [data]="phoneContact"></app-phone-box>
        </div>
        <div class="mb-4" *ngIf="user?.contatti?.phone?.length == 0;">
          <app-phone-box [data]=""></app-phone-box>
        </div>
      </div>
      <div>
        <div class="mb-4" *ngFor="let emailContact of user?.contatti?.email;">
          <app-email-box [data]="emailContact"></app-email-box>
        </div>
        <div class="mb-4" *ngIf="user?.contatti?.email?.length == 0;">
          <app-email-box [data]=""></app-email-box>
        </div>
      </div>
    </div>
    <!-- BES / REL -->
    <div *ngIf="user?.isStudent() && showTags">
      <div class="my-4 ms-3 hstack gap-3 d-flex align-items-start">
        <app-dea-chip-status [label]="'BES'" [status]="user?.bisogni_educativi_speciali?.enable"
          [clickHandler]="openBesDetailsDialog">
        </app-dea-chip-status>
        <app-dea-chip-status [label]="'REL'" [status]="user?.religione"></app-dea-chip-status>
        <app-dea-chip-status [label]="'ATL'" [status]="user?.atleta?.enable"
          [clickHandler]="openAtlDetailsDialog"></app-dea-chip-status>
      </div>
    </div>
    <!-- SUBJECTS -->
    <div *ngIf="user?.isTeacher()">
      <!-- <app-details-divider></app-details-divider> -->
      <div class="mt-4 ms-3 hstack gap-3 d-flex align-items-start">
        <div class="mb-4 rounded-pill shadow-sm px-4 py-2 mx-0 me-2" *ngFor="let subject of user?.materie;">
          {{subject.nome}}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showArrow" class="my-auto">
    <!-- GO TO DETAIL -->
    <button id="user-detail-arrow-btn" mat-icon-button aria-label="Dettagli Utente"
      title="Dettagli {{user?.getFullName()}}" routerLink="/utente/{{user?.id}}">
      <mat-icon class="pe-1 mb-1" aria-hidden="false" aria-label="Dettagli Utente"
        fontIcon="arrow_forward_ios"></mat-icon>
    </button>
  </div>
</div>
