import { DocumentForm } from 'src/app/models/documents-forms/document-form.model';
import { Component, Input, OnInit } from '@angular/core';
import { AppPaths } from 'src/app/constants/app-paths';
import { Router } from '@angular/router';

@Component({
  selector: 'app-document-form-cell',
  templateUrl: './document-form-cell.component.html',
  styleUrls: ['./document-form-cell.component.scss'],
})
export class DocumentFormCellComponent implements OnInit {
  @Input() visibility: boolean = true;
  public firstTitle?: string;
  public lastTitle?: string;
  public id?: string;

  @Input() form?: any | DocumentForm;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    if (this.form && (this.form as DocumentForm)) {
      const realForm: DocumentForm = this.form;
      this.id = realForm.dea_id;
      try {
        let splittedTitle: string[] = realForm.title?.split(' ') || [];
        this.firstTitle = splittedTitle[0] + ' ' + splittedTitle[1];
        delete splittedTitle[0];
        delete splittedTitle[1];
        this.lastTitle = splittedTitle.join(' ');
      } catch (error) {
        this.firstTitle = realForm.title;
      }
    }
  }

  goToModuloListing(): void {
    if (this.id) {
      this._router.navigateByUrl(AppPaths.MODULO_LISTING + '/' + this.id);
    }
  }
}
