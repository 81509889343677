import { Self, Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[toLowerCase]',
})
export class InputToLowerCaseDirective {
  constructor(@Self() private input: NgControl) {}

  @HostListener('input', ['$event']) onInput($event: InputEvent) {
    const target = $event.target as HTMLInputElement;
    const value = target.value;
    const result = value.toLowerCase();
    this.input?.control?.setValue(result);
  }
}
