<app-dea-widget-wrapper>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="error !== undefined; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="d-flex flex-row align-items-center gap-2">
        <div>HASH: <span class="fw-bold">{{this.enrollment?.shortHash}}</span></div>
        <div class="fw-thin fs-065r opacity-50">[{{this.enrollment?.id}}]</div>
      </div>
      <div class="v-spacer"></div>
      <form [formGroup]="enrollmentFormService.enrollmentForm" class="two-columns-grid">
        <div>
          <app-dea-label [label]="'Sede:'"></app-dea-label>
          <mat-radio-group formControlName="sede" color="primary" class="d-flex flex-row gap-3">
            <mat-radio-button value="MILANO">Milano</mat-radio-button>
            <mat-radio-button value="GORGONZOLA">Gorgonzola</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <app-dea-label [label]="'Anno Scolastico:'"></app-dea-label>
          <mat-radio-group formControlName="anno_scolastico" color="primary">
            <mat-radio-button
              [value]="enrollmentFormService.enrollmentForm.get('anno_scolastico')?.value ?? ''">{{enrollmentFormService.enrollmentForm.get('anno_scolastico')?.value ?? ''}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <app-dea-label [label]="'Indirizzo di studi:'"></app-dea-label>
          <mat-form-field class="w-100">
            <mat-select formControlName="dipartimento">
              <mat-option value="Secondaria di I Grado">Secondaria di I Grado</mat-option>
              <mat-option value="Liceo delle Scienze Umane">Liceo delle Scienze Umane</mat-option>
              <mat-option value="Liceo Scientifico">Liceo Scientifico</mat-option>
              <mat-option value="Liceo Scientifico Sportivo">Liceo Scientifico Sportivo</mat-option>
              <mat-option value="Liceo Linguistico Internazionale">Liceo Linguistico Internazionale</mat-option>
              <mat-option value="Liceo Classico">Liceo Classico</mat-option>
              <mat-option value="ITEM">ITEM</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <app-dea-label [label]="'Anno:'"></app-dea-label>
          <mat-radio-group formControlName="anno" color="primary" class="d-flex flex-row gap-3">
            <mat-radio-button value="1">1°</mat-radio-button>
            <mat-radio-button value="2">2°</mat-radio-button>
            <mat-radio-button value="3">3°</mat-radio-button>
            <mat-radio-button value="4">4°</mat-radio-button>
            <mat-radio-button value="5">5°</mat-radio-button>
          </mat-radio-group>
          <div class="opacity-50 fs-075r">
            Attenzione: tutti i controlli relativi all'indirizzo selezionato sono disattivati
          </div>
        </div>
        <div>
          <app-dea-label [label]="'Lingue:'"></app-dea-label>
          <div class="d-flex flex-row gap-3">
            <mat-radio-group formControlName="prima_lingua" color="primary" class="d-flex flex-row gap-3">
              <mat-radio-button value="Inglese">Inglese</mat-radio-button>
            </mat-radio-group>
            <mat-checkbox color="primary" [checked]="checkLanguage('Francese')" (change)="languageChanged('Francese')">
              Francese
            </mat-checkbox>
            <mat-checkbox color="primary" [checked]="checkLanguage('Tedesco')" (change)="languageChanged('Tedesco')">
              Tedesco
            </mat-checkbox>
            <mat-checkbox color="primary" [checked]="checkLanguage('Spagnolo')" (change)="languageChanged('Spagnolo')">
              Spagnolo
            </mat-checkbox>
          </div>
          <div class="opacity-50 fs-075r">
            Attenzione: tutti i controlli relativi all'indirizzo selezionato sono disattivati
          </div>
        </div>
        <div class="two-columns-grid">
          <div>
            <app-dea-label [label]="'Ora di religione:'"></app-dea-label>
            <mat-radio-group formControlName="religione" color="primary" class="d-flex flex-row gap-3">
              <mat-radio-button value="true">Sì</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <app-dea-label [label]="'DeaSport:'"></app-dea-label>
            <mat-radio-group formControlName="dea_sport" color="primary" class="d-flex flex-row gap-3">
              <mat-radio-button value="true">Accetto</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="border dea-radius col-span-2 two-columns-grid p-3" formGroupName="ultimo_anno">
          <div class="col-span-2">
            Informazioni relative all'anno scolastico e Istituto nei quali lo studente ha frequentato l'ultima classe o
            sostenuto esami:
          </div>
          <div>
            <app-dea-label [label]="'Nome Istituto:'"></app-dea-label>
            <input formControlName="istituto" type="text" class="form-control dea-form-input" />
          </div>
          <div>
            <app-dea-label [label]="'Anno scolastico:'"></app-dea-label>
            <input formControlName="anno_scolastico" type="text" class="form-control dea-form-input" />
          </div>
          <div class="col-span-2">
            <app-dea-label [label]="'Opzioni:'"></app-dea-label>
            <mat-radio-group formControlName="tipo" color="primary" class="d-flex flex-row gap-3">
              <mat-radio-button value="Classe">Classe</mat-radio-button>
              <mat-radio-button value="Esame">Esame d'idoneità / Esame di Stato</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <app-dea-label [label]="'Dettagli ultimo esame sostenuto:'"></app-dea-label>
            <input formControlName="dettaglio" type="text" class="form-control dea-form-input" />
          </div>
          <div>
            <app-dea-label [label]="'Esito:'"></app-dea-label>
            <mat-radio-group formControlName="esito" color="primary" class="d-flex flex-row gap-3">
              <mat-radio-button value="Ammesso">Ammesso</mat-radio-button>
              <mat-radio-button value="Non Ammesso">Non Ammesso</mat-radio-button>
              <mat-radio-button value="Ritirato">Ritirato</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-span-2" formArrayName="users">
          <!-- STUDENT -->
          <div class="two-columns-grid">
            <div class="d-flex flex-row justify-content-start align-items-center fw-bold">
              <div>Studente:</div>
            </div>
            <div class="d-flex flex-row justify-content-end">
              <app-dea-fast-action-btn [disabled]="enrollmentFormService.enrollmentFormStudentUsers.length !== 0"
                class="ms-auto" (click)="enrollmentFormService.pushUser('Student')">
                Aggiungi Studente
              </app-dea-fast-action-btn>
            </div>
            <ng-container *ngIf="enrollmentFormService.enrollmentFormStudentUsers.length > 0">
              <ng-container *ngFor="let user of enrollmentFormService.enrollmentFormStudentUsers;">
                <app-enrollment-user-form class="col-span-2" [uuid]="user?.get('__uuid')?.value ?? 'UUID-ERROR'">
                </app-enrollment-user-form>
              </ng-container>
            </ng-container>
          </div>
          <!-- STUDENT -->
          <div class="v-spacer col-span-2"></div>
          <div class="v-spacer col-span-2"></div>
          <!-- PARENTS -->
          <div class="two-columns-grid">
            <div class="d-flex flex-row justify-content-start align-items-center fw-bold">
              <div>Genitori:</div>
            </div>
            <div class="d-flex flex-row justify-content-end">
              <app-dea-fast-action-btn [disabled]="enrollmentFormService.enrollmentFormParentUsersNumber >= 2"
                class="ms-auto" (click)="enrollmentFormService.pushUser('Parent')">
                Aggiungi Genitore
              </app-dea-fast-action-btn>
            </div>
            <ng-container *ngIf="enrollmentFormService.enrollmentFormParentUsersNumber > 0">
              <ng-container *ngFor="let user of enrollmentFormService.enrollmentFormParentUsers; let last = last;">
                <app-enrollment-user-form class="col-span-2" [uuid]="user?.get('__uuid')?.value ?? 'UUID-ERROR'">
                </app-enrollment-user-form>
                <hr class="col-span-2" *ngIf="!last">
              </ng-container>
            </ng-container>
          </div>
          <!-- PARENTS -->
          <div class="v-spacer col-span-2"></div>
          <div class="v-spacer col-span-2"></div>
          <!-- TUTORS -->
          <div class="two-columns-grid">
            <div class="d-flex flex-row justify-content-start align-items-center fw-bold">
              <div>Tutori:</div>
            </div>
            <div class="d-flex flex-row justify-content-end">
              <app-dea-fast-action-btn [disabled]="enrollmentFormService.enrollmentFormTutorNumber >= 2" class="ms-auto"
                (click)="enrollmentFormService.pushUser('Tutor')">
                Aggiungi Tutore
              </app-dea-fast-action-btn>
            </div>
            <ng-container *ngIf="enrollmentFormService.enrollmentFormTutorUsers.length > 0">
              <ng-container *ngFor="let user of enrollmentFormService.enrollmentFormTutorUsers; let last = last;">
                <app-enrollment-user-form class="col-span-2" [uuid]="user?.get('__uuid')?.value ?? 'UUID-ERROR'">
                </app-enrollment-user-form>
                <hr class="col-span-2" *ngIf="!last">
              </ng-container>
            </ng-container>
          </div>
          <!-- TUTORS -->
        </div>
        <!-- SUBMIT -->
        <div class="v-spacer"></div>
        <div class="v-spacer"></div>
        <div class="v-spacer"></div>
        <div class="hstack">
          <div class="ms-auto">
            <button *ngIf="!posting" mat-flat-button color="secondary" class="fw-bold px-4 py-1 rounded-pill"
              (click)="backOnePage()">
              Annulla modifiche
            </button>
          </div>
          <div class="ms-2">
            <button *ngIf="!posting" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
              (click)="postData()">
              Salva modifiche
            </button>
            <button *ngIf="posting" mat-flat-button class="fw-bold px-4 py-1 rounded-pill" disabled>
              <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Salva modifiche
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>
