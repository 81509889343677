import { Headquarter } from '../headquarter';

export class Film {
  id?: string;
  sede?: Headquarter;
  year?: Number;
  title?: object;
  languages?: string[];
  subtitles?: string[];
  categories?: string[];
  cover?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.sede = json['sede'] ? Headquarter.fromString(json['sede']) : undefined;
    this.year = json['year'];
    this.title = json['title'];
    this.languages = json['languages'];
    this.subtitles = json['subtitles'];
    this.categories = json['categories'];
    this.cover = json['cover'];
  }

  toJson(): any {
    let json = {};
    if (this.sede) Object.assign(json, { sede: this.sede.backend });
    if (this.year) Object.assign(json, { year: this.year });
    if (this.title) Object.assign(json, { title: this.title });
    Object.assign(json, {
      languages: (this.languages || []).map((lang) => lang.toUpperCase()),
    });
    Object.assign(json, {
      subtitles: (this.subtitles || []).map((lang) => lang.toUpperCase()),
    });
    Object.assign(json, { categories: this.categories || [] });
    if (this.cover) Object.assign(json, { cover: this.cover });
    return json;
  }
}
