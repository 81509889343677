<app-dea-dialog-wrapper>
  <!-- <div class="d-flex justify-content-center align-items-center"
      style="position: fixed; z-index: 5; height: 545px; width: 1000px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div> -->
  <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 545px; z-index: 10;">
    <img height="545" src="{{imgScr}}" alt="Documento" style="object-fit: contain; object-position: center;">
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button color="secondary" (click)="onDismiss()">Chiudi</button>
  </div>
</app-dea-dialog-wrapper>