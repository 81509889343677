<app-dea-dialog-wrapper>
  <app-listing-top-bar (textEmitter)="onText($event)"></app-listing-top-bar>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <table class="table table-borderless m-0">
        <thead class="m-0">
          <tr class="dea-table-row-header m-0">
            <th scope="col-3">Seriale</th>
            <th scope="col-3">Modello</th>
            <th scope="col-3">Memoria</th>
            <th scope="col-3">Colore</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let device of devices;" class="pointer dea-radius">
            <td class="col-3 py-auto" title="{{device.getFullName()}}" (click)="onSelected(device.id)">
              {{device.serial_number}}
            </td>
            <td class="col-3 py-auto" title="{{device.getFullName()}}" (click)="onSelected(device.id)">
              {{device.model_name}}
            </td>
            <td class="col-3 py-auto" title="{{device.getFullName()}}" (click)="onSelected(device.id)">
              {{device.capacity}}
            </td>
            <td class="col-3 py-auto" title="{{device.getFullName()}}" (click)="onSelected(device.id)">
              {{device.color | toTitleCase}}
            </td>
          </tr>
        </tbody>
      </table>
      <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)">
      </app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-dialog-wrapper>