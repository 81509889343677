<app-dea-dialog-wrapper [formGroup]="featureForm">
  <div class="fw-semibold text-start fs-4 mb-2">{{feature ? 'Modifica' : 'Crea'}}</div>
  <div class="v-spacer"></div>
  <div class="row m-0 p-0">
    <div class="col-auto m-0 p-0">
      <app-dea-label class="mb-2" [label]="'Stato:'"></app-dea-label>
      <mat-radio-group aria-label="Seleziona lo status" color="primary" formControlName="status">
        <mat-radio-button class="me-3" value="PENDING">Pending</mat-radio-button>
        <mat-radio-button class="me-3" value="IN PROGRESS">In Progress</mat-radio-button>
        <mat-radio-button class="me-3" value="DONE">Done</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col m-0 p-0 ms-5">
      <app-dea-label class="mb-2" [label]="'Pin:'"></app-dea-label>
      <mat-slide-toggle class="mt-2" color="primary" formControlName="pinned">
        <div class="ms-3">Metti in evidenza</div>
      </mat-slide-toggle>
    </div>
  </div>
  <div class="v-spacer"></div>
  <app-dea-label class="mb-2" [label]="'Data creazione:'"></app-dea-label>
  <input formControlName="created" type="date" class="form-control dea-form-input" placeholder="Creato" />
  <div class="v-spacer"></div>
  <app-dea-label class="mb-2" [label]="'Titolo:'"></app-dea-label>
  <input formControlName="title" type="text" class="form-control dea-form-input" placeholder="Titolo" />
  <div class="v-spacer"></div>
  <textarea formControlName="description" class="form-control dea-form-input" placeholder="Descrizione" rows="3">
  </textarea>
  <div class="v-spacer"></div>
  <app-dea-label class="mb-2" [label]="'Categorie (separate da virgola):'"></app-dea-label>
  <input formControlName="categories" type="text" class="form-control dea-form-input" placeholder="Categorie" />
  <div class="v-spacer"></div>
  <app-dea-label class="mb-2" [label]="'Tags (separati da virgola):'"></app-dea-label>
  <input formControlName="tags" type="text" class="form-control dea-form-input" placeholder="Tags" />
  <div *ngIf="error">
    {{error}}
  </div>
  <div class="m-0 p-0 mt-3 gap-2" mat-dialog-actions align="end">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
      {{feature ? 'Annulla modifiche' : 'Annulla'}}
    </button>
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="onSave()">
      {{feature ? 'Salva modifiche' : 'Salva'}}
    </button>
  </div>
</app-dea-dialog-wrapper>