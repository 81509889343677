import { Component, Input, OnInit } from '@angular/core';
import { Class } from 'src/app/models/class/class.model';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/constants/app-paths';

@Component({
  selector: 'app-class-cell',
  templateUrl: './class-cell.component.html',
  styleUrls: ['./class-cell.component.scss'],
})
export class ClassCellComponent implements OnInit {
  @Input() visibility: boolean = true;
  public scholasticYear?: string;
  public department?: string;
  public students?: number;
  public section?: string;
  public year?: number;
  public id?: string;

  @Input() classe?: any | Class;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    if (this.classe && (this.classe as Class)) {
      const realClass: Class = this.classe;
      this.scholasticYear = realClass.anno_scolastico;
      this.students = realClass.numero_studenti;
      this.department = realClass.dipartimento;
      this.section = realClass.sezione;
      this.year = realClass.anno;
      this.id = realClass.id;
    }
  }

  goToClassDetails(): void {
    if (this.id) this._router.navigateByUrl(AppPaths.CLASSE + '/' + this.id);
  }
}
