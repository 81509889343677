import { DepartmentUtils } from '../department.model';
import { ClassTeacher } from './class-teacher';
import { Headquarter } from '../headquarter';
import { User } from '../user/user.model';
import { File } from '../file.model';

export class Class {
  id?: string;
  dea_id?: string;
  sede?: Headquarter;
  anno_scolastico?: string;
  dipartimento?: string;
  sezione?: string;
  anno?: number;
  studenti?: User[];
  docenti?: ClassTeacher[];
  genitori_tutori?: User[];
  coordinatori?: string[];
  rappresentanti?: string[];
  numero_docenti?: number;
  numero_studenti?: number;
  files?: File[];

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.sede = Headquarter.fromString(json['sede']);
    this.dea_id = json['dea_id'];
    this.anno_scolastico = json['anno_scolastico'];
    this.dipartimento = json['dipartimento'];
    this.sezione = json['sezione'];
    this.anno = json['anno'];
    this.numero_docenti = json['numero_docenti'];
    this.numero_studenti = json['numero_studenti'];
    this.docenti = (json['docenti'] || []).map(
      (json: any) => new ClassTeacher(json)
    );
    this.studenti = (json['studenti'] || []).map((json: any) => new User(json));
    this.genitori_tutori = (json['genitori_tutori'] || []).map(
      (json: any) => new User(json)
    );
    this.coordinatori = json['coordinatori'] || [];
    this.rappresentanti = json['rappresentanti'] || [];
    this.files = json['files']
      ? (json['files'] || []).map((file: any) => new File(file))
      : undefined;
  }

  getName(): string {
    let tmpName: string = '';
    if (this.anno) tmpName = `${tmpName} ${this.anno}`;
    if (this.sezione) tmpName = `${tmpName}${this.sezione}`;
    if (this.dipartimento) {
      tmpName =
        tmpName.length == 0
          ? this.dipartimento
          : `${tmpName} - ${this.dipartimento}`;
    }
    return tmpName.toString().trim();
  }

  getShortName(): string {
    let tmpName: string = '';
    if (this.anno) tmpName = `${tmpName} ${this.anno}`;
    if (this.sezione) tmpName = `${tmpName}${this.sezione}`;
    if (this.dipartimento) {
      let dipartimento = DepartmentUtils.getShortName(this.dipartimento) || '';
      tmpName =
        tmpName.length == 0 ? dipartimento : `${tmpName} ${dipartimento}`;
    }
    return tmpName.toString().trim();
  }

  toJson(defaultUndefined: boolean = true): any {
    const value = defaultUndefined ? undefined : '';
    let json = {
      _id: this.id,
      sede: this.sede?.backend || value,
      dea_id: this.dea_id?.toUpperCase() || value,
      anno_scolastico: this.anno_scolastico || value,
      dipartimento: this.dipartimento || value,
      sezione: this.sezione?.toUpperCase() || value,
      anno: this.anno || value,
    };
    json = Object.assign(json, {
      studenti: (this.studenti || []).map((s) => s.id?.toString()),
    });
    json = Object.assign(json, {
      docenti: (this.docenti || []).map((classDocente) => {
        return {
          docente: classDocente.docente?.id,
          discipline: classDocente.discipline?.map((disciplina) => {
            return {
              libri: disciplina.libri?.map((libro) => libro.id),
              materia: disciplina.materia?.id,
              ore: disciplina.ore,
            };
          }),
        };
      }),
    });
    json = Object.assign(json, {
      coordinatori: this.coordinatori || value,
      rappresentanti: this.rappresentanti || value,
    });
    return json;
  }
}
