import { Tech, TechDiscriminatorKey } from './tech.model';
import { TechInsurance } from './tech-insurance.model';
import { TechBreaking } from './tech-breaking.model';
import { TechRelease } from './tech-release.model';
import { TechReturn } from './tech-return.model';
import { TechLoss } from './tech-loss.model';
import { Device } from './device.model';

export class AggregatedTech {
  device?: Device;
  blocks?: any[];

  constructor(json?: any) {
    if (!json) return;
    this.device = json['device'] ? new Device(json['device']) : undefined;
    this.blocks = (json['blocks'] || []).map((block: any) => {
      const type: TechDiscriminatorKey = block['__type'];
      switch (type) {
        case 'insurance':
          return new TechInsurance(block);
        case 'loss':
          return new TechLoss(block);
        case 'breaking':
          return new TechBreaking(block);
        case 'return':
          return new TechReturn(block);
        case 'release':
          return new TechRelease(block);
        default:
          return new Tech(block);
      }
    });
  }
}
