import { DeaPdfDialogComponent } from '../../dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { DocumentForm } from 'src/app/models/documents-forms/document-form.model';
import { DocumentsFormsService } from 'src/app/services/documents-forms.service';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-documents-forms-dea-id-listing',
  templateUrl: './documents-forms-dea-id-listing.component.html',
  styleUrls: ['./documents-forms-dea-id-listing.component.scss'],
})
export class DocumentsFormsDeaIdListingComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 20;
  @Input() department?: string;
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() cbMainBtn: Function = () => {};

  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public error?: ApiError;
  private text?: string;

  private formDeaId?: string;
  public forms: DocumentForm[] = [];

  constructor(
    private _documentsFormsService: DocumentsFormsService,
    private _route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.formDeaId = params['id'];
      this.getData();
    });
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._documentsFormsService
      .getListingByDeaId({
        dea_id: this.formDeaId || 'MD0000',
        txt: this.text && this.text.trim() != '' ? this.text : undefined,
        page: this.page,
        limit: this.limit,
      })
      .subscribe({
        next: (data) => {
          if (data.data != undefined && (data.data as DocumentForm[])) {
            //@ts-ignore
            this.forms = data.data!.map((item) => DocumentForm.fromJson(item));
          }
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Documents Forms Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  openPdfPreviewPDF(id?: string): void {
    this.dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: {
        id: id || 'NO-ID',
        url: API.downloadDocumentFormPDF(id || 'ID-ERROR'),
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  documentFormPDF(id?: string): void {
    if (!id) return;
    this._documentsFormsService.downloadDocumentFormPDF(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      const selected = this.forms.filter((form) => form.id == id);
      if (selected.length == 1) {
        a.download =
          `${selected[0].getClass() || 'No-Class'} - ${
            selected[0].owner?.getFullName() || 'No-Owner'
          }` + '.pdf';
      } else a.download = new Date().toISOString() + '.pdf';
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }
}
