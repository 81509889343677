import { Headquarter } from '../../headquarter';
import { GuestCard } from './guest-card.model';

export class GuestAttendance {
  id?: string;
  sede?: Headquarter;
  guest_card?: GuestCard;
  cognome?: string;
  nome?: string;
  secondo_nome?: string;
  email?: string;
  telefono?: string;
  motivo_della_visita?: string;
  entrata?: Date;
  uscita?: Date;
  firma?: string;
  privacy?: boolean;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.sede = Headquarter.fromString(json['sede']);
    this.cognome = json['cognome'];
    this.nome = json['nome'];
    this.secondo_nome = json['secondo_nome'];
    this.email = json['email'];
    this.telefono = json['telefono'];
    this.motivo_della_visita = json['motivo_della_visita'];
    this.guest_card = new GuestCard(json['guest_card']);
    this.entrata = json['entrata'] ? new Date(json['entrata']) : undefined;
    this.uscita = json['uscita'] ? new Date(json['uscita']) : undefined;
    this.firma = json['firma'] ? json['firma'] : undefined;
    this.privacy = json['privacy'] ? json['privacy'] : undefined;
  }

  getFullName(): string {
    return `${this.cognome} ${this.nome} ${this.secondo_nome}`
      .replaceAll('undefined', '')
      .trim();
  }
}
