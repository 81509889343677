import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { DepartmentUtils } from 'src/app/models/department.model';
import { UtilityService } from 'src/app/services/utility.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.scss'],
})
export class UtilityComponent implements OnInit {
  fieldListing = [
    { fe: 'Nome', be: 'nome' },
    { fe: 'Secondo Nome', be: 'secondo_nome' },
    { fe: 'Cognome', be: 'cognome' },
    { fe: 'Codice Fiscale', be: 'codice_fiscale' },
    { fe: 'Sesso', be: 'sesso' },
    { fe: 'Luogo Di Nascita', be: 'luogo_di_nascita' },
    { fe: 'Provincia Di Nascita', be: 'provincia_di_nascita' },
    { fe: 'Nazionalita', be: 'nazionalita' },
    { fe: 'Indirizzo (Domicilio)', be: 'domicilio.indirizzo' },
    { fe: 'Civico (Domicilio)', be: 'domicilio.civico' },
    { fe: 'CAP (Domicilio)', be: 'domicilio.cap' },
    { fe: 'Citta (Domicilio)', be: 'domicilio.citta' },
    { fe: 'Provincia (Domicilio)', be: 'domicilio.provincia' },
    { fe: 'Indirizzo (Residenza)', be: 'residenza.indirizzo' },
    { fe: 'Civico (Residenza)', be: 'residenza.civico' },
    { fe: 'CAP (Residenza)', be: 'residenza.cap' },
    { fe: 'Citta (Residenza)', be: 'residenza.citta' },
    { fe: 'Provincia (Residenza)', be: 'residenza.provincia' },
    { fe: 'Indirizzo E-Mail', be: 'contatti.email.indirizzo' },
    { fe: 'Numero di Telefono', be: 'contatti.telefono.numero' },
  ];
  departmentsListing: string[] = DepartmentUtils.getDepartmentsStrings();
  anno_scolastico: string = ScholasticYear.getCurrent();
  headquarter: string = 'MILANO';
  departments: string[] = [];
  type: string = 'Student';
  sections: string[] = [];
  features: string[] = [];
  fields: string[] = [];
  years: string[] = [];
  text: string = '';

  textInputTimer: any;

  number: number = 0;
  getting: boolean = false;

  constructor(private _utilityService: UtilityService) {}

  ngOnInit(): void {
    this.getNumber();
  }

  setUserType(event: any) {
    this.type = event.value;
    if (this.type != 'Student') this.features = [];
    this.getNumber();
  }

  isUserType(type: string): boolean {
    return this.type === type;
  }

  onText(event: any) {
    this.text = event.target.value;
    if (this.textInputTimer) clearTimeout(this.textInputTimer);
    this.textInputTimer = setTimeout(() => this.getNumber(), 1000);
  }

  onCheckboxChanged(param: string, value: string) {
    if (param == 'years') {
      if (this.years.includes(value)) {
        this.years.splice(this.years.indexOf(value), 1);
      } else this.years.push(value);
    } else if (param == 'sections') {
      if (this.sections.includes(value)) {
        this.sections.splice(this.sections.indexOf(value), 1);
      } else this.sections.push(value);
    } else if (param == 'departments') {
      if (this.departments.includes(value)) {
        this.departments.splice(this.departments.indexOf(value), 1);
      } else this.departments.push(value);
    } else if (param == 'fields') {
      if (this.fields.includes(value)) {
        this.fields.splice(this.fields.indexOf(value), 1);
      } else this.fields.push(value);
    } else if (param == 'features') {
      if (this.features.includes(value)) {
        this.features.splice(this.features.indexOf(value), 1);
      } else this.features.push(value);
    }
    this.getNumber();
  }

  getParameters() {
    return {
      type: this.type,
      y: this.anno_scolastico,
      years: this.years.join(','),
      sections: this.sections.join(','),
      departments: this.departments.join(','),
      features: this.features.join(','),
      text: this.text,
      fields: this.fields.join(','),
    };
  }

  getNumber() {
    this._utilityService
      .getExportNumberOfItems(this.getParameters())
      .subscribe({
        next: (data: any) => {
          this.number = parseInt(data.data) || 0;
        },
        error: (err: any) => {
          console.error('Get Number of Items Error:', err);
          this.number = -1;
        },
      });
  }

  getData() {
    this.getting = true;
    this._utilityService
      .getExportCSV(this.getParameters())
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let a = document.createElement('a');
        a.download =
          `${this.formatDateToyyyyMMddhhmmss()} • De Amicis Ring • ${
            this.type
          } ${this.years.join('|')} ${this.departments.join('|')}` + '.csv';
        a.href = window.URL.createObjectURL(blob);
        a.click();
      })
      .add(() => {
        this.getting = false;
      });
  }

  formatDateToyyyyMMddhhmmss(): string {
    const now = new Date();
    const year = now.getFullYear().toString().padStart(4, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }
}
