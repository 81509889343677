import { File } from '../file.model';

export class TechDocument extends File {
  anno_scolastico?: string;
  type?: string;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    this.type = json?.metadata?.type;
    this.anno_scolastico = json?.metadata?.anno_scolastico;
  }
}
