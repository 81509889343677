export class Pcto {
  id?: string;
  filename?: string;
  contentType?: string;
  length?: number;
  chunkSize?: number;
  uploadDate?: Date;

  mimetype?: string;
  originalname?: string;
  owner?: string;
  anno_scolastico?: string;
  ente?: string;
  note?: string;
  ore?: number;

  public static fromJson(json: any): Pcto {
    const pctoDoc: Pcto = new Pcto();
    pctoDoc.id = json['_id'] || json['id'];
    pctoDoc.filename = json['filename'];
    pctoDoc.contentType = json['contentType'];
    pctoDoc.length = json['length'];
    pctoDoc.chunkSize = json['chunkSize'];
    pctoDoc.uploadDate = json['uploadDate']
      ? new Date(json['uploadDate'])
      : undefined;
    pctoDoc.mimetype = json['metadata']['mimetype'];
    pctoDoc.originalname = json['metadata']['originalname'];
    pctoDoc.owner = json['metadata']['owner'];
    pctoDoc.anno_scolastico = json['metadata']['anno_scolastico'];
    pctoDoc.ente = json['metadata']['ente'];
    pctoDoc.note = json['metadata']['note'];
    pctoDoc.ore = json['metadata']['ore'];
    return pctoDoc;
  }

  isPDF(): boolean {
    return this.contentType?.includes('pdf') ? true : false;
  }

  getExt(): string {
    if (this.isPDF()) return 'pdf';
    if (this.contentType?.includes('png')) return 'png';
    if (this.contentType?.includes('jpg')) return 'jpg';
    if (this.contentType?.includes('jpeg')) return 'jpeg';
    return '';
  }
}
