import { ItemDocumentForm } from './item-document-form';

export class SectionDocumentForm {
  title?: string;
  subtitle?: string;
  description?: string;
  items?: ItemDocumentForm[];

  public static fromJson(json?: any): SectionDocumentForm {
    const newSection: SectionDocumentForm = new SectionDocumentForm();
    newSection.title = json['title'];
    newSection.subtitle = json['subtitle'];
    newSection.description = json['description'];
    newSection.items = (json['items'] || []).map((i: any) => {
      const newItem: ItemDocumentForm = ItemDocumentForm.fromJson({ ...i });
      return newItem;
    });
    return newSection;
  }
}
