<div class="row m-0 p-0">
  <div class="col m-0 p-0">
    <app-dea-search-bar (text)="onText($event)"></app-dea-search-bar>
  </div>
  <div *ngIf="actionBtn" class="col-auto m-0 p-0 ms-2">
    <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill" routerLink="{{linkMainBtn}}"
      [queryParams]="linkMainBtnQueryParams">
      {{actionBtnTitle}}
    </button>
  </div>
</div>