import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import {
  ClassListingParameters,
  PostNextYearClassParameters,
} from '../constants/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ClassesService {
  constructor(private http: HttpClient) {}

  listing(parameters: ClassListingParameters) {
    return this.http.get<ApiResponse<any>>(API.classesListing(parameters), {
      withCredentials: true,
    });
  }

  getDetail(id?: string) {
    return this.http.get<ApiResponse<any>>(API.classDetail(id), {
      withCredentials: true,
    });
  }

  getFile(classId?: string, fileId?: string) {
    return this.http.get(API.classFile(classId, fileId), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  getScholasticYears() {
    return this.http.get<ApiResponse<any>>(API.scholasticYears(), {
      withCredentials: true,
    });
  }

  postClass(data?: any) {
    return this.http.post<ApiResponse<any>>(API.newClass(), data, {
      withCredentials: true,
    });
  }

  putClass(id?: string, data?: any) {
    return this.http.put<ApiResponse<any>>(API.editClass(id), data, {
      withCredentials: true,
    });
  }

  getNextYearPreview(id?: string) {
    return this.http.get<ApiResponse<any>>(API.classNextYear(id), {
      withCredentials: true,
    });
  }

  postNextYearPreview(p: PostNextYearClassParameters) {
    return this.http.post<ApiResponse<any>>(
      API.classNextYear(p.id),
      {
        studenti: p.studenti || [],
        docenti: p.docenti || [],
      },
      {
        withCredentials: true,
      }
    );
  }

  downloadCSV(id: string, types: string[], options: string[]) {
    return this.http.get(API.classDownloadCSV(id, types, options), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }
}
