import { UtilityService } from 'src/app/services/utility.service';
import { Status } from 'src/app/models/status/status.model';
import { Component, OnInit, inject } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  private _utilityService: UtilityService = inject(UtilityService);

  status?: Status;

  ngOnInit(): void {
    this.getData();
  }

  onRefresh() {
    this.getData();
  }

  getClassBool(clas: string, uptime?: number): boolean {
    if (uptime != undefined) {
      switch (clas) {
        case 'bg-success':
          return uptime >= 0.8;
        case 'bg-warning':
          return 0.8 > uptime && uptime > 0.55;
        case 'bg-danger':
          return uptime <= 0.55;
        default:
          return false;
      }
    }
    return false;
  }

  getData() {
    this._utilityService.getStatus().subscribe({
      next: (data: any) => (this.status = new Status(data.data)),
      error: (err) => {
        this.status = undefined;
        console.error('Get Status Error:', err);
      },
    });
  }
}
