<div class="d-flex justify-content-center align-items-center p-3" style="height: 100vh; z-index: 5000 !important;">
  <div class="mt-5 text-center p-3">
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-aspect-ratio mb-3"
      viewBox="0 0 16 16">
      <path
        d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
      <path
        d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z" />
    </svg>
    <h2>Attenzione</h2>
    <p>Le dimensioni attuali della finestra non permettono di utilizzare questa applicazione</p>
    <p style="font-size: 0.8rem;">(Dimenzioni minime richieste: 1275 x 680)</p>
  </div>
</div>