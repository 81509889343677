import { Component, Input } from '@angular/core';
import { Address } from 'src/app/models/user/address.model';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss'],
})
export class UserAddressComponent {
  @Input() addressTopLabel?: string;
  @Input() address?: Address;
}
