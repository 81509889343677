<mat-card fxLayoutGap class="p-3">
  <div class="fw-semibold fs-4 mb-2">Atleta:</div>
  <div class="v-spacer"></div>
  <div class="d-flex flex-column justify-content-start gap-3">
    <div class="d-flex flex-row gap-2">
      <app-dea-label class="m-0 p-0 mb-2" [label]="'Società:'"></app-dea-label>
      <div>{{data.societa}}</div>
    </div>
    <div class="d-flex flex-row gap-2">
      <app-dea-label class="m-0 p-0 mb-2" [label]="'Disciplina:'"></app-dea-label>
      <div>{{data.disciplina}}</div>
    </div>
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">Chiudi</button>
  </div>
</mat-card>
