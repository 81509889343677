<div class="border rounded p-3 d-flex flex-column gap-3">
  <div class="hstack">
    <div class="me-auto hstack">
      <div class="fs-065r opacity-50 d-flex align-items-center py-1">
        <div class="">ID: {{event?.id}}</div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div>
    <div class="d-flex flex-row gap-2 w-100">
      <div>
        <div class="border rounded w-auto">
          <div class="w-100 text-center border-bottom px-2">{{event?.start | getMonthName}}</div>
          <div class="w-100 text-center fs-3">{{event?.start | date:'dd'}}</div>
        </div>
      </div>
      <div class="flex-fill ">
        <div class="fs-075r">
          Inizio: <span class="fw-medium">{{event?.start | date:'HH:mm'}}</span>
          <span class="fs-075r" *ngIf="event?.end">
            <span class="opacity-25"> | </span>Fine: <span class="fw-medium">{{event?.end | date:'HH:mm'}}</span>
          </span>
        </div>
        <div class="fw-bold mt-0">{{event?.title}}</div>
        <div *ngIf="event?.location && event?.location?.length !=0" class="fw-thin">
          <i class="bi bi-geo-alt me-2"></i>{{event?.location}}
        </div>
      </div>
      <div class="m-0 p-0">
        <app-dea-chip-status [label]="'Pubblico'" [status]="event?.public"></app-dea-chip-status>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column gap-2">
    <div class="d-flex flex-row justify-content-end align-items-start w-100 gap-1 fs-055r opacity-50 flex-wrap">
      <div *ngFor="let class of event?.classes; let last = last;"
        class="d-flex justify-content-center align-content-center gap-1">
        {{class.getName()}}
        <div *ngIf="!last" class="vr"></div>
      </div>
    </div>
  </div>
</div>