import { DeaDeviceListingDialogComponent } from 'src/app/components/dea-pack/dea-device-listing-dialog/dea-device-listing-dialog.component';
import { AggregatedTech } from 'src/app/models/tech/aggregated-tech.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { TechService } from 'src/app/services/tech.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Input,
  inject,
  Component,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-user-tech-data',
  templateUrl: './user-tech-data.component.html',
  styleUrls: ['./user-tech-data.component.scss'],
})
export class UserTechDataComponent implements OnChanges {
  private _techService: TechService = inject(TechService);
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() startLoading: boolean = false;
  @Input() userId?: string;

  aggregatedTechs: AggregatedTech[] = [];

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading'].currentValue &&
      !changes['startLoading'].previousValue
    ) {
      this.getTechData();
    }
  }

  async getTechData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._techService
      .getAggregateListing(this.userId)
      .subscribe({
        next: (data) => {
          if (data.data != undefined && (data.data as AggregatedTech[])) {
            this.aggregatedTechs = [];
            //@ts-ignore
            data.data.forEach((aggTechs: any) => {
              this.aggregatedTechs.push(new AggregatedTech(aggTechs));
            });
          }
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Techs Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  postAssignment(): void {
    const dialogSize = '1000px';
    const dialogRef = this.dialog.open(DeaDeviceListingDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this._techService
          .postAssignment({
            owner: this.userId || 'NO-ID',
            device: dialogResult.id,
          })
          .subscribe({
            next: (_: any) => this.getTechData(),
            error: (error: any) => {
              // TODO: GESTIRE ERRORE
              console.error('User Post Assignment Error:', error);
            },
          });
      }
    });
  }
}
