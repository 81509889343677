import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-csv-selector-dialog',
  template: `<app-dea-dialog-wrapper>
    <div class="fw-semibold fs-4 mb-3">Composizione CSV</div>
    <div class="m-0 p-0 d-flex flex-column gap-3">
      <div>
        Seleziona le tipologie di utenti che desideri esportare per questa
        classe:
      </div>
      <div class="fw-lighter">
        <mat-checkbox
          color="primary"
          [checked]="true"
          (change)="onChangeTypeCheckbox('student')"
        >
          Studenti
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [checked]="true"
          (change)="onChangeTypeCheckbox('teacher')"
        >
          Docenti
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [checked]="true"
          (change)="onChangeTypeCheckbox('parent')"
        >
          Genitori
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          [checked]="true"
          (change)="onChangeTypeCheckbox('tutor')"
        >
          Tutori
        </mat-checkbox>
      </div>
      <div>Seleziona le opzioni di esportazione per questa classe:</div>
      <div class="fw-lighter">
        <mat-checkbox
          color="primary"
          [checked]="false"
          (change)="onChangeOptionCheckbox('pcto')"
        >
          Ore PCTO Studenti
        </mat-checkbox>
      </div>
    </div>
    <div class="m-0 p-0 mt-3 gap-2" mat-dialog-actions align="end">
      <button
        class="px-4 py-1"
        color="secondary"
        mat-stroked-button
        (click)="onDismiss()"
      >
        Annulla
      </button>
      <button
        mat-flat-button
        color="primary"
        class="px-4 py-1"
        (click)="onConfirm()"
      >
        Esporta CSV
      </button>
    </div>
  </app-dea-dialog-wrapper>`,
  styles: [],
})
export class CsvSelectorDialogComponent {
  private types: string[] = ['student', 'teacher', 'parent', 'tutor'];
  private options: string[] = [];

  constructor(public dialogRef: MatDialogRef<CsvSelectorDialogComponent>) {}

  onChangeTypeCheckbox(s: string): void {
    const indexTypes = this.types.findIndex((t) => t == s);
    if (indexTypes == -1) {
      this.types.push(s);
    } else {
      if (indexTypes != -1) this.types.splice(indexTypes, 1);
    }
  }

  onChangeOptionCheckbox(s: string): void {
    const indexOption = this.options.findIndex((o) => o == s);
    if (indexOption == -1) {
      this.options.push(s);
    } else {
      if (indexOption != -1) this.options.splice(indexOption, 1);
    }
  }

  onConfirm(): void {
    this.dialogRef.close({ types: this.types, options: this.options });
  }

  onDismiss(): void {
    this.dialogRef.close({});
  }
}
