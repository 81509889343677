<app-dea-widget-wrapper>
  <div class="row m-0 p-0">
    <div class="col m-0 p-0">
      <app-dea-search-bar (text)="onText($event)"></app-dea-search-bar>
    </div>
    <div class="col-auto m-0 p-0 ms-2">
      <input #fileInput type="file" class="d-none" accept=".csv" multiple="false" (change)="onFileChange($event)" />
      <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill" (click)="openFileInput()">
        Carica CSV (ASM)
      </button>
    </div>
  </div>
  <div class="v-spacer"></div>
  <div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
  <div *ngIf="!loading && error" class="d-flex justify-content-center align-items-center" style="height: 96px;">
    {{error.message}}
  </div>
  <div *ngIf="!loading && !error" class="m-0 p-0">
    <div *ngFor="let device of devices;" class="mb-3">
      <app-user-device-box [device]="device"></app-user-device-box>
    </div>
  </div>
  <div class="v-spacer"></div>
  <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)"></app-listing-pagination-bar>
</app-dea-widget-wrapper>