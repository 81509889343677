<app-dea-dialog-wrapper>
  <app-listing-top-bar (textEmitter)="onText($event)" [linkMainBtnQueryParams]="linkMainBtnQueryParams">
  </app-listing-top-bar>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <table class="table table-borderless m-0">
        <thead class="m-0">
          <tr class="dea-table-row-header m-0">
            <th scope="col-3">Cognome</th>
            <th scope="col-3">Nome</th>
            <th scope="col-3">Secondo Nome</th>
            <th scope="col-3">Codice Fiscale</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users;" class="user-listing-table-row dea-radius">
            <td class="col-3 py-auto" title="{{user.getFullName()}}" (click)="onSelected(user.id)">
              {{user.cognome}}
            </td>
            <td class="col-3 py-auto" title="{{user.getFullName()}}" (click)="onSelected(user.id)">
              {{user.nome}}
            </td>
            <td class="col-3 py-auto" title="{{user.getFullName()}}" (click)="onSelected(user.id)">
              {{user.secondo_nome}}
            </td>
            <td class="col-3 py-auto" title="{{user.getFullName()}}" (click)="onSelected(user.id)">
              {{user.codice_fiscale}}
            </td>
          </tr>
        </tbody>
      </table>
      <app-listing-pagination-bar [paginationData]="pagination"
        (pageEmitter)="onPage($event)"></app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-dialog-wrapper>