<app-dea-widget-wrapper>
  <div class="hstack">
    <div class="me-auto"></div>
    <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill" routerLink="/events/create">
      Aggiungi Evento
    </button>
  </div>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="d-flex flex-column gap-3 mb-3">
        <app-event-row *ngFor="let event of events;" [event]="event">
          <div class="ms-3" routerLink="{{'/events/edit/'+event.id}}">
            <i class="bi bi-pencil-square dea-icon-btn" title="Modifica Evento"></i>
          </div>
          <div class="ms-3" (click)="deleteEvent(event)">
            <i class="bi bi-trash3 dea-icon-btn text-danger" title="Elimina Evento"></i>
          </div>
        </app-event-row>
      </div>
      <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)">
      </app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>