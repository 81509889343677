import { StorageKeys } from 'src/app/constants/storege-keys';
import { Headquarter } from '../models/headquarter';
import { VERSION } from 'src/environments/version';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClientStorageService {
  constructor() {}

  getVersionHash(): string {
    return localStorage.getItem(StorageKeys.HASHVERSION) || 'NO-HASH-VERSION';
  }

  setVersionHash(): string {
    localStorage.setItem(StorageKeys.HASHVERSION, VERSION.hash);
    return this.getVersionHash();
  }

  getSessionCheck(): boolean {
    switch (sessionStorage.getItem(StorageKeys.SESSION)) {
      case 'true':
        return true;
      default:
        return false;
    }
  }

  setSessionCheck(value: boolean): boolean {
    sessionStorage.setItem(StorageKeys.SESSION, value.toString());
    return this.getSessionCheck();
  }

  getHeadquarter(): Headquarter {
    let headquarterStr: string | null = localStorage.getItem(
      StorageKeys.HEADQUARTER
    );
    headquarterStr = (headquarterStr ?? Headquarter.MILANO.backend).toString();
    switch (headquarterStr) {
      case Headquarter.GORGONZOLA.backend:
        return Headquarter.GORGONZOLA;
      default:
        return Headquarter.MILANO;
    }
  }

  getHeadquarterString(): string {
    return (
      Headquarter.fromString(
        localStorage.getItem(StorageKeys.HEADQUARTER) || undefined
      )?.frontend ?? Headquarter.MILANO.frontend
    ).toString();
  }

  setHeadquarter(value: string | null): string {
    switch (value) {
      case Headquarter.GORGONZOLA.backend:
        localStorage.setItem(
          StorageKeys.HEADQUARTER,
          Headquarter.GORGONZOLA.backend
        );
        break;
      default:
        localStorage.setItem(
          StorageKeys.HEADQUARTER,
          Headquarter.MILANO.backend
        );
        break;
    }
    return this.getHeadquarterString();
  }

  getStatusWelcomeMessage(): boolean {
    switch (sessionStorage.getItem(StorageKeys.WELCOMEMESSAGE)) {
      case 'true':
        return true;
      default:
        return false;
    }
  }

  setStatusWelcomeMessage(value: boolean): boolean {
    sessionStorage.setItem(StorageKeys.WELCOMEMESSAGE, value.toString());
    return this.getSessionCheck();
  }
}
