import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, inject } from '@angular/core';

@Component({
  selector: 'app-dea-json-preview',
  template: `<app-dea-dialog-wrapper>
    <div class="d-flex flex-column gap-3">
      <div class="text-start fw-semibold fs-4">Anteprima dei dati:</div>
      <div
        *ngIf="data"
        style="height: 400px; overflow-y: scroll; overflow-x: scroll;"
      >
        <table class="table m-0">
          <thead class="m-0">
            <tr class="dea-table-row-header m-0">
              <th *ngFor="let key of keys" scope="col-auto">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let obj of data"
              class="user-listing-table-row dea-radius"
            >
              <td *ngFor="let key of keys" class="col">
                {{ obj[key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="gap-2" mat-dialog-actions align="end">
        <button class="px-5 py-1" mat-stroked-button (click)="close(false)">
          Annulla
        </button>
        <button
          color="primary"
          mat-flat-button
          class="px-5 py-1"
          (click)="close(true)"
        >
          Carica
        </button>
      </div>
    </div>
  </app-dea-dialog-wrapper>`,
  styles: [],
})
export class DeaJsonPreviewComponent {
  private _dialogRef: MatDialogRef<DeaJsonPreviewComponent> = inject(
    MatDialogRef<DeaJsonPreviewComponent>
  );

  get keys(): string[] {
    return Object.keys(this.data ? this.data[0] : {});
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  close(confirmed: boolean = false): void {
    this._dialogRef.close(confirmed);
  }
}
