import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeatureService } from 'src/app/services/feature.service';
import { Component, Inject, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Feature } from 'src/app/models/feature.model';

@Component({
  selector: 'app-feature-dialog',
  templateUrl: './feature-dialog.component.html',
  styleUrls: ['./feature-dialog.component.scss'],
})
export class FeatureDialogComponent {
  private _featureService: FeatureService = inject(FeatureService);
  error: any;
  public featureForm: FormGroup = new FormGroup({
    status: new FormControl('PENDING'),
    pinned: new FormControl(false),
    title: new FormControl(),
    description: new FormControl(),
    categories: new FormControl(),
    tags: new FormControl(),
    created: new FormControl(new Date().toISOString().split('T')[0]),
  });

  constructor(
    public dialogRef: MatDialogRef<FeatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public feature?: Feature
  ) {
    if (feature != undefined) {
      this.featureForm.setValue({
        status: feature?.status || 'PENDING',
        pinned: feature?.pinned || false,
        title: feature?.title || '',
        description: feature?.description || '',
        categories: feature?.categories?.join(', ') || '',
        tags: feature?.tags?.join(', ') || '',
        created: feature?.created
          ? feature?.created?.toISOString().split('T')[0]
          : '',
      });
    }
  }

  onSave(): void {
    const jsonFeature = this.featureForm.value;
    jsonFeature.categories = jsonFeature?.categories?.split(',') || [];
    jsonFeature.tags = jsonFeature?.tags?.split(',') || [];
    const newFeature = new Feature(this.featureForm.value);
    if (this.feature) {
      this._featureService
        .patch(this.feature.id || 'ID-ERROR', newFeature)
        .subscribe({
          next: (_: any) => this.dialogRef.close(true),
          error: (error: any) => console.error('Patch Feature Error:', error),
        });
    } else {
      this._featureService.create(newFeature).subscribe({
        next: (_: any) => this.dialogRef.close(true),
        error: (error: any) => console.error('Create Feature Error:', error),
      });
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
