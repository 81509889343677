import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-phone-label',
  templateUrl: './phone-label.component.html',
  styleUrls: ['./phone-label.component.scss'],
})
export class PhoneLabelComponent implements OnInit {
  @Input() phone?: string;

  ngOnInit(): void {
    if (this.phone) {
      this.phone =
        parsePhoneNumberFromString(this.phone, 'IT')?.number ?? this.phone;
    }
  }
}
