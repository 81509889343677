import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-attendance-in-out',
  templateUrl: './attendance-in-out.component.html',
  styleUrls: ['./attendance-in-out.component.scss'],
})
export class AttendanceInOutComponent {
  @Input() entrata?: Date;
  @Input() uscita?: Date;
}
