<app-dea-widget-wrapper>
  <app-listing-top-bar [actionBtn]="false" (textEmitter)="onText($event)">
  </app-listing-top-bar>
  <div class="opacity-50 fs-075r px-2 mt-1">
    <strong class="text-decoration-underline">Attenzione:</strong> quando si esegue una ricerca in questo campo si
    cercherà tra tutte le risposte delle celle dei form ma a causa dell'attuale struttura logica non sarà possibile
    filtrare i risultati per docenti. Per visualizzare i moduli compilati da un singolo docente è possibile andare
    nel dettaglio del docente (nella sezione utenti) e selezionare la scheda "Moduli".<br>La buona notizia è che stiamo
    lavorando per creare una nuova versione dei moduli che risolverà questo e molti altri problemi, <span
      class="text-decoration-underline">grazie per la pazienza</span>!
  </div>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <table class="table table-borderless m-0">
        <thead class="m-0">
          <tr class="dea-table-row-header m-0">
            <th scope="col">Proprietario</th>
            <th scope="col">Classe</th>
            <th scope="col">Materia</th>
            <th scope="col">Data Creazione</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of forms;" class="guest-attendance-listing-table-row dea-radius">
            <td class="col py-auto">
              {{a.owner?.getFullName() || ''}}
            </td>
            <td class="col py-auto">
              {{a.getClass()}}
            </td>
            <td class="col py-auto">
              {{a.getSubject()}}
            </td>
            <td class="col py-auto">{{a.createdAt | date:'yyyy-MM-dd'}}</td>
            <td class="col py-auto">
              <mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="openPdfPreviewPDF(a.id)"
                title="Visualizza {{a?.title}}">
                visibility
              </mat-icon>
              <mat-icon class="ms-3 m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="documentFormPDF(a.id)"
                title="Download: {{a?.title}}">
                download
              </mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)">
      </app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>