<app-dea-homepage-wrapper [showBackHomeBtn]="true">
  <div class="mb-3">
    Inserisci l'indirizzo e-mail asociato alle tue credenziali di accesso ed inserisci la password temporanea
    che ti è stata assegnata quindi crea la tua nuova password.
    <div class="fs-055r opacity-50 mt-1">
      Promemoria: per la tua nuova password ricordati di inserire un carattere maiuscolo, un carattere
      minuscolo, un carattere speciale e un numero, per una lunghezza totale di almeno 8 caratteri.
    </div>
  </div>
  <form [formGroup]="credentialsForm" class="w-100">
    <div class="d-flex flex-column input-group-lg">
      <input auth-input type="email" class="form-control rounded-0 rounded-top" placeholder="E-Mail" spellcheck="false"
        autocomplete="off" name="email" formControlName="email" autofocus required
        style="border-bottom-width: 0.00625em;">
    </div>
    <div class="d-flex flex-column input-group-lg">
      <input auth-input type="text" class="form-control rounded-0" placeholder="Password Temporanea" spellcheck="false"
        autocomplete="off" name="tmp_password" formControlName="tmp_password" required
        style="border-bottom-width: 0.00625em; border-top-width: 0.00625em;">
    </div>
    <div class="d-flex flex-column input-group-lg">
      <input auth-input type="password" class="form-control rounded-0" placeholder="Nuova Password" spellcheck="false"
        autocomplete="off" name="password" formControlName="password" required
        style="border-bottom-width: 0.00625em; border-top-width: 0.00625em;">
    </div>
    <div class="input-group input-group-lg mb-1 form-control p-0 rounded-0 rounded-bottom input-wrapper"
      style="border-top-width: 0.00625em;">
      <input auth-input type="password" class="form-control pe-0 border-0" placeholder="Verifica Password"
        spellcheck="false" autocomplete="off" name="verify_password" formControlName="verify_password"
        (keydown.enter)="setCredentials()" required>
      <div class="my-auto px-2">
        <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
        <ng-template #loadingTrue>
          <mat-spinner strokeWidth="1" color="primary" diameter="22"></mat-spinner>
        </ng-template>
        <ng-template #loadingFalse>
          <div (click)="setCredentials()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
              class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="ms-3 me-3 text-danger fs-075r">{{errorMessage}}</div>
  </form>
  <div class="ms-3 mt-3 fw-lighter text-decoration-underline fs-075r dea-icon-btn">
    <a target="_blank" href="mailto:bellani.gabriele@ideamicis.it">
      Problemi ad accedere? Contatta l'Admin e spiega il tuo problema
    </a>
  </div>
</app-dea-homepage-wrapper>