<app-dea-widget-wrapper>
  <app-listing-top-bar [actionBtn]="false" (textEmitter)="onText($event)">
  </app-listing-top-bar>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <table class="table table-borderless m-0">
        <thead class="m-0">
          <tr class="dea-table-row-header m-0">
            <th scope="col">Cognome</th>
            <th scope="col">Nome</th>
            <th scope="col">E-Mail</th>
            <th scope="col">Telefono</th>
            <th scope="col">Motivo della Visita</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of guestsAttendances;" class="guest-attendance-listing-table-row dea-radius">
            <td class="col py-auto" routerLink="/ingresso-ospite/{{a.id}}" title="{{a.getFullName()}}">
              {{a.cognome}}
            </td>
            <td class="col py-auto" routerLink="/ingresso-ospite/{{a.id}}" title="{{a.getFullName()}}">
              {{a.nome}}
            </td>
            <td class="col py-auto" routerLink="/ingresso-ospite/{{a.id}}" title="{{a.getFullName()}}">
              {{a.email}}
            </td>
            <td class="col py-auto" routerLink="/ingresso-ospite/{{a.id}}" title="{{a.getFullName()}}">
              {{a.telefono}}
            </td>
            <td class="col py-auto" routerLink="/ingresso-ospite/{{a.id}}" title="{{a.getFullName()}}">
              {{a.motivo_della_visita}}
            </td>
          </tr>
        </tbody>
      </table>
      <app-listing-pagination-bar [paginationData]="pagination"
        (pageEmitter)="onPage($event)"></app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>