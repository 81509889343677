import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dea-chip-status',
  templateUrl: './dea-chip-status.component.html',
  styleUrls: ['./dea-chip-status.component.scss'],
})
export class DeaChipStatusComponent implements OnInit {
  @Input() label: string = '';
  @Input() status?: boolean;
  @Input() clickHandler?: () => void;

  hidden: boolean = true;

  ngOnInit(): void {
    if (this.status == undefined || this.status == null) this.hidden = true;
    else this.hidden = false;
  }

  onClick() {
    if (this.clickHandler) this.clickHandler();
  }
}
