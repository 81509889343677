<app-dea-widget-wrapper>
  <!-- TIPO DI UTENTE -->
  <div class="row m-0 p-0 gap-3">
    <div class="col m-0 p-0">
      <app-dea-label class="mb-2" [label]="'Tipo di utenti:'"></app-dea-label>
      <div class="fw-lighter opacity-50 fs-075r">
        Attenzione: in fase di esportazione sono considerati solo gli utenti che hanno un rapporto attivo con una classe
        dell'anno scolastico in corso.
      </div>
      <mat-radio-group aria-label="Seleziona il tipo di utente" color="primary" (change)="setUserType($event)">
        <mat-radio-button class="me-3" value="Student" [checked]="isUserType('Student')">Studenti</mat-radio-button>
        <mat-radio-button class="me-3" value="Teacher" [checked]="isUserType('Teacher')">Docenti</mat-radio-button>
        <mat-radio-button class="me-3" value="Parent" [checked]="isUserType('Parent')">Genitori</mat-radio-button>
        <mat-radio-button class="me-3" value="Tutor" [checked]="isUserType('Tutor')">Tutori</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <!-- ANNI/SEZIONI -->
  <div class="v-spacer"></div>
  <div class="row m-0 p-0">
    <div class="col m-0 p-0">
      <app-dea-label class="mb-2" [label]="'Anni:'"></app-dea-label>
      <div class="d-flex flex-row justify-content-start flex-wrap gap-3" (click)="getNumber()">
        <mat-checkbox color="primary" (change)="onCheckboxChanged('years', '1')">1</mat-checkbox>
        <mat-checkbox color="primary" (change)="onCheckboxChanged('years', '2')">2</mat-checkbox>
        <mat-checkbox color="primary" (change)="onCheckboxChanged('years', '3')">3</mat-checkbox>
        <mat-checkbox color="primary" (change)="onCheckboxChanged('years', '4')">4</mat-checkbox>
        <mat-checkbox color="primary" (change)="onCheckboxChanged('years', '5')">5</mat-checkbox>
      </div>
    </div>
    <div class="col m-0 p-0">
      <app-dea-label class="mb-2" [label]="'Sezioni:'"></app-dea-label>
      <div class="d-flex flex-row justify-content-start flex-wrap gap-3" (click)="getNumber()">
        <mat-checkbox color="primary" (change)="onCheckboxChanged('sections', 'A')">A</mat-checkbox>
        <mat-checkbox color="primary" (change)="onCheckboxChanged('sections', 'B')">B</mat-checkbox>
        <mat-checkbox color="primary" (change)="onCheckboxChanged('sections', 'C')">C</mat-checkbox>
      </div>
    </div>
  </div>
  <!-- DIPARTIMENTI -->
  <div class="v-spacer"></div>
  <app-dea-label class="mb-2" [label]="'Dipartimenti:'"></app-dea-label>
  <div class="d-flex flex-row justify-content-start flex-wrap gap-3" (click)="getNumber()">
    <mat-checkbox *ngFor="let d of departmentsListing" color="primary" (change)="onCheckboxChanged('departments', d)">
      {{d}}
    </mat-checkbox>
  </div>
  <!-- FILTRI STUDENTI -->
  <ng-container *ngIf="type === 'Student'">
    <div class="v-spacer"></div>
    <div class="row m-0 p-0">
      <div class="col m-0 p-0">
        <app-dea-label class="mb-2" [label]="'Filtri:'"></app-dea-label>
        <div class="d-flex flex-row justify-content-start flex-wrap gap-3" (click)="getNumber()">
          <mat-checkbox color="primary" (change)="onCheckboxChanged('features', 'bes')">BES</mat-checkbox>
          <mat-checkbox color="primary" (change)="onCheckboxChanged('features', 'dsa')">DSA</mat-checkbox>
          <mat-checkbox color="primary" (change)="onCheckboxChanged('features', 'rel')">REL</mat-checkbox>
          <mat-checkbox color="primary" (change)="onCheckboxChanged('features', 'atl')">ATL</mat-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- FILTRI STUDENTI -->
  <!-- TEXT-QUERY -->
  <div class="v-spacer"></div>
  <div class="dea-border dea-radius p-3">
    <app-dea-label class="mb-2" [label]="'Filtro testuale:'"></app-dea-label>
    <div class="my-3 input-group flex-nowrap">
      <input type="text" class="form-control rounded-pill px-3 pe-5 py-1" placeholder="Testo da cercare"
        aria-label="Testo da cercare" aria-describedby="addon-wrapping" (keyup)="onText($event)">
      <span class="ps-2" style="position:absolute;right:12px;top:3px;z-index:10;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
          viewBox="0 0 16 16">
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
      </span>
    </div>
    <app-dea-label class="mb-2" [label]="'Seleziona i campi in cui eseguire la ricerca testuale:'"></app-dea-label>
    <div class="d-flex flex-row justify-content-start flex-wrap gap-3" (click)="getNumber()">
      <mat-checkbox *ngFor="let f of fieldListing" color="primary" (change)="onCheckboxChanged('fields', f.be)">
        {{f.fe}}
      </mat-checkbox>
    </div>
  </div>
  <!-- FOOTER -->
  <div class="v-spacer"></div>
  <div class="v-spacer"></div>
  <div class="hstack">
    <div class="fw-bold">
      Utenti: <span class="counter-number" [appCountUp]=number.toString() [duration]="1000"></span>
    </div>
    <div class="ms-auto">
      <button *ngIf="!getting" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
        (click)="getData()">
        Esporta
      </button>
      <button *ngIf="getting" mat-flat-button class="fw-bold px-4 py-1 rounded-pill" disabled>
        <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Esportazione in corso...
      </button>
    </div>
  </div>
</app-dea-widget-wrapper>
