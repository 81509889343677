import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeaInfoDialogData } from 'src/app/constants/dialog-interfaces';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dea-single-btn-dialog',
  templateUrl: './dea-single-btn-dialog.component.html',
  styleUrls: ['./dea-single-btn-dialog.component.scss'],
})
export class DeaSingleBtnDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeaSingleBtnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaInfoDialogData
  ) {
    if (!data.title) data.title = 'Attenzione';
    if (!data.message) data.message = 'Si è verificato un errore';
    if (!data.button) data.button = 'Ok';
    if (data.error) {
      data.title = data.error.title;
      data.message = data.error.message;
      data.details = data.error.details;
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
