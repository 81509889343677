import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeaInfoDialogData } from 'src/app/constants/dialog-interfaces';
import { Component, Inject } from '@angular/core';
import { ClientStorageService } from 'src/app/services/client-storage.service';

@Component({
  selector: 'app-dea-new-version-dialog',
  templateUrl: './dea-new-version-dialog.component.html',
  styleUrls: ['./dea-new-version-dialog.component.scss'],
})
export class DeaNewVersionDialogComponent {
  constructor(
    private _clientStorageService: ClientStorageService,
    public dialogRef: MatDialogRef<DeaNewVersionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaInfoDialogData
  ) {
    data.title = 'Novità:';
    data.message = 'È disponibile una nuova versione di questa app!';
    data.details = 'Esegui il refresh della pagina per aggiornarla!';
    data.button = 'AGGIORNA';
  }
  onDismiss(): void {
    this._clientStorageService.setVersionHash();
    window.location.reload();
    this.dialogRef.close();
  }
}
