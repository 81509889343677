import { Enrollment } from 'src/app/models/enrollment/enrollment.model';
import { CfData } from 'src/app/models/cf-data.model';
import { inject, Injectable } from '@angular/core';
import { ToolService } from '../tool.service';
import { Subscription } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import {
  FormArray,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { User } from 'src/app/models/user/user.model';
import { UserTypeBackendStr } from 'src/app/models/user/user-type';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentFormService {
  private cfChecking: boolean = false;
  private _toolService: ToolService = inject(ToolService);
  private cfSubscriptions: Subscription[] = [];
  private _baseAcademicYearsListing: {
    label: string;
    description?: string;
    value: string | number | boolean;
  }[] = [
    { value: '1', label: '1°' },
    { value: '2', label: '2°' },
    { value: '3', label: '3°' },
    { value: '4', label: '4°' },
    { value: '5', label: '5°' },
  ];
  private readonly _options = [
    {
      value: 'MILANO',
      label: 'Istituti E. De Amicis - Milano',
      description: 'Via Lamarmora, 34 - 20122, Milano (MI)',
      departmentsListing: [
        {
          title: 'Secondaria di I Grado',
          academicYearsListing: [
            { value: '1', label: '1°' },
            { value: '2', label: '2°' },
            { value: '3', label: '3°' },
          ],
        },
        {
          title: 'Liceo delle Scienze Umane',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico Sportivo',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Linguistico Internazionale',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Classico',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'ITEM',
          academicYearsListing: [
            { value: '1', label: '1°' },
            { value: '2', label: '2°' },
            { value: '3', label: '3°' },
            { value: '4', label: '4°' },
          ],
        },
      ],
    },
    {
      value: 'GORGONZOLA',
      label: 'Licei Santagostino - Gorgonzola',
      description: 'Via Matteotti, 30 - 20064, Gorgonzola (MI)',
      departmentsListing: [
        {
          title: 'Liceo Linguistico Internazionale',
          academicYearsListing: this._baseAcademicYearsListing,
        },
        {
          title: 'Liceo Scientifico Sportivo',
          academicYearsListing: this._baseAcademicYearsListing,
        },
      ],
    },
  ];
  enrollment: Enrollment = new Enrollment();
  index: number = 0;
  steps: readonly string[] = [
    'Configurazione',
    'Studente',
    'Genitori | Tutori',
    'Finalizzazione',
    'Anteprima',
  ];

  enrollmentForm: FormGroup = new FormGroup({
    sede: new FormControl(),
    anno_scolastico: new FormControl(),
    dipartimento: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    anno: new FormControl({ value: 1, disabled: true }),
    users: new FormArray([]),
    religione: new FormControl(),
    dea_sport: new FormControl(),
    regolamento: new FormControl(),
    prima_lingua: new FormControl('Inglese'),
    seconda_lingua: new FormControl(),
    terza_lingua: new FormControl(),
    privacy_policy_1: new FormControl(),
    privacy_policy_2: new FormControl(),
    privacy_policy_3: new FormControl(),
    ultimo_anno: new FormGroup({
      anno_scolastico: new FormControl(),
      istituto: new FormControl(),
      tipo: new FormControl(),
      dettaglio: new FormControl({ value: '', disabled: true }),
      esito: new FormControl({ value: '', disabled: true }),
    }),
  });

  get enrollmentFormUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls;
  }

  get enrollmentFormStudentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Student'
    );
  }

  get enrollmentFormNotStudentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value !== 'Student'
    );
  }

  get enrollmentFormParentUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Parent'
    );
  }

  get enrollmentFormTutorUsers() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Tutor'
    );
  }

  get enrollmentFormParentUsersNumber() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Parent'
    ).length;
  }

  get enrollmentFormTutorNumber() {
    return (this.enrollmentForm.get('users') as FormArray)?.controls?.filter(
      (user) => user.get('__type')?.value === 'Tutor'
    ).length;
  }

  get headquartersListing() {
    return this._options;
  }

  public dynamicDepartmentsListing: string[] = [];

  public dynamicAcademicYearsListing = this._baseAcademicYearsListing;

  constructor() {
    this.initializeEnrollmentFormSession();
  }

  canGoToPrevIndex(): boolean {
    return this.index > 0;
  }

  canGoToNextIndex(): boolean {
    return this.index < this.steps.length - 1;
  }

  goToPrevIndex(): void {
    this.index -= 1;
  }

  goToNextIndex(): void {
    this.index += 1;
  }

  private isPrinting: boolean = false;
  printForm(delay: number = 500) {
    if (this.isPrinting) return;
    this.isPrinting = !this.isPrinting;
    setTimeout(() => {
      this.isPrinting = !this.isPrinting;
      console.info('ENROLLMENT-FORM:', this.enrollmentForm.value);
    }, delay);
  }

  getBaseUserForm(
    type?: 'Student' | 'Parent' | 'Tutor',
    user?: User
  ): FormGroup {
    if (user) {
      switch (user.type?.backend) {
        case UserTypeBackendStr.STUDENT:
          type = 'Student';
          break;
        case UserTypeBackendStr.PARENT:
          type = 'Parent';
          break;
        case UserTypeBackendStr.TUTOR:
          type = 'Tutor';
          break;
      }
    }
    return new FormGroup({
      __uuid: new FormControl(user?.__uuid ?? uuidV4()),
      __type: new FormControl(type, [Validators.required]),
      cognome: new FormControl(user?.cognome ?? undefined, [
        Validators.required,
        Validators.minLength(2),
      ]),
      nome: new FormControl(user?.nome ?? undefined, [
        Validators.required,
        Validators.minLength(2),
      ]),
      secondo_nome: new FormControl(user?.secondo_nome ?? undefined),
      codice_fiscale: new FormControl(user?.codice_fiscale ?? undefined, [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
      ]),
      sesso: new FormControl(user?.sesso ?? undefined, Validators.required),
      data_di_nascita: new FormControl(
        user?.data_di_nascita
          ? moment(user.data_di_nascita).format('YYYY-MM-DD')
          : undefined,
        Validators.required
      ),
      luogo_di_nascita: new FormControl(
        user?.luogo_di_nascita ?? undefined,
        Validators.required
      ),
      provincia_di_nascita: new FormControl(
        user?.provincia_di_nascita ?? undefined,
        Validators.required
      ),
      nazionalita: new FormControl(
        user?.nazionalita ?? undefined,
        Validators.required
      ),
      domicilio: new FormGroup({
        indirizzo: new FormControl(
          user?.domicilio?.address ?? undefined,
          Validators.required
        ),
        civico: new FormControl(
          user?.domicilio?.civic ?? undefined,
          Validators.required
        ),
        cap: new FormControl(
          user?.domicilio?.cap ?? undefined,
          Validators.required
        ),
        citta: new FormControl(
          user?.domicilio?.city ?? undefined,
          Validators.required
        ),
        provincia: new FormControl(
          user?.domicilio?.province ?? undefined,
          Validators.required
        ),
      }),
      residenza: new FormGroup({
        indirizzo: new FormControl(
          user?.residenza?.address ?? undefined,
          Validators.required
        ),
        civico: new FormControl(
          user?.residenza?.civic ?? undefined,
          Validators.required
        ),
        cap: new FormControl(
          user?.residenza?.cap ?? undefined,
          Validators.required
        ),
        citta: new FormControl(
          user?.residenza?.city ?? undefined,
          Validators.required
        ),
        provincia: new FormControl(
          user?.residenza?.province ?? undefined,
          Validators.required
        ),
      }),
      contatti: new FormGroup({
        email: new FormArray([]),
        telefono: new FormArray<any>([]),
      }),
    });
  }

  pushUser(userType: 'Student' | 'Parent' | 'Tutor') {
    this.removeCfListeners();
    (this.enrollmentForm.controls['users'] as FormArray).push(
      this.getBaseUserForm(userType)
    );

    if (this.enrollmentFormParentUsersNumber >= 2) {
      const tutorsArray = this.enrollmentFormTutorUsers;
      tutorsArray.forEach((tutor: any) => tutor?.get('__type')?.disable());
    }
    if (this.enrollmentFormTutorNumber >= 2) {
      const parentsArray = this.enrollmentFormParentUsers;
      parentsArray.forEach((parent: any) => parent?.get('__type')?.disable());
    }

    this.setCfListeners();
  }

  removeUser(userUUID: string) {
    const index: number = this.enrollmentFormUsers?.findIndex(
      (user) => user.get('__uuid')?.value === userUUID
    );
    if (index !== -1) this.enrollmentFormUsers.splice(index, 1);
  }

  setCfListeners(): void {
    this.enrollmentFormUsers?.map((user, i) => {
      let sub = user.get('codice_fiscale')?.valueChanges.subscribe(() => {
        if (user.get('codice_fiscale')?.value.length === 16) {
          const cf = user.get('codice_fiscale')?.value;
          if (!this.cfChecking) {
            this.cfChecking = true;
            this._toolService
              .getDataFromCF(cf)
              .subscribe({
                error: (e) =>
                  console.error(
                    `Data from CF for user at index ${i} error:`,
                    e
                  ),
                next: (response) => {
                  if (response?.data?.dati) {
                    const cfData: CfData = new CfData(response.data);
                    this.setDataFromCf(
                      this.enrollmentFormUsers?.at(i) as FormGroup,
                      cfData
                    );
                  }
                },
              })
              .add(() => (this.cfChecking = false));
          }
        }
      });
      if (sub) this.cfSubscriptions.push(sub);
    });
  }

  removeCfListeners() {
    this.cfSubscriptions.map((sub) => sub.unsubscribe());
    this.cfSubscriptions = [];
  }

  getUserByUUID(userUUID: string): FormGroup {
    return this.enrollmentFormUsers
      ?.filter((user) => user.get('__uuid')?.value === userUUID)
      ?.at(0) as FormGroup;
  }

  setDataFromCf(user: FormGroup, data: CfData) {
    user.get('sesso')?.setValue(data.gender);
    user.get('nazionalita')?.setValue('Italiana');
    user.get('luogo_di_nascita')?.setValue(data.birthplace);
    user.get('provincia_di_nascita')?.setValue(data.birthplaceProvincia);
    user
      .get('data_di_nascita')
      ?.setValue(
        data?.birthday
          ? new Date(data.birthday)?.toISOString()?.slice(0, 10)
          : undefined
      );
  }

  copyAddressDataFromTo(
    user: FormGroup,
    fromPath: 'domicilio' | 'residenza',
    toPath: 'domicilio' | 'residenza'
  ) {
    user.get(toPath)?.setValue(user.get(fromPath)?.getRawValue());
  }

  copyAddressDataFromStudentTo(
    user: FormGroup,
    path: 'domicilio' | 'residenza'
  ) {
    user
      .get(path)
      ?.setValue(
        this.enrollmentFormStudentUsers?.at(0)?.get(path)?.getRawValue()
      );
  }

  getListOfContacts(user: FormGroup, type: 'email' | 'telefono'): FormArray {
    return user.get('contatti')?.get(type) as FormArray;
  }

  addContactFields(user: FormGroup, type: 'email' | 'telefono', contact?: any) {
    if (type === 'email' && contact?.phone !== undefined) contact = {};
    if (type === 'telefono' && contact?.address !== undefined) contact = {};
    (user.get('contatti')?.get(type) as FormArray)?.push(
      type === 'email'
        ? new FormGroup({
            etichetta: new FormControl(contact?.etichetta ?? 'Personale'),
            indirizzo: new FormControl(contact?.address ?? undefined, [
              Validators.required,
              Validators.email,
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
            ]),
            enable: new FormControl(contact?.enable ?? true),
          })
        : new FormGroup({
            etichetta: new FormControl(contact?.etichetta ?? 'Cellulare'),
            numero: new FormControl(
              contact?.phone ?? undefined,
              Validators.required
            ),
            prefisso: new FormControl(contact?.prefisso ?? '+39'),
            enable: new FormControl(contact?.enable ?? true),
          })
    );
  }

  removeContactFields(
    user: FormGroup,
    type: 'email' | 'telefono',
    contactIndex: number
  ) {
    (user.get('contatti')?.get(type) as FormArray)?.removeAt(contactIndex);
  }

  setContactLabel(
    user: FormGroup,
    contactType: 'email' | 'telefono',
    contactIndex: number,
    label: 'Personale' | 'Lavoro' | 'Casa'
  ) {
    (user.get('contatti')?.get(contactType) as FormArray)
      ?.at(contactIndex)
      ?.get('etichetta')
      ?.setValue(label);
  }

  setDocument(user: FormGroup, documentSide: 'fronte' | 'retro', data: string) {
    user.get('ci')?.get(documentSide)?.setValue(data);
  }

  removeDocument(user: FormGroup, documentSide: 'fronte' | 'retro') {
    user.get('ci')?.get(documentSide)?.setValue(undefined);
  }

  initializeEnrollmentFormSession(enrollment?: Enrollment): void {
    console.info('ENROLLMENT =>', enrollment);
    console.info(
      '*** enrollment?.religione?.toString() ?? undefined =>',
      enrollment?.religione?.toString()
    );
    // Imposto i valori di default;
    this.enrollmentForm = new FormGroup({
      sede: new FormControl(enrollment?.sede ?? undefined, Validators.required),
      anno_scolastico: new FormControl(
        enrollment?.anno_scolastico ?? undefined,
        Validators.required
      ),
      dipartimento: new FormControl(
        enrollment?.dipartimento ?? undefined,
        Validators.required
      ),
      anno: new FormControl(
        enrollment?.anno?.toString() ?? undefined,
        Validators.required
      ),
      users: new FormArray([]),
      religione: new FormControl(
        enrollment?.religione?.toString() ?? undefined,
        Validators.required
      ),
      dea_sport: new FormControl(
        enrollment?.dea_sport?.toString() ?? undefined,
        Validators.required
      ),
      regolamento: new FormControl(
        enrollment?.regolamento?.toString(),
        Validators.required
      ),
      prima_lingua: new FormControl(enrollment?.prima_lingua),
      seconda_lingua: new FormControl(enrollment?.seconda_lingua),
      terza_lingua: new FormControl(enrollment?.terza_lingua),
      // privacy_policy_1: new FormControl(enrollment?.privacy_policy_1),
      // privacy_policy_2: new FormControl(enrollment?.privacy_policy_2),
      // privacy_policy_3: new FormControl(enrollment?.privacy_policy_3),
      ultimo_anno: new FormGroup({
        anno_scolastico: new FormControl(
          enrollment?.ultimo_anno?.anno_scolastico ?? undefined
        ),
        istituto: new FormControl(
          enrollment?.ultimo_anno?.istituto ?? undefined
        ),
        tipo: new FormControl(enrollment?.ultimo_anno?.tipo ?? undefined),
        dettaglio: new FormControl(
          enrollment?.ultimo_anno?.dettaglio ?? undefined
        ),
        esito: new FormControl(enrollment?.ultimo_anno?.esito ?? undefined),
      }),
    });

    if (enrollment?.users && enrollment.users.length !== 0) {
      const usersFormArray: FormArray = this.enrollmentForm.get(
        'users'
      ) as FormArray;
      for (let u = 0; u < enrollment.users.length; u++) {
        // Creo il form per l'utente
        const newUserFormGroup = this.getBaseUserForm(
          undefined,
          enrollment.users[u]
        );
        // Aggiungo l'utente al main-form
        usersFormArray.push(newUserFormGroup);
        // Aggiorno la sezione dei contatti
        enrollment.users.at(u)?.contatti?.email?.map((emailContact) => {
          this.addContactFields(newUserFormGroup, 'email', emailContact);
        });
        enrollment.users.at(u)?.contatti?.phone?.map((phoneContact) => {
          this.addContactFields(newUserFormGroup, 'telefono', phoneContact);
        });
      }
    }
  }

  validateStep(i: number, event: Event, alertsAllowed: boolean): boolean {
    let showDialog: boolean = false;

    const controlsAreValid = (
      fields: string[],
      control?: FormGroup<any> | AbstractControl<any, any>
    ) => {
      control ??= this.enrollmentForm;
      let results: (boolean | undefined)[] = [];
      for (let i = 0; i < fields.length; i++) {
        if (control.get(fields[i])) {
          control.get(fields[i])?.markAsDirty();
          results.push(control.get(fields[i])?.valid);
        } else console.error('Error: field', fields[i], 'not found!');
      }
      return results.filter((r) => r !== true).length === 0;
    };
    const getValidContacts = (
      users?: (FormGroup<any> | AbstractControl<any, any>)[]
    ): { emails: string[]; phones: string[] } => {
      users ??= [];
      let emails: string[] = [];
      let phones: string[] = [];
      users.forEach((user: any) => {
        const emailsArray = user.get('contatti.email') as FormArray;
        const phonesArray = user.get('contatti.telefono') as FormArray;
        for (let e = emailsArray.length - 1; e >= 0; e--) {
          const email = emailsArray.at(e)?.get('indirizzo')?.value;
          if (email) emails.push(email);
          else this.removeContactFields(user, 'email', e);
        }
        for (let t = phonesArray.length - 1; t >= 0; t--) {
          const phone = phonesArray.at(t)?.get('numero')?.value;
          if (phone) phones.push(phone);
          else this.removeContactFields(user, 'telefono', t);
        }
      });
      return { emails, phones };
    };

    let fields: string[] = [];
    switch (i) {
      case 0:
        fields = ['sede', 'anno_scolastico', 'dipartimento', 'anno'];
        if (
          this.enrollmentForm.get('dipartimento')?.value ===
          'Liceo Linguistico Internazionale'
        ) {
          fields.push('seconda_lingua');
        }
        showDialog = !controlsAreValid(fields);
        break;
      case 1:
        fields = [
          'cognome',
          'nome',
          'codice_fiscale',
          'sesso',
          'data_di_nascita',
          'luogo_di_nascita',
          'provincia_di_nascita',
          'nazionalita',
          'domicilio.indirizzo',
          'domicilio.civico',
          'domicilio.cap',
          'domicilio.citta',
          'domicilio.provincia',
          'residenza.indirizzo',
          'residenza.civico',
          'residenza.cap',
          'residenza.citta',
          'residenza.provincia',
        ];
        getValidContacts(this.enrollmentFormStudentUsers);
        showDialog = !controlsAreValid(
          fields,
          this.enrollmentFormStudentUsers.at(0)
        );
        break;
      case 2:
        const users = this.enrollmentFormNotStudentUsers;
        if (users.length === 0) {
          if (alertsAllowed) {
            console.error('Should use ConfirmationService!');
            // this._confirmationService.confirm({
            //   target: event.target as EventTarget,
            //   message:
            //     'Per proseguire è necessario inserire almeno un genitore/tutore.',
            //   header: 'Attenzione',
            //   icon: 'pi pi-exclamation-triangle',
            //   acceptIcon: 'none',
            //   rejectIcon: 'none',
            //   acceptButtonStyleClass: 'p-button-primary p-button-outlined',
            //   rejectButtonStyleClass: 'p-button-primary p-button-link p-hide',
            //   acceptLabel: 'Ok, capito',
            // });
          }
          return false;
        }
        fields = [
          'cognome',
          'nome',
          'codice_fiscale',
          'sesso',
          'data_di_nascita',
          'luogo_di_nascita',
          'provincia_di_nascita',
          'nazionalita',
          'domicilio.indirizzo',
          'domicilio.civico',
          'domicilio.cap',
          'domicilio.citta',
          'domicilio.provincia',
          'residenza.indirizzo',
          'residenza.civico',
          'residenza.cap',
          'residenza.citta',
          'residenza.provincia',
        ];
        for (let u = 0; u < users.length; u++) {
          const check = controlsAreValid(
            fields,
            this.enrollmentFormNotStudentUsers.at(u)
          );
          if (!showDialog) showDialog = !check;
        }
        break;
      case 3:
        break;
      case 4:
        break;
    }

    if (showDialog && alertsAllowed) {
      console.error('Should use ConfirmationService!');
      // this._confirmationService.confirm({
      //   target: event.target as EventTarget,
      //   message:
      //     'Per proseguire è necessario compilare tutti i campi obbligatori.<br/><span class="tw-font-light tw-text-xs">Promemoria: tutti i campi sono obbligatori di default ad esclusione di quelli contrassegnati come opzionali</span>',
      //   header: 'Attenzione',
      //   icon: 'pi pi-exclamation-triangle',
      //   acceptIcon: 'none',
      //   rejectIcon: 'none',
      //   acceptButtonStyleClass: 'p-button-primary p-button-outlined',
      //   rejectButtonStyleClass: 'p-button-primary p-button-link p-hide',
      //   acceptLabel: 'Ok, capito',
      // });
    } else {
      if (i == 2) {
        const results = getValidContacts(this.enrollmentFormNotStudentUsers);
        const emails: string[] = results.emails;
        const phones: string[] = results.phones;
        const hasDuplicates = (array: string[]): boolean => {
          const uniqueValues = new Set(array);
          return uniqueValues.size !== array.length;
        };
        let message: string | undefined;
        if (emails.length === 0) {
          message =
            'Per proseguire è necessario inserire almeno 1 contatto email valido per genitori/tutori.';
        } else if (hasDuplicates(emails)) {
          message =
            'Sembra che un indirizzo email di un genitore/tutore sia stato inserito più volte.<br/><span class="tw-font-light tw-text-xs">Per proseguire è necessario sostituire i contatti email duplicati con dei valori validi.</span>';
        } else if (phones.length === 0) {
          message =
            'Per proseguire è necessario inserire almeno un contatto telefonico valido per genitori/tutori.';
        } else if (hasDuplicates(phones)) {
          message =
            'Sembra che un numero di telefono di un genitore/tutore sia stato inserito più volte.<br/><span class="tw-font-light tw-text-xs">Per proseguire è necessario sostituire i contatti telefonici duplicati con dei valori validi.</span>';
        }
        if (message) {
          showDialog = true;
          if (alertsAllowed) {
            console.error('Should use ConfirmationService!');
            // this._confirmationService.confirm({
            //   target: event.target as EventTarget,
            //   header: 'Attenzione',
            //   message: message,
            //   icon: 'pi pi-exclamation-triangle',
            //   acceptIcon: 'none',
            //   rejectIcon: 'none',
            //   acceptButtonStyleClass: 'p-button-primary p-button-outlined',
            //   rejectButtonStyleClass: 'p-button-primary p-button-link p-hide',
            //   acceptLabel: 'Ok, capito',
            // });
          }
        }
      }
    }
    return !showDialog;
  }
}
