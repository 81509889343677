import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dea-img-dialog',
  templateUrl: './dea-img-dialog.component.html',
  styleUrls: ['./dea-img-dialog.component.scss'],
})
export class DeaImgDialogComponent {
  refreshed: boolean = false;
  imgScr: string = '';
  id: string = '';

  constructor(
    private _authService: AuthService,
    public dialogRef: MatDialogRef<DeaImgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imgScr = this.data;
  }

  onError(error: any) {
    if (error.status === 401 && !this.refreshed) {
      this.refreshed = true;
      this._authService
        .token()
        .subscribe()
        .add(() => {
          this.imgScr = '';
          this.imgScr = this.data;
        });
    } else {
      console.error('Errore generale');
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
