import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-listing-top-bar',
  templateUrl: './listing-top-bar.component.html',
  styleUrls: ['./listing-top-bar.component.scss'],
})
export class ListingTopBarComponent {
  @Input() linkMainBtn: string = '';
  @Input() linkMainBtnQueryParams: object = {};
  @Input() actionBtn: boolean = false;
  @Input() actionBtnTitle: string = '';
  @Output() textEmitter = new EventEmitter<string>();

  onText(text: string): void {
    this.textEmitter.emit(text);
  }
}
