import { DeaUserListingDialogData } from 'src/app/constants/dialog-interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { SubjectService } from 'src/app/services/subject.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Subject } from 'src/app/models/subject.model';

@Component({
  selector: 'app-subjects-dialog',
  templateUrl: './subjects-dialog.component.html',
  styleUrls: ['./subjects-dialog.component.scss'],
})
export class SubjectsDialogComponent {
  @Input() linkMainBtnQueryParams: object = {};
  @Input() limit: number = 10;
  @Input() page: number = 0;
  @Input() type?: string;

  public loading: boolean = true;
  public isError: boolean = false;
  public pagination?: ApiPagination;
  public subjects: Subject[] = [];
  public error?: ApiError;
  private text?: string;

  constructor(
    private _subjectService: SubjectService,
    public dialogRef: MatDialogRef<SubjectsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaUserListingDialogData
  ) {
    if (data.type) this.type = data.type;
  }

  onSelected(id?: string): void {
    const selectedUser: Subject[] = id
      ? this.subjects.filter((s) => s.id === id)
      : [];
    const dialogOutput: any = {
      id: selectedUser[0].id || '',
      dea_id: selectedUser[0].dea_id || '',
      nome: selectedUser[0].nome || '',
    };
    this.dialogRef.close(dialogOutput);
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._subjectService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
      })
      .subscribe({
        next: (data) => {
          this.subjects = data?.data?.map((json: any) => new Subject(json));
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Subjects Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
