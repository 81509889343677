import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-dea-thumbnail-img',
  templateUrl: './dea-thumbnail-img.component.html',
  styleUrls: ['./dea-thumbnail-img.component.scss'],
})
export class DeaThumbnailImgComponent implements OnChanges {
  @Input() loading: string = 'lazy';
  @Input() size: string = '32';
  @Input() url?: string;
  @Input() id?: string;

  isError: boolean = false;
  scr: string = API.transparent();

  constructor() {
    this.loadImg();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.loadImg();
  }

  loadImg(): void {
    this.isError = false;
    if (this.url) this.scr = this.url;
    else if (this.id) this.scr = API.userThumbnail(this.id, true);
    else this.scr = API.transparent();
  }

  onThumbnailError(event: any) {
    if (!this.isError) {
      this.isError = true;
      event.target.src = '/assets/thumbnail.webp';
    }
  }
}
