import { ApiPagination } from '../models/api/api-pagination.model';
import { UserListingParameters } from '../constants/interfaces';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user/user.model';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getListing(parameters: UserListingParameters) {
    return this.http
      .get<ApiResponse<User[]>>(API.userListing(parameters), {
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          res.pagination = new ApiPagination(res.pagination);
          res.data = res.data?.map((user) => new User(user));
          return res;
        })
      );
  }

  getDetail(id?: string) {
    return this.http.get<ApiResponse<any>>(API.userDetail(id), {
      withCredentials: true,
    });
  }

  postThumbnail(id: string, formData: FormData) {
    return this.http.post(API.userThumbnail(id, true), formData, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  deleteThumbnail(id: string) {
    return this.http.delete<ApiResponse<any>>(API.userThumbnail(id), {
      withCredentials: true,
    });
  }

  postUser(data?: any) {
    return this.http.post<ApiResponse<any>>(API.newUser(), data, {
      withCredentials: true,
    });
  }

  putUser(id?: string, data?: any) {
    return this.http.put<ApiResponse<any>>(API.editUser(id), data, {
      withCredentials: true,
    });
  }

  getUserJustificationsCards(id?: string) {
    return this.http.get<ApiResponse<any>>(API.userJustificationsCards(id), {
      withCredentials: true,
    });
  }

  getFormListing(id?: string) {
    return this.http.get<ApiResponse<any>>(API.userForms(id), {
      withCredentials: true,
    });
  }

  patchCorsoSicurezza(id?: string, value?: boolean) {
    return this.http.patch<ApiResponse<any>>(
      API.patchCorsoSicurezza(id),
      value != undefined ? { corso_sicurezza: value } : {},
      { withCredentials: true }
    );
  }

  getClasses(id?: string) {
    return this.http.get<ApiResponse<any>>(API.userClasses(id), {
      withCredentials: true,
    });
  }

  getBirthdaysListing(parameters?: { types?: string; date?: string }) {
    return this.http.get<ApiResponse<any>>(API.birthdaysListing(parameters), {
      withCredentials: true,
    });
  }
}
