<app-dea-widget-wrapper>
  <div *ngIf="!status" class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
  <div *ngIf="status && status.groupList">
    <div class="hstack">
      <div>
        <div class="fs-3 fw-semibold"><i class="bi bi-activity"></i> {{status.title}}</div>
        <div class="fs-6 opacity-50">{{status.date | date:'yyyy-MM-dd - HH:mm:ss'}}</div>
      </div>
      <div class="ms-auto pointer" (click)="onRefresh()" title="Aggiorna i dati">
        <i class="bi bi-arrow-clockwise"></i>
      </div>
      <a class="ms-3 pointer" title="Pagina pubblica" target="_blank" rel="noopener noreferrer"
        href="http://status.deamicis.cloud">
        <i class="bi bi-share"></i>
      </a>
    </div>
    <div *ngFor="let group of status.groupList">
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="v-spacer"></div>
        <div class="fs-5 fw-medium m-0 p-0 mb-3">{{group?.name || ''}}</div>
        <div *ngFor="let monitor of group?.monitorList" class="row m-0 p-3 shadow-sm mb-3 dea-radius">
          <div class="hstack m-0 p-0">
            <div class="px-3 rounded rounded-pill fs-075r fw-semibold" [ngClass]="{
              'bg-success': getClassBool('bg-success', monitor.uptime),
              'bg-warning': getClassBool('bg-warning', monitor.uptime),
              'bg-danger': getClassBool('bg-danger', monitor.uptime) }">
              {{monitor.uptime | percent:'1.2-2'}}
            </div>
            <div class="ms-3 fs-6 m-0 p-0">{{monitor?.name || ''}}</div>
            <div class="ms-auto d-flex gap-1 me-0 justify-content-end" style="max-width: 380px;">
              <div *ngFor="let h of monitor?.heartbeat" class="m-0 p-0 dea-radius w-100 pointer"
                [ngClass]="{'bg-success':h.status == 1,'bg-danger':h.status == 0,'bg-warning':h.status == 2 }"
                title="{{h.time}}">
                 
              </div>
            </div>
          </div>
          <!-- <div class="d-flex gap-1 my-2" style="width: 350px;">
            <div *ngFor="let h of monitor?.heartbeat" class="m-0 p-0 dea-radius w-100 pointer"
              [ngClass]="{'bg-success':h.status == 1,'bg-danger':h.status == 0,'bg-warning':h.status == 2 }"
              title="{{h.time}}">
               
            </div>
          </div> -->
          <!-- <div class="hstack opacity-75">
            <div class="m-0 p-0 fs-055r">
              <span *ngIf="monitor && monitor.heartbeat && monitor.heartbeat.length > 0">
                | {{ monitor.heartbeat[0].time | date:'yyyy-MM-dd • HH:mm' }}
              </span>
            </div>
            <div class="ms-auto m-0 p-0 fs-055r">
              <span *ngIf="monitor && monitor.heartbeat && monitor.heartbeat.length > 0">
                {{ monitor.heartbeat[monitor.heartbeat.length - 1].time | date:'yyyy-MM-dd • HH:mm' }} |
              </span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</app-dea-widget-wrapper>