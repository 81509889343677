import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-tech-block-section-head',
  template: `<div class="row m-0 p-0 gap-3">
    <div class="col-auto m-0 p-0 d-flex flex-column justify-content-center">
      <app-dea-chip-status
        [label]="label"
        [status]="true"
      ></app-dea-chip-status>
    </div>
    <div
      class="col-auto m-0 p-0 fs-075r opacity-75 d-flex flex-column justify-content-center"
    >
      {{ tech.createdAt | toItDateDdMmmmYyyy }}
    </div>
    <div
      class="col m-0 p-0 text-end fs-075r opacity-50 d-flex flex-column justify-content-center"
    >
      {{ tech.anno_scolastico }}
    </div>
    <div
      *ngIf="editBtn"
      (click)="this.editAction.emit()"
      class="col-auto m-0 p-0 px-2 fs-075r dea-icon-btn d-flex flex-column justify-content-center"
      title="Modifica{{
        label !== '' && label !== undefined && label !== null ? ' ' + label : ''
      }}"
    >
      <i class="bi bi-pencil-square"></i>
    </div>
  </div>`,
  styles: [],
})
export class UserTechBlockSectionHeadComponent {
  @Output() editAction: EventEmitter<any> = new EventEmitter();
  @Input() editBtn: boolean = false;
  @Input() label!: string;
  @Input() tech?: any;
}
