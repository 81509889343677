import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { AppTools } from '../utils/tools';

@Directive({
  selector: 'input[toTitleCase]',
})
export class InputToTitleCaseDirective extends DefaultValueAccessor {
  @HostListener('input', ['$event']) input($event: InputEvent) {
    const target = $event.target as HTMLInputElement;
    const start = target.selectionStart;
    target.value = AppTools.capitalizeFirstLetterOfEachWord(target.value);
    target.setSelectionRange(start, start);
    this.onChange(target.value);
  }

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }
}
