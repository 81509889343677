<app-dea-dialog-wrapper>
  <div class="fw-semibold text-center fs-4 mb-2">Nuova Cartella</div>
  <form id="newFolderForm" [formGroup]="newFolderForm">
    <input type="text" formControlName="name" class="form-control dea-form-input" placeholder="Nome nuova cartella"
      toTitleCase />
    <div *ngIf="newFolderForm?.get('name')?.invalid && newFolderForm?.get('name')?.touched"
      class="px-2 text-danger fs-075r">
      <p *ngIf="newFolderForm?.get('name')?.errors?.['required']">Inserire il nome della cartella</p>
      <p *ngIf="newFolderForm?.get('name')?.errors?.['minlength']">Il nome deve avere almeno 3 caratteri</p>
    </div>
  </form>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
      Annulla
    </button>
    <button form="newFolderForm" class="px-5 py-1" mat-flat-button color="primary" (click)="onConfirm()">
      Crea
    </button>
  </div>
</app-dea-dialog-wrapper>