import { CalendarEvent } from 'src/app/models/calendar/calendar-event.model';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-month-view',
  templateUrl: './calendar-month-view.component.html',
  styleUrl: './calendar-month-view.component.scss',
})
export class CalendarMonthViewComponent implements OnInit {
  @Input() public mainDate: moment.Moment = moment();
  @Input() public events: CalendarEvent[] = [];

  public today: moment.Moment = moment();

  monthDates: moment.Moment[] = [];
  weekDates: moment.Moment[] = [];
  weekDays: string[] = [];
  dayHours: string[] = [];

  ngOnInit(): void {
    moment.updateLocale('it', { week: { dow: 1 } });
    this.dayHours = Array.from({ length: 24 }, (_, i) => `${i}:00`);
    this.weekDays = moment.weekdaysShort(true);
    this.generateMonthView();
  }

  generateMonthView(): void {
    this.monthDates = [];
    const startOfMonth = this.mainDate.clone().startOf('month').startOf('week');
    const endOfMonth = this.mainDate.clone().endOf('month').endOf('week');

    let date = startOfMonth.clone();
    while (!date.isAfter(endOfMonth, 'day')) {
      this.monthDates.push(date.clone());
      date.add(1, 'day');
    }
  }

  getNumberOfEvents(date: moment.Moment): string {
    let filteredEvents = this.events.filter((e: CalendarEvent) =>
      date.isSame(e.start, 'day')
    );
    if (filteredEvents.length != 0) {
      return `${filteredEvents.length} ${
        filteredEvents.length === 1 ? 'evento' : 'eventi'
      }`;
    }
    return ' ';
  }
}
