import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    return this.http.post<ApiResponse<string>>(
      API.login(),
      { username: username, password: password },
      {
        withCredentials: true,
        responseType: 'json',
        headers: {
          Audience: 'Dashboard',
          'X-Auth-Mode': 'cookie',
          'Content-Type': 'application/json',
        },
      }
    );
  }

  logout() {
    return this.http.get(API.logout(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  token() {
    return this.http.get(API.token(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        Audience: 'Dashboard',
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  session() {
    return this.http.get(API.session(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  tmpCredentials(email?: string, withCredentials?: boolean) {
    withCredentials ??= false;
    return this.http.get<ApiResponse<any>>(API.tmpCred(email), {
      withCredentials: withCredentials,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  credentials(
    email: string,
    tmp_password: string,
    password: string,
    verify_password: string
  ) {
    return this.http.post(
      API.credentials(),
      { email, tmp_password, password, verify_password },
      {
        withCredentials: true,
        responseType: 'json',
        headers: {
          Audience: 'Dashboard',
          'X-Auth-Mode': 'cookie',
          'Content-Type': 'application/json',
        },
      }
    );
  }

  // Sections Authorization
  getSectionsAuth() {
    return this.http.get(API.dashboardSections(), {
      withCredentials: true,
    });
  }
}
