import { HttpClient } from '@angular/common/http';
import { Observable, map, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

interface ThumbnailCache {
  url: string;
  blob: Blob;
}

@Injectable({
  providedIn: 'root',
})
export class ThumbnailCacheService {
  private _cachedImages: ThumbnailCache[] = [];
  private _objURL: string[] = [];

  constructor(private _http: HttpClient) {}

  setCachedImage(image: ThumbnailCache) {
    if (this._cachedImages.findIndex((img) => img.url === image.url) === -1) {
      this._cachedImages.push(image);
    }
  }

  get(url?: any, withCredentials: boolean = false): Observable<string> {
    if (typeof url === 'string') {
      const index = this._cachedImages.findIndex((image) => image.url === url);
      if (index > -1) {
        const image = this._cachedImages[index];
        const newObjURL = URL.createObjectURL(image.blob);
        this._objURL.push(newObjURL);
        return of(newObjURL);
      }
      return this._http
        .get(url, { withCredentials: withCredentials, responseType: 'blob' })
        .pipe(
          tap((blob: Blob) => this.setCachedImage({ url: url, blob: blob }))
        )
        .pipe(
          map((blob: Blob) => {
            const newObjURL = URL.createObjectURL(blob);
            this._objURL.push(newObjURL);
            return newObjURL;
          })
        );
    } else return of(API.transparent());
  }

  clear(url?: string): void {
    const index = this._cachedImages.findIndex((img) => img.url === url);
    if (index > -1) this._cachedImages.splice(index, 1);
  }

  clearAll(): void {
    this._cachedImages = [];
    while (this._objURL.length > 0) {
      URL.revokeObjectURL(this._objURL.pop() ?? '');
    }
  }
}
