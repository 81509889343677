<app-dea-widget-wrapper>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="hstack">
        <div class="fw-semibold">Ospite</div>
        <div class="ms-3 opacity-50">{{guestAttendance?.id}}</div>
        <div class="ms-auto">
          <button mat-icon-button aria-label="Elimina Presenza" title="Elimina" color="warn"
            (click)="deleteGuestAttendanceConfirmDialog()">
            <mat-icon class="mb-1">delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <img mat-card-image class="rounded-circle my-auto dea-shadow" width="64" height="64" loading="lazy"
            src="https://static.deamicis.cloud/user/thumbnail.webp">
        </div>
        <div class="col m-0 p-0 ps-3">
          <!-- FULL NAME -->
          <app-user-base-data [showFullData]="false" [user]="guestUser"></app-user-base-data>
          <app-details-divider></app-details-divider>
          <div class="v-spacer"></div>
          <app-attendance-in-out [entrata]="guestAttendance?.entrata" [uscita]="guestAttendance?.uscita">
          </app-attendance-in-out>
          <div class="v-spacer"></div>
          <app-details-divider></app-details-divider>
          <div class="v-spacer"></div>
          <app-guest-attendance-signature [base64]="guestAttendance?.firma"></app-guest-attendance-signature>
          <div class="v-spacer"></div>
          <app-details-divider></app-details-divider>
          <div class="v-spacer"></div>
          <app-guest-card [guestCard]="guestAttendance?.guest_card"></app-guest-card>
        </div>
      </div>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>