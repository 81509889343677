<app-dea-dialog-wrapper [title]="dialogTitle">
  @if(event.id) {
  <div class="d-flex justify-content-end">
    <div class="fs-065r opacity-50">{{event.id}}</div>
  </div>
  }
  <form [formGroup]="eventFormGroup">
    <div class="row row-cols-2 gap-0 row-gap-3" style="max-height: calc(100vh - 200px); overflow-y: scroll;">
      <div class="w-100">
        <app-dea-label class="mb-2" [label]="'Titolo:'"></app-dea-label>
        <input formControlName="title" type="text" class="form-control dea-form-input" placeholder="Titolo"
          toTitleCase />
      </div>
      <div class="w-100">
        <app-dea-label class="mb-2" [label]="'Descrizione:'"></app-dea-label>
        <textarea formControlName="description" class="form-control dea-form-input" placeholder="Descrizione" rows="3">
      </textarea>
      </div>
      <div>
        <app-dea-label class="mb-2" [label]="'Inizio:'"></app-dea-label>
        <input formControlName="start" type="datetime-local" class="form-control dea-form-input" placeholder="Inizio" />
      </div>
      <div>
        <app-dea-label class="mb-2" [label]="'Fine:'"></app-dea-label>
        <input formControlName="end" type="datetime-local" class="form-control dea-form-input" placeholder="Fine" />
      </div>
      <div class="w-100">
        <app-dea-label class="mb-2" [label]="'Fuso Orario:'"></app-dea-label>
        <select class="form-select" formControlName="tz">
          <option *ngFor="let timeZone of timeZones;" value="{{timeZone.value}}">
            {{timeZone.name}}
          </option>
        </select>
      </div>
      <div class="w-100 d-flex flex-row justify-content-end">
        <app-dea-label [label]="'Evento Ricorrente:'"></app-dea-label>
        <mat-slide-toggle formControlName="isRecursiveEvent" class="ms-3" color="primary"></mat-slide-toggle>
      </div>

      @if(eventFormGroup.get('isRecursiveEvent')?.value === true) {
      <ng-container formGroupName="recurrence" class="gap-3">
        <div class="w-100 d-flex flex-row align-items-center">
          <app-dea-label class="text-nowrap" style="min-width: 90px;" [label]="'Ripeti ogni:'"></app-dea-label>
          <input formControlName="interval" type="text" class="form-control dea-form-input me-2"
            placeholder="Ripeti ogni" justInt />
          <select class="form-select" formControlName="frequency">
            <option *ngFor="let frequencyOption of frequencyOptions;" value="{{frequencyOption.value}}">
              {{frequencyOption.name}}
            </option>
          </select>
        </div>
        <div class="w-100 d-flex flex-row align-items-center">
          <app-dea-label class="text-nowrap" style="min-width: 90px;" [label]="'Si ripete il:'"></app-dea-label>
          <div class="m-0 p-0 w-100 d-flex justify-content-between gap-2">
            <ng-container *ngFor="let byDayOption of byDayOptions">
              <div class="checkbox-btn-container">
                <input type="checkbox" class="btn-check" autocomplete="off"
                  [id]="'btn-check-outlined-'+byDayOption.value"
                  [checked]="eventFormGroup.controls['recurrence'].get('byDay')?.value.includes(byDayOption.value)"
                  (change)="setUpByDay(byDayOption.value)">
                <label [for]="'btn-check-outlined-'+byDayOption.value" title="{{byDayOption.title}}"
                  class="btn btn-outline-primary border border-rounded">
                  {{byDayOption.name}}
                </label>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="w-100">
          <app-dea-label class="text-nowrap" style="min-width: 90px;" [label]="'Fine:'"></app-dea-label>
          <mat-radio-group formControlName="endOption" class="d-flex flex-column gap-1 align-items-start"
            color="primary">
            <mat-radio-button value="never">Mai</mat-radio-button>
            <mat-radio-button value="date">
              <div class="d-flex flex-row align-items-center gap-2 w-50">
                <div style="min-width: 45px;">Data</div>
                <div>
                  <input formControlName="endDate" type="date" class="form-control dea-form-input"
                    title="Data fine evento ricorrente" />
                </div>
              </div>
            </mat-radio-button>
            <mat-radio-button value="count">
              <div class="d-flex flex-row align-items-center gap-2 w-50">
                <div style="min-width: 45px;">Dopo</div>
                <input formControlName="count" type="text" class="form-control dea-form-input me-2" title="Numero"
                  justInt />
                <div>{{eventFormGroup.get('recurrence')?.get('count')?.value === '1' ? 'Occorrenza' : 'Occorrenze'}}
                </div>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>
      }
      <div>
        <app-dea-label class="mb-2" [label]="'Luogo:'"></app-dea-label>
        <input formControlName="location" type="text" class="form-control dea-form-input" placeholder="Luogo" />
      </div>
      <div>
        <app-dea-label class="mb-2" [label]="'Stanza:'"></app-dea-label>
        <input formControlName="room" type="text" class="form-control dea-form-input" placeholder="Stanza" />
      </div>
      <div class="w-100">
        <app-dea-label class="mb-2" [label]="'Link:'"></app-dea-label>
        <input formControlName="meeting_link" type="text" class="form-control dea-form-input" placeholder="Link" />
      </div>
      <div class="w-100">
        <app-dea-label class="mb-2" [label]="'Colore:'"></app-dea-label>
        <mat-radio-group class="color-radio-group" formControlName="color">
          <mat-radio-button color="primary" *ngFor="let colorConfig of colors" [value]="colorConfig.hex"
            title="{{colorConfig.name}}">
            <span class="color-circle" [style.backgroundColor]="colorConfig.hex"></span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="w-100">
        <app-dea-label class="mb-2" [label]="'Note:'"></app-dea-label>
        <textarea formControlName="note" class="form-control dea-form-input" placeholder="Note" rows="3">
          </textarea>
      </div>
      @if(eventFormGroup.get('__type')?.value === 'TIMETABLE-EVENT') {
      <div class="w-75">
        <app-dea-label [label]="'Tipo:'"></app-dea-label>
        <select class="form-select" formControlName="timetable_block">
          <option value="LEZIONE">Lezione</option>
          <option value="RICEVIMENTO">Ricevimento</option>
          <option value="DISPOSIZIONE">Disposizione</option>
          <option value="SORVEGLIANZA">Sorveglianza</option>
        </select>
      </div>
      <div class="w-25 d-flex flex-row gap-3 justify-content-end align-items-center pt-3">
        <app-dea-label [label]="'Out:'"></app-dea-label>
        <mat-slide-toggle formControlName="out" color="primary"></mat-slide-toggle>
      </div>
      <!-- TEACHERS -->
      <div class="w-100">
        <div class="hstack">
          <app-dea-label class="mb-2" [label]="'Docenti:'"></app-dea-label>
          <div class="ms-auto">
            <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Docente\''"
              (click)="openUsersDialog('Teacher', 'teachers')">
            </app-dea-label>
          </div>
        </div>
        <div class="row m-0 p-0 gap-3" formArrayName="teachers">
          <ng-container *ngFor="let item of teachersEventFormGroup.controls; let i = index;" [formGroupName]="i">
            <div class="col-10 m-0 p-0">
              <div class="row m-0 p-0 gap-3">
                <div class="col m-0 p-0">
                  <input formControlName="cognome" type="text" class="form-control dea-form-input" placeholder="Cognome"
                    toTitleCase />
                </div>
                <div class="col m-0 p-0">
                  <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                    toTitleCase />
                </div>
                <div class="col m-0 p-0">
                  <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                    placeholder="Secondo Nome" toTitleCase />
                </div>
              </div>
            </div>
            <div class="col m-0 p-0 text-center">
              <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                (click)="deleteItemInAt('teachers',i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- MATERIE -->
      <div class="w-100">
        <div class="hstack">
          <app-dea-label class="mb-2" [label]="'Materia:'"></app-dea-label>
          <div class="ms-auto">
            @if(eventFormGroup.get('subject')?.get('_id')?.value != undefined &&
            eventFormGroup.get('subject')?.get('nome')?.value != undefined) {
            <!-- <app-dea-label class="mb-2 fast-action-btn fs-075r" style="cursor: not-allowed;"
              [label]="'Aggiungi \'Materia\''">
            </app-dea-label> -->
            } @else {
            <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Materia\''"
              (click)="openSubjectsDialog()">
            </app-dea-label>
            }
          </div>
        </div>
        @if(eventFormGroup.get('subject')?.get('_id')?.value != undefined &&
        eventFormGroup.get('subject')?.get('nome')?.value != undefined) {
        <div class="row m-0 p-0 gap-3" formGroupName="subject">
          <div class="col-10 m-0 p-0">
            <div class="row m-0 p-0 gap-3">
              <div class="col m-0 p-0">
                <input formControlName="dea_id" type="text" class="form-control dea-form-input"
                  placeholder="DEA-ID Materia" toUpperCase />
              </div>
              <div class="col m-0 p-0">
                <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome Materia"
                  toTitleCase />
              </div>
            </div>
          </div>
          <div class="col m-0 p-0 text-center">
            <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
              (click)="deleteSubject()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        }
      </div>
      <!-- CLASSI -->
      <div class="w-100">
        <div class="hstack">
          <app-dea-label class="mb-2" [label]="'Classi:'"></app-dea-label>
          <div class="ms-auto">
            <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Classe\''"
              (click)="openClassesDialog()">
            </app-dea-label>
          </div>
        </div>
        <div class="row m-0 p-0 gap-3" formArrayName="classes">
          <ng-container *ngFor="let item of classesEventFormGroup.controls; let i = index;" [formGroupName]="i">
            <div class="col-10 m-0 p-0">
              <div class="row m-0 p-0 gap-3">
                <div class="col-2 m-0 p-0">
                  <input formControlName="year" type="text" class="form-control dea-form-input" placeholder="Anno" />
                </div>
                <div class="col-2 m-0 p-0">
                  <input formControlName="section" type="text" class="form-control dea-form-input"
                    placeholder="Sezione" />
                </div>
                <div class="col m-0 p-0">
                  <input formControlName="department" type="text" class="form-control dea-form-input"
                    placeholder="Dipartimento" />
                </div>
              </div>
            </div>
            <div class="col m-0 p-0 text-center">
              <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                (click)="deleteItemInAt('classes',i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      }
    </div>
    <!-- SUBMIT -->
    <div class="v-spacer"></div>
    @if(error) {
    <div class="text-end text-error fs-085r">
      {{error.message}}
    </div>
    <div class="v-spacer"></div>
    }
    <div class="hstack">
      <div class="ms-auto">
        <button *ngIf="!posting" mat-flat-button color="secondary" class="fw-bold px-4 py-1 rounded-pill"
          (click)="cancel()">
          Annulla
        </button>
      </div>
      <div class="ms-2">
        <button *ngIf="!posting" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
          (click)="postData()">
          Salva
        </button>
        <button *ngIf="posting" mat-flat-button class="fw-bold px-4 py-1 rounded-pill" disabled>
          <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Salva
        </button>
      </div>
    </div>
  </form>
</app-dea-dialog-wrapper>
