import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class JustificationsCardService {
  text?: string;
  page: number = 0;
  limit: number = 20;

  constructor(private http: HttpClient) {}

  getListing(p?: { date?: string; tag?: string }) {
    return this.http.get<ApiResponse<any>>(
      API.getJustificationsCards(
        {
          page: this.page,
          limit: this.limit,
          txt: this.text,
        },
        p?.tag,
        p?.date
      ),
      { withCredentials: true }
    );
  }

  postJustificationsCard(user_id?: string, type?: number, tag?: string) {
    return this.http.post<ApiResponse<any>>(
      API.createJustificationsCard(),
      { user_id: user_id ? user_id : 'ID-ERROR', type, tag },
      { withCredentials: true }
    );
  }

  patchJustificationsCard(cardId?: string, type?: number) {
    return this.http.patch<ApiResponse<any>>(
      API.patchJustificationsCard(cardId, type),
      {},
      { withCredentials: true }
    );
  }

  removeLastJustificationToCard(cardId?: string, type?: number) {
    return this.http.delete<ApiResponse<any>>(
      API.patchJustificationsCard(cardId, type),
      { withCredentials: true }
    );
  }

  downloadPDF(id: string) {
    return this.http.get(API.downloadJustificationsCardPDF(id), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }
}
