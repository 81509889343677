<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <div class="text-center">
        <div>{{error?.message}}</div>
        <button *ngIf="user?.classe" class="mx-auto mt-3" mat-stroked-button color="primary"
          (click)="postJustificationCard(userId)">
          Nuova Card
        </button>
        <button *ngIf="user?.classe?.dipartimento == 'Liceo delle Scienze Umane'" class="ms-3 mx-auto mt-3"
          mat-stroked-button color="primary" (click)="postJustificationCard(userId, 0, 'LICOS')">
          Nuova Card LICOS
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <div class="row m-0 p-0">
      <div class="col-auto m-0 p-0">
        <div class="user-img-spacer"></div>
      </div>
      <div class="col m-0 p-0 ps-3">
        <div class="d-flex justify-content-end gap-4">
          <button *ngIf="canCreateNewCard && user?.classe" mat-stroked-button color="primary"
            (click)="postJustificationCard(userId)">
            Nuova Card
          </button>
          <button *ngIf="canCreateNewLICOSCard && user?.classe?.dipartimento == 'Liceo delle Scienze Umane'"
            mat-stroked-button color="primary" (click)="postJustificationCard(userId, 0, 'LICOS')">
            Nuova Card LICOS
          </button>
        </div>
        <div *ngFor="let justificationsCard of justificationsCards;">
          <div class="v-spacer"></div>
          <app-details-divider></app-details-divider>
          <div class="v-spacer"></div>
          <div class="ms-3 hstack">
            <div>
              <app-data-box [label]="'Anno Scolastico'" [data]="justificationsCard?.anno_scolastico"></app-data-box>
              <app-data-box *ngIf="justificationsCard.tag != undefined" [label]="'Tag'"
                [data]="justificationsCard?.tag">
              </app-data-box>
            </div>
            <div class="d-flex flex-column justify-content-start w-100">
              <div *ngIf="justificationsCard.type === 0 || justificationsCard.type === 1">
                <div class="hstack gap-2">
                  <div class="me-auto">
                    <app-dea-label [label]="'Ingressi posticipati'"></app-dea-label>
                    <app-justifications-counter [slots]="justificationsCard?.entry_slots"
                      [cards_counter]="justificationsCard?.entry_cards_counter"
                      [slots_counter]="justificationsCard?.entry_slots_counter">
                    </app-justifications-counter>
                  </div>
                  <button mat-stroked-button color="secondary" class="fw-bold px-3 py-1 rounded-pill" type="button"
                    (click)="addJustificationToCard(justificationsCard?.id, 1)" style="width: 190px;">
                    Giustifica Ingresso
                  </button>
                  <button mat-stroked-button color="secondary" class="fw-bold px-3 py-1 rounded-pill" type="button"
                    (click)="removeLastJustificationToCard(justificationsCard?.id, 1)"
                    title="Rimuovi l'utimo ingresso posticipato">
                    <i class="bi bi-arrow-counterclockwise"></i>
                  </button>
                </div>
              </div>
              <div *ngIf="justificationsCard.type === 0 || justificationsCard.type === 2">
                <div class="hstack gap-2">
                  <div class="me-auto">
                    <app-dea-label [label]="'Uscite anticipate'"></app-dea-label>
                    <app-justifications-counter [slots]="justificationsCard?.exit_slots"
                      [cards_counter]="justificationsCard?.exit_cards_counter"
                      [slots_counter]="justificationsCard?.exit_slots_counter">
                    </app-justifications-counter>
                  </div>
                  <button mat-stroked-button color="secondary" class="fw-bold px-3 py-1 rounded-pill" type="button"
                    (click)="addJustificationToCard(justificationsCard?.id, 2)" style="width: 190px;">
                    Giustifica Uscita
                  </button>
                  <button mat-stroked-button color="secondary" class="fw-bold px-3 py-1 rounded-pill" type="button"
                    (click)="removeLastJustificationToCard(justificationsCard?.id, 2)"
                    title="Rimuovi l'utima uscita anticipata">
                    <i class="bi bi-arrow-counterclockwise"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="ms-4 space-nowrap">
              <mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="openPdfPreviewPDF(justificationsCard?.id)"
                title="Visualizza anteprima">
                visibility
              </mat-icon>
              <mat-icon class="ms-3 m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="downloadPDF(justificationsCard?.id)"
                title="Download Modulo PDF">
                download
              </mat-icon>
            </div>
          </div>
          <div class="ms-3">
            <div class="v-spacer"></div>
            <div id="accordion-{{justificationsCard.id}}"
              class="accordion accordion-flush border border-opacity-50 rounded-2"
              *ngIf="(justificationsCard.entry_slots ?? []).length != 0 || (justificationsCard.exit_slots ?? []).length != 0">
              <!-- INGRESSI -->
              <div class="accordion-item rounded-2"
                *ngIf="(justificationsCard.type === 0 || justificationsCard.type === 1) && (justificationsCard?.entry_slots ?? []).length > 0">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed rounded-2" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#ingressi-' + justificationsCard.id" aria-expanded="false"
                    [attr.aria-controls]="'ingressi-' + justificationsCard.id">
                    <app-dea-label [label]="'Dettagli ingressi posticipati'"></app-dea-label>
                  </button>
                </h2>
                <div id="ingressi-{{justificationsCard.id}}" class="accordion-collapse collapse"
                  [attr.data-bs-parent]="'#accordion-' + justificationsCard.id">
                  <div class="accordion-body">
                    <div *ngFor="let slot of justificationsCard?.entry_slots?.reverse()" class="p-3"
                      style="border-bottom: 0.5px solid rgba(0,0,0,0.1); border-bottom: 0;">
                      {{slot | date:'yyyy-MM-dd'}} <span class="mx-1" style="opacity: 0.08;">|</span>
                      {{slot | date:'HH:mm:ss'}}
                    </div>
                  </div>
                </div>
              </div>
              <!-- USCITE -->
              <div class="accordion-item rounded-2"
                *ngIf="(justificationsCard.type === 0 || justificationsCard.type === 2) && (justificationsCard?.exit_slots ?? []).length > 0">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed rounded-2" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#uscite-' + justificationsCard.id" aria-expanded="false"
                    [attr.aria-controls]="'uscite-' + justificationsCard.id">
                    <app-dea-label [label]="'Dettagli uscite anticipate'"></app-dea-label>
                  </button>
                </h2>
                <div id="uscite-{{justificationsCard.id}}" class="accordion-collapse collapse"
                  [attr.data-bs-parent]="'#accordion-' + justificationsCard.id">
                  <div class="accordion-body">
                    <div *ngFor="let slot of justificationsCard?.exit_slots?.reverse()" class="p-3"
                      style="border-bottom: 0.5px solid rgba(0,0,0,0.1); border-bottom: 0;">
                      {{slot | date:'yyyy-MM-dd'}} <span class="mx-1" style="opacity: 0.08;">|</span>
                      {{slot | date:'HH:mm:ss'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>