import { MatDialogRef } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dea-welcome-dialog',
  templateUrl: './dea-welcome-dialog.component.html',
  styleUrls: ['./dea-welcome-dialog.component.scss'],
})
export class DeaWelcomeDialogComponent {
  public logoUrl = API.deaLogo();
  public data = {
    title: 'Da grandi poteri derivano grandi responsabilità',
    message: 'Ricorda che questa piattaforma gestisce dati condivisi',
    details: 'Se cancelli qualcosa o modifichi un dato, lo fai per tutti!',
    button: 'Ok',
  };

  constructor(public dialogRef: MatDialogRef<DeaWelcomeDialogComponent>) {}

  onDismiss(): void {
    this.dialogRef.close();
  }

  onThumbnailError(event: any) {
    event.target.src = API.deaLogo();
  }
}
