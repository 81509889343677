import { PhoneContact } from 'src/app/models/user/phone-contact.model';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-phone-box',
  templateUrl: './phone-box.component.html',
  styleUrls: ['./phone-box.component.scss'],
})
export class PhoneBoxComponent implements OnInit {
  @Input() data?: PhoneContact;

  label: string = 'Telefono';
  phoneNumber: string = '';
  enable: boolean = true;

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {
    if (this.data?.enable != undefined) this.enable = this.data.enable;
    if (this.data?.label) {
      this.label = ('Telefono ' + this.data.label).toString().trim();
    }
    if (this.data?.phone) {
      this.phoneNumber =
        parsePhoneNumberFromString(this.data?.phone, 'IT')?.number ??
        this.data?.phone;
    }
  }

  copyInClipboard() {
    this.clipboard.copy(this.phoneNumber);
  }

  onDoubleClick() {
    if (this.data) {
      const telLink = document.createElement('a');
      const linkPhone = this.phoneNumber?.replace('+', '%2B');
      telLink.href = `https://api.whatsapp.com/send/?phone=${linkPhone}&text&type=phone_number&app_absent=0`;
      telLink.target = '_blank';
      telLink.click();
    }
  }
}
