<div class="row m-0 p-0 mb-3">
  <div class="col-auto m-0 p-0">
    <div class="user-img-spacer"></div>
  </div>
  <div class="col m-0 p-0 ps-3">
    <div class="row m-0 p-0 mb-3">
      <div class="border rounded p-3">
        <div class="hstack">
          <div>Corso sulla sicurezza</div>
          <div class="ms-auto">
            <mat-checkbox color="primary" [labelPosition]="'before'" [checked]="corso_sicurezza"
              (change)="onChangeCheckboxCorsoSicurezza($event)">
            </mat-checkbox>
          </div>
        </div>
        <app-user-pcto-sicurezza-row-block [userId]="userId" [startLoading]="startLoading">
        </app-user-pcto-sicurezza-row-block>
      </div>
    </div>
  </div>
</div>
<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <div class="text-center">
        <div>{{error?.message}}</div>
        <button class="mx-auto mt-3" mat-stroked-button color="primary" (click)="openUploadDialog()">
          Carica Documento PCTO
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <div class="row m-0 p-0">
      <div class="col-auto m-0 p-0">
        <div class="user-img-spacer"></div>
      </div>
      <div class="col m-0 p-0 ps-3">
        <div class="hstack">
          <div class="fw-normal">
            <strong>Totale Ore: {{totaleOrePcto}}</strong>
          </div>
          <button class="ms-auto" mat-stroked-button color="primary" (click)="openUploadDialog()">
            Carica Documento PCTO
          </button>
        </div>
        <div class="row m-0 p-0" *ngFor="let doc of pctos;">
          <app-details-divider></app-details-divider>
          <app-user-pcto-row [pctoDoc]="doc" (userDocRowEmitter)="getDocumentsData()"></app-user-pcto-row>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>