import { PageDocumentForm } from './page-document-form';
import { User } from '../user/user.model';
import { DocumentFormItemType } from './item-document-form';

export class DocumentForm {
  id?: string;
  dea_id?: string;
  uuid?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  pages?: PageDocumentForm[];
  owner?: User;
  createdAt?: Date;
  updatedAt?: Date;

  public static fromJson(json?: any): DocumentForm {
    const newForm: DocumentForm = new DocumentForm();
    newForm.id = json['_id'] || json['id'];
    newForm.dea_id = json['dea_id'];
    newForm.uuid = json['uuid'];
    newForm.title = json['title'];
    newForm.subtitle = json['subtitle'];
    newForm.description = json['description'];
    newForm.owner = new User(json['owner']);
    newForm.pages = (json['pages'] || []).map((p: any) => {
      const newPage: PageDocumentForm = PageDocumentForm.fromJson({ ...p });
      return newPage;
    });
    newForm.createdAt = json['createdAt']
      ? new Date(json['createdAt'])
      : undefined;
    newForm.updatedAt = json['updatedAt']
      ? new Date(json['updatedAt'])
      : undefined;
    return newForm;
  }

  getClass(): string {
    let classe: string = '';
    try {
      for (let p = 0; p < this.pages!.length; p++) {
        for (let s = 0; s < this.pages![p].sections!.length; s++) {
          for (let i = 0; i < this.pages![p].sections![s].items!.length; i++) {
            const item = this.pages![p].sections![s].items![i];
            if (item.type && item.type.toString() === 'class') {
              if (item.answer) {
                classe = item.answer.toString().trim();
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('getClass() ERROR => ', error);
    }
    return classe;
  }

  getSubject(): string {
    let subject: string = '';
    try {
      for (let p = 0; p < this.pages!.length; p++) {
        for (let s = 0; s < this.pages![p].sections!.length; s++) {
          for (let i = 0; i < this.pages![p].sections![s].items!.length; i++) {
            const item = this.pages![p].sections![s].items![i];
            if (item.type && item.type.toString() === 'subject') {
              if (item.answer) {
                subject = item.answer.toString().trim();
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('getSubject() ERROR => ', error);
    }
    return subject;
  }
}
