import { SpecialEducationalNeeds } from './special-educational-needs.model';
import { UserSportInformation } from './user-sport-information.model';
import { Department, DepartmentUtils } from '../department.model';
import { Headquarter } from '../headquarter';
import { Class } from '../class/class.model';
import { Contacts } from './contacts.model';
import { Subject } from '../subject.model';
import { Address } from './address.model';
import { UserType } from './user-type';

import isMongoId from 'validator/es/lib/isMongoId';

export class User {
  id?: string;
  type?: UserType;
  sede?: Headquarter;
  archiviato?: boolean;
  dea_id?: string;
  nome?: string;
  secondo_nome?: string;
  cognome?: string;
  codice_fiscale?: string;
  sesso?: string;
  data_di_nascita?: Date;
  luogo_di_nascita?: string;
  provincia_di_nascita?: string;
  nazionalita?: string;
  domicilio?: Address;
  residenza?: Address;
  contatti?: Contacts;
  // // Student
  anno?: Number;
  dipartimento?: Department;
  bisogni_educativi_speciali?: SpecialEducationalNeeds;
  corso_sicurezza?: boolean;
  religione?: boolean;
  atleta?: UserSportInformation;
  classe?: Class;
  genitori?: User[] = [];
  tutori?: User[] = [];
  siblings?: User[] = [];
  totale_ritardi?: Number;
  // // Parent
  figli?: User[] = [];
  // // Tutor
  tutorati?: User[] = [];
  // // Teacher
  materie?: Subject[] = [];
  // Enrollment
  __uuid?: string;

  constructor(json?: any) {
    if (!json) return;
    if (isMongoId(json.toString().trim())) {
      this.id = json.toString().trim();
      return;
    }
    this.id = json['_id'] || json['id'];
    this.type = json['__type']
      ? UserType.fromString(json['__type'])
      : undefined;
    this.__uuid = json['__uuid'];
    this.sede = json['sede'] ? Headquarter.fromString(json['sede']) : undefined;
    this.archiviato =
      json['archiviato'] === undefined
        ? undefined
        : (json['archiviato'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.dea_id = json['dea_id'];
    this.nome = json['nome'];
    this.secondo_nome = json['secondo_nome'];
    this.cognome = json['cognome'];
    this.codice_fiscale = json['codice_fiscale'];
    this.sesso = json['sesso'];
    this.data_di_nascita = json['data_di_nascita']
      ? new Date(json['data_di_nascita'])
      : undefined;
    this.luogo_di_nascita = json['luogo_di_nascita'];
    this.provincia_di_nascita = json['provincia_di_nascita'];
    this.nazionalita = json['nazionalita'];
    this.domicilio = json['domicilio']
      ? Address.fromJson(json['domicilio'])
      : undefined;
    this.residenza = json['residenza']
      ? Address.fromJson(json['residenza'])
      : undefined;
    this.contatti = json['contatti']
      ? Contacts.fromJson(json['contatti'])
      : undefined;
    // STUDENT
    this.corso_sicurezza =
      json['corso_sicurezza'] === undefined
        ? undefined
        : (json['corso_sicurezza'] + '').toString().toLowerCase().trim() ===
          'true'
        ? true
        : false;
    this.religione =
      json['religione'] === undefined
        ? undefined
        : (json['religione'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.atleta = json['atleta']
      ? new UserSportInformation(json['atleta'])
      : undefined;
    // bisogni_educativi_speciali
    this.bisogni_educativi_speciali = json['bisogni_educativi_speciali']
      ? SpecialEducationalNeeds.fromJson(json['bisogni_educativi_speciali'])
      : undefined;
    this.anno = json['anno'];
    this.dipartimento = json['dipartimento']
      ? DepartmentUtils.fromString(json['dipartimento'])
      : undefined;
    this.classe = new Class(json['classe']);
    this.genitori = (json['genitori'] || []).map((json: any) => new User(json));
    this.tutori = (json['tutori'] || []).map((json: any) => new User(json));
    this.siblings = (json['siblings'] || []).map((json: any) => new User(json));
    this.totale_ritardi = json['totale_ritardi'];
    // PARENT
    this.figli = (json['figli'] || []).map((json: any) => new User(json));
    // TUTOR
    this.tutorati = (json['tutorati'] || []).map((json: any) => new User(json));
    // TEACHER
    this.materie = (json['materie'] || []).map((s: any) => new Subject(s));
  }

  toJson(defaultUndefined: boolean = true): any {
    const value = defaultUndefined ? undefined : '';
    let json = {
      __type: this.type?.backend || value,
      sede: this.sede?.backend || value,
      archiviato: this.archiviato === undefined ? value : this.archiviato,
      dea_id: this.dea_id?.toUpperCase() || value,
      cognome: this.cognome || value,
      nome: this.nome || value,
      secondo_nome: this.secondo_nome || value,
      codice_fiscale: this.codice_fiscale?.toUpperCase() || value,
      sesso: this.sesso?.toUpperCase() || value,
      data_di_nascita:
        this.data_di_nascita?.toISOString().slice(0, 10) || undefined,
      luogo_di_nascita: this.luogo_di_nascita || value,
      provincia_di_nascita: this.provincia_di_nascita?.toUpperCase() || value,
      nazionalita: this.nazionalita || value,
      domicilio: this.domicilio?.toJson() || value,
      residenza: this.residenza?.toJson() || value,
      contatti: this.contatti?.toJson() || value,
      __uuid: this.__uuid || value,
    };
    if (this.id) Object.assign(json, { _id: this.id });
    if (json.data_di_nascita === new Date().toISOString().slice(0, 10)) {
      console.info('BUG SAFARI');
      // Fix bug Safari
      // Se la data è uguale a quella di oggi => È stata impostata da Safari
      // Se la data è stata impostata da Safari => Imposto la data al valore stabilit
      json.data_di_nascita = undefined;
    }
    switch (this.type?.backend) {
      case UserType.STUDENT.backend:
        json = Object.assign(json, {
          anno: this.anno || value,
          dipartimento: this.dipartimento?.toString() || value,
          genitori: (this.genitori || []).map((u) => u.id?.toString()),
          tutori: (this.tutori || []).map((u) => u.id?.toString()),
        });
        if (this.corso_sicurezza != undefined) {
          json = Object.assign(json, { corso_sicurezza: this.corso_sicurezza });
        }
        if (this.religione != undefined) {
          json = Object.assign(json, { religione: this.religione });
        }
        if (this.atleta != undefined) {
          json = Object.assign(json, { atleta: this.atleta });
        }
        if (this.bisogni_educativi_speciali) {
          Object.assign(json, {
            bisogni_educativi_speciali:
              this.bisogni_educativi_speciali.toJson() || value,
          });
        }
        break;
      case UserType.PARENT.backend:
        json = Object.assign(json, {
          figli: (this.figli || []).map((u) => u.id?.toString()),
        });
        break;
      case UserType.TEACHER.backend:
        json = Object.assign(json, {
          materie: this.materie?.map((m) => m.id?.toString()),
        });
        break;
      case UserType.TUTOR.backend:
        json = Object.assign(json, {
          materie: (this.tutorati || []).map((t) => t.id?.toString()),
        });
        break;
      default:
        break;
    }
    return json;
  }

  getFullName(): string {
    return `${this.cognome} ${this.nome} ${this.secondo_nome}`
      .replaceAll('undefined', '')
      .trim();
  }

  hasClass(): boolean {
    return this.classe && this.classe != null && this.classe != undefined
      ? true
      : false;
  }

  isTeacher(): boolean {
    return this.type && this.type === UserType.TEACHER ? true : false;
  }

  isStudent(): boolean {
    return this.type && this.type === UserType.STUDENT ? true : false;
  }
}
