export class Resource {
  id?: string;
  name?: string;
  mimeType?: string;
  createdTime?: Date;
  parents?: string[];
  size?: number;
  extension?: string;
  ringFolder?: boolean;

  constructor(json?: any) {
    if (!!!json) return;
    this.id = json['_id'] || json['id'];
    this.name = json['name'];
    this.mimeType = json['mimeType'];
    this.createdTime = json['createdTime']
      ? new Date(json['createdTime'])
      : undefined;
    this.parents = json['parents']
      ? (json['parents'] || []).map((p: any) => p.trim())
      : [];
    this.size = json['size'];
    this.extension = json['extension'];
    this.ringFolder =
      json['ringFolder'] === undefined
        ? false
        : (json['ringFolder'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
  }

  fullname(): string {
    let str = this.name || '';
    str += this.extension ? '.' + this.extension : '';
    return str;
  }

  isSystemFolder(): boolean {
    return this.ringFolder || false;
  }

  isFolder(): boolean {
    return this.mimeType === 'application/vnd.google-apps.folder';
  }

  getIconClasses(): string {
    if (!this.mimeType) return 'bi bi-file-earmark';
    switch (this.mimeType) {
      case 'application/vnd.google-apps.folder':
        return 'bi bi-folder';
      case 'application/x-zip':
        return 'bi bi-file-zip';
      case 'application/pdf':
        return 'bi bi-filetype-pdf';
      case 'text/csv':
        return 'bi bi-filetype-csv';
      case 'image/png':
        return 'bi bi-filetype-png';
      case 'image/jpg':
        return 'bi bi-filetype-jpg';
      case 'image/jpeg':
        return 'bi bi-filetype-jpg';
      case 'image/webp':
        return 'bi bi-file-earmark-image';
      default:
        return 'bi bi-file-earmark';
    }
  }
}
