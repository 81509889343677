import { DeaSingleBtnDialogComponent } from '../dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { DeaConfirmDialogComponent } from '../dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { DeaPdfDialogComponent } from '../dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { EnrollmentService } from 'src/app/services/enrollment/enrollment.service';
import { MainLoaderService } from 'src/app/services/main-loader.service';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { Enrollment } from 'src/app/models/enrollment/enrollment.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Input, inject } from '@angular/core';
import { AppPaths } from 'src/app/constants/app-paths';
import { MatDialog } from '@angular/material/dialog';
import { AppTools } from 'src/app/utils/tools';
import { API } from 'src/app/constants/api';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enrollments',
  templateUrl: './enrollments.component.html',
  styleUrl: './enrollments.component.scss',
})
export class EnrollmentsComponent {
  @Input() page: number = 0;
  @Input() limit: number = 25;
  private _router: Router = inject(Router);
  private _dialog: MatDialog = inject(MatDialog);
  private _enrollmentService: EnrollmentService = inject(EnrollmentService);
  private _mainLoaderService: MainLoaderService = inject(MainLoaderService);
  public enrollments: Enrollment[] = [];
  public pagination?: ApiPagination;
  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;
  private text?: string;

  ngOnInit(): void {
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  getFormattedDate(date?: Date): string {
    moment.locale('it-IT');
    return AppTools.capitalizeFirstLetterOfEachWord(
      date ? moment(date).format('DD MMMM YYYY - HH:mm') : '-'
    );
  }

  async refreshData() {
    this.page = 0;
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    await this.getData();
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._enrollmentService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
      })
      .subscribe({
        next: (response) => {
          this.pagination = new ApiPagination(response?.pagination);
          this.enrollments = response?.data?.map(
            (json: any) => new Enrollment(json)
          );
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Enrollments Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  printEnrollmentPDF(id?: string) {
    if (!id) return;
    this._enrollmentService.getEnrollmentPDF(id).subscribe((response) => {
      const pdfBlob: Blob | null = response.body;
      if (!pdfBlob) return;
      // Crea un URL per il Blob
      const fileURL = URL.createObjectURL(pdfBlob);

      // Creiamo un iframe nascosto per la stampa
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none'; // Nascondiamo l'iframe
      iframe.src = fileURL;
      document.body.appendChild(iframe);

      // Una volta che l'iframe ha caricato il PDF, attiviamo la stampa
      iframe.onload = () => {
        iframe.contentWindow?.print();
      };
    });
  }

  openPreviewPDF(id?: string): void {
    if (!id) return;
    this._dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: {
        id: id || 'NO-ID',
        url: API.getEnrollmentPDF(id || 'ID-ERROR'),
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  downloadEnrollmentPDF(id?: string): void {
    if (!id) return;
    this._enrollmentService.getEnrollmentPDF(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      const selected = this.enrollments.filter(
        (enrollment) => enrollment.id == id
      );
      if (selected.length == 1) {
        a.download =
          `${moment(selected[0].createdAt).format('YYYY-MM-DD')} - Iscrizione ${
            selected[0].anno_scolastico
          } ${selected[0].getFirstUserFullName() || ''} (${
            selected[0].anno || ''
          } ${selected[0].dipartimento || ''})` + `.pdf`;
      } else a.download = new Date().toISOString() + '.pdf';
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }

  goToEnrollmentFormPage(id?: string): void {
    if (id) this._router.navigateByUrl(AppPaths.EDIT_ENROLLMENT + '/' + id);
  }

  async onChangeCheckbox(
    enrollment: Enrollment,
    type: 'segreteria_didattica' | 'segreteria_amministrativa'
  ) {
    let updated = {};
    switch (type) {
      case 'segreteria_didattica':
        updated = {
          segreteria_didattica:
            enrollment.segreteria_didattica === true ? false : true,
        };
        break;
      case 'segreteria_amministrativa':
        updated = {
          segreteria_amministrativa:
            enrollment.segreteria_amministrativa === true ? false : true,
        };
        break;
    }

    if (Object.keys(updated).length === 0) return;
    const index: number = this.enrollments
      .map((e) => e.id)
      .indexOf(enrollment.id);
    if (index < 0) return;
    this._enrollmentService
      .patchEnrollmentCheckBox(enrollment.id, updated)
      .subscribe({
        next: (response) => {
          this.enrollments[index] = new Enrollment(response.data);
        },
        error: (error) => {
          console.error('ERROR:', error);
          this.enrollments[index] = new Enrollment();
          setTimeout(() => (this.enrollments[index] = enrollment), 1);
        },
      });
  }

  deleteEnrollment(id?: string): void {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questa iscrizione?',
        details:
          "Attenzione: saranno eliminati anche tutti i documenti allegati all'iscrizione e l'operazione NON sarà reversibile.",
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult && id != undefined && id != '') {
        this._enrollmentService.delete(id).subscribe({
          next: (_: any) => this.getData(),
          error: (error: any) => {
            const apiError: ApiError = new ApiError(error);
            this._dialog.open(DeaSingleBtnDialogComponent, {
              maxWidth: '450px',
              minWidth: '450px',
              data: {
                message:
                  apiError.message ??
                  "Non è stato possibile completare l'operazione richiesta",
              },
              autoFocus: false,
              restoreFocus: false,
            });
            console.error('Delete Enrollment Error: ', error);
          },
        });
      }
    });
  }

  convertEnrollment(id?: string): void {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        message:
          'Confermi di voler convertire gli utenti registrati in questa iscrizione in utenti Ring?',
        details:
          "Attenzione: al termine dell'operazione non sarà più possibile modificare l'iscrizione e l'operazione non sarà reversibile.",
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, converti',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult && id != undefined && id != '') {
        this._mainLoaderService.show = true;
        this._enrollmentService
          .convert(id)
          .subscribe({
            next: (_: any) => this.getData(),
            error: (error: any) => {
              const apiError: ApiError = new ApiError(error);
              this._dialog.open(DeaSingleBtnDialogComponent, {
                maxWidth: '450px',
                minWidth: '450px',
                data: {
                  message:
                    apiError.message ??
                    "Non è stato possibile completare l'operazione richiesta",
                },
                autoFocus: false,
                restoreFocus: false,
              });
              console.error('Convert Enrollment Error: ', error);
            },
          })
          .add(() => (this._mainLoaderService.show = false));
      }
    });
  }
}
