<div class="two-columns-grid ps-2" style="border-left: 1px solid rgba(0,0,0,0.1);" *ngIf="user"
  [formGroup]="$any(user)">
  <div>
    <app-dea-label [label]="'Cognome:'"></app-dea-label>
    <input toTitleCase formControlName="cognome" type="text" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Nome:'"></app-dea-label>
    <input toTitleCase formControlName="nome" type="text" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Secondo Nome:'"></app-dea-label>
    <input toTitleCase formControlName="secondo_nome" type="text" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Codice Fiscale:'"></app-dea-label>
    <input toUpperCase formControlName="codice_fiscale" type="text" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Data di nascita:'"></app-dea-label>
    <input formControlName="data_di_nascita" type="date" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Luogo di nascita:'"></app-dea-label>
    <input toTitleCase formControlName="luogo_di_nascita" type="text" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Provincia di nascita:'"></app-dea-label>
    <input toUpperCase formControlName="provincia_di_nascita" type="text" class="form-control dea-form-input" />
  </div>
  <div>
    <app-dea-label [label]="'Nazionalità:'"></app-dea-label>
    <input toTitleCase formControlName="nazionalita" type="text" class="form-control dea-form-input" />
  </div>
  <ng-container formGroupName="domicilio">
    <div>Domicilio:</div>
    <div></div>
    <div>
      <app-dea-label [label]="'Indirizzo:'"></app-dea-label>
      <input toTitleCase formControlName="indirizzo" type="text" class="form-control dea-form-input" />
    </div>
    <div class="row m-0 p-0" style="gap: 2rem;">
      <div class="col m-0 p-0">
        <app-dea-label [label]="'Civico:'"></app-dea-label>
        <input toUpperCase formControlName="civico" type="text" class="form-control dea-form-input" />
      </div>
      <div class="col m-0 p-0">
        <app-dea-label [label]="'CAP:'"></app-dea-label>
        <input formControlName="cap" type="text" class="form-control dea-form-input" />
      </div>
    </div>
    <div>
      <app-dea-label [label]="'Città:'"></app-dea-label>
      <input toTitleCase formControlName="citta" type="text" class="form-control dea-form-input" />
    </div>
    <div>
      <app-dea-label [label]="'Provincia:'"></app-dea-label>
      <input toUpperCase formControlName="provincia" type="text" class="form-control dea-form-input" />
    </div>
  </ng-container>
  <div class="col-span-2">
    Contatti <span *ngIf="user.get('__type')?.value === 'Student'">dello studente</span><span
      *ngIf="user.get('__type')?.value === 'Parent'">del genitore</span><span
      *ngIf="user.get('__type')?.value === 'Tutor'">del tutore</span>:
  </div>
  <div>
    E-Mail:
  </div>
  <div class="d-flex flex-row justify-content-end">
    <app-dea-fast-action-btn (click)="enrollmentFormService.addContactFields(user, 'email')">
      Aggiungi indirizzo E-Mail
    </app-dea-fast-action-btn>
  </div>
  <ng-container *ngFor="let e of enrollmentFormService.getListOfContacts(user, 'email')?.controls; let ei = index;">
    <div class="col-span-2 d-flex flex-row gap-4" [formGroup]="$any(e)">
      <div style="width: 300px;">
        <app-dea-label [label]="'Etichetta:'"></app-dea-label>
        <mat-radio-group formControlName="etichetta" color="primary" class="d-flex flex-row gap-3">
          <mat-radio-button value="Personale">Personale</mat-radio-button>
          <mat-radio-button value="Lavoro">Lavoro</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="w-100">
        <app-dea-label [label]="'Indirizzo:'"></app-dea-label>
        <input formControlName="indirizzo" type="email" class="form-control dea-form-input" />
        <mat-checkbox color="primary" [labelPosition]="'after'" formControlName="enable">
          Contatto abilitato per le comunicazioni scolastiche
        </mat-checkbox>
      </div>
      <div class="d-flex flex-column justify-content-start align-items-start">
        <app-dea-label [label]="' '"></app-dea-label>
        <button class="m-0 p-0 pt-1" mat-icon-button color="primary" aria-label="Elimina contatto E-Mail"
          title="Elimina contatto E-Mail" (click)="enrollmentFormService.removeContactFields(user, 'email', ei)">
          <mat-icon style="transform: scale(0.8);">delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <div>
    Telefono:
  </div>
  <div class="d-flex flex-row justify-content-end">
    <app-dea-fast-action-btn (click)="enrollmentFormService.addContactFields(user, 'telefono')">
      Aggiungi numero di telefono
    </app-dea-fast-action-btn>
  </div>
  <ng-container *ngFor="let t of enrollmentFormService.getListOfContacts(user, 'telefono')?.controls; let it = index;">
    <div class="col-span-2 d-flex flex-row gap-4" [formGroup]="$any(t)">
      <div style="width: 300px;">
        <app-dea-label [label]="'Etichetta:'"></app-dea-label>
        <mat-radio-group formControlName="etichetta" color="primary" class="d-flex flex-row gap-3">
          <mat-radio-button value="Cellulare">Cellulare</mat-radio-button>
          <mat-radio-button value="Lavoro">Lavoro</mat-radio-button>
          <mat-radio-button value="Casa">Casa</mat-radio-button>
        </mat-radio-group>
      </div>
      <div style="width: 80px;">
        <app-dea-label [label]="'Prefisso:'"></app-dea-label>
        <input formControlName="prefisso" type="text" class="form-control dea-form-input" />
      </div>
      <div class="w-100">
        <app-dea-label [label]="'Numero:'"></app-dea-label>
        <input formControlName="numero" type="text" class="form-control dea-form-input" />
        <mat-checkbox color="primary" [labelPosition]="'after'" formControlName="enable">
          Contatto abilitato per le comunicazioni scolastiche
        </mat-checkbox>
      </div>
      <div class="d-flex flex-column justify-content-start align-items-start">
        <app-dea-label [label]="' '"></app-dea-label>
        <button class="m-0 p-0 pt-1" mat-icon-button color="primary" aria-label="Elimina contatto telefonico"
          title="Elimina contatto telefonico" (click)="enrollmentFormService.removeContactFields(user, 'telefono', it)">
          <mat-icon style="transform: scale(0.8);">delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</div>