import { RingFormAnswer } from './ring-form-answer.model';

export class RingFormCell {
  id?: string;
  type?: string;
  header?: boolean;
  title?: string;
  subtitle?: string;
  description?: string;
  information?: string;
  input_type?: string;
  optional?: boolean;
  multi?: boolean;
  label?: string;
  answers?: RingFormAnswer[];

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.type = json['type'];
    this.header =
      json['header'] === undefined
        ? undefined
        : (json['header'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.title = json['title'];
    this.subtitle = json['subtitle'];
    this.description = json['description'];
    this.information = json['information'];
    this.input_type = json['input_type'];
    this.optional =
      json['optional'] === undefined
        ? undefined
        : (json['optional'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.multi =
      json['multi'] === undefined
        ? undefined
        : (json['multi'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.label = json['label'];
    this.answers = (json['answers'] || []).map(
      (json: any) => new RingFormAnswer(json)
    );
  }
}
