import { User } from 'src/app/models/user/user.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-users-listing',
  templateUrl: './custom-users-listing.component.html',
  styleUrls: ['./custom-users-listing.component.scss'],
})
export class CustomUsersListingComponent {
  @Input() users: User[] = [];
  @Input() idHightlights: string[] = [];

  constructor() {}

  isHightlight(id?: string): boolean {
    return id ? this.idHightlights.includes(id) : false;
  }
}
