import { API } from 'src/app/constants/api';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-homepage-wrapper',
  templateUrl: './dea-homepage-wrapper.component.html',
  styleUrls: ['./dea-homepage-wrapper.component.scss'],
})
export class DeaHomepageWrapperComponent {
  @Input() showBackHomeBtn: boolean = false;
  iconUrl = API.deAmicisRingIcon();
}
