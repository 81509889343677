import { UserPctoUploadDialogComponent } from './user-pcto-upload-dialog/user-pcto-upload-dialog.component';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Pcto } from 'src/app/models/user/user-pcto/pcto.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { PctoService } from 'src/app/services/pcto.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-pcto-data',
  templateUrl: './user-pcto-data.component.html',
  styleUrls: ['./user-pcto-data.component.scss'],
})
export class UserPctoDataComponent implements OnChanges {
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() startLoading: boolean = false;
  @Input() corso_sicurezza?: boolean;
  @Input() userId?: string;

  totaleOrePcto: number = 0;
  pctos: Pcto[] = [];

  constructor(
    private dialog: MatDialog,
    private _userService: UserService,
    private _userPctoService: PctoService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading'].currentValue &&
      !changes['startLoading'].previousValue
    ) {
      this.getDocumentsData();
    }
  }

  onChangeCheckboxCorsoSicurezza(event: any) {
    // TODO: GESTIRE ERRORI!
    this.corso_sicurezza = event.checked;
    this._userService
      .patchCorsoSicurezza(this.userId, event.checked)
      .subscribe({
        next: (data: any) => {
          if ([true, false].includes(data?.data?.corso_sicurezza)) {
            this.corso_sicurezza = data?.data?.corso_sicurezza;
          }
        },
        error: (error) => console.error('User Corso Sicurezza Error: ', error),
      });
  }

  async getDocumentsData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userPctoService
      .getPctoListing(this.userId)
      .subscribe({
        next: (data) => {
          if (data.data != undefined && (data.data as Pcto[])) {
            this.pctos = [];
            this.totaleOrePcto = 0;
            //@ts-ignore
            data.data.forEach((doc: any) => {
              const newPCTO = Pcto.fromJson(doc);
              this.totaleOrePcto += newPCTO.ore || 0;
              this.pctos.push(newPCTO);
            });
          }
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User PCTO Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  openUploadDialog() {
    const uploadDialogRef = this.dialog.open(UserPctoUploadDialogComponent, {
      maxWidth: '480px',
      minWidth: '480px',
      data: {
        userId: this.userId,
        type: 'PCTO',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    uploadDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === true) this.getDocumentsData();
    });
  }
}
