import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-guest-attendance-signature',
  templateUrl: './guest-attendance-signature.component.html',
  styleUrls: ['./guest-attendance-signature.component.scss'],
})
export class GuestAttendanceSignatureComponent {
  @Input() base64?: string;
}
