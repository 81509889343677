import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { Component, inject, Input, OnInit } from '@angular/core';
import { LimboService } from 'src/app/services/limbo.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { User } from 'src/app/models/user/user.model';

enum LimboSet {
  OLD = 0,
  NEW = 1,
  FUTURE = 2,
}

@Component({
  selector: 'app-limbo',
  templateUrl: './limbo.component.html',
  styleUrls: ['./limbo.component.scss'],
})
export class LimboComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 20;
  @Input() type?: string;
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() linkMainBtn: string = '';
  @Input() linkMainBtnQueryParams: object = {};

  private _limboService: LimboService = inject(LimboService);

  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public users: User[] = [];
  public error?: ApiError;
  private text?: string;

  public tabIndex = 1;

  public selectedFutureYear?: string;
  public scholasticFutureYears: string[] = [
    ScholasticYear.getFromCurrent(1),
    ScholasticYear.getFromCurrent(2),
    ScholasticYear.getFromCurrent(3),
  ];

  onSelectedIndexChange(event: any) {
    this.tabIndex = event;
    this.getData();
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onSelectFutureYear(year: string) {
    this.selectedFutureYear = year;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._limboService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
        set: LimboSet[this.tabIndex],
        year: this.selectedFutureYear,
      })
      .subscribe({
        next: (data) => {
          this.users = data?.data?.map((json: any) => new User(json));
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
