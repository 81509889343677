import { DeaPdfDialogComponent } from '../../dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { DeaImgDialogComponent } from '../../dea-pack/dea-img-dialog/dea-img-dialog.component';
import { CsvSelectorDialogComponent } from './dialogs/csv-selector-dialog.component';
import { EmailContact } from 'src/app/models/user/email-contact.model';
import { Discipline } from 'src/app/models/class/discipline.model';
import { ClassesService } from 'src/app/services/classes.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Class } from 'src/app/models/class/class.model';
import { UserType } from 'src/app/models/user/user-type';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { API } from 'src/app/constants/api';
import {
  EmailSelectorDialogType,
  EmailSelectorDialogComponent,
} from './dialogs/email-selector-dialog.component';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss'],
})
export class ClassDetailsComponent implements OnInit {
  public loading: boolean = false;
  public isError: boolean = false;
  public error?: ApiError;
  public classId?: string;
  public class?: Class;

  constructor(
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _classService: ClassesService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.classId = params['id'];
      this.getData();
    });
  }

  getDisciplineListing(discipline?: Discipline[]): string {
    if (!discipline || discipline.length === 0) return '';
    let disciplineStr: string[] = [];
    for (let i = 0; i < discipline.length; i++) {
      let nome = discipline[i].materia?.nome;
      if (typeof nome === 'string') disciplineStr.push(nome);
    }
    return disciplineStr.join(', ');
  }

  getFigliTutorati(user?: User): string {
    let usersId: string[] = [];
    let fullNames: string[] = [];
    user?.figli?.forEach((user: User) => {
      if (user.id) usersId.push(user.id);
    });
    user?.tutorati?.forEach((user: User) => {
      if (user.id) usersId.push(user.id);
    });
    usersId.forEach((id: string) => {
      const filtered: User[] = (this.class?.studenti || []).filter(
        (student: User) => student.id === id
      );
      if (filtered.length === 1) fullNames.push(filtered[0].getFullName());
    });
    return fullNames.join(', ');
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._classService
      .getDetail(this.classId)
      .subscribe({
        next: (data) => (this.class = new Class(data?.data)),
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Class Detail Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  emailLisitng() {
    const emailSelectorDialogRef = this._dialog.open(
      EmailSelectorDialogComponent,
      {
        maxWidth: '450px',
        minWidth: '450px',
        autoFocus: false,
        restoreFocus: false,
      }
    );
    emailSelectorDialogRef
      .afterClosed()
      .subscribe((options: EmailSelectorDialogType[] | undefined) => {
        if (options !== undefined) {
          let allEmail: EmailContact[] = [];
          if (options.includes('Studenti')) {
            this.class?.studenti?.forEach((st: User) => {
              const index = st?.contatti?.email?.findIndex(
                (emailContact: EmailContact) =>
                  emailContact.enable === true &&
                  emailContact.address !== undefined
              );
              if (
                index != undefined &&
                index > -1 &&
                st?.contatti?.email?.at(index) !== undefined
              ) {
                allEmail.push(st?.contatti?.email[index]);
              }
            });
          }
          if (options.includes('Docenti')) {
            this.class?.docenti?.forEach((doc) => {
              const index = doc?.docente?.contatti?.email?.findIndex(
                (emailContact: EmailContact) =>
                  emailContact.enable === true &&
                  emailContact.address !== undefined
              );
              if (
                index != undefined &&
                index > -1 &&
                doc?.docente?.contatti?.email?.at(index) !== undefined
              ) {
                allEmail.push(doc?.docente?.contatti?.email[index]);
              }
            });
          }
          if (options.includes('Genitori')) {
            this.class?.genitori_tutori?.forEach((gnTr) => {
              if (gnTr.type?.backend === UserType.PARENT.backend) {
                const index = gnTr?.contatti?.email?.findIndex(
                  (emailContact: EmailContact) =>
                    emailContact.enable === true &&
                    emailContact.address !== undefined
                );
                if (
                  index != undefined &&
                  index > -1 &&
                  gnTr?.contatti?.email?.at(index) !== undefined
                ) {
                  allEmail.push(gnTr?.contatti?.email[index]);
                }
              }
            });
          }
          if (options.includes('Tutori')) {
            this.class?.genitori_tutori?.forEach((gnTr) => {
              if (gnTr.type?.backend === UserType.TUTOR.backend) {
                const index = gnTr?.contatti?.email?.findIndex(
                  (emailContact: EmailContact) =>
                    emailContact.enable === true &&
                    emailContact.address !== undefined
                );
                if (
                  index != undefined &&
                  index > -1 &&
                  gnTr?.contatti?.email?.at(index) !== undefined
                ) {
                  allEmail.push(gnTr?.contatti?.email[index]);
                }
              }
            });
          }
          const a = document.createElement('a');
          a.href = `mailto:?bcc=${encodeURIComponent(
            allEmail.map((e) => e.address).join(',')
          )}&subject=Comunicazione ${encodeURIComponent(
            this.class?.getName() || ''
          )}`;
          a.click();
        }
      });
  }

  downloadCSV(id?: string): void {
    if (!id) return;
    const csvSelectorDialogRef = this._dialog.open(CsvSelectorDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      autoFocus: false,
      restoreFocus: false,
    });
    csvSelectorDialogRef.afterClosed().subscribe((dialogData: any) => {
      if (dialogData.types == undefined && dialogData.options == undefined) {
        return;
      }
      this._classService
        .downloadCSV(id, dialogData.types, dialogData.options)
        .subscribe((res) => {
          let blob: Blob = res.body as Blob;
          let a = document.createElement('a');
          a.download =
            `${this.class?.anno_scolastico} • ${this.class?.getName()}` +
            '.csv';
          a.href = window.URL.createObjectURL(blob);
          a.click();
        });
    });
  }

  onPreviewFile(fileId?: string, isPdf?: boolean): void {
    if (fileId != undefined) {
      const dialogComponet: any = isPdf
        ? DeaPdfDialogComponent
        : DeaImgDialogComponent;
      this._dialog.open(dialogComponet, {
        minHeight: '600px',
        minWidth: '1000px',
        data: isPdf
          ? {
              id: fileId || 'NO-ID',
              url: API.classFile(this.class?.id, fileId),
            }
          : API.classFile(this.class?.id, fileId),
        autoFocus: false,
        restoreFocus: false,
      });
    }
  }

  onDownloadFile(fileId?: string) {
    this._classService.getFile(this.class?.id, fileId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download =
        res.headers.get('originalname') ||
        `${new Date().toISOString()} - Programma ${this.class?.getName()}.pdf`;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }
}
