import { TechDocument } from './tech-document.model';
import { Tech } from './tech.model';

export class TechLoss extends Tech {
  document?: TechDocument;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    this.document = json['document']
      ? new TechDocument(json['document'])
      : undefined;
  }

  hasDocument(): boolean {
    return this.document != undefined;
  }
}
