export class RingFormAnswer {
  id?: string;
  entity?: string;
  visible?: boolean;
  answer?: string;
  note?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.entity = json['entity'];
    this.visible =
      json['visible'] === undefined
        ? undefined
        : (json['visible'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.answer = json['answer'];
    this.note = json['note'];
  }
}
