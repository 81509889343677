import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TechReturn } from 'src/app/models/tech/tech-return.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { TechService } from 'src/app/services/tech.service';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-user-tech-return-dialog',
  template: `<app-dea-dialog-wrapper>
    <div class="fw-semibold fs-4">
      {{ this.data?.docId === undefined ? '' : 'Modifica ' }}Restituzione
    </div>
    <div class="v-spacer"></div>
    <div class="row m-0 p-0">
      <form
        [formGroup]="userReturnForm"
        class="m-0 p-0 d-flex flex-column gap-3"
      >
        <app-dea-label [label]="'Stato (Opzionale):'"></app-dea-label>
        <mat-radio-group
          formControlName="status"
          aria-label="Specifica uno stato"
          color="primary"
        >
          <mat-radio-button class="me-3" value="Perfetto">
            Perfetto
          </mat-radio-button>
          <mat-radio-button class="me-3" value="Buono">
            Buono
          </mat-radio-button>
          <mat-radio-button class="me-3" value="Danneggiato">
            Danneggiato
          </mat-radio-button>
        </mat-radio-group>
        <app-dea-label [label]="'Oggetti (Opzionale):'"></app-dea-label>
        <div>
          <mat-checkbox
            *ngFor="let item of itemsOptions"
            (change)="onChangeItemsCheckbox(item)"
            [checked]="itemIsChecked(item)"
            color="primary"
            class="me-3"
          >
            {{ item }}
          </mat-checkbox>
        </div>
        <app-dea-label [label]="'Note (Opzionali):'"></app-dea-label>
        <textarea
          formControlName="note"
          class="form-control dea-form-input"
          placeholder="Note"
          rows="3"
        ></textarea>
        <div class="text-danger">{{ error?.message }}</div>
      </form>
    </div>
    <div class="m-0 p-0 mt-3 gap-2" mat-dialog-actions align="end">
      <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
        Annulla
      </button>
      <button
        *ngIf="!loading"
        mat-flat-button
        color="primary"
        class="px-5 py-1"
        (click)="onConfirm()"
      >
        {{ this.data?.docId === undefined ? 'Crea' : 'Modifica' }}
      </button>
      <button *ngIf="loading" class="px-5 py-1" mat-flat-button disabled>
        <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon>
        {{ this.data?.docId === undefined ? 'Creazione' : 'Aggiornamento' }}
      </button>
    </div>
  </app-dea-dialog-wrapper>`,
  styles: [],
})
export class UserTechReturnDialogComponent {
  public itemsOptions: string[] = ['Alimentatore', 'Cavo', 'iPad', 'Scatola'];
  public loading: boolean = false;
  public error?: ApiError;

  public userReturnForm: FormGroup;

  constructor(
    private _dialogRef: MatDialogRef<UserTechReturnDialogComponent>,
    private _techService: TechService,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      userId?: string;
      deviceId?: string;
      docId?: string;
    }
  ) {
    this.userReturnForm = new FormGroup({
      owner: new FormControl(this.data?.userId, Validators.required),
      device: new FormControl(this.data?.deviceId, Validators.required),
      status: new FormControl(null),
      items: new FormControl(null),
      note: new FormControl(null),
    });
    if (this.data?.docId !== undefined) {
      this._techService.getTechBlockDetails(this.data.docId).subscribe({
        next: (response: any) => {
          const data: TechReturn = new TechReturn(response.data);
          this.userReturnForm = new FormGroup({
            owner: new FormControl(data.owner?.id, Validators.required),
            device: new FormControl(data.device?.id, Validators.required),
            status: new FormControl(data.status),
            items: new FormControl((data.items || []).join(',')),
            note: new FormControl(data.note),
          });
        },
        error: (error: any) => console.error('Get Tech Details Error: ', error),
      });
    }
  }

  itemIsChecked(item: string): boolean {
    return this.userReturnForm?.get('items')?.value?.split(',')?.includes(item);
  }

  onChangeItemsCheckbox(item: string): void {
    let itemsValue = this.userReturnForm?.get('items')?.value?.split(',') || [];
    if (itemsValue.includes(item)) {
      itemsValue.splice(itemsValue.indexOf(item), 1);
    } else itemsValue.push(item);
    this.userReturnForm
      ?.get('items')
      ?.setValue(itemsValue.length != 0 ? itemsValue.join(',') : undefined);
  }

  onConfirm(): void {
    this.loading = true;
    this.error = undefined;
    const data = this.userReturnForm.value;
    if (data.note === undefined || data.note === null) delete data.note;
    if (data.status === undefined || data.status === null) delete data.status;
    if (data.items === undefined || data.items === null) data.items = [];
    if (typeof data.items === 'string') {
      data.items = data.items.trim().split(',');
    }
    data.items = data.items.filter((item: string) => item.trim() !== '');
    const subscription = {
      next: (_: any) => this._dialogRef.close(true),
      error: (error: any) => {
        this.error = new ApiError(error);
        console.error('User Post/Patch ReturnTechBlock Error: ', error);
      },
    };
    if (this.data?.docId) {
      this._techService
        .patchReturn(this.data?.docId, data)
        .subscribe(subscription)
        .add(() => (this.loading = false));
    } else {
      this._techService
        .postReturn(data)
        .subscribe(subscription)
        .add(() => (this.loading = false));
    }
  }

  onDismiss(): void {
    this._dialogRef.close(false);
  }
}
