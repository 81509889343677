import { ExportUtilityParameters } from '../constants/interfaces';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private http: HttpClient) {}

  getGeneralStatistics() {
    return this.http.get<ApiResponse<any>>(API.generalStatistics(), {
      withCredentials: true,
    });
  }

  getExportNumberOfItems(p: ExportUtilityParameters) {
    return this.http.get<ApiResponse<any>>(API.utilityNumberOfItems(p), {
      withCredentials: true,
    });
  }

  getExportCSV(p: ExportUtilityParameters) {
    return this.http.get(API.utilityCSV(p), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  getStatus() {
    return this.http.get<ApiResponse<any>>(API.utilityStatus(), {
      withCredentials: false,
    });
  }

  getLanguages(code: boolean = false) {
    return this.http.get<ApiResponse<any>>(API.languagesListing(code), {
      withCredentials: false,
    });
  }
}
