import { Headquarter } from '../headquarter';
import { Class } from '../class/class.model';
import { User } from '../user/user.model';

export class JustificationsCard {
  id?: string;
  type?: number;
  tag?: string;
  sede?: Headquarter;
  anno_scolastico?: string;
  class?: Class;
  user?: User;
  nome?: string;
  cognome?: string;
  entry_slots?: [Date];
  entry_cards_counter?: number;
  entry_slots_counter?: number;
  exit_slots?: [Date];
  exit_cards_counter?: number;
  exit_slots_counter?: number;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.type = json['type'];
    this.tag = json['tag'];
    this.sede = Headquarter.fromString(json['sede']);
    this.anno_scolastico = json['anno_scolastico'];
    this.class = new Class(json['classe']);
    this.user = json['user'] ? new User(json['user']) : undefined;
    this.nome = json['nome'];
    this.cognome = json['cognome'];
    this.entry_slots = (json['entry_slots'] || []).map((s: any) => new Date(s));
    this.entry_cards_counter = json['entry_cards_counter'] || 0;
    this.entry_slots_counter = json['entry_slots_counter'] || 0;
    this.exit_slots = (json['exit_slots'] || []).map((s: any) => new Date(s));
    this.exit_cards_counter = json['exit_cards_counter'] || 0;
    this.exit_slots_counter = json['exit_slots_counter'] || 0;
  }
}
