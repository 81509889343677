import { DeaConfirmDialogComponent } from '../../dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { FilmDialogComponent } from './film-dialog/film-dialog.component';
import { FilmService } from 'src/app/services/items/film.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Film } from 'src/app/models/items/film.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-films',
  templateUrl: './films.component.html',
  styleUrls: ['./films.component.scss'],
})
export class FilmsComponent implements OnInit {
  public loading: boolean = true;
  public error?: ApiError;

  public films: Film[] = [];

  constructor(private _dialog: MatDialog, private _filmService: FilmService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(txt?: string) {
    this.loading = true;
    this.error = undefined;
    this._filmService
      .getListing(txt)
      .subscribe({
        next: (data: any) => {
          this.films = data?.data?.map((json: any) => new Film(json));
        },
        error: (error: any) => {
          this.error = new ApiError(error);
          console.error('Get Films Error:', this.error);
        },
      })
      .add(() => (this.loading = false));
  }

  onText(text?: string) {
    this.getData(text);
  }

  openFilmDialog(): void {
    const dialogRef = this._dialog.open(FilmDialogComponent, {
      maxWidth: '800px',
      minWidth: '800px',
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((refresh: boolean) => {
      if (refresh) this.getData();
    });
  }

  editFilm(index: number): void {
    const dialogRef = this._dialog.open(FilmDialogComponent, {
      maxWidth: '800px',
      minWidth: '800px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        film: this.films[index],
      },
    });

    dialogRef.afterClosed().subscribe((refresh: boolean) => {
      if (refresh) this.getData();
    });
  }

  deleteFilm(index: number): void {
    const deleteConfirmDialogRef = this._dialog.open(
      DeaConfirmDialogComponent,
      {
        data: {
          destructive: true,
          title: 'Attenzione!',
          message: 'Sei sicuro di voler eliminare questo film?',
          details: "(L'operazione non sarà reversibile)",
          trueBtn: 'Sì, elimina',
          falseBtn: 'No, annulla',
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );

    deleteConfirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult === true && this.films?.at(index)?.id) {
        this._filmService.deleteFilm(this.films?.at(index)?.id).subscribe({
          next: () => this.getData(),
          error: (error: any) => console.error('Error:', error),
        });
      }
    });
  }
}
