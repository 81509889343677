export class AppPaths {
  // AUTH
  static SESSION: string = 'session';
  static LOGIN: string = 'login';
  static LOGOUT: string = 'logout';

  static TEMP_CREDENTIALS: string = 'tmp/credentials';
  static TEMP_LOGIN: string = 'tmp/login';

  // ROUTES
  static DASHBOARD: string = '';
  static STUDENTI: string = 'studenti';
  static GENITORI: string = 'genitori';
  static DOCENTI: string = 'docenti';
  static TUTORI: string = 'tutori';
  static STAFF: string = 'staff';
  static CLASSI: string = 'classi';
  static INGRESSI_OSPITI: string = 'ingressi-ospiti';
  static INGRESSO_OSPITE: string = 'ingresso-ospite';
  static RITARDI: string = 'ritardi';
  static RITARDI_LICOS: string = 'ritardi-licos';
  static MODULI: string = 'moduli';
  static MODULO_LISTING: string = 'lista-moduli';
  static RESOURCES: string = 'risorse';
  static FILMS: string = 'films';
  static DEVICES: string = 'devices';
  static EVENTS: string = 'events';
  static EVENT: string = 'event';
  static EDIT_EVENT: string = this.EVENTS + '/edit';
  static CREATE_EVENT: string = this.EVENTS + '/create';
  static CALENDAR: string = 'calendar';
  static TIMETABLE_CALENDAR: string = 'timetable-calendar';

  // USER
  static UTENTE: string = 'utente';
  static NUOVO_UTENTE: string = 'nuovo-utente';
  static MODIFICA_UTENTE: string = 'modifica-anagrafica';

  // CLASS
  static CLASSE: string = 'classe';
  static NUOVA_CLASSE: string = 'nuova-classe';
  static MODIFICA_CLASSE: string = 'modifica-classe';
  static CLASSE_PREVIEW1: string = 'classe';
  static CLASSE_PREVIEW2: string = 'next-year-preview';

  // ENROLLMENT
  static ENROLLMENTS: string = 'iscrizioni';
  static EDIT_ENROLLMENT: string = this.ENROLLMENTS + '/edit';

  // LIMBO
  static LIMBO: string = 'limbo';

  // UTILITY
  static STATUS: string = 'status';
  static UTILITA: string = 'utilities';
  static FEATURES: string = 'features';
  static NOT_FOUND: string = 'not-found';
  static ALL: string = '**';
}
