<app-dea-widget-wrapper>
  <div class="fw-lighter opacity-75">{{formTitle}}</div>
  <div class="v-spacer"></div>
  <div class="my-user-form">
    <form [formGroup]="classForm" novalidate class="form">
      <!-- SEDE / DIPARTIMENTO -->
      <div class="row m-0 p-0 gap-3">
        <div class="col m-0 p-0">
          <app-dea-label class="mb-2" [label]="'Sede:'"></app-dea-label>
          <app-dea-dropdown-menu [dropdownMenuId]="'classFormHeadquarterDropdownMenu'"
            [selectedItem]="selectedHeadquarterClassForm || headquarters[0]" [items]="headquarters"
            (ddmEventEmitter)="onClassHeadquarterChange($event)" [menuIsDisabled]="disableClassHeadquarterDropdownMenu">
          </app-dea-dropdown-menu>
        </div>
        <div class="col m-0 p-0">
          <app-dea-label class="mb-2" [label]="'Dipartimento:'"></app-dea-label>
          <app-dea-dropdown-menu [dropdownMenuId]="'classFormDepartmentDropdownMenu'"
            [selectedItem]="selectedDepartmentClassForm || departments[0]" [items]="departments"
            (ddmEventEmitter)="onClassDepartmentChange($event)">
          </app-dea-dropdown-menu>
        </div>
      </div>
      <!-- ANNO SCOLASTICO / ANNO / SEZIONE -->
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-6 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-auto m-0 p-0">
              <app-dea-label class="mb-2" [label]="'A.S.:'"></app-dea-label>
              <input formControlName="anno_scolastico" type="text" class="form-control dea-form-input"
                placeholder="A.S." style="max-width: 120px;" />
            </div>
            <div class="col-auto m-0 ms-3 p-0">
              <app-dea-label class="mb-2" [label]="'Anno:'"></app-dea-label>
              <mat-radio-group aria-label="Seleziona l'anno" color="primary" (change)="setClassYear($event)">
                <mat-radio-button class="me-3" value="1" [checked]="classFormYear == '1'">1</mat-radio-button>
                <mat-radio-button class="me-3" value="2" [checked]="classFormYear == '2'">2</mat-radio-button>
                <mat-radio-button class="me-3" value="3" [checked]="classFormYear == '3'">3</mat-radio-button>
                <mat-radio-button class="me-3" value="4" [checked]="classFormYear == '4'">4</mat-radio-button>
                <mat-radio-button class="me-0" value="5" [checked]="classFormYear == '5'">5</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="col-auto m-0 ms-2 p-0">
          <app-dea-label class="mb-2" [label]="'Sezione:'"></app-dea-label>
          <mat-radio-group aria-label="Seleziona la sezione" color="primary" (change)="setClassSection($event)">
            <mat-radio-button class="me-3" value="A" [checked]="classFormSection == 'A'">A</mat-radio-button>
            <mat-radio-button class="me-3" value="B" [checked]="classFormSection == 'B'">B</mat-radio-button>
            <mat-radio-button class="me-3" value="C" [checked]="classFormSection == 'C'">C</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- STUDENTI -->
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="hstack mb-3">
        <app-dea-label [label]="'Studenti:'"></app-dea-label>
        <app-dea-fast-action-btn class="ms-auto" (click)="openUsersDialog('Student', 'studenti')">
          Aggiungi 'Studente'
        </app-dea-fast-action-btn>
      </div>
      <div class="row m-0 p-0 gap-3" formArrayName="studenti">
        <ng-container *ngFor="let item of classFormStudents.controls; let i = index;" [formGroupName]="i">
          <div class="col-10 m-0 p-0">
            <div class="row m-0 p-0 gap-3">
              <div class="col m-0 p-0">
                <input formControlName="cognome" type="text" class="form-control dea-form-input" placeholder="Cognome"
                  toTitleCase />
              </div>
              <div class="col m-0 p-0">
                <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                  toTitleCase />
              </div>
              <div class="col m-0 p-0">
                <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                  placeholder="Secondo Nome" toTitleCase />
              </div>
              <div class="col-auto m-0 p-0">
                <mat-checkbox color="primary" [checked]="true" title="Rappresentante di classe"
                  (click)="onRappresentanteChangedAt(i)" [checked]="isCheckedStudentAt(i)">
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="col m-0 p-0 text-center">
            <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
              (click)="deleteUserInAt('studenti',i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>

      </div>
      <!-- DOCENTI -->
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="hstack mb-3">
        <app-dea-label [label]="'Docenti:'"></app-dea-label>
        <app-dea-fast-action-btn class="ms-auto" (click)="openUsersDialog('Teacher', 'docenti')">
          Aggiungi 'Docente'
        </app-dea-fast-action-btn>
      </div>
      <div class="row m-0 p-0 gap-3" formArrayName="docenti">
        <ng-container *ngFor="let item of classFormTeachers.controls; let iTeacher = index;" [formGroupName]="iTeacher">
          <div class="col-10 m-0 p-0">
            <div class="row m-0 p-0 gap-3" formArrayName="docente">
              <div class="col m-0 p-0">
                <input formControlName="cognome" type="text" class="form-control dea-form-input" placeholder="Cognome"
                  toTitleCase />
              </div>
              <div class="col m-0 p-0">
                <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                  toTitleCase />
              </div>
              <div class="col m-0 p-0">
                <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                  placeholder="Secondo Nome" toTitleCase />
              </div>
              <div class="col-auto m-0 p-0">
                <mat-checkbox color="primary" title="Coordinatore di classe" (click)="onCorrdinatoreChangedAt(iTeacher)"
                  [checked]="isCheckedTeacherAt(iTeacher)">
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="col m-0 p-0 text-center">
            <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
              (click)="deleteUserInAt('docenti',iTeacher)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <!-- DISCIPLINE -->
          <div class="me-0 pe-0 section-left-border">
            <div class="hstack mb-3">
              <app-dea-label [label]="'Discipline:'"></app-dea-label>
              <app-dea-fast-action-btn class="ms-auto" (click)="openSubjectsDialog(iTeacher)">
                Aggiungi 'Disciplina'
              </app-dea-fast-action-btn>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="discipline">
              <ng-container
                *ngFor="let itemDisciplina of (classFormTeacherDisciplines(iTeacher)).controls; let iDisciplina = index;"
                [formGroupName]="iDisciplina">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <ng-container formArrayName="materia">
                      <div class="col m-0 p-0 ps-2">
                        <input formControlName="dea_id" type="text" class="form-control dea-form-input"
                          placeholder="DEA-ID" toUpperCase />
                      </div>
                      <div class="col m-0 p-0">
                        <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                          toTitleCase />
                      </div>
                    </ng-container>
                    <div class="col-2 m-0 p-0">
                      <input formControlName="ore" type="text" class="form-control dea-form-input" placeholder="Ore"
                        justInt />
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                    (click)="deleteDisciplineAtAt(iTeacher, iDisciplina)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <!-- LIBRI -->
                <div class="ms-2 me-0 pe-0 section-left-border">
                  <div class="hstack mb-3">
                    <app-dea-label [label]="'Libri:'"></app-dea-label>
                    <app-dea-fast-action-btn class="ms-auto" (click)="openSchoolbooksDialog(iTeacher, iDisciplina)">
                      Aggiungi 'Libro'
                    </app-dea-fast-action-btn>
                  </div>
                  <div class="row m-0 p-0 gap-3" formArrayName="libri">
                    <ng-container
                      *ngFor="let itemLibro of (classFormTeacherDisciplineBooks(iTeacher, iDisciplina)).controls; let iBook = index;"
                      [formGroupName]="iBook">
                      <div class="col-10 m-0 p-0">
                        <div class="row m-0 p-0 gap-3" style="margin-right: 16px !important;">
                          <div class="col m-0 p-0">
                            <input formControlName="dea_id" type="text" class="form-control dea-form-input"
                              placeholder="DEA-ID" toUpperCase />
                          </div>
                          <div class="col m-0 p-0">
                            <input formControlName="titolo" type="text" class="form-control dea-form-input"
                              placeholder="Titolo" toTitleCase />
                          </div>
                          <div class="col m-0 p-0">
                            <input formControlName="isbn" type="text" class="form-control dea-form-input"
                              placeholder="ISBN" />
                          </div>
                        </div>
                      </div>
                      <div class="col m-0 p-0 text-center">
                        <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina"
                          title="Elimina" (click)="deleteBookAtAtAt(iTeacher, iDisciplina, iBook)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- LIBRI -->
              </ng-container>
            </div>
          </div>
          <!-- DISCIPLINE -->
          <hr style="opacity: 0.1;">
        </ng-container>
      </div>

      <!-- CANCEL / SUBMIT -->
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="hstack">
        <div class="ms-auto">
          <button *ngIf="!posting" mat-flat-button color="secondary" class="fw-bold px-4 py-1 rounded-pill"
            (click)="backOnePage()">
            Annulla
          </button>
        </div>
        <div class="ms-2">
          <button *ngIf="!posting" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
            (click)="postData()">
            Salva
          </button>
          <button *ngIf="posting" mat-flat-button class="fw-bold px-4 py-1 rounded-pill" disabled>
            <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</app-dea-widget-wrapper>