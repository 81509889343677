import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

export type EmailSelectorDialogType =
  | 'Studenti'
  | 'Docenti'
  | 'Genitori'
  | 'Tutori';

@Component({
  selector: 'app-email-selector-dialog',
  template: `<app-dea-dialog-wrapper>
    <div class="fw-semibold fs-4 mb-3">Composizione lista contatti</div>
    <div class="m-0 p-0 d-flex flex-column gap-3">
      <div>
        Seleziona le tipologie di utenti che desideri contattare per E-Mail:
      </div>
      <div class="fw-lighter">
        <mat-checkbox
          *ngFor="let item of itemsOptions; let last = last"
          (change)="onChangeItemsCheckbox(item)"
          class="{{ last ? '' : 'me-4' }}"
          [checked]="itemIsChecked(item)"
          color="primary"
        >
          {{ item }}
        </mat-checkbox>
      </div>
      <div class="fw-lighter fs-075r">
        Saranno selezionati solo gli utenti con almeno un contatto e-mail
        abilitato. Se un utente presente più indirizzi e-mail abilitati verrà
        inserito nella lista solo il primo contatto che risulta essere
        contattabile.
      </div>
    </div>
    <div class="m-0 p-0 mt-3 gap-2" mat-dialog-actions align="end">
      <button
        class="px-4 py-1"
        color="secondary"
        mat-stroked-button
        (click)="onDismiss()"
      >
        Annulla
      </button>
      <button
        mat-flat-button
        color="primary"
        class="px-4 py-1"
        (click)="onConfirm()"
      >
        Contatta
      </button>
    </div>
  </app-dea-dialog-wrapper>`,
  styles: [],
})
export class EmailSelectorDialogComponent {
  private _options: EmailSelectorDialogType[] = [];
  public itemsOptions: EmailSelectorDialogType[] = [
    'Studenti',
    'Docenti',
    'Genitori',
    'Tutori',
  ];

  constructor(private _dialogRef: MatDialogRef<EmailSelectorDialogComponent>) {}

  itemIsChecked(item: EmailSelectorDialogType): boolean {
    return this._options.includes(item);
  }

  onChangeItemsCheckbox(item: EmailSelectorDialogType): void {
    if (this._options.includes(item)) {
      this._options.splice(this._options.indexOf(item), 1);
    } else this._options.push(item);
  }

  onConfirm(): void {
    this._dialogRef.close(this._options);
  }

  onDismiss(): void {
    this._dialogRef.close(undefined);
  }
}
