import { StatusHeartbeat } from './status-heartbeat.model';

export class StatusMonitor {
  id?: number;
  name?: string;
  url?: string;
  uptime?: number;
  heartbeat?: StatusHeartbeat[];

  constructor(json?: any) {
    if (!json) return;
    this.id = json['id'];
    this.name = json['name'];
    this.url = json['url'];
    this.uptime = json['uptime'];
    this.heartbeat = (json['heartbeat'] || []).map(
      (h: any) => new StatusHeartbeat(h)
    );
  }
}
