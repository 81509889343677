import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TechRelease } from 'src/app/models/tech/tech-release.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { TechService } from 'src/app/services/tech.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, Inject } from '@angular/core';

import isNumeric from 'validator/es/lib/isNumeric';
import isDate from 'validator/es/lib/isDate';

@Component({
  selector: 'app-user-tech-release-dialog',
  template: `<app-dea-dialog-wrapper>
    <div class="fw-semibold fs-4">
      {{ this.data?.docId === undefined ? '' : 'Modifica ' }}Riscatto
    </div>
    <div class="v-spacer"></div>
    <form
      [formGroup]="userReleaseForm"
      enctype="multipart/form-data"
      class="m-0 p-0 d-flex flex-column gap-3"
    >
      <app-dea-label [label]="'Data pagamento:'"></app-dea-label>
      <input
        type="date"
        formControlName="payment_date"
        class="form-control dea-form-input mb-3"
        placeholder="Data del pagamento"
      />
      <app-dea-label [label]="'Importo pagato (€):'"></app-dea-label>
      <input
        type="text"
        formControlName="value"
        class="form-control dea-form-input mb-3"
        placeholder="Importo pagato"
        justNumber
      />
      <app-dea-label [label]="'Allegato: (Opzionale)'"></app-dea-label>
      <div>
        <input
          type="file"
          formControlName="file"
          accept="image/png,image/jpeg,.pdf"
          class="form-control dea-form-input mb-1"
          placeholder="Seleziona un file"
          (change)="onFileChange($event)"
        />
        <div class="fw-lighter mb-3" style="font-size: 0.5rem;">
          Formati accettati: PDF - PNG/JPG/JPEG | Dimensione Masima: 48Mb x 1
        </div>
      </div>
      <app-dea-label [label]="'Note (Opzionali):'"></app-dea-label>
      <textarea
        formControlName="note"
        class="form-control dea-form-input"
        placeholder="Note"
        rows="3"
      ></textarea>
      <div class="text-danger">{{ error?.message }}</div>
    </form>
    <div class="m-0 p-0 mt-3 gap-2" mat-dialog-actions align="end">
      <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
        Annulla
      </button>
      <button
        *ngIf="!loading"
        (click)="onConfirm()"
        class="px-5 py-1"
        mat-flat-button
        color="primary"
      >
        {{ this.data?.docId === undefined ? 'Crea' : 'Modifica' }}
      </button>
      <button *ngIf="loading" class="px-5 py-1" mat-flat-button disabled>
        <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon>
        {{ this.data?.docId === undefined ? 'Creazione' : 'Aggiornamento' }}
      </button>
    </div>
  </app-dea-dialog-wrapper>`,
  styles: [],
})
export class UserTechReleaseDialogComponent {
  public loading: boolean = false;
  public error?: ApiError;

  public userReleaseForm: FormGroup;

  constructor(
    private _dialogRef: MatDialogRef<UserTechReleaseDialogComponent>,
    private _techService: TechService,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      userId?: string;
      deviceId?: string;
      docId?: string;
    }
  ) {
    this.userReleaseForm = new FormGroup({
      owner: new FormControl(this.data?.userId),
      device: new FormControl(this.data?.deviceId),
      value: new FormControl(105),
      payment_date: new FormControl(new Date().toISOString().slice(0, 10)),
      file: new FormControl(null),
      document: new FormControl(null),
      note: new FormControl(null),
    });
    if (this.data?.docId !== undefined) {
      this._techService.getTechBlockDetails(this.data?.docId).subscribe({
        next: (data: any) => {
          const releaseData = new TechRelease(data.data);
          this.userReleaseForm = new FormGroup({
            owner: new FormControl(releaseData?.owner?.id),
            device: new FormControl(releaseData?.device?.id),
            value: new FormControl(releaseData?.value || null),
            payment_date: new FormControl(
              releaseData?.payment_date?.toISOString().slice(0, 10) || null
            ),
            file: new FormControl(null),
            document: new FormControl(null),
            note: new FormControl(releaseData?.note),
          });
        },
        error: (error: any) => console.error('Get Tech Details Error: ', error),
      });
    }
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userReleaseForm?.patchValue({ document: file });
    }
  }

  onConfirm(): void {
    this.loading = true;
    this.error = undefined;
    const formData = new FormData();
    var data = this.userReleaseForm.value;
    formData.append('owner', data.owner);
    formData.append('device', data.device);
    if (isNumeric(`${data.value}`)) formData.append('value', data.value);
    if (isDate(data.payment_date)) {
      formData.append('payment_date', data.payment_date);
    }
    if (data.document) {
      const file: any = this.userReleaseForm?.get('document')?.value;
      formData.append(
        'documento',
        file,
        file.name || new Date().toISOString().slice(0, 10)
      );
    }
    formData.append('note', data.note);
    const subscription = {
      next: (_: any) => this._dialogRef.close(true),
      error: (error: any) => {
        this.error = new ApiError(error);
        console.error('User Post/Patch Release Error: ', error);
      },
    };
    if (this.data?.docId) {
      this._techService
        .patchRelease(formData, this.data?.docId)
        .subscribe(subscription)
        .add(() => (this.loading = false));
    } else {
      this._techService
        .postRelease(formData, this.data?.userId)
        .subscribe(subscription)
        .add(() => (this.loading = false));
    }
  }

  onDismiss(): void {
    this._dialogRef.close(false);
  }
}
