import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { DeaPdfDialogComponent } from 'src/app/components/dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { DeaImgDialogComponent } from 'src/app/components/dea-pack/dea-img-dialog/dea-img-dialog.component';
import { UserDocument } from 'src/app/models/user/user-document/user-document.model';
import { UserDocumentsService } from 'src/app/services/user-documents.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-user-document-row',
  templateUrl: './user-document-row.component.html',
  styleUrls: ['./user-document-row.component.scss'],
})
export class UserDocumentRowComponent {
  @Input() document?: UserDocument;
  @Output() userDocRowEmitter = new EventEmitter<number>();

  constructor(
    private _dialog: MatDialog,
    private _userDocumentsService: UserDocumentsService
  ) {}

  onChangeCheckbox(event: any) {
    // TODO: GESTIRE ERRORI!
    this._userDocumentsService
      .setDocumentCheckbox(
        this.document?.owner,
        this.document?.id,
        event.checked
      )
      .subscribe({
        next: (data) => console.info('DATA => ', data),
        error: (error) => console.error('User Document Error: ', error),
      });
  }

  onPreviewFile(): void {
    if (this.document?.isPDF()) {
      this._dialog.open(DeaPdfDialogComponent, {
        minHeight: '600px',
        minWidth: '1000px',
        data: {
          id: this.document.id || 'NO-ID',
          url: API.userDocumentDownload(this.document.owner, this.document.id),
        },
        autoFocus: false,
        restoreFocus: false,
      });
    } else if (this.document) {
      this._dialog.open(DeaImgDialogComponent, {
        minHeight: '600px',
        minWidth: '1000px',
        data: API.userDocumentDownload(this.document.owner, this.document.id),
        autoFocus: false,
        restoreFocus: false,
      });
    }
  }

  onDownloadFile(): void {
    this._userDocumentsService
      .downloadDocument(this.document?.owner, this.document?.id)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let a = document.createElement('a');
        a.download = this.document?.nome
          ? `${this.document.nome}.${this.document.getExt()}`
          : new Date().toISOString();
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }

  onDeleteDocument(): void {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questo documento?',
        details:
          'Attenzione: se confermi non sarà più possibile recuperare il file',
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult === true) {
        this._userDocumentsService
          .deleteDocument(this.document?.owner, this.document?.id)
          .subscribe({
            next: (_: any) => this.userDocRowEmitter.emit(1),
            error: (error) => console.error('ERROR => ', error),
          });
      }
    });
  }
}
