<div class="bg-w dea-radius dea-shadow dea-padding">
  <!-- RICERCA -->
  <div class="row m-0 p-0" style="max-width: calc(100vw - var(--dea-side-drawer-w) - (var(--dea-spacer) * 4));">
    <div class="col m-0 p-0">
      <app-dea-search-bar (text)="onText($event)"></app-dea-search-bar>
    </div>
    <div class="col-auto m-0 p-0 ms-2">
      <button *ngIf="!gettingPdfData" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
        (click)="getPdfData()">
        Download PDF
      </button>
      <button *ngIf="gettingPdfData" mat-flat-button class="fw-bold px-4 py-1 rounded-pill" disabled>
        <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Elaborazione PDF...
      </button>
    </div>
  </div>
  <!-- RICERCA -->
  <!-- LOADING / ERROR -->
  <div *ngIf="loading || isError" class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner *ngIf="loading && !isError" strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    <div *ngIf="!loading && isError">{{error?.message}}</div>
  </div>
  <!-- LOADING / ERROR -->
  <!-- DATA -->
  <ng-container *ngIf="!loading && !isError">
    <div class="v-spacer"></div>
    <div class="d-flex flex-row justify-content-between gap-2">
      <app-dea-chip-status class="cursor-pointer"
        *ngFor="let value of ['Tutti', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì']" [label]="value"
        [status]="dayFilterCheck(value)" [clickHandler]="" (click)="onDayFilter(value)">
      </app-dea-chip-status>
    </div>
    <div class="v-spacer"></div>
    <!-- Contenitore per lo scroll -->
    <div style="max-height: calc(100vh - var(--dea-main-dashboard-navbar-h)); overflow: auto; border: 1px solid #ddd;">
      <table class="table table-bordered m-0 p-0 align-middle timetable-table">
        <thead>
          <tr>
            <td rowspan="2" class="align-middle text-center fw-bold">
              {{scholasticYear}}
            </td>
            <th rowspan="2" class="align-middle text-center sticky-cell-y">ORE</th>
            @for (weekDay of weekDays; track weekDay) {
            <th colspan="7" class="text-center fw-bold sticky-cell-y">
              {{weekDay}}
            </th>
            }
          </tr>
          <tr>
            @for (weekDay of weekDays; track weekDay) {
            @for (daySlot of daySlots; track daySlot) {
            <th class="text-center sticky-cell-y-0">
              {{daySlot}}
            </th>
            }
            }
          </tr>
        </thead>
        <tbody>
          @for (weekSchedule of timetableWeekSchedules; track weekSchedule;let i = $index) {
          <tr class="hover-bg">
            <td class="text-nowrap sticky-cell cursor-pointer" [routerLink]="'/utente/'+weekSchedule.teacher._id"
              [title]="'Visualizza  dettagli utente'">
              {{weekSchedule.teacher.cognome}} {{weekSchedule.teacher.nome}}
            </td>
            <td class="text-center">
              {{weekSchedule.counters.blocks.lezione}}
            </td>
            @for (weekDay of ['mo', 'tu', 'we', 'th', 'fr']; track weekDay;) {
            <ng-container>
              @for (daySlot of daySlots; track daySlot;) {
              <ng-container>
                <td class="w-fit m-0 p-1">
                  @for (event of getBlocks(i,weekDay, daySlot); track $index; let last = $last) {
                  <div class="text-center border p-1 dea-radius" [id]="event.id"
                    [title]="(event.timetableBlock == 'LEZIONE' ? event.getClassesShortName() : event.timetableBlock == 'DISPOSIZIONE' ? 'Disposizione': 'Ricevimento') +' - ' + weekSchedule.teacher.cognome + ' ' + weekSchedule.teacher.nome"
                    [ngClass]="{'mb-1': !last, 'mb-0': last}"
                    [ngStyle]="{'background': (event.timetableBlock === 'DISPOSIZIONE' ? event.color ?? '' : 'event.color')}">
                    @switch (event.timetableBlock) {
                    @case ('LEZIONE') {
                    {{event.getClassesShortName('\n').replaceAll(' ', '').length === 0 ? 'LEZ' : event.getClassesShortName('\n').replaceAll(' ', '')}}
                    }
                    @case ('DISPOSIZIONE') {
                    DIS
                    }
                    @case ('RICEVIMENTO') {
                    RIC
                    }
                    }
                  </div>
                  } @empty {
                  <div class="text-nowarp"> </div>
                  }
                </td>
              </ng-container>
              }
            </ng-container>
            }
          </tr>
          }
        </tbody>
      </table>
    </div>
    <!-- Fine Contenitore scroll -->

  </ng-container>
  <!-- DATA -->
</div>