import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  getListing(folderId: string | undefined = undefined, split = true) {
    return this.http.get<ApiResponse<any>>(
      API.resourcesListing({ folderId, split }),
      {
        withCredentials: true,
      }
    );
  }

  download(id?: string) {
    return this.http.get(API.downloadResource(id), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  upload(formData: FormData, folderId?: string) {
    return this.http.post(API.uploadResource(folderId), formData, {
      withCredentials: true,
    });
  }

  delete(id?: string) {
    return this.http.delete(API.deleteResource(id), {
      withCredentials: true,
    });
  }

  createFolder(folderName: string, folderId?: string) {
    return this.http.post(
      API.createFolder(),
      {
        name: folderName.toString().trim(),
        folderId: folderId?.toString().trim(),
      },
      {
        withCredentials: true,
      }
    );
  }
}
