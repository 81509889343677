import { RingFormsFiltersService } from 'src/app/services/ring-forms-filters.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { Class } from 'src/app/models/class/class.model';
import { Subject } from 'src/app/models/subject.model';
import { User } from 'src/app/models/user/user.model';

interface FilterDialogInterface {
  title: string;
  value: string;
  info: () => boolean;
}

export interface FiltersDialogConfigInterface {
  scholasticYear?: string;
  classes?: Class[];
  subjects?: Subject[];
  owners?: User[];
}

@Component({
  selector: 'app-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss'],
})
export class FiltersDialogComponent implements OnInit {
  private _ringFormsFiltersService = inject(RingFormsFiltersService);
  private _dialogRef = inject(MatDialogRef<FiltersDialogComponent>);
  selectedYear?: string;
  selectedClass?: Class;
  selectedSubject?: Subject;
  selectedOwner?: User;

  filters: FilterDialogInterface[] = [
    {
      title: 'Anno',
      value: 'scholastic-years',
      info: () => this.selectedYear != undefined,
    },
    {
      title: 'Classi',
      value: 'classes',
      info: () => this.selectedClass != undefined,
    },
    {
      title: 'Materie',
      value: 'subjects',
      info: () => this.selectedSubject != undefined,
    },
    {
      title: 'Proprietario',
      value: 'owners',
      info: () => this.selectedOwner != undefined,
    },
  ];
  selectedFilter: string = this.filters[0].value;

  scholasticYears: string[] = [];
  subjects: Subject[] = [];
  classes: Class[] = [];
  users: User[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FiltersDialogConfigInterface
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.selectedYear = this.data.scholasticYear;
      if (this.data.classes?.at(0)) {
        this.selectedClass = this.data.classes.at(0);
      }
      if (this.data.subjects?.at(0)) {
        this.selectedSubject = this.data.subjects.at(0);
      }
      if (this.data.owners?.at(0)) {
        this.selectedOwner = this.data.owners.at(0);
      }
    }
    this._getScholasticYears().add(() =>
      this._getClasses().add(() =>
        this._getSubjects().add(() => this._getUsers())
      )
    );
  }

  _getScholasticYears() {
    return this._ringFormsFiltersService.getScholasticYears().subscribe({
      next: (data) => {
        if (data.data != undefined) {
          this.scholasticYears = data.data as string[];
        }
      },
      error: (error) => {
        console.error('*** | getScholasticYears ERROR =>', error);
      },
    });
  }

  _getClasses() {
    return this._ringFormsFiltersService
      .getClasses(this.selectedYear)
      .subscribe({
        next: (data) => {
          this.classes = (data.data || []).map((json: any) => new Class(json));
        },
        error: (error) => {
          console.error('*** | getClasses ERROR => ', error);
        },
      });
  }

  _getSubjects() {
    return this._ringFormsFiltersService.getSubjects().subscribe({
      next: (data) => {
        this.subjects = (data.data || []).map((json: any) => new Subject(json));
        this.subjects.sort((a, b) => {
          if (a.nome === undefined) return 1;
          if (b.nome === undefined) return -1;
          return a.nome.localeCompare(b.nome);
        });
      },
      error: (error) => {
        console.error('*** | getSubjects ERROR => ', error);
      },
    });
  }

  _getUsers(txt?: string) {
    return this._ringFormsFiltersService.getUsers(txt).subscribe({
      next: (data) => {
        this.users = (data.data || []).map((json: any) => {
          delete json.contatti;
          return new User(json);
        });
      },
      error: (error) => {
        console.error('*** | getUsers ERROR => ', error);
      },
    });
  }

  isThereSelectedUserInUsers(): boolean {
    return this.users.filter((u) => u.id == this.selectedOwner?.id).length != 0;
  }

  selectFilter(str: string) {
    this.selectedFilter = str;
  }

  onSelectScholasticYear(year: string) {
    const resetClassesList: boolean = this.selectedYear != year;
    this.selectedYear = year;
    if (resetClassesList) {
      this.selectedClass = undefined;
      this._getClasses();
    }
  }

  onSelectSubject(subject: Subject) {
    this.selectedSubject =
      this.selectedSubject == subject ? undefined : subject;
  }

  onSelectClass(classe: Class) {
    this.selectedClass = this.selectedClass == classe ? undefined : classe;
  }

  onOwnerText(txt: string) {
    this._getUsers(txt);
  }

  onSelectOwner(owner: User) {
    this.selectedOwner = this.selectedOwner == owner ? undefined : owner;
  }

  onDismiss() {
    this._dialogRef.close();
  }

  onReset() {
    this.selectedYear = undefined;
    this.selectedClass = undefined;
    this.selectedSubject = undefined;
    this._dialogRef.close(true);
  }

  onConfirm() {
    let data: FiltersDialogConfigInterface = {};
    if (this.scholasticYears) {
      Object.assign(data, { scholasticYear: this.selectedYear });
    }
    if (this.selectedClass) {
      Object.assign(data, { classes: [this.selectedClass] });
    }
    if (this.selectedSubject) {
      Object.assign(data, { subjects: [this.selectedSubject] });
    }
    if (this.selectedOwner) {
      Object.assign(data, { owners: [this.selectedOwner] });
    }
    this._dialogRef.close(data);
  }
}
