<div class="d-flex flex-row justify-content-start gap-2">
  <div class="d-flex flex-row justify-content-start align-items-center gap-2">
    <!-- <i class="bi bi-house dea-icon-btn me-1" title="Risorse" (click)="backToResources()"></i>
    <div class="vr opacity-25"></div> -->
    <i class="bi bi-chevron-left dea-icon-btn" title="Indietro" (click)="popPath()"></i>
    <div class="ms-2 me-3">{{namepaths[namepaths.length - 1]}}</div>
    <div *ngIf="loading" class="d-flex align-items-center">
      <mat-icon><mat-spinner diameter="20"></mat-spinner></mat-icon>
    </div>
  </div>
  <div class="ms-auto d-flex flex-row justify-content-end gap-2">
    <button *ngIf="!isCreating" color="secondary" class="fw-bold px-3 py-1 rounded-pill" (click)="createNewFolder()"
      mat-stroked-button><i class="bi bi-folder-plus me-2"></i>Nuova Cartella
    </button>
    <button *ngIf="isCreating" color="secondary" class="fw-bold px-3 py-1 rounded-pill" mat-flat-button disabled>
      <i class="bi bi-folder-plus me-2"></i>Creazione...
    </button>
    <button *ngIf="!isUploading" color="primary" class="fw-bold px-3 py-1 rounded-pill" (click)="triggerFileUpload()"
      mat-flat-button><i class="bi bi-cloud-upload me-2"></i>Carica File
    </button>
    <button *ngIf="isUploading" color="primary" class="fw-bold px-3 py-1 rounded-pill" mat-flat-button disabled>
      <i class="bi bi-cloud-upload me-2"></i>Caricamento...
    </button>
    <input class="d-none" id="resourceFileInput" type="file" (change)="onFileChange($event)" />
  </div>
</div>
<div class="v-spacer"></div>
<app-resources-section [resources]="systemFolders" [label]="'Ring Folders'"
  (pushPathEmitter)="pushPath($event.split(',')[0],$event.split(',')[1])">
</app-resources-section>
<div *ngIf="systemFolders.length != 0" class="v-spacer"></div>
<app-resources-section [resources]="baseFolders" [label]="'Cartelle'"
  (pushPathEmitter)="pushPath($event.split(',')[0],$event.split(',')[1])" (deleteEmitter)="deleteResource($event)">
</app-resources-section>
<div *ngIf="baseFolders.length != 0" class="v-spacer"></div>
<app-resources-section [resources]="files" [label]="'File'" [downloadingId]="downloadingId"
  (downloadEmitter)="downloadResource($event)" (deleteEmitter)="deleteResource($event)">
</app-resources-section>