<!-- NAV BAR -->
<mat-toolbar color="primary" class="main-dashboard-navbar dea-shadow d-flex justify-content-between align-items-center"
  [style]="env.type != 'prod' ? 'background-color: greenyellow;' : ''">
  <a class="d-flex justify-content-start align-items-center" style="width: 100px;" href="/" title="Dashboard">
    <img src="{{ringIconUrl}}" alt="" height="24" width="24" crossorigin="anonymous">
    <div class="ms-2 my-auto main-dashboard-navbar-title">
      <div class="main-dashboard-navbar-title" style="margin-bottom: -2px;">Un db per</div>
      <div class="main-dashboard-navbar-title" style="margin-top: -2px;">domarli tutti</div>
    </div>
    <div class="fs-055r font-lighter opacity-25 d-flex flex-column justify-content-start align-items-end">
      <div class="ms-2 mb-2" style="height: 0.55rem;">v2.12</div>
    </div>
  </a>
  <div class="d-flex flex-row align-items-center gap-3 m-0 p-0 me-2">
    <div class="dropdown">
      <button class="btn btn-light dropdown-toggle btn-sm btn-services" type="button" data-bs-toggle="dropdown"
        data-bs-auto-close="true" aria-expanded="false" title="Servizi De Amicis Cloud">
        Servizi
      </button>
      <ul class="dropdown-menu dropdown-menu-end px-2">
        <li>
          <a href="https://status.deamicis.cloud" target="_blank" rel="noopener noreferrer"
            title="Vai alla pagina di stato (pubblica) dei servizi">
            <button class="dropdown-item rounded rounded-1" type="button">
              <i class="bi bi-activity me-2"></i> Pagina di Stato
            </button>
          </a>
        </li>
        <li>
          <a href="https://openday.deamicis.cloud/iscritti" target="_blank" rel="noopener noreferrer"
            title="Vai alla piattaforma di gestione delle presenze degli OpenDay (v0.0)">
            <button class="dropdown-item rounded rounded-1" type="button">
              <i class="bi bi-asterisk me-2"></i> Open Day <span class="fs-055r">(v0.0)</span>
            </button>
          </a>
        </li>
        <li>
          <button class="dropdown-item rounded rounded-1" type="button" routerLink="/events">
            <i class="bi bi-calendar-plus me-2"></i> Eventi
          </button>
        </li>
        <li>
          <a href="{{env.type != 'prod' ? 'https://www.deamicis.cloud/dev-events':'https://www.deamicis.cloud/events'}}"
            target="_blank" rel="noopener noreferrer" title="Gestore eventi calendario pubblico De Amicis">
            <button class="dropdown-item rounded rounded-1" type="button">
              <i class="bi bi-layout-three-columns me-2"></i> Calendario Pubblico
            </button>
          </a>
        </li>
        <!-- <li>
          <a href="{{env.type != 'prod' ? 'https://dev-events.deamicis.cloud/dashboard':'https://events.deamicis.cloud/dashboard'}}"
            target="_blank" rel="noopener noreferrer" title="Vai alla piattaforma di gestione degli eventi De Amicis">
            <button class="dropdown-item rounded rounded-1" type="button">
              <i class="bi bi-calendar4-event me-2"></i> Gestore Eventi
            </button>
          </a>
        </li> -->
        <li>
          <!-- <a href="https://enrollment.deamicis.cloud" target="_blank" rel="noopener noreferrer"> -->
          <button class="dropdown-item rounded rounded-1 disabled" aria-disabled="true" type="button">
            <i class="bi bi-people me-2"></i> Gestore Uscite
          </button>
          <!-- </a> -->
        </li>
        <li>
          <a href="{{env.type != 'prod' ? 'https://dev-enrollment.deamicis.cloud':'https://enrollment.deamicis.cloud'}}"
            target="_blank" rel="noopener noreferrer" title="Visualizza WebApp per Iscrizione Digitale">
            <button class="dropdown-item rounded rounded-1" aria-disabled="true" type="button">
              <i class="bi bi-card-checklist me-2"></i> Iscrizione Digitale
            </button>
          </a>
        </li>
        <li>
          <a href="{{env.type != 'prod' ? 'https://dev-network.deamicis.cloud':'https://network.deamicis.cloud'}}"
            target="_blank" rel="noopener noreferrer" title="Vai alla piattaforma di monitoraggio della rete">
            <button class="dropdown-item rounded rounded-1" type="button">
              <i class="bi bi-wifi me-2"></i> Network Status
            </button>
          </a>
        </li>
        <li *ngIf="devicesSection">
          <button routerLink="/devices" class="dropdown-item rounded rounded-1" type="button"
            title="Gestione Dispositivi">
            <i class="bi bi-tablet me-2"></i> Devices
          </button>
        </li>
        <li *ngIf="filmSection">
          <button routerLink="/films" class="dropdown-item rounded rounded-1" type="button" title="Gestione Film">
            <i class="bi bi-film me-2"></i> Film
          </button>
        </li>
      </ul>
    </div>
    <div class="dea-icon-btn" routerLink="/status" title="Stato del sistema">
      <i class="bi bi-activity"></i>
    </div>
    <div class="dea-icon-btn" routerLink="/features" title="Aggiornamenti Features">
      <!-- <i class="bi bi-wrench"></i> -->
      <i class="bi bi-star"></i>
    </div>
    <div class="dea-icon-btn position-relative" title="Compleanni" (click)="openBirthdaysLisitng()">
      <div *ngIf="usersBirthdaysListing.length != 0" class="position-absolute top-0 start-100 translate-middle pt-3">
        <div class="rounded-pill bg-danger d-flex justify-content-center align-items-center"
          style="width: 12px; height: 12px;">
          <div class="m-0 p-0 text-white" style="font-size: 8px; line-height: 8px;">
            {{usersBirthdaysListing.length}}
          </div>
        </div>
      </div>
      <i class="bi bi-cake2"></i>
    </div>
    <a class="dea-icon-btn" title="Contatta lo Sviluppatore"
      href="mailto:gabrielebellani98@gmail.com?subject=Richiesta da Dashboard De Amicis Ring&body=Ciao Gabriele,">
      <i class="bi bi-envelope-at"></i>
    </a>
    <div class="py-2 d-flex align-items-center">
      <div class="vr"></div>
    </div>
    <a routerLink="/utente" title="Profilo" class="mb-1">
      <app-dea-thumbnail-img size="24" url="{{avatarUrl}}"></app-dea-thumbnail-img>
    </a>
  </div>
</mat-toolbar>
<!-- MAIN -->
<mat-drawer-container class="main-dashboard-container">
  <mat-drawer mode="side" class="side-drawer dea-shadow" opened>
    <div class="d-flex flex-column px-3 py-4 h-100" style="overflow-y: scroll;">
      <!-- Dropdown Menu -->
      <app-dea-dropdown-menu [dropdownMenuId]="'dropdownInputDashboardHeadquarter'" [items]="headquarters"
        [selectedItem]="selectedHeadquarter" (ddmEventEmitter)="onHeadquarterChange($event)">
      </app-dea-dropdown-menu>
      <!-- Dropdown Menu -->
      <!-- List -->
      <div class="side-drawer-item pre-subitem" routerLink="/" [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }">
        Dashboard
      </div>
      <div class="side-drawer-item subitem" routerLink="studenti" [routerLinkActive]="'active'">Studenti</div>
      <div class="side-drawer-item subitem" routerLink="genitori" [routerLinkActive]="'active'">Genitori</div>
      <div class="side-drawer-item subitem" routerLink="docenti" [routerLinkActive]="'active'">Docenti</div>
      <div class="side-drawer-item subitem" routerLink="tutori" [routerLinkActive]="'active'">Tutori</div>
      <div class="side-drawer-item subitem" routerLink="staff" [routerLinkActive]="'active'">Staff</div>
      <div class="side-drawer-item sub" routerLink="classi" [routerLinkActive]="'active'">Classi</div>
      <mat-divider class="my-2"></mat-divider>
      <div class="side-drawer-item" routerLink="moduli" [routerLinkActive]="'active'">Moduli</div>
      <div class="side-drawer-item" routerLink="risorse" [routerLinkActive]="'active'">Risorse</div>
      <mat-divider class="my-2"></mat-divider>
      <div class="side-drawer-item" routerLink="ingressi-ospiti" [routerLinkActive]="'active'">Ingressi</div>
      <div class="side-drawer-item pre-subitem" routerLink="ritardi" [routerLinkActive]="'active'">Ritardi</div>
      <div class="side-drawer-item subitem" routerLink="ritardi-licos" [routerLinkActive]="'active'">Ritardi LICOS</div>
      <mat-divider class="my-2"></mat-divider>
      <div class="side-drawer-item" routerLink="iscrizioni" [routerLinkActive]="'active'">Iscrizioni</div>
      <div class="side-drawer-item" routerLink="limbo" [routerLinkActive]="'active'">Limbo</div>
      <div class="side-drawer-item mb-3 disabled" routerLink="archivio" [routerLinkActive]="'active'">Archivio</div>
      <div class="side-drawer-item mt-auto" routerLink="utilities" [routerLinkActive]="'active'">Utilità</div>
      <div class="side-drawer-item" [routerLinkActive]="'active'" (click)="logoutConfirmDialog()">Logout</div>
      <!-- List -->
    </div>
  </mat-drawer>
  <mat-drawer-content class="dea-padding">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>