import { DeaPdfDialogComponent } from 'src/app/components/dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { DeaImgDialogComponent } from 'src/app/components/dea-pack/dea-img-dialog/dea-img-dialog.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pcto } from 'src/app/models/user/user-pcto/pcto.model';
import { PctoService } from 'src/app/services/pcto.service';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-user-pcto-row',
  templateUrl: './user-pcto-row.component.html',
  styleUrls: ['./user-pcto-row.component.scss'],
})
export class UserPctoRowComponent {
  @Output() userDocRowEmitter = new EventEmitter<number>();
  @Input() showHours?: boolean = true;
  @Input() pctoDoc?: Pcto;

  constructor(private _pctoService: PctoService, private _dialog: MatDialog) {}

  onPreviewFile(): void {
    if (this.pctoDoc?.isPDF()) {
      this._dialog.open(DeaPdfDialogComponent, {
        minHeight: '600px',
        minWidth: '1000px',
        data: {
          id: this.pctoDoc.id || 'NO-ID',
          url: API.pctoDownload(this.pctoDoc.owner, this.pctoDoc.id),
        },
        autoFocus: false,
        restoreFocus: false,
      });
    } else if (this.pctoDoc) {
      this._dialog.open(DeaImgDialogComponent, {
        minHeight: '600px',
        minWidth: '1000px',
        data: API.pctoDownload(this.pctoDoc.owner, this.pctoDoc.id),
        autoFocus: false,
        restoreFocus: false,
      });
    }
  }

  onDownloadFile(): void {
    this._pctoService
      .downloadPcto(this.pctoDoc?.owner, this.pctoDoc?.id)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let a = document.createElement('a');
        a.download = this.pctoDoc?.ente
          ? `${this.pctoDoc.ente}.${this.pctoDoc.getExt()}`
          : new Date().toISOString();
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }

  onDeleteDocument(): void {
    this._pctoService
      .deletePcto(this.pctoDoc?.owner, this.pctoDoc?.id)
      .subscribe({
        next: (_: any) => this.userDocRowEmitter.emit(1),
        error: (error) => console.error('ERROR => ', error),
      });
  }
}
