<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <div class="text-center">
        <div>{{error?.message}}</div>
      </div>
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <div class="row m-0 p-0">
      <div class="col-auto m-0 p-0">
        <div class="user-img-spacer"></div>
      </div>
      <div class="col m-0 p-0 ps-3">
        <div class="row m-0 p-0" *ngFor="let class of classes;">
          <app-details-divider></app-details-divider>
          <div class="p-3 d-flex flex-row justify-content-between align-items-center">
            <div>
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="opacity-50">{{class.anno_scolastico}}</div>
                <!-- <div>{{class.sede?.frontend}}</div> -->
              </div>
              <div><span class="fw-bold">{{class.dea_id}}</span> - {{class.getName()}}</div>
            </div>
            <div>
              <button id="user-detail-arrow-btn" mat-icon-button aria-label="Dettagli Classe"
                title="Dettagli {{class?.getName()}}" routerLink="/classe/{{class?.id}}">
                <mat-icon class="pe-1 mb-1" aria-hidden="false" aria-label="Dettagli Classe"
                  fontIcon="arrow_forward_ios"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
