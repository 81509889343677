import { TechDocument } from './tech-document.model';
import { Tech } from './tech.model';

export class TechInsurance extends Tech {
  value?: Number;
  expiration?: Date;
  document?: TechDocument;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    this.value = json['value'];
    this.expiration = json['expiration']
      ? new Date(json['expiration'])
      : undefined;
    this.document = json['document']
      ? new TechDocument(json['document'])
      : undefined;
  }

  hasDocument(): boolean {
    return this.document != undefined;
  }
}
