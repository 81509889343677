import { User } from '../user/user.model';
import { Device } from './device.model';

export type TechDiscriminatorKey =
  | 'insurance'
  | 'assignment'
  | 'breaking'
  | 'loss'
  | 'return'
  | 'release';

export class Tech {
  id?: string;
  owner?: User;
  device?: Device;
  anno_scolastico?: string;
  sede?: string;
  note?: string;
  createdAt?: Date;
  __type?: TechDiscriminatorKey;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.owner = json['owner'] ? new User(json['owner']) : undefined;
    this.device = json['device'] ? new Device(json['device']) : undefined;
    this.anno_scolastico = json['anno_scolastico'];
    this.sede = json['sede'];
    this.note = json['note'];
    this.__type = json['__type'];
    this.createdAt = json['createdAt'];
  }
}
