import { User } from 'src/app/models/user/user.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-birth-data',
  templateUrl: './user-birth-data.component.html',
  styleUrls: ['./user-birth-data.component.scss'],
})
export class UserBirthDataComponent {
  @Input() user?: User;
}
