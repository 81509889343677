import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { API } from 'src/app/constants/api';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-temporary-login',
  templateUrl: './temporary-login.component.html',
  styleUrls: ['./temporary-login.component.scss'],
})
export class TemporaryLoginComponent {
  public errorMessage = '';
  public loading = false;

  public credentialsForm: FormGroup = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.email,
    ]),
    tmp_password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8),
    ]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
    ]),
    verify_password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  get iconUrl(): string {
    return API.deAmicisRingIcon();
  }

  constructor(private _router: Router, private _authService: AuthService) {}

  setCredentials() {
    this.loading = true;
    this.errorMessage = '';
    setTimeout(() => {
      if (!this.credentialsForm.valid) {
        this.loading = false;
        this.errorMessage =
          'Assicurati di aver compilato tutti i campi correttamente e di aver rispettato tutti i requisiti minimi.';
        return;
      }
      this._authService
        .credentials(
          this.credentialsForm?.value?.email?.toString().trim(),
          this.credentialsForm?.value?.tmp_password?.toString().trim(),
          this.credentialsForm?.value?.password?.toString(),
          this.credentialsForm?.value?.verify_password?.toString()
        )
        .subscribe({
          next: () => {
            this.credentialsForm?.reset();
            this._router.navigateByUrl(AppPaths.SESSION);
          },
          error: (error) => {
            this.errorMessage = new ApiError(error).message || 'Errore...';
            this.loading = false;
          },
        });
    }, 1500);
  }
}
