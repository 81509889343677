<app-dea-widget-wrapper [formGroup]="eventForm">
  <div class="fw-semibold text-start fs-4">{{eventId ? 'Modifica' : 'Crea'}}</div>
  <div class="v-spacer"></div>
  <app-dea-label class="mb-2" [label]="'Titolo:'"></app-dea-label>
  <input formControlName="title" type="text" class="form-control dea-form-input" placeholder="Titolo" />
  <div class="v-spacer"></div>
  <textarea formControlName="description" class="form-control dea-form-input" placeholder="Descrizione" rows="3">
    </textarea>
  <div class="v-spacer"></div>
  <div class="row m-0 p-0 gap-2">
    <div class="col m-0 p-0">
      <app-dea-label class="mb-2" [label]="'Inizio:'"></app-dea-label>
      <input formControlName="start" type="datetime-local" class="form-control dea-form-input" placeholder="Inizio" />
    </div>
    <div class="col m-0 p-0">
      <app-dea-label class="mb-2" [label]="'Fine: (Opzionale)'"></app-dea-label>
      <input formControlName="end" type="datetime-local" class="form-control dea-form-input" placeholder="Fine" />
    </div>
  </div>
  <div class="v-spacer"></div>
  <div class="row m-0 p-0 gap-2">
    <div class="col m-0 p-0">
      <div>
        <app-dea-label class="mb-2" [label]="'Luogo: (Opzionale)'"></app-dea-label>
        <input formControlName="location" type="text" class="form-control dea-form-input" placeholder="Luogo" />
      </div>
      <div class="v-spacer"></div>
      <div>
        <app-dea-label class="mb-2" [label]="'Mostra nel calendario pubblico: '"></app-dea-label>
        <mat-slide-toggle class="mt-2" color="primary" formControlName="public"></mat-slide-toggle>
      </div>
    </div>
    <div class="col m-0 p-0">
      <div class="row m-0 p-0 gap-2">
        <div class="col m-0 p-0">
          <app-dea-label class="mb-2" [label]="'Copertina: (Opzionale)'"></app-dea-label>
          <input formControlName="cover" type="text" class="d-none" />
          <input #coverFileInput type="file" class="d-none" accept=".jpg, .jpeg, .png"
            (change)="handleFileSelect($event)" />
          <div class="d-flex gap-2">
            <button class="px-3 py-1 flex-fill" mat-stroked-button (click)="uploadCover()" title="Carica cover">
              Carica
            </button>
            <button class="px-3 py-1 flex-fill" mat-stroked-button (click)="deleteCover()" title="Elimina cover"
              [disabled]="!eventForm.get('cover')?.value">
              Elimina
            </button>
          </div>
        </div>
        <div class="col-auto m-0 p-0 pt-4">
          <img src="{{eventForm.get('cover')?.value || defaultCoverPlaceholder}}" height="120" width="120"
            class="rounded border" style="object-fit: cover;" />
        </div>
      </div>
    </div>
  </div>
  <div class="v-spacer"></div>
  <!-- CLASSI -->
  <div class="m-0 p-0">
    <div class="v-spacer"></div>
    <div class="hstack">
      <app-dea-label class="mb-2" [label]="'Classi:'"></app-dea-label>
      <div class="ms-auto">
        <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Classe\''"
          (click)="openClassesDialog()">
        </app-dea-label>
      </div>
    </div>
    <div class="row m-0 p-0 gap-3" formArrayName="classes">
      <ng-container *ngFor="let item of eventFormClasses.controls; let i = index;" [formGroupName]="i">
        <div class="col-10 m-0 p-0">
          <div class="row m-0 p-0 gap-3">
            <div class="col m-0 p-0">
              <input formControlName="year" type="text" class="form-control dea-form-input" placeholder="Anno" />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="section" type="text" class="form-control dea-form-input" placeholder="Sezione" />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="department" type="text" class="form-control dea-form-input"
                placeholder="Dipartimento" />
            </div>
          </div>
        </div>
        <div class="col m-0 p-0 text-center">
          <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
            (click)="deleteItemInAt('classes',i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row m-0 p-0 gap-2">
    <div class="col m-0 p-0"></div>
    <div class="col m-0 p-0"></div>
  </div>
  <div *ngIf="error" class="d-flex justify-content-end">
    {{error}}
  </div>
  <div class="d-flex justify-content-end m-0 p-0 mt-3 gap-2">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
      {{eventId ? 'Annulla modifiche' : 'Annulla'}}
    </button>
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="onSave()">
      {{eventId ? 'Salva modifiche' : 'Salva'}}
    </button>
  </div>
</app-dea-widget-wrapper>