import { DeaPdfDialogComponent } from '../dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { JustificationsCardService } from 'src/app/services/justifications-card.service';
import { JustificationsCard } from 'src/app/models/register/justifications-card.model';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { API } from 'src/app/constants/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-justifications-cards',
  templateUrl: './justifications-cards.component.html',
  styleUrls: ['./justifications-cards.component.scss'],
})
export class JustificationsCardsComponent {
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() linkMainBtn: string = '';
  @Input() linkMainBtnQueryParams: object = {};

  justificationsCards: JustificationsCard[] = [];
  public pagination?: ApiPagination;
  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;

  private date: Date = new Date();

  constructor(
    private _justificationsCardService: JustificationsCardService,
    private _dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  onText(text: any) {
    this._justificationsCardService.text = text;
    this._justificationsCardService.page = 0;
    this.getData();
  }

  onPage(page: any) {
    this._justificationsCardService.page = page;
    this.getData();
  }

  onNewDate(date: Date) {
    if (this.loading) return;
    this.date = date;
    this.getData();
  }

  dateToStr(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._justificationsCardService
      .getListing({
        date: this.dateToStr(this.date),
        tag: this._router.url === '/ritardi-licos' ? 'LICOS' : undefined,
      })
      .subscribe({
        next: (data) => {
          this.justificationsCards = data?.data?.map(
            (card: any) => new JustificationsCard(card)
          );
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = error.error.error;
          console.error('Delays Listing Error: ', error.error.error);
        },
      })
      .add(() => (this.loading = false));
  }

  // openUsersDialog() {
  //   const dialogSize = '1000px';
  //   const dialogRef = this._dialog.open(DeaUserListingDialogComponent, {
  //     maxWidth: dialogSize,
  //     minWidth: dialogSize,
  //     data: {
  //       type: 'Student',
  //     },
  //   });
  //   dialogRef
  //     .afterClosed()
  //     .subscribe((userDialogResult: DeaUserListingDialogComponentOutput) => {
  //       console.log('userDialogResult => ', userDialogResult);
  //       if (userDialogResult?.id) {
  //         this.postJustificationCard(userDialogResult?.id);
  //       }
  //     });
  // }

  // postJustificationCard(user_id?: string) {
  //   this._justificationsCardService.postJustificationsCard(user_id).subscribe({
  //     next: (_: any) => this.getData(),
  //     error: (error: ApiError) => {
  //       console.log('POST Justifications Card Error: ', error);
  //     },
  //   });
  // }

  // patchJustificationCard(cardId?: string) {
  //   this._justificationsCardService.patchJustificationsCard(cardId).subscribe({
  //     next: (data: ApiResponse) => {
  //       if (data.data != undefined && (data.data as JustificationsCard)) {
  //         // @ts-ignore
  //         const justificationsCard = JustificationsCard.fromJson(data.data);
  //         const index = this.justificationsCards.findIndex(
  //           (card) => card.id === justificationsCard.id
  //         );
  //         this.justificationsCards[index] = justificationsCard;
  //       }
  //       this.pagination = new ApiPagination(data?.pagination);
  //     },
  //     error: (error: ApiError) => {
  //       console.log('PATCH Justifications Card Error: ', error);
  //     },
  //   });
  // }

  openPdfPreviewPDF(id?: string): void {
    this._dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: {
        id: id || 'NO-ID',
        url: API.downloadJustificationsCardPDF(id || 'ID-ERROR'),
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  downloadPDF(id?: string): void {
    if (!id) return;
    this._justificationsCardService.downloadPDF(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      const selected = this.justificationsCards.filter((card) => card.id == id);
      if (selected.length == 1) {
        a.download =
          `${selected[0].anno_scolastico || 'No-AS'} - ${
            selected[0].user?.getFullName() || 'No-User'
          }` + ' (Modulo Ingressi Posticipati e Uscite Anticipate).pdf';
      } else a.download = new Date().toISOString() + '.pdf';
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }
}
