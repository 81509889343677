export class Address {
  address?: string;
  civic?: string;
  cap?: string;
  city?: string;
  cityhall?: string;
  province?: string;

  public static fromJson(json: any): Address {
    const address = new Address();
    address.address = json['indirizzo'];
    address.civic = json['civico'];
    address.cap = json['cap'];
    address.city = json['citta'];
    address.cityhall = json['comune'];
    address.province = json['provincia'];
    return address;
  }

  toJson(defaultUndefined: boolean = true): any {
    const value = defaultUndefined ? undefined : '';
    if (
      !this.address &&
      !this.civic &&
      !this.cap &&
      !this.city &&
      !this.cityhall &&
      !this.province
    ) {
      return undefined;
    }
    return {
      indirizzo: this.address || value,
      civico: this.civic || value,
      cap: this.cap || value,
      citta: this.city || value,
      comune: this.cityhall || value,
      provincia: this.province || value,
    };
  }

  getFullAddress(): string {
    let tmpString: string = '';
    if (this.address != null) tmpString += this.address!;
    if (this.civic != null) tmpString += `, ${this.civic!}`;
    if (this.city != null) tmpString += ` - ${this.city!}`;
    if (this.cityhall != null) tmpString += ` (Comune di ${this.cityhall!})`;
    if (this.cap != null) tmpString += ` ${this.cap!}`;
    if (this.province != null) tmpString += ` (${this.province!})`;
    return tmpString.trim();
  }
}
