import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user/user.model';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dea-birthdays-listing-dialog',
  templateUrl: './dea-birthdays-listing-dialog.component.html',
  styleUrls: ['./dea-birthdays-listing-dialog.component.scss'],
})
export class DeaBirthdaysListingDialogComponent {
  public loading: boolean = false;
  public users: User[] = [];

  constructor(
    private _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: { users?: User[] },
    public dialogRef: MatDialogRef<DeaBirthdaysListingDialogComponent>
  ) {
    this.users = this.data?.users || [];
  }

  getAge(birthdate?: Date): string {
    if (!birthdate) return '';
    const today = new Date();
    const monthDiff = today.getMonth() - birthdate.getMonth();
    let age = today.getFullYear() - birthdate.getFullYear();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdate.getDate())
    ) {
      age--;
    }
    return `${age}`;
  }

  newDate(date: Date) {
    if (this.loading) return;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    this.getBirthdaysLisitng({ date: `${year}-${month}-${day}` });
  }

  getBirthdaysLisitng(p?: { date?: string }) {
    this.loading = true;
    this.users = [];
    this._userService
      .getBirthdaysListing(p)
      .subscribe({
        next: (data) => {
          this.users = data?.data?.map((json: any) => new User(json));
        },
        error: (error) => console.error('getBirthdaysLisitng Error: ', error),
      })
      .add(() => (this.loading = false));
  }

  goToUser(id?: string) {
    this.dialogRef.close(id);
  }
}
