<div class="d-flex flex-column">
  <div class="position-relative" style="width: 64px; height: 64px;">
    <app-dea-thumbnail-img size="64" id="{{id}}"></app-dea-thumbnail-img>
    <div *ngIf="loading" class="position-absolute top-50 start-50 translate-middle">
      <mat-spinner strokeWidth="2" color="primary" diameter="32"></mat-spinner>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center mt-1 fs-075r">
    <div class="pointer" title="Elimina foto profilo" (click)="deleteThumbnail()">
      <i class="bi bi-trash3"></i>
    </div>
    <div class="ms-3 pointer" title="Modifica foto profilo" (click)="onClick()">
      <i class="bi bi-pencil"></i>
    </div>
  </div>
  <input class="d-none" type="file" name="thumbnail" id="thumbnailInput" #thumbnailInput accept=".jpg, .jpeg, .png"
    (change)="onFileChange($event)" />
</div>