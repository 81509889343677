<app-dea-dialog-wrapper>
  <div class="fw-semibold text-start fs-4 mb-2">Film</div>
  <div class="v-spacer"></div>
  <div [formGroup]="filmForm" style="max-height: 450px; overflow-y: scroll;">
    <!-- TITOLO -->
    <ng-container>
      <div class="hstack">
        <app-dea-label class="mb-2" [label]="'Titolo:'"></app-dea-label>
        <button mat-stroked-button color="primary" class="ms-auto fw-bold px-3 py-1 rounded my-auto"
          (click)="addTitle()">
          Aggiungi Titolo
        </button>
      </div>
      <div class="row m-0 p-0 gap-3" formArrayName="title">
        <ng-container *ngFor="let item of $any(filmForm?.get('title'))?.controls; let i = index;" [formGroupName]="i">
          <div class="row m-0 p-0 gap-3">
            <div class="col-auto m-0 p-0 d-flex justify-content-center">
              <mat-form-field>
                <mat-label>Seleziona una lingua</mat-label>
                <mat-select formControlName="key">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let lang of languages;" value="{{lang.code}}">
                    {{lang.code.toUpperCase()}} ({{lang.name}})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col m-0 p-0 d-flex justify-content-center">
              <input formControlName="value" type="text" class="form-control dea-form-input my-auto"
                placeholder="Titolo" toTitleCase />
            </div>
            <div class="col-auto m-0 p-0 d-flex justify-content-center">
              <button mat-stroked-button color="warn" class="fw-bold px-3 py-1 rounded my-auto"
                (click)="removeTitle(i)">
                Elimina
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- TITOLO -->
    <div class="v-spacer"></div>
    <!-- LINGUE / SOTTOTITOLI -->
    <div class="row m-0 p-0 gap-3">
      <div class="col m-0 p-0">
        <mat-form-field class="w-100">
          <mat-label>Lingue disponibili:</mat-label>
          <mat-select formControlName="languages" multiple>
            <mat-select-trigger>
              {{filmForm.get('languages')?.value?.join(', ')?.toUpperCase() || ''}}
            </mat-select-trigger>
            <mat-option *ngFor=" let lang of languages;" [value]="lang.code">
              {{lang.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col m-0 p-0">
        <mat-form-field class="w-100">
          <mat-label>Sottotitoli disponibili:</mat-label>
          <mat-select formControlName="subtitles" multiple>
            <mat-select-trigger>
              {{filmForm.get('subtitles')?.value?.join(', ')?.toUpperCase() || ''}}
            </mat-select-trigger>
            <mat-option *ngFor=" let lang of languages;" [value]="lang.code">
              {{lang.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- LINGUE / SOTTOTITOLI -->
    <div class="v-spacer"></div>
    <!-- ANNO / CATEGORIE -->
    <div class="row m-0 p-0 gap-3">
      <div class="col-2 m-0 p-0 d-flex flex-column justify-content-center">
        <app-dea-label class="mb-2" [label]="'Anno:'"></app-dea-label>
        <input formControlName="year" type="text" class="form-control dea-form-input my-auto" placeholder="1998"
          justInt />
      </div>
      <div class="col m-0 p-0 d-flex flex-column justify-content-center">
        <app-dea-label class="mb-2" [label]="'Categorie: (separate da virgola)'"></app-dea-label>
        <input formControlName="categories" type="text" class="form-control dea-form-input my-auto"
          placeholder="Documentario, Fantascienza, ..." toTitleCase />
      </div>
    </div>
    <!-- ANNO / CATEGORIE -->
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="end">
    <button class="px-5 py-1" mat-stroked-button (click)="cancelBtnAction()">Annulla</button>
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="confirmBtnAction()">Salva</button>
  </div>
</app-dea-dialog-wrapper>