import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class PctoService {
  constructor(private http: HttpClient) {}

  getPctoListing(id?: string, type?: string) {
    return this.http.get<ApiResponse<any>>(API.userPctoListing(id, type), {
      withCredentials: true,
    });
  }

  uploadPcto(formData: FormData, userId?: string, type?: string) {
    return this.http.post(API.pctoUpload(userId, type), formData, {
      withCredentials: true,
    });
  }

  downloadPcto(userId?: string, documentId?: string) {
    return this.http.get(API.pctoDownload(userId, documentId), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  deletePcto(userId?: string, documentId?: string) {
    return this.http.delete(API.pctoDelete(userId, documentId), {
      withCredentials: true,
    });
  }
}
