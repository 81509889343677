<app-dea-widget-wrapper>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div style="overflow-y: scroll !important; max-height: 400px !important;">
        <table class="table table-borderless m-0">
          <thead class="m-0">
            <tr class="dea-table-row-header m-0">
              <th scope="col"></th>
              <th scope="col-3">Cognome</th>
              <th scope="col-3">Nome</th>
              <th scope="col-4">Secondo Nome</th>
              <th scope="col-auto">
                <div class="text-center">Disposizione</div>
              </th>
              <th scope="col-auto pe-3">
                <div class="text-center">Classe</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let option of options;" class="user-listing-table-row dea-radius cursor-pointer">
              <td (click)="onSelected(option.user)">
                <app-dea-thumbnail-img size="32" id="{{option.user.id}}"></app-dea-thumbnail-img>
              </td>
              <td (click)="onSelected(option.user)">{{option.user.cognome}}</td>
              <td (click)="onSelected(option.user)">{{option.user.nome}}</td>
              <td (click)="onSelected(option.user)">{{option.user.secondo_nome}}</td>
              <td class="text-center" (click)="onSelected(option.user)">
                <i [ngStyle]=" {'color': option.hasDisposizione ? 'var(--success-color)' : 'var(--failure-color)'
                }" class="bi bi-check-circle-fill">
                </i>
              </td>
              <td class="text-center" (click)="onSelected(option.user)">
                <i [ngStyle]=" {'color': option.hasClasse ? 'var(--success-color)' : 'var(--failure-color)' }"
                  class="bi bi-check-circle-fill">
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>