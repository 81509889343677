<div class="w-100 border rounded m-0 p-0 p-3">
  <div class="v-spacer"></div>
  <app-user-device-box *ngIf="aggregatedTech" [device]="aggregatedTech.device"></app-user-device-box>
  <div class="v-spacer"></div>
  <div class="row m-0 p-0" *ngFor="let tech of aggregatedTech?.blocks; let first = first;">
    <!-- CELL SEPARATOR -->
    <div class="row m-0 my-2 p-0">
      <div class="col m-0 p-0 d-flex d-col justify-content-center align-content-center opacity-50">
        <hr class="my-auto w-100 me-4 opacity-25">
      </div>
      <div class="col-auto m-0 p-0">
        <div class="hstack">
          <div class="ms-auto fs-075r">
            <mat-icon
              *ngIf="first && tech.__type?.toLowerCase() !== 'return' && tech.__type?.toLowerCase() !== 'release'"
              class="m-0 p-0 dea-icon-btn" title="Aggiungi blocco" [matMenuTriggerFor]="menu">
              add-circle
            </mat-icon>
            <mat-menu #menu="matMenu" class="w-100">
              <button mat-menu-item (click)="showTechBlockDialog(undefined, 'insurance')">Assicurazione</button>
              <button mat-menu-item (click)="showTechBlockDialog(undefined, 'loss')">Furto / Smarrimento</button>
              <button mat-menu-item (click)="showTechBlockDialog(undefined, 'breaking')">Guasto / Rottura</button>
              <button mat-menu-item (click)="showTechBlockDialog(undefined, 'return')">Restituzione</button>
              <button mat-menu-item (click)="showTechBlockDialog(undefined, 'release')">Riscatto</button>
            </mat-menu>
          </div>
          <!-- <div class="ms-4 fs-075r"><mat-icon class="m-0 p-0 dea-icon-btn" title="Visualizza anteprima blocco">visibility</mat-icon></div>
          <div class="ms-4 fs-075r"><mat-icon class="m-0 p-0 dea-icon-btn" title="Download documento blocco">download</mat-icon></div> -->
          <div class="ms-4 fs-075r">
            <mat-icon class="m-0 p-0 dea-icon-btn" title="Elimina blocco" color="warn" (click)="onDelete(tech.id)">
              delete
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- CELL SEPARATOR -->
    <!-- ASSIGNMENT -->
    <div *ngIf="tech.__type?.toLowerCase() === 'assignment'" class="m-0 p-0 py-3">
      <app-user-tech-block-section-head [label]="'Assegnazione'" [tech]="tech" [editBtn]="false">
      </app-user-tech-block-section-head>
    </div>
    <!-- ASSIGNMENT -->
    <!-- INSURANCE -->
    <div *ngIf="tech.__type?.toLowerCase() === 'insurance'" class="m-0 p-0 py-3">
      <app-user-tech-block-section-head [label]="'Assicurazione'" [tech]="tech" [editBtn]="true"
        (editAction)="showTechBlockDialog(tech)">
      </app-user-tech-block-section-head>
      <div class="row m-0 p-0 mt-3">
        <div class="col m-0 p-0 fs-075r opacity-75 d-flex flex-column gap-3">
          <div *ngIf=" tech.expiration !==undefined">
            Scadenza: <span class="fw-semibold">{{tech.expiration | toItDateDdMmmmYyyy}}</span>
          </div>
          <div *ngIf="tech.value !== undefined">
            Totale: <span class="fw-semibold">{{ tech.value | currency:'EUR':'':'1.2-2' }} €</span>
          </div>
        </div>
        <div class="col m-0 p-0 fs-075r opacity-75">
          Allegato: <span class="fw-semibold">{{tech.hasDocument() ? 'Sì' : 'No'}}</span>
          <span *ngIf="tech.document !== undefined && tech.document.id !== undefined"
            (click)="downloadDoc(tech.document.id)" class="ms-4 text-decoration-underline pointer">Scarica</span>
          <span *ngIf="tech.document !== undefined && tech.document.id !== undefined"
            (click)="deleteDoc(tech.document.id)" class="ms-3 text-decoration-underline pointer">Elimina</span>
        </div>
      </div>
    </div>
    <!-- INSURANCE -->
    <!-- LOSS -->
    <div *ngIf="tech.__type?.toLowerCase() === 'loss'" class="m-0 p-0 py-3">
      <app-user-tech-block-section-head [label]="'Furto / Smarrimento'" [tech]="tech" [editBtn]="true"
        (editAction)="showTechBlockDialog(tech)">
      </app-user-tech-block-section-head>
      <div class="row m-0 p-0 mt-3">
        <div *ngIf="tech.note !== undefined && tech.note !== ''" class="col m-0 p-0 fs-075r opacity-75">
          Note: <span class="fw-semibold">{{tech.note}}</span>
        </div>
        <div class="col m-0 p-0 fs-075r opacity-75">
          Allegato: <span class="fw-semibold">{{tech.hasDocument() ? 'Sì' : 'No'}}</span>
          <span *ngIf="tech.document !== undefined && tech.document.id !== undefined"
            class="ms-4 text-decoration-underline pointer" (click)="downloadDoc(tech.document.id)">Scarica</span>
          <span *ngIf="tech.document !== undefined && tech.document.id !== undefined"
            (click)="deleteDoc(tech.document.id)" class="ms-3 text-decoration-underline pointer">Elimina</span>
        </div>
      </div>
    </div>
    <!-- LOSS -->
    <!-- BREAKING -->
    <div *ngIf="tech.__type?.toLowerCase() === 'breaking'" class="m-0 p-0 py-3">
      <app-user-tech-block-section-head [label]="'Guasto / Rottura'" [tech]="tech" [editBtn]="true"
        (editAction)="showTechBlockDialog(tech)">
      </app-user-tech-block-section-head>
      <div class="row m-0 p-0 mt-3 gap-3">
        <div *ngIf="tech.type !== undefined" class="col m-0 mb-1 p-0 fs-075r opacity-75">
          Tipo: <span class="fw-semibold">{{tech.type}}</span>
        </div>
        <div *ngIf="tech.note !== undefined" class="col m-0 mb-1 p-0 fs-075r opacity-75">
          Note: <span class="fw-semibold">{{tech.note}}</span>
        </div>
      </div>
    </div>
    <!-- BREAKING -->
    <!-- RETURN -->
    <div *ngIf="tech.__type?.toLowerCase() === 'return'" class="m-0 p-0 py-3">
      <app-user-tech-block-section-head [label]="'Restituzione'" [tech]="tech" [editBtn]="true"
        (editAction)="showTechBlockDialog(tech)">
      </app-user-tech-block-section-head>
      <div class="row m-0 p-0 mt-3 gap-3">
        <div *ngIf="tech.status !== undefined" class="col m-0 mb-1 p-0 fs-075r opacity-75">
          Stato: <span class="fw-semibold">{{tech.status}}</span>
        </div>
        <div *ngIf="tech.items !== undefined && tech.items.length > 0" class="col m-0 mb-1 p-0 fs-075r opacity-75">
          Oggetti: <span class="fw-semibold">{{tech.items.join(", ")}}</span>
        </div>
      </div>
      <div *ngIf="tech.note !== undefined && tech.note !== ''" class="col m-0 p-0 fs-075r opacity-75 mt-3">
        Note: <span class="fw-semibold">{{tech.note}}</span>
      </div>
    </div>
    <!-- RETURN -->
    <!-- RELEASE -->
    <div *ngIf="tech.__type?.toLowerCase() === 'release'" class="m-0 p-0 py-3">
      <app-user-tech-block-section-head [label]="'Riscatto'" [tech]="tech" [editBtn]="true"
        (editAction)="showTechBlockDialog(tech)">
      </app-user-tech-block-section-head>
      <div class="row m-0 p-0 mt-3">
        <div class="col m-0 p-0 fs-075r opacity-75 d-flex flex-column gap-3">
          <div *ngIf=" tech.expiration !==undefined">
            Data Pagamento: <span class="fw-semibold">{{tech.expiration | toItDateDdMmmmYyyy}}</span>
          </div>
          <div *ngIf="tech.value !== undefined">
            Importo: <span class="fw-semibold">{{ tech.value | currency:'EUR':'':'1.2-2' }} €</span>
          </div>
        </div>
        <div class="col m-0 p-0 fs-075r opacity-75">
          Allegato: <span class="fw-semibold">{{tech.hasDocument() ? 'Sì' : 'No'}}</span>
          <span *ngIf="tech.document !== undefined && tech.document.id !== undefined"
            class="ms-4 text-decoration-underline pointer" (click)="downloadDoc(tech.document.id)">Scarica</span>
          <span *ngIf="tech.document !== undefined && tech.document.id !== undefined"
            (click)="deleteDoc(tech.document.id)" class="ms-3 text-decoration-underline pointer">Elimina</span>
        </div>
      </div>
      <div *ngIf="tech.note !== undefined && tech.note !== ''" class="col m-0 p-0 fs-075r opacity-75 mt-3">
        Note: <span class="fw-semibold">{{tech.note}}</span>
      </div>
    </div>
    <!-- RELEASE -->
  </div>
</div>