<app-listing-top-bar [actionBtn]="enableMainBtn" [actionBtnTitle]="titleMainBtn" (textEmitter)="onText($event)"
  [linkMainBtn]="linkMainBtn" [linkMainBtnQueryParams]="linkMainBtnQueryParams">
</app-listing-top-bar>
<div class="v-spacer"></div>
<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      {{error?.message}}
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <table class="table table-borderless m-0">
      <thead class="m-0">
        <tr class="dea-table-row-header m-0">
          <th scope="col"></th>
          <th scope="col-3">Cognome</th>
          <th scope="col-3">Nome</th>
          <th scope="col-4">E-Mail</th>
          <th scope="col-auto pe-3">Telefono</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users;" class="user-listing-table-row dea-radius">
          <td class="col" routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}">
            <app-dea-thumbnail-img size="32" id="{{user.id}}"></app-dea-thumbnail-img>
          </td>
          <td class="col-3 py-auto" routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}">
            {{user.cognome}}
          </td>
          <td class="col-3 py-auto" routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}">
            {{user.nome}}
          </td>
          <td class="col-4 py-auto">
            <app-email-label [email]="user.contatti?.getFirstEmailAddress() ?? ''"></app-email-label>
          </td>
          <td class="col-auto py-auto pe-3">
            <app-phone-label [phone]="user.contatti?.getFirstPhoneNumber() ?? ''"></app-phone-label>
          </td>
        </tr>
      </tbody>
    </table>
    <app-listing-pagination-bar [paginationData]="pagination"
      (pageEmitter)="onPage($event)"></app-listing-pagination-bar>
  </ng-template>
</ng-template>
