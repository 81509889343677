import { DeaSubjectsListingDialogComponent } from '../../dea-pack/dea-subjects-listing-dialog/dea-subjects-listing-dialog.component';
import { DeaClassesListingDialogComponent } from '../../dea-pack/dea-classes-listing-dialog/dea-classes-listing-dialog.component';
import { DeaUserListingDialogComponent } from '../../dea-pack/dea-user-listing-dialog/dea-user-listing-dialog.component';
import { CalendarTimetableEventBlockTypes } from 'src/app/models/calendar/calendar-timetable-event-block-types.enum';
import { DeaSingleBtnDialogComponent } from '../../dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { CalendarEventTypes } from 'src/app/models/calendar/calendar-event-types.enum';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { Headquarter } from 'src/app/models/headquarter';
import { Class } from 'src/app/models/class/class.model';
import { User } from 'src/app/models/user/user.model';
import * as moment from 'moment';
import {
  byDayOptions,
  CalendarEvent,
  CalendarEventRecurrence,
} from 'src/app/models/calendar/calendar-event.model';
import {
  DeaUserListingDialogComponentOutput,
  DeaSubjectsListingDialogComponentOutput,
} from 'src/app/constants/interfaces';
import {
  timezoneOption,
  CalendarService,
  CalendarColorsConfig,
} from 'src/app/services/calendar.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export interface CalendarEventFormInterface {
  newEventType?: CalendarEventTypes;
  eventId?: string;

  title?: string;
  start?: moment.Moment;
  end?: moment.Moment;
  recurrence?: CalendarEventRecurrence;
  endDate?: moment.Moment;
  teachers?: User[];
  classes?: Class[];
  timetableBlock?:
    | 'LEZIONE'
    | 'RICEVIMENTO'
    | 'DISPOSIZIONE'
    | 'SORVEGLIANZA'
    | 'SOSTITUZIONE'
    | 'GITA'
    | undefined;
}

@Component({
  selector: 'app-calendar-event-form-dialog',
  templateUrl: './calendar-event-form-dialog.component.html',
  styleUrl: './calendar-event-form-dialog.component.scss',
})
export class CalendarEventFormDialogComponent implements OnInit {
  private _clientStorageService: ClientStorageService =
    inject(ClientStorageService);
  private _calendarService: CalendarService = inject(CalendarService);
  private _userService: UserService = inject(UserService);
  private _dialog: MatDialog = inject(MatDialog);

  public event: CalendarEvent = new CalendarEvent();
  public eventFormGroup!: FormGroup;
  public posting: boolean = false;
  public loading: boolean = false;
  public isError: boolean = false;
  public dialogTitle: string = '';
  public error?: ApiError;

  public frequencyOptions = [
    { name: 'giorno', value: 'DAILY' },
    { name: 'settimana', value: 'WEEKLY' },
    { name: 'mese', value: 'MONTHLY' },
    { name: 'anno', value: 'YEARLY' },
  ];

  get colors(): CalendarColorsConfig[] {
    return this._calendarService.colors;
  }

  get timeZones(): timezoneOption[] {
    return this._calendarService.timeZones;
  }

  get byDayOptions() {
    return byDayOptions;
  }

  get teachersEventFormGroup() {
    return this.eventFormGroup.get('teachers') as FormArray;
  }

  get classesEventFormGroup() {
    return this.eventFormGroup.get('classes') as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<CalendarEventFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CalendarEventFormInterface
  ) {
    this.event.__type =
      data.newEventType == CalendarEventTypes.EVENT
        ? CalendarEventTypes.EVENT
        : CalendarEventTypes.TIMETABLE_EVENT;

    if (data.title) this.event.title = data.title;
    if (data.start) this.event.start = data.start;
    if (data.end) this.event.end = data.end;
    if (data.start || data.end) this.event.tz = 'Europe/Rome';
    if (data.recurrence) this.event.recurrence = data.recurrence;
    if (data.teachers !== undefined && data.teachers.length > 0) {
      this.event.teachers = data.teachers;
      if (
        data.teachers.length === 1 &&
        data.teachers.at(0)?.materie?.length === 1
      ) {
        this.event.subject = data.teachers.at(0)?.materie?.at(0);
      }
    }
    if (data.classes !== undefined && data.classes.length > 0) {
      this.event.classes = data.classes;
    }
    if (data.timetableBlock) this.event.timetableBlock = data.timetableBlock;

    this.setEvent(this.event);
  }

  ngOnInit(): void {
    this.dialogTitle = this.data.eventId ? 'Modifica Evento:' : 'Nuovo Evento:';
    if (this.data.eventId) this.getEventDetails();
  }

  setEvent(event: CalendarEvent) {
    this.event = event;
    this.eventFormGroup = this._calendarService.createCalendarEventFormGroup(
      this.event,
      this.data.newEventType
    );
  }

  async getEventDetails() {
    this._calendarService.eventDetails(this.data.eventId).subscribe({
      next: (response) => {
        console.info('Get Event Details Data:', response.data);
        this.setEvent(new CalendarEvent(response.data));
      },
      error: (err) => {
        console.error('Get Event Details Error:', err);
      },
    });
  }

  setupFormGroup(event: CalendarEvent) {
    this.eventFormGroup = new FormGroup({
      title: new FormControl(event.title, Validators.required),
      description: new FormControl(event.description),
      start: new FormControl(
        event.start
          ? moment.tz(event?.start, event.tz).format('YYYY-MM-DD HH:mm:ss')
          : moment().add(1, 'day').format('YYYY-MM-DD HH:') + '00:00',
        Validators.required
      ),
      end: new FormControl(
        event.end
          ? moment.tz(event?.end, event.tz).format('YYYY-MM-DD HH:mm:ss')
          : moment().add(1, 'day').add(1, 'hour').format('YYYY-MM-DD HH:') +
            '00:00',
        Validators.required
      ),
      tz: new FormControl(
        event.tz || this._calendarService.getDefaultTZ(),
        Validators.required
      ),
      location: new FormControl(event.location),
      room: new FormControl(event.room),
      meetingLink: new FormControl(event.meetingLink),
      owner: new FormControl(event.owner),
      color: new FormControl(event.color ?? this._calendarService.defaultColor),
      note: new FormControl(event.note),
      __type: new FormControl(event.__type),
      isRecursiveEvent: new FormControl(event.recurrence !== undefined),
      recurrence: new FormGroup({
        frequency: new FormControl(event.recurrence?.frequency),
        interval: new FormControl(event.recurrence?.interval),
        endOption: new FormControl(
          event.recurrence?.endDate !== undefined
            ? 'date'
            : event.recurrence?.count !== undefined
            ? 'count'
            : 'never'
        ),
        endDate: new FormControl({
          value: event.recurrence?.endDate
            ? moment
                .tz(event.recurrence?.endDate, event.tz)
                .format('YYYY-MM-DD')
            : undefined,
          disabled: event.recurrence?.endDate === undefined,
        }),
        count: new FormControl({
          value: event.recurrence?.count,
          disabled: event.recurrence?.count === undefined,
        }),
        byDay: new FormArray(
          event.recurrence?.byDay
            ? (event.recurrence?.byDay ?? []).map(
                (value) => new FormControl(value)
              )
            : []
        ),
        // byMonthDay: new FormArray(
        //   event.recurrence?.byMonthDay
        //     ? (event.recurrence?.byMonthDay ?? []).map(
        //         (value) => new FormControl(value)
        //       )
        //     : []
        // ),
        // bySetPosition: new FormControl(event.recurrence?.bySetPosition),
      }),
      // TIMETABLE-EVENT
      headquarter: new FormControl(
        event.headquarter?.frontend ??
          this._clientStorageService.getHeadquarterString()
      ),
      timetableBlock: new FormControl(
        event.timetableBlock ?? CalendarTimetableEventBlockTypes.LEZIONE
      ),
      teachers: new FormArray(
        event.__type === CalendarEventTypes.TIMETABLE_EVENT && event.teachers
          ? (event.teachers ?? []).map(
              (value) =>
                new FormControl({
                  _id: new FormControl({ value: value.id, disabled: true }),
                  nome: new FormControl({ value: value.nome, disabled: true }),
                  cognome: new FormControl({
                    value: value.cognome,
                    disabled: true,
                  }),
                })
            )
          : []
      ),
      subject: new FormGroup({
        _id: new FormControl(event.subject?.id),
        nome: new FormControl(event.subject?.nome),
        dea_id: new FormControl(event.subject?.dea_id),
      }),
      classes: new FormArray(
        event.__type === CalendarEventTypes.TIMETABLE_EVENT && event.teachers
          ? (event.classes ?? []).map(
              (value) =>
                new FormControl({
                  _id: new FormControl({ value: value.id, disabled: true }),
                  dea_id: new FormControl({
                    value: value.dea_id,
                    disabled: true,
                  }),
                  name: new FormControl({
                    value: value.getName(),
                    disabled: true,
                  }),
                })
            )
          : []
      ),
      out: new FormControl(event.out ?? false),
    });

    this.eventFormGroup
      .get('isRecursiveEvent')
      ?.valueChanges.subscribe((value: any) => {
        const byDayArray = <FormArray>(
          this.eventFormGroup.controls['recurrence'].get('byDay')
        );
        if (value === true) {
          this.eventFormGroup.controls['recurrence']
            .get('interval')
            ?.setValue(1);
          this.eventFormGroup.controls['recurrence']
            .get('frequency')
            ?.setValue('WEEKLY');
          byDayArray.push(
            new FormControl(this._calendarService.getCurrentByDayValue())
          );
          this.eventFormGroup.controls['recurrence']
            .get('endOption')
            ?.setValue(
              event.recurrence?.endDate !== undefined
                ? 'date'
                : event.recurrence?.count !== undefined
                ? 'count'
                : 'never'
            );
          event.recurrence?.endDate === undefined
            ? this.eventFormGroup.controls['recurrence']
                .get('endDate')
                ?.disable()
            : this.eventFormGroup.controls['recurrence']
                .get('endDate')
                ?.enable();
          this.eventFormGroup.controls['recurrence']
            .get('endDate')
            ?.setValue(
              event.recurrence?.endDate
                ? moment
                    .tz(event.recurrence?.endDate, event.tz)
                    .format('YYYY-MM-DD')
                : undefined
            );
          event.recurrence?.count === undefined
            ? this.eventFormGroup.controls['recurrence'].get('count')?.disable()
            : this.eventFormGroup.controls['recurrence'].get('count')?.enable();
          this.eventFormGroup.controls['recurrence']
            .get('count')
            ?.setValue(
              event.recurrence?.count ? event.recurrence?.count : undefined
            );
        } else {
          this.eventFormGroup.controls['recurrence']
            .get('interval')
            ?.setValue(undefined);
          this.eventFormGroup.controls['recurrence']
            .get('frequency')
            ?.setValue(undefined);
          this.eventFormGroup.controls['recurrence']
            .get('endOption')
            ?.setValue('never');
          byDayArray.clear();
        }
      });

    this.eventFormGroup.controls['recurrence']
      .get('endOption')
      ?.valueChanges.subscribe((value: any) => {
        switch (value) {
          case 'never':
            this.eventFormGroup.controls['recurrence']
              .get('count')
              ?.setValue(undefined);
            this.eventFormGroup.controls['recurrence']
              .get('endDate')
              ?.setValue(undefined);
            this.eventFormGroup.controls['recurrence'].get('count')?.disable();
            this.eventFormGroup.controls['recurrence']
              .get('endDate')
              ?.disable();
            break;
          case 'date':
            this.eventFormGroup.controls['recurrence'].get('count')?.disable();
            this.eventFormGroup.controls['recurrence'].get('endDate')?.enable();
            this.eventFormGroup.controls['recurrence']
              .get('count')
              ?.setValue(undefined);
            this.eventFormGroup.controls['recurrence']
              .get('endDate')
              ?.setValue(moment().add(1, 'month').format('YYYY-MM-DD'));
            break;
          case 'count':
            this.eventFormGroup.controls['recurrence'].get('count')?.enable();
            this.eventFormGroup.controls['recurrence']
              .get('endDate')
              ?.disable();
            this.eventFormGroup.controls['recurrence']
              .get('count')
              ?.setValue(1);
            this.eventFormGroup.controls['recurrence']
              .get('endDate')
              ?.setValue(undefined);
            break;
        }
      });
  }

  setUpByDay(byDay: string): void {
    const days: string[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    if (days.includes(byDay)) {
      const recurrenceGroup = this.eventFormGroup.get('recurrence');
      const byDayArray = recurrenceGroup?.get('byDay') as FormArray;
      if (byDayArray) {
        const value = byDayArray.value as string[];
        if (value.includes(byDay)) {
          const index = value.indexOf(byDay);
          if (index > -1) byDayArray.removeAt(index);
        } else {
          byDayArray.push(new FormControl(byDay));
        }
        if (byDayArray.length === 0) {
          const currentDay = this._calendarService.getCurrentByDayValue();
          byDayArray.push(new FormControl(currentDay));
        }
      }
    } else console.error('Func setUpByDay - Not Valid Value!');
  }

  openUsersDialog(type: string, forArray: string) {
    if (['Teacher'].includes(type) && ['teachers'].includes(forArray)) {
      const dialogSize = '1000px';
      const dialogRef = this._dialog.open(DeaUserListingDialogComponent, {
        maxWidth: dialogSize,
        minWidth: dialogSize,
        data: {
          type: type.toString().trim(),
        },
        autoFocus: false,
        restoreFocus: false,
      });
      dialogRef
        .afterClosed()
        .subscribe(async (result: DeaUserListingDialogComponentOutput) => {
          if (result) {
            let formArray;
            switch (forArray.toString().toLowerCase()) {
              case 'teachers':
                formArray = <FormArray>this.eventFormGroup.get('teachers');
                break;
            }
            if (formArray) {
              if (!this.eventFormGroup.get('subject')?.get('_id')?.value) {
                const getUserData = async () => {
                  return new Promise<User | undefined>((resolve) => {
                    if (result.id) {
                      this._userService.getDetail(result.id).subscribe({
                        next: (response) => resolve(new User(response.data)),
                        error: (err) => console.error('GetData Error:', err),
                      });
                    } else return resolve(undefined);
                  });
                };
                const userData: User | undefined = await getUserData();
                if (userData?.materie?.length === 1) {
                  this.eventFormGroup.controls['subject'] = new FormGroup({
                    _id: new FormControl({
                      value: userData?.materie?.at(0)?.id,
                      disabled: true,
                    }),
                    nome: new FormControl({
                      value: userData?.materie?.at(0)?.nome,
                      disabled: true,
                    }),
                    dea_id: new FormControl({
                      value: userData?.materie?.at(0)?.dea_id,
                      disabled: true,
                    }),
                  });
                }
              }
              formArray.push(
                new FormGroup({
                  _id: new FormControl({ value: result.id, disabled: true }),
                  nome: new FormControl({
                    value: result.nome,
                    disabled: true,
                  }),
                  secondo_nome: new FormControl({
                    value: result.secondo_nome,
                    disabled: true,
                  }),
                  cognome: new FormControl({
                    value: result.cognome,
                    disabled: true,
                  }),
                })
              );
            }
          }
        });
    } else console.log('Attenzione: chiave non valida!');
  }

  openSubjectsDialog() {
    const dialogSize = '800px';
    const dialogRef = this._dialog.open(DeaSubjectsListingDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef
      .afterClosed()
      .subscribe((result: DeaSubjectsListingDialogComponentOutput) => {
        if (result) {
          this.eventFormGroup.controls['subject'] = new FormGroup({
            _id: new FormControl({ value: result.id, disabled: true }),
            nome: new FormControl({ value: result.nome, disabled: true }),
            dea_id: new FormControl({ value: result.dea_id, disabled: true }),
          });
        }
      });
  }

  deleteSubject(): void {
    this.eventFormGroup.controls['subject'].reset();
  }

  openClassesDialog() {
    const dialogSize = '1000px';
    const dialogRef = this._dialog.open(DeaClassesListingDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((classDialogResult: Class) => {
      if (classDialogResult) {
        let formArray = <FormArray>this.eventFormGroup.get('classes');
        if (formArray) {
          formArray.push(
            new FormGroup({
              _id: new FormControl({
                value: classDialogResult.id,
                disabled: true,
              }),
              year: new FormControl({
                value: classDialogResult.anno,
                disabled: true,
              }),
              section: new FormControl({
                value: classDialogResult.sezione,
                disabled: true,
              }),
              department: new FormControl({
                value: classDialogResult.dipartimento,
                disabled: true,
              }),
            })
          );
        }
      }
    });
  }

  deleteItemInAt(forArray: string, index: number) {
    if (['teachers', 'classes'].includes(forArray)) {
      let formArray = <FormArray>this.eventFormGroup.get(forArray);
      if (formArray) formArray.removeAt(index);
    } else console.log('Attenzione: chiave non valida!');
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getCalendarEventFromForm(): CalendarEvent {
    return new CalendarEvent();
  }

  postData() {
    this.posting = true;
    let json = this.eventFormGroup.getRawValue();
    json.start = moment.tz(json.start, json.tz).toISOString();
    json.end = moment.tz(json.end, json.tz).toISOString();

    const removeNullAndReplaceWithUndefined = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(removeNullAndReplaceWithUndefined);
      } else if (obj !== null && typeof obj === 'object') {
        const newObj: any = {};
        for (const key in obj) {
          if (obj[key] === null) {
            newObj[key] = undefined;
          } else {
            newObj[key] = removeNullAndReplaceWithUndefined(obj[key]);
          }
        }
        return newObj;
      }
      return obj;
    };

    json = removeNullAndReplaceWithUndefined(json);
    if (Object.keys(json.subject ?? {}).length === 0) delete json.subject;
    if (json.subject._id == undefined) delete json.subject;
    console.info('JSON => ', json);

    const method = this.data.eventId
      ? this._calendarService.putEvent(this.data.eventId, json)
      : this._calendarService.postEvent(json);
    method.subscribe({
      next: (data) => {
        console.info('DATA => ', data.data);
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.error = new ApiError(error);
        const errorDialogRef = this._dialog.open(DeaSingleBtnDialogComponent, {
          maxWidth: '450px',
          minWidth: '450px',
          data: {
            error: this.error,
          },
          autoFocus: false,
          restoreFocus: false,
        });
        errorDialogRef.afterClosed().subscribe(() => (this.posting = false));
      },
    });
  }
}
