<mat-card fxLayoutGap class="p-3">
  <div class="fw-semibold fs-4 mb-2">Bisogni educativi speciali:</div>
  <div class="v-spacer"></div>
  <div class="d-flex flex-column justify-content-start gap-3">
    <div class="hstack gap-3 d-flex align-items-start">
      <app-dea-chip-status [label]="'DSA'" [status]="data.dsa"></app-dea-chip-status>
    </div>
    <div>
      <app-dea-label class="m-0 p-0 mb-2" [label]="'Dettagli:'"></app-dea-label>
      <div>{{data.diagnosi?.join(', ')}}</div>
    </div>
    <div>
      <app-dea-label class="m-0 p-0 mb-2" [label]="'Note:'"></app-dea-label>
      <div>{{data.note}}</div>
    </div>
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">Chiudi</button>
  </div>
</mat-card>
