import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { ClassesService } from 'src/app/services/classes.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Input, OnInit } from '@angular/core';
import { Class } from 'src/app/models/class/class.model';

@Component({
  selector: 'app-classes-listing',
  templateUrl: './classes-listing.component.html',
  styleUrls: ['./classes-listing.component.scss'],
})
export class ClassesListingComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 20;
  @Input() department?: string;
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() cbMainBtn: Function = () => {};

  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;
  private text?: string;
  private year: string = ScholasticYear.getCurrent();

  public classesGroupedBy = [];

  constructor(private _classesService: ClassesService) {}

  get thisYear(): string {
    return this.year;
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  onScholasticYear(year: string) {
    this.year = year;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._classesService
      .listing({
        year: this.year || undefined,
        department: this.department,
        txt: this.text,
      })
      .subscribe({
        next: (data) => {
          if (data.data != undefined && (data.data as Class[])) {
            const classesArray: Class[] = data.data as Class[];
            let groupBy = classesArray.reduce(function (r, a) {
              if (a.dipartimento) {
                r[a.dipartimento] = r[a.dipartimento] || [];
                r[a.dipartimento].push(a);
              }
              return r;
            }, Object.create(null));
            for (let key in groupBy) {
              const tmpList = groupBy[key];
              groupBy[key] = tmpList.reduce(function (r: any, a: any) {
                if (a.sezione) {
                  r[a.sezione] = r[a.sezione] || [];
                  r[a.sezione].push(a);
                }
                return r;
              }, Object.create(null));
            }
            this.classesGroupedBy = groupBy;
          }
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Classes Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
