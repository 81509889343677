<div *ngIf="visibility"
  class="h-100 bg-w dea-radius dea-shadow p-2 d-flex flex-column justify-content-between class-cell"
  (click)="goToClassDetails()">
  <div>
    <div class="d-flex">
      <div class="fs-2 fw-semibold">{{year}}{{section}}</div>
      <div class="ms-auto fw-light mt-2" style="font-size: 0.65rem;">
        {{scholasticYear | shorteningSchoolYear}}<br>
      </div>
    </div>
    <div>{{department}}</div>
  </div>
  <div class="mt-auto text-end">
    <div class="m-auto">
      <span class="students-counter px-3 py-1 rounded-pill">{{students}}</span>
    </div>
  </div>
</div>