import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { GuestAttendance } from 'src/app/models/register/guest/guest-attendance.model';
import { GuestAttendanceService } from 'src/app/services/guest-attendance.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AppPaths } from 'src/app/constants/app-paths';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest-attendance-details',
  templateUrl: './guest-attendance-details.component.html',
  styleUrls: ['./guest-attendance-details.component.scss'],
})
export class GuestAttendanceDetailsComponent implements OnInit {
  public loading: boolean = false;
  public isError: boolean = false;
  public error?: ApiError;

  public guestAttendanceId?: string;
  public guestAttendance?: GuestAttendance;
  public guestUser?: User;

  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private _guestAttendanceService: GuestAttendanceService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.guestAttendanceId = params['id'];
      this.getData();
    });
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._guestAttendanceService
      .getDetail(this.guestAttendanceId)
      .subscribe({
        next: (data) => {
          this.guestAttendance = new GuestAttendance(data?.data);
          this.guestUser = new User({
            nome: this.guestAttendance.nome,
            cognome: this.guestAttendance.cognome,
            contatti: {
              email: [
                {
                  etichetta: '',
                  indirizzo: this.guestAttendance.email,
                  enable: true,
                },
              ],
              telefono: [
                {
                  etichetta: '',
                  indirizzo: this.guestAttendance.telefono,
                  enable: true,
                },
              ],
            },
          });
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Detail Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  deleteGuestAttendanceConfirmDialog() {
    const confirmDialogRef = this.dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Attenzione!',
        message: 'Sei sicuro di voler eliminare questo elemento?',
        details: "(L'operazione non sarà reversibile)",
      },
      autoFocus: false,
      restoreFocus: false,
    });

    confirmDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === true) this.deleteGuestAttendance();
    });
  }

  deleteGuestAttendance() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._guestAttendanceService
      .deleteGuestAttendance(this.guestAttendanceId)
      .subscribe({
        next: (_) => this._router.navigateByUrl(AppPaths.INGRESSI_OSPITI),
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Detail Error: ', error);
        },
      });
  }
}
