<table class="table table-borderless m-0">
  <thead class="m-0">
    <tr class="dea-table-row-header m-0">
      <th scope="col"></th>
      <th scope="col-3">Cognome</th>
      <th scope="col-3">Nome</th>
      <th scope="col-4">E-Mail</th>
      <th scope="col-auto pe-3">Telefono</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users;" class="user-listing-table-row dea-radius">
      <td class="col" routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}">
        <app-dea-thumbnail-img size="32" id="{{user.id}}"></app-dea-thumbnail-img>
      </td>
      <td class="col-3 py-auto" routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}">
        <div [ngClass]="{'fw-bold': isHightlight(user.id), 'fw-normal': !isHightlight(user.id)}">
          {{user.cognome}}
        </div>
      </td>
      <td class="col-3 py-auto" routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}">
        <div [ngClass]="{'fw-bold': isHightlight(user.id), 'fw-normal': !isHightlight(user.id)}">
          {{user.nome}}
        </div>
      </td>
      <td class="col-4 py-auto">
        <app-email-label [email]="user.contatti?.getFirstEmailAddress() ?? ''"></app-email-label>
      </td>
      <td class="col-auto py-auto pe-3">
        <app-phone-label [phone]="user.contatti?.getFirstPhoneNumber() ?? ''"></app-phone-label>
      </td>
    </tr>
  </tbody>
</table>
