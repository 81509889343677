export class AppTools {
  static capitalizeFirstLetterOfEachWord(string: string): string {
    let capitalizedArray: string[] = [];
    string.split(' ').forEach((word) => {
      capitalizedArray.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    });
    return capitalizedArray.join(' ');
  }
}
