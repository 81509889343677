<app-dea-dialog-wrapper>
  <div class="d-flex flex-column gap-3">
    <div class="text-center fw-semibold fs-4">{{data.title}}</div>
    <div class="text-center">{{data.message}}</div>
    <div class="text-center fw-lighter" style="font-size: 0.875rem; line-height: 1.25rem;">
      {{data.details}}
    </div>
  </div>
  <div class="mt-4 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
      {{data.falseBtn === undefined ? 'No': data.falseBtn}}
    </button>
    <button class="px-5 py-1" mat-flat-button (click)="onConfirm()"
      color="{{data.destructive === true ? 'warn' : 'primary' }}">
      {{data.trueBtn === undefined ? 'Sì': data.trueBtn}}
    </button>
  </div>
</app-dea-dialog-wrapper>