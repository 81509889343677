import { SpecialEducationalNeeds } from 'src/app/models/user/special-educational-needs.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-bes-details-dialog',
  templateUrl: './bes-details-dialog.component.html',
  styleUrls: ['./bes-details-dialog.component.scss'],
})
export class BesDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BesDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpecialEducationalNeeds
  ) {}

  onDismiss(): void {
    this.dialogRef.close();
  }
}
