export class Schoolbook {
  id?: string;
  dea_id?: string;
  autori?: string;
  titolo?: string;
  volume?: string;
  editore?: string;
  isbn?: string;
  prezzo?: Number;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.dea_id = json['dea_id'];
    this.autori = json['autori'];
    this.titolo = json['titolo'];
    this.volume = json['volume'];
    this.editore = json['editore'];
    this.isbn = json['isbn'];
    this.prezzo = json['prezzo'];
  }
}
