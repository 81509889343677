export class EventClass {
  id?: string;
  entity?: string;
  year?: number;
  section?: string;
  department?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.entity = json['entity'];
    this.year = json['year'];
    this.section = json['section'];
    this.department = json['department'];
  }

  toJson(): any {
    return {
      _id: this.id,
      entity: this.entity,
      year: this.year,
      section: this.section,
      department: this.department,
    };
  }

  getName(): string {
    let tmpName: string = '';
    if (this.year) tmpName = `${tmpName}${this.year}`;
    if (this.section) tmpName = `${tmpName}${this.section}`;
    if (this.department) {
      tmpName =
        tmpName.length == 0
          ? this.department
          : `${tmpName} - ${this.department}`;
    }
    return tmpName.toString().trim();
  }
}
