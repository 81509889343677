import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class ToolService {
  constructor(private http: HttpClient) {}

  getDataFromCF(cf: string) {
    return this.http.get<ApiResponse<any>>(API.dataFromCF(cf), {
      withCredentials: true,
    });
  }
}
