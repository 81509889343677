<div class="border rounded p-3 d-flex flex-column gap-3">
  <div class="hstack">
    <div class="me-auto hstack">
      <div *ngIf=" feature?.status=='PENDING'" class="bg-warning text-white fw-semibold fs-065r rounded py-1 px-3">
        {{feature?.status}}
      </div>
      <div *ngIf="feature?.status == 'IN PROGRESS'" class="bg-info text-white fw-semibold fs-065r rounded py-1 px-3">
        {{feature?.status}}
      </div>
      <div *ngIf="feature?.status == 'DONE'" class="bg-success text-white fw-semibold fs-065r rounded py-1 px-3">
        {{feature?.status}}
      </div>
      <div class="fs-065r opacity-50 d-flex align-items-center py-1 px-3">
        <div class="">ID: {{feature?.id}}</div>
      </div>
    </div>
    <div *ngIf=" feature?.isPinned()">
      <i class="bi bi-pin-angle-fill text-primary"></i>
    </div>
    <div *ngIf="!feature?.isPinned()">
      <i class="bi bi-pin-angle opacity-50"></i>
    </div>
    <ng-content></ng-content>
  </div>
  <div>
    <div class="fs-065r opacity-65">
      Creato: {{feature?.created | date:'dd MMMM yyyy'}}<span *ngIf="feature?.updated">
        | Ultimo Aggiornamento: {{feature?.updated | date:'dd MMMM yyyy'}}
      </span>
    </div>
    <div class="fw-semibold">{{feature?.title}}</div>
  </div>
  <div class="opacity-75">{{feature?.description}}</div>
  <div class="d-flex flex-column gap-2">
    <div class="d-flex flex-row justify-content-end align-items-start w-100 gap-1 fs-055r opacity-50 flex-wrap">
      <div *ngFor="let tag of feature?.tags; let last = last;"
        class="d-flex justify-content-center align-content-center gap-1">
        {{tag}}
        <div *ngIf=" !last" class="vr"></div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-end align-items-start w-100 gap-1 fs-055r opacity-50 flex-wrap">
      <app-dea-chip-status *ngFor="let category of feature?.categories;" [label]="category" [status]="false">
      </app-dea-chip-status>
    </div>
  </div>
</div>