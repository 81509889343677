import { Discipline } from './discipline.model';
import { User } from '../user/user.model';

export class ClassTeacher {
  docente?: User;
  discipline?: Discipline[];

  constructor(json?: any) {
    this.docente = new User(json['docente']);
    this.discipline = (json['discipline'] || []).map(
      (json: any) => new Discipline(json)
    );
  }
}
