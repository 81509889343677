import { Schoolbook } from '../schoolbook.model';
import { Subject } from '../subject.model';

export class Discipline {
  materia?: Subject;
  libri?: Schoolbook[];
  ore?: Number;

  constructor(json?: any) {
    this.materia = new Subject(json['materia']);
    this.libri = (json['libri'] || []).map((json: any) => new Schoolbook(json));
    this.ore = json['ore'];
  }
}
