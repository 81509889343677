import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorteningSchoolYear',
})
export class ShorteningSchoolYearPipe implements PipeTransform {
  transform(value?: string | null): unknown {
    if (value) {
      // return parsePhoneNumberFromString(value, 'IT')?.number ?? value;
    }
    return value;
  }
}
