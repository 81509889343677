export class Device {
  id?: string;
  serial_number?: string;
  device_id?: string;
  product_family?: string;
  model_name?: string;
  capacity?: string;
  color?: string;
  orgAdded?: Date;
  orgRemoved?: Date;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.serial_number = json['serial_number'];
    this.device_id = json['device_id'];
    this.product_family = json['product_family'];
    this.model_name = json['model_name'];
    this.capacity = json['capacity'];
    this.color = json['color'];
    this.orgAdded = json['orgAdded'];
    this.orgRemoved = json['orgRemoved'];
  }

  getFullName(): string {
    return `${this.model_name || this.product_family} (${this.serial_number})`;
  }

  getIconPath(): string {
    let basePath = '/assets/devices-icons';
    switch (this.product_family) {
      case 'Mac':
        return basePath + '/macbook.png';
      case 'iPad':
        return basePath + '/ipad.png';
      case 'Apple TV':
        return basePath + '/appletv.png';
      default:
        return 'https://static.deamicis.cloud/transparent.png';
    }
  }
}
