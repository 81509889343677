import { UserTypeBackendStr } from 'src/app/models/user/user-type';
import { Component } from '@angular/core';

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.scss'],
})
export class StaffsComponent {
  type: string = UserTypeBackendStr.STAFF;
}
