<!-- LOADING -->
<div *ngIf="loading;" class="d-flex justify-content-center align-items-center" style="height: 96px;">
  <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
</div>
<!-- ERROR -->
<div *ngIf="!loading && isError;" class="d-flex flex-column justify-content-center align-items-center gap-3"
  style="height: 96px;">
  <div>{{error?.message}}</div>
</div>
<!-- DATA -->
<div *ngIf="!loading && !isError;" class="row m-0 p-0">
  <div class="col-auto m-0 p-0">
    <div class="user-img-spacer"></div>
  </div>
  <div class="col m-0 p-0 ps-3">
    <table class="table table-borderless m-0">
      <thead class="m-0">
        <tr class="dea-table-row-header m-0">
          <th scope="col">Modulo</th>
          <th scope="col">Classe</th>
          <th scope="col">Materia</th>
          <th scope="col">Data Creazione</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of forms;" class="guest-attendance-listing-table-row dea-radius">
          <td class="col py-auto">
            {{a.title}}
          </td>
          <td class="col py-auto">
            {{a.getClass()}}
          </td>
          <td class="col py-auto">
            {{a.getSubject()}}
          </td>
          <td class="col py-auto">{{a.createdAt | date:'yyyy-MM-dd'}}</td>
          <td class="col py-auto">
            <mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="openPdfPreviewPDF(a.id)"
              title="Visualizza {{a?.title}}">
              visibility
            </mat-icon>
            <mat-icon class="ms-3 m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="documentFormPDF(a.id)"
              title="Download: {{a?.title}}">
              download
            </mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>