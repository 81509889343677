import { UserTypeBackendStr } from 'src/app/models/user/user-type';
import { Component } from '@angular/core';

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss'],
})
export class TeachersComponent {
  type: string = UserTypeBackendStr.TEACHER;
}
