import { CalendarEvent } from 'src/app/models/calendar/calendar-event.model';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, inject, OnInit } from '@angular/core';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { User } from 'src/app/models/user/user.model';

//
// Interfaccia per la struttura delle informazioni del "teacher"
export interface Teacher {
  _id: string;
  dea_id: string;
  cognome: string;
  nome: string;
  secondo_nome: string;
}

// Interfaccia per le informazioni sui minuti
export interface Mins {
  lezione: number;
  disposizione: number;
  ricevimento: number;
}

// Interfaccia per le informazioni sui minuti
export interface Blocks {
  lezione: number;
  disposizione: number;
  ricevimento: number;
}

export interface WeeklyScheduleCounters {
  mins: Mins;
  blocks: Blocks;
}

class CalendarEventShift extends CalendarEvent {
  slot?: string;
  weekday?: string;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    this.slot = json['slot'];
    this.weekday = json['weekday'];
  }
}

// Interfaccia principale per rappresentare la struttura settimanale
export interface WeeklySchedule {
  teacher: Teacher;
  counters: WeeklyScheduleCounters;
  shifts: CalendarEventShift[];
}

// Definizione del tipo che rappresenta l'array di oggetti principali
export type WeeklySchedules = WeeklySchedule[];
//

@Component({
  selector: 'app-general-timetable',
  templateUrl: './general-timetable.component.html',
  styleUrl: './general-timetable.component.scss',
})
export class GeneralTimetableComponent implements OnInit {
  private _calendarService: CalendarService = inject(CalendarService);
  private _clientStorageService = inject(ClientStorageService);
  public gettingPdfData: boolean = false;
  public loading: boolean = false;
  public isError: boolean = false;
  public error?: ApiError;

  private timetableWeekSchedulesCompleteData: WeeklySchedules = [];
  public timetableWeekSchedules?: WeeklySchedules = [];
  public scholasticYear = ScholasticYear.getCurrent();

  public daySlots: string[] = ['1', '2', '3', '4', '5', '6', '7'];
  public weekDays: string[] = [
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
  ];

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this._calendarService.getGeneralTimetableData().subscribe({
      next: (response) => {
        this.timetableWeekSchedules = response.data as WeeklySchedules;
        this.timetableWeekSchedulesCompleteData = this.timetableWeekSchedules;
      },
      error: (err) => {
        console.error('Error =>', err);
      },
    });
  }

  getBlocks(
    index: number,
    weekday: string,
    slot: string
  ): CalendarEventShift[] {
    const blocks =
      this.timetableWeekSchedules
        ?.at(index)
        ?.shifts.filter(
          (shift) => shift.weekday === weekday && shift.slot === slot
        ) ?? [];
    return blocks.map((b) => new CalendarEventShift(b));
  }

  async getPdfData() {
    this.gettingPdfData = true;
    this._calendarService
      .getGeneralTimetablePDF()
      .subscribe({
        next: (res) => {
          let blob: Blob = res.body as Blob;
          let a = document.createElement('a');
          a.download =
            `${
              this.scholasticYear
            } - Quadro Orario Generale (${this._clientStorageService.getHeadquarterString()})` +
            '.pdf';
          a.href = window.URL.createObjectURL(blob);
          a.click();
        },
        error: (error) => {
          console.error('Error:', error);
        },
      })
      .add(() => (this.gettingPdfData = false));
  }

  txtFilter: string = '';
  onText(txt: string) {
    this.txtFilter = txt;
    this.applyFilters();
  }

  private allFilter: string = 'Tutti';
  daysFilter: string[] = [this.allFilter];
  onDayFilter(item: string) {
    if (item === this.allFilter) this.daysFilter = [this.allFilter];
    else {
      const allIndex = this.daysFilter.indexOf(this.allFilter);
      if (allIndex !== -1) this.daysFilter.splice(allIndex, 1);

      const itemIndex = this.daysFilter.indexOf(item);
      if (itemIndex !== -1) this.daysFilter.splice(itemIndex, 1);
      else this.daysFilter.push(item);

      if (this.daysFilter.length === 0) this.daysFilter = [this.allFilter];
    }
    this.applyFilters();
  }

  dayFilterCheck(filter: string): boolean {
    return this.daysFilter.includes(filter);
  }

  applyFilters(): void {
    this.loading = true;

    // Ripristina i dati originali facendo una deep copy
    this.timetableWeekSchedules = JSON.parse(
      JSON.stringify(this.timetableWeekSchedulesCompleteData)
    );

    // 1. FILTRO TESTO (Se presente)
    if (this.txtFilter.length !== 0) {
      this.timetableWeekSchedules = this.timetableWeekSchedules?.filter(
        (data) => {
          const tcFullName =
            `${data.teacher.cognome} ${data.teacher.nome} ${data.teacher.secondo_nome}`
              .replaceAll('undefined', '')
              .trim();
          const regex = new RegExp(this.txtFilter.split('').join('.*'), 'i'); // Ricerca fuzzy
          return regex.test(tcFullName);
        }
      );
    }

    // 2. FILTRO GIORNI
    this.timetableWeekSchedules = this.timetableWeekSchedules?.map((data) => {
      data.shifts = data.shifts.filter((shift) => {
        if (this.dayFilterCheck(this.allFilter)) return true;

        const dictionary: Record<string, string> = {
          mo: 'Lunedì',
          tu: 'Martedì',
          we: 'Mercoledì',
          th: 'Giovedì',
          fr: 'Venerdì',
          sa: 'Sabato',
          su: 'Domenica',
        };

        if (typeof shift.weekday === 'string') {
          const weekday = dictionary[shift.weekday];
          return weekday && this.daysFilter.includes(weekday);
        }

        return false;
      });

      return data;
    });

    // Rimuove eventuali oggetti senza turni
    this.timetableWeekSchedules = this.timetableWeekSchedules?.filter(
      (data) => data.shifts.length > 0
    );

    setTimeout(() => (this.loading = false), 500);
  }
}
