import { DeaClassesListingDialogComponent } from '../../dea-pack/dea-classes-listing-dialog/dea-classes-listing-dialog.component';
import { FormGroupService } from 'src/app/services/_app/form-group.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EventsService } from 'src/app/services/events.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Class } from 'src/app/models/class/class.model';
import { Event } from 'src/app/models/event/event.model';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import {
  Input,
  inject,
  OnInit,
  ViewChild,
  Component,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
  @Input() eventId?: string;

  @ViewChild('coverFileInput') coverFileInput?: ElementRef;
  public defaultCoverPlaceholder: string = API.transparent();
  private _eventsService: EventsService = inject(EventsService);
  private _location: Location = inject(Location);
  public loading: boolean = false;
  public error: any;

  public eventForm = new FormGroup({
    public: new FormControl(false),
    title: new FormControl(),
    description: new FormControl(),
    location: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    cover: new FormControl(),
    classes: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        entity: new FormControl({ value: '', disabled: true }),
        year: new FormControl({ value: '', disabled: true }),
        section: new FormControl({ value: '', disabled: true }),
        department: new FormControl({ value: '', disabled: true }),
      }),
    ]),
  });

  get eventFormClasses() {
    return this.eventForm.controls.classes as FormArray;
  }

  constructor(private _dialog: MatDialog) {}

  ngOnInit(): void {
    FormGroupService.resetFormArrays(this.eventForm, ['classes']);
    if (this.eventId != undefined) {
      this.getEventDetails((event?: Event) => {
        if (!!!event) return;
        this.eventForm.setValue({
          public: event?.public || false,
          title: event?.title || '',
          description: event?.description || '',
          location: event?.location || '',
          start: event?.start
            ? moment(event?.start).format('YYYY-MM-DD HH:mm:ss')
            : '',
          end: event?.end
            ? moment(event?.end).format('YYYY-MM-DD HH:mm:ss')
            : '',
          cover: event?.cover || '',
          classes: [],
        });
        // Imposto le classi
        let classesFormArray = <FormArray>this.eventForm.get('classes');
        for (let i = 0; i < (event.classes ?? []).length; i++) {
          let cl = event.classes?.at(i);
          if (cl) {
            classesFormArray.push(
              new FormGroup({
                _id: new FormControl({ value: cl.id, disabled: true }),
                entity: new FormControl({ value: cl.entity, disabled: true }),
                year: new FormControl({ value: cl.year, disabled: true }),
                section: new FormControl({ value: cl.section, disabled: true }),
                department: new FormControl({
                  value: cl.department,
                  disabled: true,
                }),
              })
            );
          }
        }
      });
    }
  }

  async getEventDetails(callback: (event?: Event) => void) {
    this.loading = true;
    this._eventsService
      .details(this.eventId || 'ID-ERROR')
      .subscribe({
        next: (data: any) => {
          const event = new Event(data?.data);
          callback(event);
        },
        error: (error: any) => {
          console.error('Get Event Details Error:', error);
          this.onDismiss();
        },
      })
      .add(() => (this.loading = false));
  }

  deleteItemInAt(forArray: string, index: number) {
    FormGroupService.deleteItemInAt(forArray, index, this.eventForm, [
      'classes',
    ]);
  }

  cleanJSON(obj: any): any {
    // Se non è un oggetto, o è null, restituisci l'oggetto così com'è
    if (typeof obj !== 'object' || obj === null) return obj;
    // Se è un array, applica la funzione cleanJSON a ciascun elemento dell'array
    if (Array.isArray(obj)) return obj.map((item) => this.cleanJSON(item));
    // Se è un oggetto, filtra le sue chiavi e ricorsivamente pulisci i valori
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => value !== null && value !== undefined) // Filtra le coppie chiave-valore con valori null o undefined
        .map(([key, value]) => [key, this.cleanJSON(value)]) // Ricorsivamente pulisci i valori
    );
  }

  onSave(): void {
    this.loading = true;
    this.error = undefined;
    const newEvent = new Event(this.eventForm.getRawValue());
    console.info('NEW EVENT => ', newEvent);
    console.info('TO JSON => ', newEvent.toJson());
    // const jsonEvent = newEvent.toJson();
    const jsonEvent = this.cleanJSON(newEvent.toJson());
    console.info('JSON EVENT => ', jsonEvent);
    if (this.eventId) {
      this._eventsService
        .patch(this.eventId || 'ID-ERROR', jsonEvent)
        .subscribe({
          next: (_: any) => this.onDismiss(),
          error: (error: any) => console.error('Patch Event Error:', error),
        })
        .add(() => (this.loading = false));
    } else {
      this._eventsService
        .create(jsonEvent)
        .subscribe({
          next: (_: any) => this.onDismiss(),
          error: (err: any) => {
            this.error =
              new ApiError(err)?.message ||
              'Si è verificato un errore, verifica i dati inseriti e riprova.';
            console.error('Create Event Error:', err);
          },
        })
        .add(() => (this.loading = false));
    }
  }

  handleFileSelect(event: any) {
    var file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.info('Cover Data: ', reader.result);
        this.eventForm.get('cover')?.setValue(reader.result?.toString());
      };
    }
  }

  uploadCover(): void {
    this.coverFileInput?.nativeElement.click();
  }

  deleteCover(): void {
    this.eventForm.get('cover')?.setValue(undefined);
  }

  openClassesDialog() {
    const dialogSize = '1000px';
    const dialogRef = this._dialog.open(DeaClassesListingDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((classDialogResult: Class) => {
      console.info('classDialogResult ===> ', classDialogResult);

      if (classDialogResult) {
        let formArray = <FormArray>this.eventForm.get('classes');
        if (formArray) {
          formArray.push(
            new FormGroup({
              entity: new FormControl({
                value: classDialogResult.id,
                disabled: true,
              }),
              year: new FormControl({
                value: classDialogResult.anno,
                disabled: true,
              }),
              section: new FormControl({
                value: classDialogResult.sezione,
                disabled: true,
              }),
              department: new FormControl({
                value: classDialogResult.dipartimento,
                disabled: true,
              }),
            })
          );
        }
      }
    });
  }

  onDismiss(): void {
    this._location.back();
  }
}
