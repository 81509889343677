<div class="hstack m-0 p-0">
  <button class="ms-auto" mat-stroked-button color="primary" (click)="showTimetableBlocksSlotsDialog()">
    Aggiungi Blocco Predefinito
  </button>
  <button class="ms-1" mat-stroked-button color="primary"
    (click)="showTimetableBlockDialog({newEventType: timetableEventType})">
    Aggiungi Blocco
  </button>
</div>
<div class="v-spacer"></div>
<div class="row m-0 p-0">
  <div class="col-auto m-0 p-0 position-relative" style="width: 24px;">
    <div class="w-100 d-flex justify-content-center align-items-center m-0 p-0" style="top: 0; left: 0; height: 40px;">
      <div class="fs-065r"></div>
    </div>
    @for(block of (timetableConfigs?.blocks ?? []); track block;) {
    @if(['1', '2', '3', '4', '5', '6', '7', 'INT'].includes(block.id)) {
    <div class="position-absolute w-100 d-flex justify-content-center no-select align-items-center m-0 p-0"
      title="{{block.id}} - Dalle {{block.from}} alle {{block.to}}"
      [ngStyle]="{ 'top': 'calc('+minHeightPxValue+'px * '+getLineTopShift(block)+' + 40px)', 'height': ''+getLineHeight(block)+'px' }">
      <div class="fs-065r">{{block.id}}</div>
    </div>
    }
    }
  </div>
  <div class="col m-0 p-0">
    <!-- GIORNI DELLA SETTIMANA -->
    <div class="row m-0 p-0 row-cols-7 border dea-radius rounded-bottom-0 no-select"
      style="height: 40px; border-bottom: none !important;">
      @for (day of weekDays; track day) {
      <div class="d-flex flex-column justify-content-center align-items-center" style="height: 40px;">
        <div class="opacity-65 fw-bold">{{ day.toUpperCase() }}</div>
      </div>
      }
    </div>
    <div class="position-relative m-0 p-0 border dea-radius rounded-top-0 overflow-hidden no-select"
      [ngStyle]="{'height': timetableConfigsHeight+'px'}">
      <!-- Moltiplico il valore dell'altezza del minuto per i minuti della griglia (se fosse 8:00/18:00 sarebbe H * 600min) -->
      <!-- Genero le linee della griglia del quadro orario -->
      @for(block of (timetableConfigs?.blocks ?? []); track block;) {
      <div class="w-100 position-absolute line-section no-select row m-0 p-0 row-cols-7" [ngStyle]="{
                  'top': 'calc('+minHeightPxValue+'px * '+getLineTopShift(block)+')',
                  'height': ''+getLineHeight(block)+'px',
                  }">
      </div>
      }
      <!-- Genero le linee della griglia del quadro orario -->
      @for(block of (timetableConfigs?.blocks ?? []); track block;) {
      @if(['1', '2', '3', '4', '5', '6', '7', 'INT'].includes(block.id)) {
      <div class="w-100 position-absolute line-section no-select row m-0 p-0 row-cols-7" [ngStyle]="{
            'top': 'calc('+minHeightPxValue+'px * '+getLineTopShift(block)+')',
            'height': ''+getLineHeight(block)+'px',
            }">
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(0, block)"></div>
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(1, block)"></div>
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(2, block)"></div>
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(3, block)"></div>
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(4, block)"></div>
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(5, block)"></div>
        <div class="w-100 h-100 cursor-pointer timetable-new-block-section dea-radius"
          (click)="showPreConfigTimetableBlockDialog(6, block)"></div>
      </div>
      }
      }
      <!-- Genero i blocchi degli eventi -->
      @for (event of timetableEvents; track event) {
      <div [title]="getEventHTMLTitle(event)"
        class="pointer position-absolute w-cols-7 d-flex flex-column justify-content-center align-items-center border-white dea-radius timetable-cell position-relative no-select p-1"
        style="border: 0.05px solid white !important;" [ngStyle]="{
              'height': 'calc('+minHeightPxValue+'px * '+event.end?.diff(event.start, 'minutes')+')',
              'top': 'calc('+minHeightPxValue+'px * '+getBlockTopShift(event)+')',
              'left': 'calc((100% / 7) * '+getBlockColumnShift(event)+')',
              'background-color': event.color ? event.color : '#f4f4f4',
              'color': event.color ? getTextColor(event.color):'#ffffff',
            }">
        <div class="text-center fs-085r truncate">
          @if(classId !== undefined && event.subject !== undefined) {
          <div>{{event.subject.nome}}</div>
          @if(event.teachers?.length != 0) {
          <div class="opacity-75">{{event.getTeachersFullName()}}</div>
          }
          }@else if(classId !== undefined && event.subject === undefined && event.teachers?.length != 0) {
          <div class="opacity-75">{{event.getTeachersFullName()}}</div>
          } @else if (teacherId !== undefined && event.classes !== undefined && event.classes.length > 0) {
          {{event.getClassesShortName()}}
          @if(event.subject?.nome) {
          <div class="opacity-75">{{event.subject?.nome}}</div>
          }
          } @else {
          {{event.title}}
          }
        </div>
        <div class="text-center fs-065r opacity-65">
          {{event.start?.tz(event.tz)?.format('HH:mm')}} - {{event.end?.tz(event.tz)?.format('HH:mm')}}
        </div>
        <!-- BUTTONS-LAYER -->
        <div
          class="hover-layer justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100 bg-dark bg-opacity-75 dea-radius">
          <button class="m-0 p-0" mat-icon-button color="primary" aria-label="Modifica" title="Modifica"
            (click)="showTimetableBlockDialog({eventId:event.id})">
            <i class="bi bi-pencil dea-icon-btn" style="opacity: 1;" title="Modifica Evento"></i>
          </button>
          <button class="m-0 p-0" mat-icon-button color="warn" aria-label="Elimina" title="Elimina"
            (click)="deleteTimetableBlock(event.id)">
            <i class="bi bi-trash3 dea-icon-btn text-danger" style="opacity: 1;" title="Elimina Evento"></i>
          </button>
        </div>
      </div>
      }
    </div>
  </div>
</div>
