import { AppJsonDecoder } from 'src/app/utils/app-json-decoder';
import { Headquarter } from '../headquarter';
import { User } from '../user/user.model';

interface LastYearEnrollment {
  anno_scolastico?: string;
  istituto?: string;
  tipo?: string;
  dettaglio?: string;
  esito?: string;
}

export class Enrollment {
  id?: string;
  sede?: Headquarter;
  shortHash?: string;
  archiviata?: boolean;
  anno?: string;
  dipartimento?: string;
  anno_scolastico?: string;
  ultimo_anno?: LastYearEnrollment;
  religione?: boolean;
  dea_sport?: boolean;
  regolamento?: boolean;
  prima_lingua?: string;
  seconda_lingua?: string;
  terza_lingua?: string;
  users?: User[];
  segreteria_amministrativa?: boolean;
  segreteria_didattica?: boolean;
  created_users?: boolean;
  known?: boolean;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.shortHash = json['short_hash'];
    this.sede = json['sede'];
    this.archiviata = AppJsonDecoder.optBool(json['archiviata']);
    this.anno = json['anno'];
    this.dipartimento = json['dipartimento'];
    this.anno_scolastico = json['anno_scolastico'];
    this.religione = AppJsonDecoder.optBool(json['religione']);
    this.dea_sport = AppJsonDecoder.optBool(json['dea_sport']);
    this.regolamento = AppJsonDecoder.optBool(json['regolamento']);
    this.prima_lingua = json['prima_lingua'];
    this.seconda_lingua = json['seconda_lingua'];
    this.terza_lingua = json['terza_lingua'];
    this.users = (json['users'] || []).map((json: any) => new User(json));

    this.ultimo_anno = json['ultimo_anno'];

    this.known = AppJsonDecoder.optBool(json['known']);
    this.created_users = AppJsonDecoder.optBool(json['created_users']);
    this.segreteria_amministrativa = AppJsonDecoder.optBool(
      json['segreteria_amministrativa']
    );
    this.segreteria_didattica = AppJsonDecoder.optBool(
      json['segreteria_didattica']
    );

    this.createdAt = AppJsonDecoder.optDate(json['createdAt']);
    this.updatedAt = AppJsonDecoder.optDate(json['updatedAt']);
  }

  getFirstUserFullName(): string {
    return this.users?.at(0)?.getFullName() || '';
  }
}
