import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import isDate from 'validator/es/lib/isDate';

@Component({
  selector: 'app-dea-date-stepper',
  templateUrl: './dea-date-stepper.component.html',
  styleUrls: ['./dea-date-stepper.component.scss'],
})
export class DeaDateStepperComponent implements OnInit {
  private oneDayInMilliseconds: number = 24 * 60 * 60 * 1000;

  @Output() date = new EventEmitter<Date>();
  @Input() todayRight: boolean = false;
  @Input() todayLeft: boolean = false;
  @Input() dateDate?: Date | string;

  ngOnInit(): void {
    if (
      !this.dateDate ||
      (typeof this.dateDate === 'string' && !isDate(this.dateDate))
    ) {
      this.dateDate = new Date();
    }
    if (typeof this.dateDate === 'string' && isDate(this.dateDate)) {
      this.dateDate = new Date(this.dateDate);
    }
  }

  setDate(date: Date) {
    this.dateDate = date;
    this.date.emit(this.dateDate);
  }

  addMilliseconds(milliseconds: number) {
    if (this.dateDate && typeof this.dateDate != 'string') {
      this.setDate(new Date(this.dateDate.getTime() + milliseconds));
    }
  }

  today() {
    this.setDate(new Date());
  }

  prev() {
    this.addMilliseconds(-this.oneDayInMilliseconds);
  }

  next() {
    this.addMilliseconds(this.oneDayInMilliseconds);
  }
}
