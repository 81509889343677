import { Self, Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[justInt]',
})
export class InputJustIntDirective {
  constructor(@Self() private input: NgControl) {}

  @HostListener('input', ['$event']) onInput($event: InputEvent) {
    const target = $event.target as HTMLInputElement;
    const value = target.value;
    const result = value.replace(/[^0-9]*/g, '');
    this.input?.control?.setValue(result);
  }
}
