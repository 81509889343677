import { EmailContact } from './email-contact.model';
import { PhoneContact } from './phone-contact.model';

export class Contacts {
  constructor(public email?: EmailContact[], public phone?: PhoneContact[]) {}

  public static fromJson(json: any): Contacts {
    const contacts = new Contacts([], []);
    contacts.email = json['email'].map((item: any) => {
      return new EmailContact(item.etichetta, item.indirizzo, item.enable);
    });
    contacts.phone = json['telefono'].map((item: any) => {
      return new PhoneContact(item.etichetta, item.numero, item.enable);
    });
    return contacts;
  }

  toJson(): any {
    return {
      email: this.email?.map((e) => e.toJson()),
      telefono: this.phone?.map((t) => t.toJson()),
    };
  }

  getFirstEmailAddress(): string | null {
    if (this.email == null || this.email?.length == 0) return null;
    return this.email[0].address ?? null;
  }

  getFirstEmailAddressEnabled(): boolean | undefined {
    if (this.email == null || this.email?.length == 0) return undefined;
    return this.email[0].enable ?? undefined;
  }

  getFirstPhoneNumber(): string | null {
    if (this.phone == null || this.phone?.length == 0) return null;
    return this.phone[0].phone ?? null;
  }

  getFirstPhoneNumberEnabled(): boolean | undefined {
    if (this.phone == null || this.phone?.length == 0) return undefined;
    return this.phone[0].enable ?? undefined;
  }
}
