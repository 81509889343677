<div class="d-flex w-100" style="height: 95px;">
  <div class="w-100 bg-w dea-radius dea-shadow p-0">
    <app-counter label="Studenti" value="{{statistics.students}}" link="studenti"></app-counter>
  </div>
  <div class="h-spacer"></div>
  <div class="w-100 bg-w dea-radius dea-shadow">
    <app-counter label="Genitori" value="{{statistics.parents}}" link="genitori"></app-counter>
  </div>
  <div class="h-spacer"></div>
  <div class="w-100 bg-w dea-radius dea-shadow">
    <app-counter label="Docenti" value="{{statistics.teachers}}" link="docenti"></app-counter>
  </div>
  <div class="h-spacer"></div>
  <div class="w-100 bg-w dea-radius dea-shadow">
    <app-counter label="Moduli" value="{{statistics.documents}}" link="moduli"></app-counter>
  </div>
  <div class="h-spacer"></div>
  <div class="w-100 bg-w dea-radius dea-shadow">
    <app-counter label="Giustifiche" value="{{statistics.justifications}}" link="ritardi"></app-counter>
  </div>
</div>