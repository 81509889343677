export class ApiError {
  code?: number;
  title?: string;
  message?: string;
  details?: string;

  constructor(json?: any) {
    if (!json) return;
    while (json['error']) json = json['error'];
    this.code = json['code'];
    this.title = json['title'];
    this.message = json['message'];
    this.details = json['details'];
  }
}
