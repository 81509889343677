import { DeaSubjectsListingDialogComponentOutput } from 'src/app/constants/interfaces';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { SubjectService } from 'src/app/services/subject.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'src/app/models/subject.model';

@Component({
  selector: 'app-dea-subjects-listing-dialog',
  templateUrl: './dea-subjects-listing-dialog.component.html',
  styleUrls: ['./dea-subjects-listing-dialog.component.scss'],
})
export class DeaSubjectsListingDialogComponent implements OnInit {
  @Input() limit: number = 10;
  @Input() page: number = 0;

  public pagination?: ApiPagination;
  public subjects: Subject[] = [];
  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;
  private text?: string;

  constructor(
    private _subjectService: SubjectService,
    public dialogRef: MatDialogRef<DeaSubjectsListingDialogComponent>
  ) {}

  onSelected(id?: string): void {
    const selectedSubject: Subject[] = id
      ? this.subjects.filter((s) => s.id === id)
      : [];
    const dialogOutput: DeaSubjectsListingDialogComponentOutput = {
      id: selectedSubject[0].id || '',
      nome: selectedSubject[0].nome,
      dea_id: selectedSubject[0].dea_id,
    };
    this.dialogRef.close(dialogOutput);
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._subjectService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
      })
      .subscribe({
        next: (data) => {
          this.subjects = data?.data?.map((json: any) => new Subject(json));
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Subjects Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
