<div class="w-100">
  <div class="hstack">
    <app-class-years-listing (scholasticYearEmitter)="onScholasticYear($event)"
      [slected]="thisYear"></app-class-years-listing>
    <div class="ms-auto ps-3">
      <button mat-flat-button color="primary" type="button" class="fw-bold px-3 py-1 rounded-pill"
        routerLink="/nuova-classe">
        Aggiungi Classe
      </button>
    </div>
  </div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="v-spacer"></div>
      <div *ngFor="let department of classesGroupedBy | keyvalue;">
        <div *ngFor="let section of $any(department).value | keyvalue;">
          <div class="d-flex class-row-size justify-content-start">
            <app-class-cell *ngIf="$any(section).value[0]?.anno != 1 && $any(section).value[0]?.anno > 1" class="w-100"
              [visibility]="false">
            </app-class-cell>
            <app-class-cell *ngIf="$any(section).value[0]?.anno != 2 && $any(section).value[0]?.anno > 2" class="w-100"
              [visibility]="false">
            </app-class-cell>
            <app-class-cell *ngIf="$any(section).value[0]?.anno != 3 && $any(section).value[0]?.anno > 3" class="w-100"
              [visibility]="false">
            </app-class-cell>
            <app-class-cell *ngIf="$any(section).value[0]?.anno != 4 && $any(section).value[0]?.anno > 4" class="w-100"
              [visibility]="false">
            </app-class-cell>
            <app-class-cell *ngIf="$any(section).value[0]?.anno != 5 && $any(section).value[0]?.anno > 5" class="w-100"
              [visibility]="false">
            </app-class-cell>

            <app-class-cell *ngFor="let class of $any(section).value;" class="w-100" [classe]="class"></app-class-cell>

            <app-class-cell
              *ngIf="$any(section).value[0]?.anno != 1 && $any(section).value[$any(section).value.length - 1]?.anno < 1"
              class="w-100" [visibility]="false">
            </app-class-cell>
            <app-class-cell
              *ngIf="$any(section).value[1]?.anno != 2 && $any(section).value[$any(section).value.length - 1]?.anno < 2"
              class="w-100" [visibility]="false">
            </app-class-cell>
            <app-class-cell
              *ngIf="$any(section).value[2]?.anno != 3 && $any(section).value[$any(section).value.length - 1]?.anno < 3"
              class="w-100" [visibility]="false">
            </app-class-cell>
            <app-class-cell
              *ngIf="$any(section).value[3]?.anno != 4 && $any(section).value[$any(section).value.length - 1]?.anno < 4"
              class="w-100" [visibility]="false">
            </app-class-cell>
            <app-class-cell
              *ngIf="$any(section).value[4]?.anno != 5 && $any(section).value[$any(section).value.length - 1]?.anno < 5"
              class="w-100" [visibility]="false">
            </app-class-cell>
          </div>
          <div class="v-spacer"></div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>