import { DeviceListingParameters } from '../constants/interfaces';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private http: HttpClient) {}

  getListing(p: DeviceListingParameters) {
    return this.http.get<ApiResponse<any>>(API.deviceListing(p), {
      withCredentials: true,
    });
  }

  postJSON(jsonData: any) {
    return this.http.post<ApiResponse<any>>(API.devicesJSON(), jsonData, {
      withCredentials: true,
    });
  }
}
