import { FormArray, FormGroup } from '@angular/forms';

export class FormGroupService {
  static resetFormArrays(form: FormGroup, keys: string[]): void {
    for (let kI = 0; kI < keys.length; kI++) {
      let key = keys[kI];
      let formArray = <FormArray>form.get(key);
      const length = formArray.length;
      for (let i = length - 1; i >= 0; i--) formArray.removeAt(i);
    }
  }

  static deleteItemInAt(
    forArray: string,
    index: number,
    form: FormGroup,
    keys?: string[]
  ): void {
    if ((keys ?? [forArray]).includes(forArray)) {
      let formArray = <FormArray>form.get(forArray);
      if (formArray) formArray.removeAt(index);
    } else console.error('Attenzione: chiave non valida!');
  }
}
