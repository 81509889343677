import { DeaSingleBtnDialogComponent } from '../dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { DeaJsonPreviewComponent } from '../dea-pack/dea-json-preview.component';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { DeviceService } from 'src/app/services/device.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Device } from 'src/app/models/tech/device.model';
import { MatDialog } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';
import {
  OnInit,
  inject,
  ViewChild,
  Component,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  @ViewChild('fileInput') fileInput?: ElementRef;

  private _deviceService: DeviceService = inject(DeviceService);
  private _dialog: MatDialog = inject(MatDialog);
  private _papa: Papa = inject(Papa);
  private _txt?: string = undefined;
  public error?: ApiError = undefined;
  public pagination?: ApiPagination;
  public loading: boolean = true;
  public devices: Device[] = [];

  ngOnInit(): void {
    this.getData();
  }

  public onText(event: string): void {
    this._txt = event?.toString().trim() || undefined;
    this.getData();
  }

  public onPage(event: number): void {
    if (this.pagination) {
      this.pagination.page = event;
      this.getData();
    }
  }

  private getData(): void {
    this.loading = true;
    this.error = undefined;
    this._deviceService
      .getListing({
        page: this.pagination?.page || 0,
        limit: this.pagination?.limit || 25,
        txt: this._txt,
      })
      .subscribe({
        next: (data: any) => {
          this.pagination = new ApiPagination(data.pagination);
          this.devices = data?.data?.map((json: any) => new Device(json));
        },
        error: (error: ApiError) => {
          this.error = error;
          console.error('Device Listing Error:', error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  // CSV
  public openFileInput(): void {
    this.fileInput?.nativeElement.click();
  }

  async onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const json = await this.parseCSV(file);
      const dialogRef = this._dialog.open(DeaJsonPreviewComponent, {
        maxWidth: '1000px',
        minWidth: '1000px',
        data: json,
        autoFocus: false,
        restoreFocus: false,
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed === true) this.postJsonData(json);
      });
    }
  }

  private postJsonData(jsonData: any): void {
    this.loading = true;
    this.error = undefined;
    this._deviceService.postJSON(jsonData).subscribe({
      next: (data: any) => {
        this._dialog.open(DeaSingleBtnDialogComponent, {
          maxWidth: '450px',
          minWidth: '450px',
          data: {
            title: 'Informazioni',
            message: `Sono stati aggiunti ${data.data.added} dispositivi per un totale di ${data.data.total}.`,
          },
          autoFocus: false,
          restoreFocus: false,
        });
        setTimeout(() => this.getData(), 500);
      },
      error: (error: ApiError) => {
        this.error = error;
        console.error('Post JSON data error:', this.error.message);
      },
    });
  }

  private parseCSV(file: any) {
    return new Promise(async (resolve, reject) => {
      try {
        this._papa.parse(file, {
          header: true,
          complete: (data) => resolve(data.data),
        });
      } catch (error) {
        console.error('ParseCSV Error:', error);
        return reject(error);
      }
    });
  }
}
