import { DeaSingleBtnDialogComponent } from 'src/app/components/dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { DeaPdfDialogComponent } from 'src/app/components/dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { ApiError } from 'src/app/models/api/api-error.model';
import { MatDialog } from '@angular/material/dialog';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseComponentService {
  private _http: HttpClient = inject(HttpClient);
  private _dialog: MatDialog = inject(MatDialog);
  private _location: Location = inject(Location);

  get dialog(): MatDialog {
    return this._dialog;
  }

  backOnePage(): void {
    this._location.back();
  }

  alert(title: string, message: string, btnTxt?: string): Observable<void> {
    const dialogRef = this._dialog.open(DeaSingleBtnDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: { title: title, message: message, button: btnTxt },
      autoFocus: false,
      restoreFocus: false,
    });
    return dialogRef.afterClosed();
  }

  showErrorDialog(arg: {
    title?: string;
    message?: string;
    btnTxt?: string;
    error?: ApiError;
  }): Observable<void> {
    const dialogRef = this._dialog.open(DeaSingleBtnDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        title: arg.title,
        message: arg.message,
        button: arg.btnTxt,
        error: arg.error,
      },
      autoFocus: false,
      restoreFocus: false,
    });
    return dialogRef.afterClosed();
  }

  confirmDeletion({
    message,
    details,
    destructive,
    falseBtn,
    trueBtn,
    size,
  }: {
    message: string;
    details?: string;
    destructive?: boolean;
    falseBtn?: string;
    trueBtn?: string;
    size?: string | number;
  }): Observable<boolean> {
    size ??= '450px';
    destructive ??= true;
    trueBtn ??= 'Sì, elimina';
    falseBtn ??= 'No, annulla';
    const dialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: size,
      minWidth: size,
      data: {
        message,
        details,
        trueBtn,
        falseBtn,
        destructive,
      },
      autoFocus: false,
      restoreFocus: false,
    });
    return dialogRef.afterClosed();
  }

  showPdfFromURL(url: string): void {
    this._dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: { url },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  async downloadFile({
    url,
    defaultFileName,
    withCredentials,
  }: {
    url: string;
    defaultFileName?: string;
    withCredentials?: boolean;
  }) {
    const x = moment().format('YYYY-MM-DD') + ' - ' + crypto.randomUUID();
    withCredentials ??= true;
    defaultFileName ??= x;
    this._http
      .get(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
        withCredentials,
      })
      .subscribe({
        next: (response) => {
          const blob: Blob = response.body as Blob;
          const originalName = response.headers.get('originalname');
          const fileName: string = originalName ?? defaultFileName!;
          let a: HTMLAnchorElement = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = fileName;
          a.click();
        },
        error: (error: any) => {
          console.error('*** DOWNLOAD-ERROR:', error);
          this.showErrorDialog({
            error: new ApiError({
              title: 'Attenzione',
              message:
                'Non è stato possibile eseguire il download della risorsa richiesta. Prova a ricaricare la pagina o riprova più tardi.',
              details:
                "Se il problema persiste contatta l'amministratore, grazie!",
            }),
          });
        },
      });
  }
}
