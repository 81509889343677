<div class="my-auto data-box">
  <div class="hstack">
    <app-dea-label [label]="label"></app-dea-label>
  </div>
  <ng-template *ngIf="phoneNumber.length != 0; then dataTrue else dataFalse"></ng-template>
  <ng-template #dataTrue>
    <div title="Click: copia {{phoneNumber | phoneNumber}} • Doppio click: contatta su WhatsApp"
      (click)="copyInClipboard()" (dblclick)="onDoubleClick()" class="hstack gap-1 pointer">
      <app-dea-status-icon [enabledTitle]="'Abilitato per le comunicazioni'"
        [disabledTitle]="'Disabilitato per le comunicazioni'" [enable]="enable">
      </app-dea-status-icon>
      {{phoneNumber | phoneNumber}}
    </div>
  </ng-template>
  <ng-template #dataFalse>
    <div class="opacity-50 fs-075r">No Telefono</div>
  </ng-template>
</div>