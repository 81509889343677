<app-dea-dialog-wrapper>
  <div class="d-flex justify-content-center align-items-center"
    style="position: fixed; z-index: 5; height: 545px; width: 1000px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
  <!-- <object class="dea-radius" style="z-index: 10;" [data]="pdfURL | safeUrl" type="application/pdf" width="100%"
    height="545">
  </object> -->
  <pdf-viewer class="dea-radius" [src]="pdfSCR" [render-text]="true" [original-size]="true" [show-all]="true"
    style="width: 100%; height: 545px; z-index: 10;" (error)="onError($event)"></pdf-viewer>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button color="secondary" (click)="onDismiss()">Chiudi</button>
  </div>
</app-dea-dialog-wrapper>