import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-label',
  templateUrl: './dea-label.component.html',
  styleUrls: ['./dea-label.component.scss'],
})
export class DeaLabelComponent {
  @Input() label?: string;
}
