<div class="d-flex justify-content-between w-100">
  <div class="d-flex gap-2">
    <button *ngIf="todayLeft" class="pointer" (click)="today()" title="Oggi" type="button" mat-stroked-button>
      Oggi
    </button>
    <button class="pointer" (click)="prev()" title="Giorno precedente" type="button" mat-stroked-button>
      <i class="bi bi-chevron-left"></i>
    </button>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center gap-0"
    title="{{dateDate | toItDateDdMmmmYyyy}}">
    <div>{{dateDate | toItDateDdMmmmYyyy}}</div>
    <div *ngIf="!todayLeft && !todayRight" class="m-0 p-0 rounded-pill opacity-50 pointer" (click)="today()"
      style="width: 32px; height: 4px; background-color: black;" title="Oggi">
    </div>
  </div>
  <div class="d-flex gap-2">
    <button class="pointer" (click)="next()" title="Giorno seguente" type="button" mat-stroked-button>
      <i class="bi bi-chevron-right"></i>
    </button>
    <button *ngIf="todayRight" class="pointer" (click)="today()" title="Oggi" type="button" mat-stroked-button>
      Oggi
    </button>
  </div>
</div>