export class GeneralStatistics {
  students?: number;
  parents?: number;
  teachers?: number;
  documents?: number;
  justifications?: number;

  constructor(json?: any) {
    if (!json) return;
    this.students = json['students'];
    this.parents = json['parents'];
    this.teachers = json['teachers'];
    this.documents = json['documents'];
    this.justifications = json['justifications'];
  }
}
