<app-dea-dialog-wrapper>
  <div class="fw-semibold text-center fs-4 mb-2">
    <img mat-card-image class="rounded-circle my-auto" width="80" height="80" loading="lazy" src="{{logoUrl}}"
      (error)="onThumbnailError($event)" crossorigin="anonymous">
  </div>
  <div class="fw-semibold text-center fs-4 mb-2">{{data.title}}</div>
  <div class="text-center">{{data.message}}</div>
  <div class="text-center fw-lighter text-danger">{{data.details}}</div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="onDismiss()">{{data.button}}</button>
  </div>
</app-dea-dialog-wrapper>