import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user/user.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-master-data',
  templateUrl: './user-master-data.component.html',
  styleUrls: ['./user-master-data.component.scss'],
})
export class UserMasterDataComponent implements OnInit {
  @Output() userDataEmitter = new EventEmitter<User>();
  public loading: boolean = false;
  public isError: boolean = false;
  public error?: ApiError;
  public userId?: string;
  public user?: User;

  constructor(
    private _route: ActivatedRoute,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.userId = params['id'];
      this.getData();
    });
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getDetail(this.userId)
      .subscribe({
        next: (data) => {
          this.user = new User(data?.data);
          this.userDataEmitter.emit(this.user);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Detail Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
