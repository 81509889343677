import { GuestAttendanceService } from 'src/app/services/guest-attendance.service';
import { GuestAttendance } from 'src/app/models/register/guest/guest-attendance.model';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-guests-attendance-lisitng',
  templateUrl: './guests-attendance-lisitng.component.html',
  styleUrls: ['./guests-attendance-lisitng.component.scss'],
})
export class GuestsAttendanceLisitngComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 20;

  public guestsAttendances: GuestAttendance[] = [];
  public pagination?: ApiPagination;
  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;
  private text?: string;

  constructor(private _guestAttendanceService: GuestAttendanceService) {}

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._guestAttendanceService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
      })
      .subscribe({
        next: (data) => {
          this.pagination = new ApiPagination(data?.pagination);
          this.guestsAttendances = data?.data?.map(
            (json: any) => new GuestAttendance(json)
          );
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
