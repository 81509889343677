import { TimetableReplacementOptionsDialogComponent } from './timetable-replacement-options-dialog/timetable-replacement-options-dialog.component';
import { DeaUserListingDialogComponent } from 'src/app/components/dea-pack/dea-user-listing-dialog/dea-user-listing-dialog.component';
import { BaseComponentService } from 'src/app/services/_app/base-component.service';
import { DeaUserListingDialogComponentOutput } from 'src/app/constants/interfaces';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { CalendarEvent } from 'src/app/models/calendar/calendar-event.model';
import { MainLoaderService } from 'src/app/services/main-loader.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, inject, OnInit } from '@angular/core';
import { Class } from 'src/app/models/class/class.model';
import { User } from 'src/app/models/user/user.model';
import { ActivatedRoute } from '@angular/router';
import { AppTools } from 'src/app/utils/tools';
import * as moment from 'moment';
import {
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';
import { TimetableReplacementsSummary } from 'src/app/models/calendar/replacement/timetable-replacements-summary';

@Component({
  selector: 'app-timetable-replacement-form',
  templateUrl: './timetable-replacement-form.component.html',
  styleUrl: './timetable-replacement-form.component.scss',
})
export class TimetableReplacementFormComponent implements OnInit {
  private _mainLoaderService: MainLoaderService = inject(MainLoaderService);
  private _calendarService: CalendarService = inject(CalendarService);
  private _baseComponentService = inject(BaseComponentService);
  private _clientSessionService = inject(ClientStorageService);
  private _route: ActivatedRoute = inject(ActivatedRoute);
  public replacementsResumeId?: string;
  public isPosting: boolean = false;
  private error?: ApiError;

  public slotIDs: string[] = ['1', '2', '3', 'INT', '4', '5', '6', '7'];

  get formTitle() {
    return this.replacementsResumeId
      ? 'Modifica Scheda Sostituzioni'
      : 'Crea Scheda Sostituzioni';
  }

  public resumeTeachers: User[] = [];
  public timetableReplacementsResumeForm: FormGroup = new FormGroup({
    headquarter: new FormControl(
      this._clientSessionService.getHeadquarterString()
    ),
    date: new FormControl(moment().format('YYYY-MM-DD')),
    teachers: new FormArray([
      new FormGroup({
        id: new FormControl(),
        cognome: new FormControl(),
        nome: new FormControl(),
        secondo_nome: new FormControl(),
      }),
    ]),
    blocks: new FormArray([
      new FormGroup({
        block: new FormControl(),
        start: new FormControl(),
        end: new FormControl(),
        tz: new FormControl(),
        classes: new FormArray([
          new FormGroup({
            id: new FormControl(),
            anno: new FormControl(),
            sezione: new FormControl(),
            dipartimento: new FormControl(),
          }),
        ]),
        teacher: new FormGroup({
          id: new FormControl(),
          cognome: new FormControl(),
          nome: new FormControl(),
          secondo_nome: new FormControl(),
        }),
        modification: new FormControl(), // substitute | person | entry | exit
        substitute: new FormGroup({
          id: new FormControl(),
          cognome: new FormControl(),
          nome: new FormControl(),
          secondo_nome: new FormControl(),
        }),
        person: new FormControl(),
        time: new FormControl(),
        note: new FormControl(),
        slot: new FormGroup({
          original: new FormControl(),
          substitution: new FormControl(),
        }),
      }),
    ]),
  });

  get getFormMomentDate(): moment.Moment {
    return moment(this.timetableReplacementsResumeForm.get('date')?.value);
  }

  get getResumeTeachers(): FormArray {
    return this.timetableReplacementsResumeForm.get('teachers') as FormArray;
  }

  get getFormBlocks(): FormArray {
    return this.timetableReplacementsResumeForm.get('blocks') as FormArray;
  }

  private _getResumeTeachersIds = (): string[] => {
    const teachersFormArray: FormArray =
      this.timetableReplacementsResumeForm.get('teachers') as FormArray;
    return Array.from(
      new Set(
        teachersFormArray.value
          .map((teacher: any) => teacher.id)
          .filter((id: any) => id !== undefined && id !== null && id !== '')
      )
    );
  };

  private _getFormBlocksTeachersIds = (): string[] => {
    return Array.from(
      new Set(
        this.getFormBlocks.controls
          .map((block: any) => block.teacher?.id)
          .filter((id: any) => id !== undefined && id !== null && id !== '')
      )
    );
  };

  private _getFormBlocksSubstitutesIds = (blockId: string): string[] => {
    return this.getFormBlocks.controls
      .filter((block: any) => block.block === blockId)
      .map((block: any) => block.substitute?.id)
      .filter((id: any) => id !== undefined && id !== null && id !== '');
  };

  private _getFormGroupFromEvent = (event: CalendarEvent): FormGroup => {
    const slotId = this._calendarService.getSlotIDFrom(event);
    const newFormGroup: FormGroup = new FormGroup({
      block: new FormControl(slotId),
      start: new FormControl(event.start),
      end: new FormControl(event.end),
      tz: new FormControl(event.tz),
      classes: new FormArray(
        (event.classes ?? []).map((cl) => {
          return new FormGroup({
            id: new FormControl(cl.id),
            anno: new FormControl(cl.anno),
            sezione: new FormControl(cl.sezione),
            dipartimento: new FormControl(cl.dipartimento),
          });
        })
      ),
      // FIXME: Verificare cosa succede quando ho più docenti per la stessa ora!
      teacher: new FormGroup({
        id: new FormControl(event.teachers?.at(0)?.id),
        cognome: new FormControl(event.teachers?.at(0)?.cognome || ''),
        nome: new FormControl(event.teachers?.at(0)?.nome || ''),
        secondo_nome: new FormControl(
          event.teachers?.at(0)?.secondo_nome || ''
        ),
      }),
      modification: new FormControl('substitute'),
      substitute: new FormGroup({
        id: new FormControl(),
        cognome: new FormControl(),
        nome: new FormControl(),
        secondo_nome: new FormControl(),
      }),
      person: new FormControl(),
      time: new FormControl(),
      note: new FormControl(),
      slot: new FormGroup({
        original: new FormControl(event.id),
        substitution: new FormControl(),
      }),
    });
    newFormGroup.get('modification')?.valueChanges.subscribe((value) => {
      newFormGroup.get('substitute.id')?.patchValue(null);
      newFormGroup.get('substitute.cognome')?.patchValue(null);
      newFormGroup.get('substitute.nome')?.patchValue(null);
      newFormGroup.get('substitute.secondo_nome')?.patchValue(null);
      newFormGroup.get('person')?.patchValue(null);
      newFormGroup.get('time')?.patchValue(null);
      if (value === 'entry' && slotId) {
        newFormGroup.get('time')?.patchValue(this.getSlotTime(slotId, 'END'));
      } else if (value === 'exit' && slotId) {
        newFormGroup.get('time')?.patchValue(this.getSlotTime(slotId, 'START'));
      }
    });
    return newFormGroup;
  };

  private _getBlocksFromTeacherId = (teacherId: string) => {
    return new Promise<CalendarEvent[]>((resolve, reject) => {
      if (!moment.isMoment(this.getFormMomentDate)) {
        reject(new Error('FormDate is not valid value!'));
      }
      this._calendarService
        .timetable({
          teacherId: teacherId,
          classId: undefined,
          from: this.getFormMomentDate,
          to: this.getFormMomentDate,
        })
        .subscribe({
          next: (response) => {
            return resolve(
              (
                response.data.map(
                  (json: any) => new CalendarEvent(json)
                ) as CalendarEvent[]
              ).filter(
                (event: CalendarEvent) => event.timetableBlock === 'LEZIONE'
              )
            );
          },
          error: (error) => {
            return reject(error);
          },
        });
    });
  };

  private _setNewEventsInForm = (events: CalendarEvent[]) => {
    const blocksFormArray = this.timetableReplacementsResumeForm.get(
      'blocks'
    ) as FormArray;
    const hasEvent = (eventId: string) => {
      for (let i = 0; i < blocksFormArray.controls.length; i++) {
        const control = blocksFormArray.controls.at(i);
        if (control?.get('slot.original')?.value === eventId) {
          return true;
        }
      }
      return false;
    };
    for (let i = 0; i < events.length; i++) {
      if (events.at(i) != undefined) {
        const event: CalendarEvent = events[i];
        const check: boolean = hasEvent(event.id!);
        if (check) continue;
        this.getFormBlocks.push(this._getFormGroupFromEvent(event));
      }
    }
  };

  private _sortBlocksArray = () => {
    var formArray = this.getFormBlocks.value;
    formArray.sort((a: any, b: any) => {
      const teacherA = a.teacher;
      const teacherB = b.teacher;
      if (!teacherA || !teacherB) return 0;
      const cognomeA = teacherA.cognome.toLowerCase();
      const cognomeB = teacherB.cognome.toLowerCase();
      const nomeA = teacherA.nome.toLowerCase();
      const nomeB = teacherB.nome.toLowerCase();
      if (cognomeA !== cognomeB) return cognomeA.localeCompare(cognomeB);
      return nomeA.localeCompare(nomeB);
    });
    this.timetableReplacementsResumeForm.get('blocks')?.patchValue(formArray);
  };

  getSlotTime(slotId: string, type: 'START' | 'END'): string {
    const block: { from: string; to: string } | undefined =
      this._calendarService.timetableGridConfigs?.blocks
        .filter((block) => block.id == slotId)
        .at(0);
    if (block == undefined) {
      console.error('SLOT-ID NOT VALID!');
      return '';
    }
    return type === 'START' ? block.from : block.to;
  }

  ngOnInit(): void {
    this.getFormBlocks.clear();
    this.getResumeTeachers.clear();
    this.timetableReplacementsResumeForm.get('headquarter')?.disable();
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.replacementsResumeId = params['id'];
        this.getDetailsData();
      } else {
        this.timetableReplacementsResumeForm
          .get('headquarter')
          ?.setValue(this._clientSessionService.getHeadquarter().backend);
        this.timetableReplacementsResumeForm
          .get('date')
          ?.setValue(new Date().toISOString().slice(0, 10) ?? undefined);
      }
    });
    // TODO: Rimuovere questo listener ================================================
    this.timetableReplacementsResumeForm.valueChanges.subscribe((value) => {
      console.info('timetableReplacementsResumeForm.valueChanges =>', value);
    });
    // ================================================================================
    this.timetableReplacementsResumeForm
      .get('teachers')
      ?.valueChanges.subscribe((value) => {
        this.resumeTeachers = value.map((json: any) => new User(json));
      });
    this.timetableReplacementsResumeForm
      .get('date')
      ?.valueChanges.subscribe(async (value) => {
        console.info('*** DATE IS CHANGED:', value);
        await this.upgradeBlocksListing();
      });
  }

  // Teachers Listing funcs
  openUsersDialog() {
    const dialogSize = '1000px';
    const dialogRef = this._baseComponentService.dialog.open(
      DeaUserListingDialogComponent,
      {
        maxWidth: dialogSize,
        minWidth: dialogSize,
        data: {
          type: 'Teacher',
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );
    dialogRef
      .afterClosed()
      .subscribe((userDialogResult: DeaUserListingDialogComponentOutput) => {
        if (userDialogResult) {
          if (!this._getResumeTeachersIds().includes(userDialogResult.id)) {
            this.addTeacherToFormGroup(
              new User({
                id: userDialogResult.id,
                nome: userDialogResult.nome,
                cognome: userDialogResult.cognome,
                secondo_nome: userDialogResult.secondo_nome,
              })
            );
          } else {
            console.error('Utente già presente nella lista');
          }
        }
      });
  }

  addTeacherToFormGroup(user: User, notify: boolean = true): void {
    (this.timetableReplacementsResumeForm.get('teachers') as FormArray).push(
      new FormGroup({
        id: new FormControl(user.id),
        nome: new FormControl(user.nome ?? ''),
        cognome: new FormControl(user.cognome ?? ''),
        secondo_nome: new FormControl(user.secondo_nome ?? ''),
      })
    );
    if (notify) this.updateBlocksListing('INSERTED', user.id!);
  }

  newFormTeacherListingRemoveById(id: string) {
    const index = this._getResumeTeachersIds().indexOf(id);
    if (index > -1) {
      (
        this.timetableReplacementsResumeForm.get('teachers') as FormArray
      ).removeAt(index);
      this.updateBlocksListing('REMOVED', id);
    } else {
      console.error('ID not found in the array');
    }
  }

  async updateBlocksListing(action: 'INSERTED' | 'REMOVED', teacherId: string) {
    console.info('*** UPDATE-BLOCKS-LISTING:', action, '***');
    this._mainLoaderService.show = true;
    switch (action) {
      case 'INSERTED':
        const timetableBlocks = await this._getBlocksFromTeacherId(teacherId);
        this._setNewEventsInForm(timetableBlocks);
        break;
      case 'REMOVED':
        for (let i = this.getFormBlocks.length - 1; i >= 0; i--) {
          if (this.getFormBlocks.at(i).get('teacher.id')?.value === teacherId) {
            this.getFormBlocks.removeAt(i);
          }
        }
        break;
      default:
        return console.error(
          `updateBlocksListing action has a not valid value! (${action})`
        );
    }
    this._sortBlocksArray();
    this._mainLoaderService.show = false;
  }

  async upgradeBlocksListing() {
    console.info('*** UPGRADE-BLOCKS-LISTING ***');
    this._mainLoaderService.show = true;
    const teachersIds: string[] = this._getResumeTeachersIds();
    this.getFormBlocks.clear();
    for (let i = 0; i < teachersIds.length; i++) {
      const timetableBlocks = await this._getBlocksFromTeacherId(
        teachersIds[i]
      );
      this._setNewEventsInForm(timetableBlocks);
    }
    this._sortBlocksArray();
    this._mainLoaderService.show = false;
  }

  getUserFullnameFromAbstractControl(
    abstractControl: AbstractControl<any, any> | null
  ): string {
    const value = abstractControl?.value;
    if (value?.id && value?.nome && value?.cognome) {
      return new User(value).getFullName();
    }
    return '';
  }

  getClassesShortNameFromAbstractControl(
    abstractControl: AbstractControl<any, any> | null
  ): string {
    return (abstractControl?.value ?? [])
      .map((json: any) => new Class(json).getShortName())
      .join(', ');
  }

  getBlocksFormGroupWithSlot(slot: string): FormGroup[] {
    return this.getFormBlocks.controls?.filter(
      (block) => block.get('block')?.value === slot
    ) as FormGroup[];
  }

  showOptionsDialogForBlock(block: FormGroup): void {
    const blockData = block.value;
    const dialogSize = '1000px';
    const dialogRef = this._baseComponentService.dialog.open(
      TimetableReplacementOptionsDialogComponent,
      {
        maxWidth: dialogSize,
        minWidth: dialogSize,
        data: {
          start: blockData.start,
          end: blockData.end,
          tz: blockData.tz,
          eventId: blockData.slot.original,
          exclude: this._getFormBlocksTeachersIds().concat(
            this._getFormBlocksSubstitutesIds(block.get('block')?.value)
          ),
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );
    dialogRef
      .afterClosed()
      .subscribe((userDialogResult: DeaUserListingDialogComponentOutput) => {
        if (userDialogResult) {
          block.setControl(
            'substitute',
            new FormGroup({
              id: new FormControl(userDialogResult.id),
              cognome: new FormControl(userDialogResult.cognome),
              nome: new FormControl(userDialogResult.nome),
              secondo_nome: new FormControl(userDialogResult.secondo_nome),
            })
          );
        }
      });
  }

  // SAVE DATA
  async postFormData() {
    let json: any = this.timetableReplacementsResumeForm.getRawValue();
    json.date = moment.tz(json.date, json.tz).toISOString();
    json = AppTools.removeNullAndReplaceWithUndefined(json);
    if (this.replacementsResumeId) {
      this._calendarService
        .patchReplacementsSummary(
          this.replacementsResumeId,
          this.timetableReplacementsResumeForm.value
        )
        .subscribe({
          next: (_) => this._baseComponentService.backOnePage(),
          error: (error) => {
            this.error = new ApiError(error);
            console.error('PatchReplacementsSummary Error:', error);
            this._baseComponentService.showErrorDialog({
              error: this.error,
            });
          },
        });
    } else {
      this._calendarService
        .postReplacementsSummary(this.timetableReplacementsResumeForm.value)
        .subscribe({
          next: (_) => this._baseComponentService.backOnePage(),
          error: (error) => {
            this.error = new ApiError(error);
            console.error('PostReplacementsSummary Error:', error);
            this._baseComponentService.showErrorDialog({ error: this.error });
          },
        });
    }
  }

  // GET DATA
  async getDetailsData() {
    this._calendarService
      .getReplacementsSummary(this.replacementsResumeId)
      .subscribe({
        next: (response) => {
          const timetableReplacementsResume = new TimetableReplacementsSummary(
            response.data
          );
          this.timetableReplacementsResumeForm
            .get('headquarter')
            ?.setValue(timetableReplacementsResume.headquarter?.backend);
          this.timetableReplacementsResumeForm
            .get('date')
            ?.setValue(
              moment(timetableReplacementsResume.date?.toISOString()).format(
                'YYYY-MM-DD'
              )
            );
          timetableReplacementsResume.teachers?.map((tc: User) => {
            this.addTeacherToFormGroup(tc, false);
          });
          timetableReplacementsResume.blocks?.map((block) => {
            const newFormGroup: FormGroup = new FormGroup({
              block: new FormControl(block.block),
              start: new FormControl(block.start),
              end: new FormControl(block.end),
              tz: new FormControl(block.tz),
              classes: new FormArray(
                (block.classes ?? []).map((cl) => {
                  return new FormGroup({
                    id: new FormControl(cl.id),
                    anno: new FormControl(cl.anno),
                    sezione: new FormControl(cl.sezione),
                    dipartimento: new FormControl(cl.dipartimento),
                  });
                })
              ),
              teacher: new FormGroup({
                id: new FormControl(block.teacher?.id),
                cognome: new FormControl(block.teacher?.cognome || ''),
                nome: new FormControl(block.teacher?.nome || ''),
                secondo_nome: new FormControl(
                  block.teacher?.secondo_nome || ''
                ),
              }),
              modification: new FormControl(block.modification),
              substitute: block.substitute
                ? new FormGroup({
                    id: new FormControl(block.substitute.id ?? ''),
                    cognome: new FormControl(block.substitute.cognome ?? ''),
                    nome: new FormControl(block.substitute.nome ?? ''),
                    secondo_nome: new FormControl(
                      block.substitute.secondo_nome ?? ''
                    ),
                  })
                : new FormGroup({
                    id: new FormControl(),
                    cognome: new FormControl(),
                    nome: new FormControl(),
                    secondo_nome: new FormControl(),
                  }),
              person: new FormControl(block.person ?? null),
              time: new FormControl(block.time ?? null),
              note: new FormControl(block.note),
              slot: new FormGroup({
                original: new FormControl(block.slot?.original ?? null),
                substitution: new FormControl(block.slot?.substitution ?? null),
              }),
            });
            newFormGroup
              .get('modification')
              ?.valueChanges.subscribe((value) => {
                newFormGroup.get('substitute.id')?.patchValue(null);
                newFormGroup.get('substitute.cognome')?.patchValue(null);
                newFormGroup.get('substitute.nome')?.patchValue(null);
                newFormGroup.get('substitute.secondo_nome')?.patchValue(null);
                newFormGroup.get('person')?.patchValue(null);
                newFormGroup.get('time')?.patchValue(null);
                if (value === 'entry' && block.block) {
                  newFormGroup
                    .get('time')
                    ?.patchValue(this.getSlotTime(block.block, 'END'));
                } else if (value === 'exit' && block.block) {
                  newFormGroup
                    .get('time')
                    ?.patchValue(this.getSlotTime(block.block, 'START'));
                }
              });
            this.getFormBlocks.push(newFormGroup);
          });
        },
        error: (error) => {
          this.error = new ApiError(error);
          console.error('GetReplacementsSummaryDetails Error:', error);
          this._baseComponentService
            .showErrorDialog({ error: this.error })
            .subscribe(() => this._baseComponentService.backOnePage());
        },
      });
  }

  backOnePage(): void {
    this._baseComponentService.backOnePage();
  }
}
