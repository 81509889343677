import { ClassesService } from 'src/app/services/classes.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { EventEmitter, Component, Output, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-class-years-listing',
  templateUrl: './class-years-listing.component.html',
  styleUrls: ['./class-years-listing.component.scss'],
})
export class ClassYearsListingComponent implements OnInit {
  @Input() slected?: string;
  @Output() scholasticYearEmitter = new EventEmitter<string>();

  scholasticYears: string[] = [];

  constructor(private _classesService: ClassesService) {}

  get selectedYear() {
    return this.slected || 'NO-SELECTED';
  }

  ngOnInit(): void {
    setTimeout(() => this.getScholasticYearsData(), 500);
  }

  onSelect(year: string) {
    this.scholasticYearEmitter.emit(year);
  }

  getScholasticYearsData() {
    this._classesService.getScholasticYears().subscribe({
      next: (data) => {
        if (data.data != undefined && (data.data as string[])) {
          this.scholasticYears = data.data as string[];
        }
      },
      error: (error) => {
        console.error(
          'Classes Scholastic Years Listing Error: ',
          new ApiError(error)
        );
      },
    });
  }
}
