import { DeaBirthdaysListingDialogComponent } from 'src/app/components/dea-pack/dea-birthdays-listing-dialog/dea-birthdays-listing-dialog.component';
import { DeaUserConfirmDialogComponent } from 'src/app/components/dea-pack/dea-user-confirm-dialog/dea-user-confirm-dialog.component';
import { DeaWelcomeDialogComponent } from 'src/app/components/dea-pack/dea-welcome-dialog/dea-welcome-dialog.component';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { DeaDropdownMenuEventData } from 'src/app/constants/interfaces';
import { StorageKeys } from 'src/app/constants/storege-keys';
import { UserService } from 'src/app/services/user.service';
import { Headquarter } from 'src/app/models/headquarter';
import { AppPaths } from 'src/app/constants/app-paths';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  public ringIconUrl = API.deAmicisRingIcon();
  public avatarUrl = API.thumbnail(1);
  public env = environment;

  public headquarters: string[] = Headquarter.getFrontendHeadqarters();
  public selectedHeadquarter: string = Headquarter.MILANO.frontend;
  public usersBirthdaysListing: User[] = [];
  public devicesSection: boolean = false;
  public filmSection: boolean = false;

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _userService: UserService,
    private _clientStorageService: ClientStorageService,
    private _clientSessionService: ClientStorageService
  ) {}

  ngOnInit(): void {
    const session = this._clientSessionService.getSessionCheck();
    if (!session) this._router.navigateByUrl(AppPaths.SESSION);

    if (session && !this._clientSessionService.getStatusWelcomeMessage()) {
      const errorDialogRef = this._dialog.open(DeaWelcomeDialogComponent, {
        maxWidth: '500px',
        minWidth: '500px',
        autoFocus: false,
        restoreFocus: false,
      });
      errorDialogRef
        .afterClosed()
        .subscribe(() =>
          this._clientSessionService.setStatusWelcomeMessage(true)
        );
    }

    this.selectedHeadquarter = this._clientStorageService.setHeadquarter(
      localStorage.getItem(StorageKeys.HEADQUARTER)
    );
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setTimeout(() => {
      this.getSectionsAuth();
      this.getBirthdaysLisitng({
        date: `${year}-${month}-${day}`,
      });
    }, 1000);
  }

  onHeadquarterChange(eventData: DeaDropdownMenuEventData) {
    if (eventData.id === 'dropdownInputDashboardHeadquarter') {
      this.selectedHeadquarter = this._clientStorageService.setHeadquarter(
        Headquarter.fromString(this.headquarters[eventData.index])?.backend ??
          Headquarter.MILANO.backend
      );
      window.location.reload();
    }
  }

  openBirthdaysLisitng() {
    const birthdaysLisitngDialogRef = this._dialog.open(
      DeaBirthdaysListingDialogComponent,
      {
        maxWidth: '500px',
        minWidth: '500px',
        data: {
          users: this.usersBirthdaysListing,
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );
    birthdaysLisitngDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult != undefined) {
        this._router.navigateByUrl(AppPaths.UTENTE + `/${dialogResult}`);
      }
    });
  }

  logoutConfirmDialog() {
    const confirmDialogRef = this._dialog.open(DeaUserConfirmDialogComponent, {
      maxWidth: '400px',
      minWidth: '380px',
      data: {
        message: 'Vuoi effettuare il logout?',
      },
      autoFocus: false,
      restoreFocus: false,
    });

    confirmDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === true) this._router.navigateByUrl(AppPaths.LOGOUT);
    });
  }

  getSectionsAuth() {
    this._authService.getSectionsAuth().subscribe({
      next: (data: any) => {
        this.filmSection = data?.data?.filmSection || false;
        this.devicesSection = data?.data?.devicesSection || false;
      },
      error: (error) => console.error('Error:', error),
    });
  }

  getBirthdaysLisitng(p?: { date?: string }) {
    this._userService.getBirthdaysListing(p).subscribe({
      error: (error) => console.error('getBirthdaysLisitng Error: ', error),
      next: (data) => {
        this.usersBirthdaysListing = data?.data?.map(
          (json: any) => new User(json)
        );
      },
    });
  }
}
