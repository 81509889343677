import { StatusMonitor } from './status-monitor.model';

export class StatusGroup {
  id?: number;
  name?: string;
  weight?: number;
  monitorList?: StatusMonitor[];

  constructor(json?: any) {
    if (!json) return;
    this.id = json['id'];
    this.name = json['name'];
    this.weight = json['weight'];
    this.monitorList = (json['monitorList'] || []).map(
      (m: any) => new StatusMonitor(m)
    );
  }
}
