<app-dea-dialog-wrapper>
  <div class="d-flex gap-2" style="height: 450px; width: 550px;">
    <div class="d-flex flex-column gap-1">
      <div class="py-2 px-3 rounded filter-tab dea-mat-icon-btn d-flex flex-row" *ngFor="let filter of filters"
        (click)="selectFilter(filter.value)" [ngClass]="filter.value == selectedFilter ? 'selected' : ''">
        {{filter.title}} <div *ngIf="filter.info()" style="color: var(--primary-color);"> •</div>
      </div>
    </div>
    <div class="d-flex flex-column flex-grow-1 gap-1 justify-content-start" style="overflow-y: scroll;">
      <ng-container *ngIf="selectedFilter == 'scholastic-years'">
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let year of scholasticYears" (click)="onSelectScholasticYear(year)"
            [ngClass]="year == selectedYear ? 'text-light fw-bold dea-mat-chip-option selected' : ''"
            [selected]="year == selectedYear">
            {{year}}
          </mat-chip-option>
        </mat-chip-listbox>
      </ng-container>
      <ng-container *ngIf="selectedFilter == 'classes'">
        <div class="py-2 px-3 rounded filter-tab dea-mat-icon-btn w-100" *ngFor="let classe of classes"
          (click)="onSelectClass(classe)" [ngClass]="classe.id == selectedClass?.id ? 'selected' : ''">
          {{classe.getName()}} | {{classe.anno_scolastico}}
        </div>
      </ng-container>
      <ng-container *ngIf="selectedFilter == 'subjects'">
        <div class="py-2 px-3 rounded filter-tab dea-mat-icon-btn w-100" *ngFor="let subject of subjects"
          [ngClass]="subject.id?.toString() == selectedSubject?.id?.toString() ? 'selected' : ''"
          (click)="onSelectSubject(subject)">
          {{subject.nome}}
        </div>
      </ng-container>
      <ng-container *ngIf="selectedFilter == 'owners'">
        <app-listing-top-bar [actionBtn]="false" (textEmitter)="onOwnerText($event)"></app-listing-top-bar>
        <div style="overflow-y: scroll;">
          <div *ngIf="selectedOwner && !isThereSelectedUserInUsers()"
            class="py-2 px-3 rounded filter-tab dea-mat-icon-btn w-100 selected" (click)="onSelectOwner(selectedOwner)">
            <span class="opacity-25">{{selectedOwner.dea_id}}</span> {{selectedOwner.cognome}} {{selectedOwner.nome}}
          </div>
          <div class="py-2 px-3 rounded filter-tab dea-mat-icon-btn w-100" *ngFor="let user of users"
            [ngClass]="user.id?.toString() == selectedOwner?.id?.toString() ? 'selected' : ''"
            (click)="onSelectOwner(user)">
            <span class="opacity-25">{{user.dea_id}}</span> {{user.cognome}} {{user.nome}}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="end">
    <div class="py-1 me-auto dea-hover-underline" (click)="onReset()">Reset Filtri</div>
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">Annulla</button>
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="onConfirm()">Filtra</button>
  </div>
</app-dea-dialog-wrapper>
