import { AppPaths } from 'src/app/constants/app-paths';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private _router: Router) {}

  backHome() {
    this._router.navigateByUrl(AppPaths.SESSION);
  }
}
