<app-dea-widget-wrapper>
  <!-- TOP-BAR -->
  <div class="w-100 row m-0 p-0">
    <div class="col m-0 p-0">
      <app-dea-search-bar (text)="onText($event)"></app-dea-search-bar>
    </div>
    <div class="col-auto ms-3 p-0">
      <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill" (click)="openFilmDialog()">
        Aggiungi Film
      </button>
    </div>
  </div>
  <!-- TOP-BAR -->
  <div class="v-spacer"></div>
  <!-- LOADING / ERROR -->
  <ng-container>
    <div *ngIf=" loading" class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
    <div *ngIf="!loading && error" class="d-flex justify-content-center align-items-center" style="height: 96px;">
      {{error.message}}
    </div>
  </ng-container>
  <!-- LOADING / ERROR -->
  <!-- DATA -->
  <ng-container *ngIf="!loading && !error">
    <div *ngFor="let film of films; let index = index; let last = last;">
      <div class="row m-0 p-0 mb-4">
        <div class="col">
          <app-dea-label [label]="'Titolo:'"></app-dea-label>
          <div *ngFor="let titolo of film.title | keyvalue ">
            <span class="fw-lighter opacity-50">{{titolo.key.toUpperCase()}} | </span> {{titolo.value}}
          </div>
        </div>
        <div class="col">
          <div class="m-0 mb-3 p-0 hstack gap-3">
            <app-dea-label [label]="'Lingue:'"></app-dea-label>
            {{film.languages?.join(', ')?.trim()?.toUpperCase()}}
          </div>
          <div class="m-0 p-0 hstack gap-3">
            <app-dea-label [label]="'Sottotitoli:'"></app-dea-label>
            {{film.subtitles?.join(', ')?.trim()?.toUpperCase()}}
          </div>
        </div>
        <div class="col m-0 p-0">
          <div class="m-0 mb-3 p-0 hstack gap-3">
            <app-dea-label [label]="'Anno:'"></app-dea-label>
            {{film.year}}
          </div>
          <div class="m-0 p-0 hstack gap-3">
            <app-dea-label [label]="'Categorie:'"></app-dea-label>
            {{film.categories?.join(', ')?.trim()}}
          </div>
        </div>
        <div class="col-auto m-0 p-0 ps-3">
          <div class="dea-icon-btn" title="Modifica Film" (click)="editFilm(index)">
            <i class="bi bi-pencil"></i>
          </div>
          <div class="dea-icon-btn" title="Elimina Film" (click)="deleteFilm(index)">
            <i class="bi bi-trash3 text-danger"></i>
          </div>
        </div>
      </div>
      <div *ngIf="!last" class="row m-0 p-0 mb-4 opacity-50">
        <hr>
      </div>
    </div>
  </ng-container>
  <!-- DATA -->
</app-dea-widget-wrapper>