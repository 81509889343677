import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Feature } from '../models/feature.model';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  page: number = 0;
  limit: number = 100;
  status?: string;

  constructor(private http: HttpClient) {}

  getListing() {
    return this.http.get<ApiResponse<any>>(
      API.featuresListing({
        page: this.page,
        limit: this.limit,
        status: this.status,
      }),
      { withCredentials: true }
    );
  }

  create(data?: Feature) {
    return this.http.post<ApiResponse<any>>(API.feature(), data?.toJson(), {
      withCredentials: true,
    });
  }

  patch(id: string, data?: Feature) {
    return this.http.patch<ApiResponse<any>>(API.feature(id), data?.toJson(), {
      withCredentials: true,
    });
  }

  delete(id: string) {
    return this.http.delete<ApiResponse<any>>(API.feature(id), {
      withCredentials: true,
    });
  }
}
