import { GuestCard } from 'src/app/models/register/guest/guest-card.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-guest-card',
  templateUrl: './guest-card.component.html',
  styleUrls: ['./guest-card.component.scss'],
})
export class GuestCardComponent {
  @Input() guestCard?: GuestCard;
}
