<div class="my-auto data-box">
  <div class="hstack">
    <app-dea-label [label]="label"></app-dea-label>
  </div>
  <ng-template *ngIf="data; then dataTrue else dataFalse"></ng-template>
  <ng-template #dataTrue>
    <div title="{{ title ? title : 'Copia: ' + data }}" class="pointer" (click)="copyInClipboard()"
      (dblclick)="emitDoubleClick()">
      <span [ngClass]="{'fw-semibold': boldData}">
        {{data}}
      </span>
      <ng-content></ng-content>
    </div>
  </ng-template>
  <ng-template #dataFalse>
    <div> </div>
  </ng-template>
</div>