import { SectionDocumentForm } from './section-document-form';

export class PageDocumentForm {
  title?: string;
  subtitle?: string;
  description?: string;
  sections?: SectionDocumentForm[];

  public static fromJson(json?: any): PageDocumentForm {
    const newPage: PageDocumentForm = new PageDocumentForm();
    newPage.title = json['title'];
    newPage.subtitle = json['subtitle'];
    newPage.description = json['description'];
    newPage.sections = (json['sections'] || []).map((s: any) => {
      return SectionDocumentForm.fromJson({ ...s });
    });
    return newPage;
  }
}
