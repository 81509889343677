import { CalendarEvent } from 'src/app/models/calendar/calendar-event.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { Component, inject, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent implements OnInit {
  private _calendarService: CalendarService = inject(CalendarService);
  currentView: 'month' | 'week' | 'day' = 'month';
  mainDate: moment.Moment = moment();
  weekDays: string[] = [];
  dayHours: string[] = [];
  monthDates: moment.Moment[] = [];
  weekDates: moment.Moment[] = [];
  today: moment.Moment = moment();

  events: CalendarEvent[] = [];

  ngOnInit(): void {
    // Set the start of the week to Monday globally in moment
    moment.updateLocale('it', { week: { dow: 1 } });
    // Generate weekday labels starting from Monday
    this.weekDays = moment.weekdaysShort(true); // True ensures Monday is first
    this.dayHours = Array.from({ length: 24 }, (_, i) => `${i}:00`);
    this.mainDate = moment();
    this.today = moment();
    this.updateCalendar();
  }

  updateCalendar(): void {
    switch (this.currentView) {
      case 'month':
        this.generateMonthView();
        break;
      case 'week':
        this.generateWeekView();
        break;
      case 'day':
        this.generateDayView();
        break;
    }
  }

  generateMonthView(): void {
    this.monthDates = [];
    const startOfMonth = this.mainDate.clone().startOf('month').startOf('week');
    const endOfMonth = this.mainDate.clone().endOf('month').endOf('week');

    this.getTimetableSlots(startOfMonth, endOfMonth);

    let date = startOfMonth.clone();
    while (!date.isAfter(endOfMonth, 'day')) {
      this.monthDates.push(date.clone());
      date.add(1, 'day');
    }
    console.info(
      'monthDates',
      this.monthDates.map((date) => date.toISOString())
    );
  }

  generateWeekView(): void {
    this.weekDates = [];
    const startOfWeek = this.mainDate.clone().startOf('week');

    for (let i = 0; i < 7; i++) {
      this.weekDates.push(startOfWeek.clone().add(i, 'day'));
    }
  }

  generateDayView(): void {
    // Non c'è bisogno di generare date aggiuntive per la vista giornaliera
  }

  changeView(view: 'month' | 'week' | 'day'): void {
    this.currentView = view;
    this.updateCalendar();
  }

  goToToday(): void {
    this.mainDate = moment();
    this.updateCalendar();
  }

  goNext(): void {
    switch (this.currentView) {
      case 'month':
        this.mainDate.add(1, 'month');
        break;
      case 'week':
        this.mainDate.add(1, 'week');
        break;
      case 'day':
        this.mainDate.add(1, 'day');
        break;
    }
    this.updateCalendar();
  }

  goBack(): void {
    switch (this.currentView) {
      case 'month':
        this.mainDate.subtract(1, 'month');
        break;
      case 'week':
        this.mainDate.subtract(1, 'week');
        break;
      case 'day':
        this.mainDate.subtract(1, 'day');
        break;
    }
    this.updateCalendar();
  }

  getTimetableSlots(from: moment.Moment, to: moment.Moment) {
    this._calendarService
      .timetable({
        classId: undefined,
        teacherId: undefined,
        from: from,
        to: to,
      })
      .subscribe({
        next: (response) => {
          console.info('DATA:', response);
          if (response.data) {
            this.events = response.data.map(
              (json: any) => new CalendarEvent(json)
            );
            console.info('EVENTS:', this.events);
          }
        },
        error: (error) => {
          console.error('Error:', error);
        },
      });
  }

  getNumberOfEvents(date: moment.Moment): string {
    let filteredEvents = this.events.filter((e: CalendarEvent) =>
      date.isSame(e.start, 'day')
    );
    console.info('DATE:', date.toISOString(), '->', filteredEvents);
    if (filteredEvents.length != 0) {
      return `${filteredEvents.length} ${
        filteredEvents.length === 1 ? 'evento' : 'eventi'
      }`;
    }
    return ' ';
  }
}
