<div class="d-flex flex-row justify-content-center mt-3">
  <button mat-stroked-button disabled="{{!enablePrev}}" (click)="onClickPrev()"
    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important">
    <div class="d-flex flex-row justify-content-center align-items-center opacity-25">
      <mat-icon class="ps-1" aria-hidden="false" aria-label="Pagina precedente" fontIcon="arrow_back_ios"></mat-icon>
    </div>
  </button>
  <button mat-stroked-button disabled="{{!enableNext}}" (click)="onClickNext()"
    style="border-bottom-left-radius: 0 !important; border-top-left-radius: 0 !important">
    <div class="d-flex flex-row justify-content-center align-items-center opacity-25">
      <mat-icon class="pe-1" aria-hidden="false" aria-label="Pagina successiva" fontIcon="arrow_forward_ios"></mat-icon>
    </div>
  </button>
</div>