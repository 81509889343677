<div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
  <div class="d-flex flex-column justify-content-center align-items-center home-main-card-div"
    style="background-color: transparent;">
    <div class="hstack">
      <div class="vstack py-5 text-center" style="max-width: 96px;">
        <img class="m-0 p-0 mx-auto" width="96" height="96" mat-card-image src="{{iconUrl}}" crossorigin="anonymous">
        <div class="m-0 mt-2 p-0 fw-lighter text-center" style="font-size: 16px;">Un db per<br>domarli tutti</div>
        <div class="hstack mt-2 opacity-50 fw-lighter text-decoration-underline fs-075r user-pass-lbl ms-auto pointer"
          *ngIf="showBackHomeBtn" routerLink="/">
          <i class="bi bi-chevron-left me-2"></i> Homepage
        </div>
      </div>
      <div class="vr mx-4"></div>
      <div class="vstack py-5">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
<!-- <div class="position-absolute bottom-0 start-50 translate-middle-x mb-3" style="font-size: 0.5rem; opacity: 0.18;">
  <a href="http://www.gabrielebellani.com" target="_blank" rel="noopener noreferrer">Made with ♥︎ by G</a>
</div> -->