import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-dea-user-chip',
  templateUrl: './dea-user-chip.component.html',
  styleUrls: ['./dea-user-chip.component.scss'],
})
export class DeaUserChipComponent {
  // Action Event Emitter
  @Output() onRemove = new EventEmitter<string>();

  // Component Configs
  @Input() user: User | undefined;
  @Input() showThumbnail: boolean = false;
  @Input() showRemoveAction: boolean = false;

  onRemoveAction(): void {
    if (this.showRemoveAction == false) return;
    if (this.user != undefined && this.user.id) {
      this.onRemove.emit(this.user.id);
    } else {
      console.error('DeaUserChipComponent - Error: user has no ID!');
    }
  }
}
