import { DeaConfirmDialogComponent } from '../dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { Component, Input, OnInit, inject } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Event } from 'src/app/models/event/event.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 25;
  private _dialog: MatDialog = inject(MatDialog);
  private _eventsService: EventsService = inject(EventsService);
  public pagination?: ApiPagination;
  public isError: boolean = false;
  public loading: boolean = true;
  public events: Event[] = [];
  public error?: ApiError;

  ngOnInit(): void {
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._eventsService.page = this.page;
    this._eventsService.limit = this.limit;
    this._eventsService
      .getListing()
      .subscribe({
        next: (data: any) => {
          this.pagination = new ApiPagination(data?.pagination);
          this.events = data?.data?.map((json: any) => new Event(json));
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Events Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  deleteEvent(event?: Event) {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questo evento?',
        details:
          'Attenzione: la modifica sarà visibile anche nel calendario pubblico e non sarà più possibile ripristinarlo',
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult && event?.id != undefined && event.id != '') {
        this._eventsService.delete(event.id).subscribe({
          next: (_: any) => this.getData(),
          error: (error: any) => console.error('Delete Event Error: ', error),
        });
      }
    });
  }
}
