import { Event } from 'src/app/models/event/event.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-event-row',
  templateUrl: './event-row.component.html',
  styleUrls: ['./event-row.component.scss'],
})
export class EventRowComponent {
  @Input() event?: Event;
}
