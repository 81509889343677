import { DeaUserListingDialogComponentOutput } from 'src/app/constants/interfaces';
import { DeaUserListingDialogData } from 'src/app/constants/dialog-interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-dea-user-listing-dialog',
  templateUrl: './dea-user-listing-dialog.component.html',
  styleUrls: ['./dea-user-listing-dialog.component.scss'],
})
export class DeaUserListingDialogComponent implements OnInit {
  @Input() linkMainBtnQueryParams: object = {};
  @Input() limit: number = 10;
  @Input() page: number = 0;
  @Input() type?: string;

  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public users: User[] = [];
  public error?: ApiError;
  private text?: string;

  constructor(
    private _userService: UserService,
    public dialogRef: MatDialogRef<DeaUserListingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaUserListingDialogData
  ) {
    if (data.type) this.type = data.type;
  }

  onSelected(id?: string): void {
    const selectedUser: User[] = id
      ? this.users.filter((user) => user.id === id)
      : [];
    const dialogOutput: DeaUserListingDialogComponentOutput = {
      id: selectedUser[0].id || '',
      nome: selectedUser[0].nome || '',
      cognome: selectedUser[0].cognome || '',
      secondo_nome: selectedUser[0].secondo_nome || '',
    };
    this.dialogRef.close(dialogOutput);
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getListing({
        page: this.page,
        limit: this.limit,
        type: this.type,
        txt: this.text,
        aggregate: 'false',
      })
      .subscribe({
        next: (data) => {
          this.users = data?.data ?? [];
          this.pagination = data?.pagination;
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
