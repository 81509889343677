import { DeaSingleBtnDialogComponent } from 'src/app/components/dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { ClassTeacher } from 'src/app/models/class/class-teacher';
import { ClassesService } from 'src/app/services/classes.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Class } from 'src/app/models/class/class.model';
import { AppPaths } from 'src/app/constants/app-paths';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-class-next-year-preview',
  templateUrl: './class-next-year-preview.component.html',
  styleUrls: ['./class-next-year-preview.component.scss'],
})
export class ClassNextYearPreviewComponent implements OnInit {
  public isPosting: boolean = false;
  public loading: boolean = false;
  public isError: boolean = false;
  public error?: ApiError;
  public classId?: string;
  public class?: Class;

  public newClassStudents: User[] = [];
  public newClassTeachers: ClassTeacher[] = [];

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private location: Location,
    private _route: ActivatedRoute,
    private _classService: ClassesService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.classId = params['id'];
      this.getData();
    });
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._classService
      .getNextYearPreview(this.classId)
      .subscribe({
        next: (data) => {
          this.class = new Class(data?.data);
          this.newClassStudents = [...(this.class?.studenti || [])];
          this.newClassTeachers = [...(this.class?.docenti || [])];
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Class Next Year Preview Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  isChecked(id?: string): boolean {
    let student = this.newClassStudents.filter((s) => s.id == id);
    let teacher = this.newClassTeachers.filter((t) => t.docente?.id == id);
    return student.length != 0 || teacher.length != 0 ? true : false;
  }

  onChangeCheckbox(id?: string) {
    const indexS = this.newClassStudents.findIndex((s) => s.id == id);
    const indexT = this.newClassTeachers.findIndex((d) => d.docente?.id == id);
    if (indexS == -1 && indexT == -1) {
      // AGGIUNGO
      const iDataS = this.class?.studenti?.findIndex((s) => s.id == id);
      const iDataT = this.class?.docenti?.findIndex((d) => d.docente?.id == id);
      if (iDataS != undefined && iDataS != -1) {
        const stud = [this.class!.studenti![iDataS!]];
        this.newClassStudents = this.newClassStudents.concat(...stud);
      }
      if (iDataT != undefined && iDataT != -1) {
        const teac = [this.class!.docenti![iDataT!]];
        this.newClassTeachers = this.newClassTeachers.concat(...teac);
      }
    } else {
      // RIMUOVO
      if (indexS != -1) this.newClassStudents.splice(indexS, 1);
      if (indexT != -1) this.newClassTeachers.splice(indexT, 1);
    }
  }

  backOnePage(): void {
    this.location.back();
  }

  postData() {
    this.isPosting = true;
    const students = this.newClassStudents.map((s) => s.id);
    const teachers = this.newClassTeachers.map((t) => {
      return { discipline: t.discipline, docente: t.docente?.id };
    });
    this._classService
      .postNextYearPreview({
        id: this.classId || 'ID-ERROR',
        studenti: students,
        docenti: teachers,
      })
      .subscribe({
        next: (data) => {
          try {
            const newClass = new Class(data?.data);
            this._router.navigateByUrl(AppPaths.CLASSE + '/' + newClass.id);
          } catch (e) {
            this.goToNextPage();
          }
        },
        error: (error) => {
          console.error('Class Next Year Post Error: ', error);
          this.error = new ApiError(error);
          const errorDialogRef = this._dialog.open(
            DeaSingleBtnDialogComponent,
            {
              maxWidth: '450px',
              minWidth: '450px',
              data: {
                error: this.error,
              },
              autoFocus: false,
              restoreFocus: false,
            }
          );
          errorDialogRef
            .afterClosed()
            .subscribe(() => (this.isPosting = false));
        },
      })
      .add(() => (this.isPosting = false));
  }

  goToNextPage(): void {
    this.class?.id
      ? this._router.navigateByUrl(AppPaths.CLASSE + '/' + this.class.id)
      : this._router.navigateByUrl(AppPaths.CLASSI);
  }
}
