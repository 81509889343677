import { DeaPdfDialogComponent } from 'src/app/components/dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { DocumentForm } from 'src/app/models/documents-forms/document-form.model';
import { DocumentsFormsService } from 'src/app/services/documents-forms.service';
import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-user-modules-data',
  templateUrl: './user-modules-data.component.html',
  styleUrls: ['./user-modules-data.component.scss'],
})
export class UserModulesDataComponent {
  private _userService: UserService = inject(UserService);
  private _documentsFormsService: DocumentsFormsService = inject(
    DocumentsFormsService
  );
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() startLoading: boolean = false;
  @Input() userId?: string;

  forms: DocumentForm[] = [];

  constructor(private _dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading'].currentValue &&
      !changes['startLoading'].previousValue
    ) {
      this.getData();
    }
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getFormListing(this.userId)
      .subscribe({
        next: (data) => {
          this.forms = data?.data?.map((form: any) => {
            return DocumentForm.fromJson(form);
          });
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Forms Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  openPdfPreviewPDF(id?: string): void {
    this._dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: {
        id: id || 'NO-ID',
        url: API.downloadDocumentFormPDF(id || 'ID-ERROR'),
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  documentFormPDF(id?: string): void {
    if (!id) return;
    this._documentsFormsService.downloadDocumentFormPDF(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      const selected = this.forms.filter((form) => form.id == id);
      if (selected.length == 1) {
        a.download =
          `${selected[0].getClass() || 'No-Class'} - ${
            selected[0].owner?.getFullName() || 'No-Owner'
          }` + '.pdf';
      } else a.download = new Date().toISOString() + '.pdf';
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }
}
