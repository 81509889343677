<div class="hstack gap-3">
  <img src="{{scr}}" height="40" width="40" style="object-fit: contain; object-position: center;" loading="lazy" />
  <div class="w-100">
    <div class="row m-0 p-0 w-100">
      <div class="col m-0 p-0 fs-065r">{{device?.product_family}}</div>
    </div>
    <div class="row m-0 p-0 w-100">
      <div class="col m-0 p-0">
        <div>{{device?.model_name}}</div>
      </div>
      <div class="col m-0 p-0">
        <div>{{device?.serial_number}}</div>
      </div>
      <div class="col-2 m-0 p-0">{{device?.capacity}}</div>
      <div class="col-2 m-0 p-0 text-end">{{device?.color | toTitleCase}}</div>
    </div>
  </div>
</div>
