import { Component } from '@angular/core';

@Component({
  selector: 'app-guests-attendances',
  templateUrl: './guests-attendances.component.html',
  styleUrls: ['./guests-attendances.component.scss']
})
export class GuestsAttendancesComponent {

}
