<app-dea-widget-wrapper>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="fw-lighter opacity-75">{{formTitle}}</div>
      <div class="v-spacer"></div>
      <div class="my-user-form">
        <form [formGroup]="userForm" novalidate class="form" (keyup)="onFormChange()"
          (keydown.enter)="formEnter($event)">
          <!-- TIPO / SEDE -->
          <div class="row m-0 p-0 gap-3">
            <div class="col m-0 p-0">
              <app-dea-label class="mb-2" [label]="'Tipo:'"></app-dea-label>
              <app-dea-dropdown-menu [dropdownMenuId]="'userPersonalDataFormUserTypeDropdownMenu'"
                [selectedItem]="selecteduserTypeUserForm || userTypes[0]" [items]="userTypes"
                (ddmEventEmitter)="onUserTypeChange($event)" [menuIsDisabled]="disableUserTypeDropdownMenu">
              </app-dea-dropdown-menu>
            </div>
            <div class="col m-0 p-0">
              <app-dea-label class="mb-2" [label]="'Sede:'"></app-dea-label>
              <app-dea-dropdown-menu [dropdownMenuId]="'userPersonalDataFormHeadquarterDropdownMenu'"
                [selectedItem]="selectedHeadquarterUserForm || headquarters[0]" [items]="headquarters"
                (ddmEventEmitter)="onUserHeadquarterChange($event)"
                [menuIsDisabled]="disableUserHeadquarterDropdownMenu">
              </app-dea-dropdown-menu>
            </div>
          </div>
          <!-- DATI PERSONALI -->
          <div class="v-spacer"></div>
          <app-dea-label class="mb-2" [label]="'Dati Personali:'"></app-dea-label>
          <div class="row m-0 p-0 gap-3 mb-3">
            <div class="col m-0 p-0">
              <input formControlName="cognome" type="text" class="form-control dea-form-input" placeholder="Cognome"
                toTitleCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                toTitleCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                placeholder="Secondo Nome" toTitleCase />
            </div>
          </div>
          <div class="row m-0 p-0 gap-3 mb-3">
            <div class="col m-0 p-0">
              <input formControlName="codice_fiscale" type="text" class="form-control dea-form-input"
                placeholder="Codice Fiscale" toUpperCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="nazionalita" type="text" class="form-control dea-form-input"
                placeholder="Nazionalità" toTitleCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="sesso" type="text" class="form-control dea-form-input" placeholder="Sesso"
                toUpperCase />
            </div>
          </div>
          <div class="row m-0 p-0 gap-3 mb-3">
            <div class="col m-0 p-0">
              <input formControlName="data_di_nascita" type="date" class="form-control dea-form-input"
                placeholder="Data di Nascita" />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="luogo_di_nascita" type="text" class="form-control dea-form-input"
                placeholder="Luogo di Nascita" toTitleCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="provincia_di_nascita" type="text" class="form-control dea-form-input"
                placeholder="Provincia di Nascita" toUpperCase />
            </div>
          </div>
          <!-- CLASSE / DIPARTIMENTO / RELIGIONE -->
          <div class="v-spacer"></div>
          <app-dea-label class="mb-2" [label]="''"></app-dea-label>
          <div class="row m-0 p-0 gap-3" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[0]}">
            <div class="col m-0 p-0">
              <app-dea-label [label]="'Dipartimento (classe iscrizione):'"></app-dea-label>
              <app-dea-dropdown-menu [dropdownMenuId]="'userPersonalDataFormDepartmentDropdownMenu'"
                [selectedItem]="selectedDepartmentUserForm || departments[0]" [items]="departments"
                (ddmEventEmitter)="onUserDepartmentChange($event)">
              </app-dea-dropdown-menu>
            </div>
            <div class="col m-0 p-0">
              <app-dea-label [label]="'Anno (classe iscrizione):'"></app-dea-label>
              <mat-radio-group formControlName="anno">
                <mat-radio-button value="1" class="ms-1" color="primary">1</mat-radio-button>
                <mat-radio-button value="2" class="ms-1" color="primary">2</mat-radio-button>
                <mat-radio-button value="3" class="ms-1" color="primary">3</mat-radio-button>
                <mat-radio-button value="4" class="ms-1" color="primary">4</mat-radio-button>
                <mat-radio-button value="5" class="ms-1" color="primary">5</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col m-0 p-0">
              <div class="row m-0 p-0">
                <div class="col m-0 p-0">
                  <app-dea-label [label]="'Religione:'"></app-dea-label>
                  <mat-slide-toggle formControlName="religione" class="mt-2" color="primary"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <!-- ATLETA -->
          <div class="m-0 p-0" formGroupName="atleta" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[0]}">
            <div class="v-spacer"></div>
            <div class="m-0 p-0 d-flex flex-row gap-3">
              <div class="m-0 p-0 d-flex flex-row gap-1 align-items-center">
                <app-dea-label [label]="'Atleta:'"></app-dea-label>
                <mat-slide-toggle formControlName="enable" class="ms-3" color="primary"
                  (change)="atletaSwitchChanged()"></mat-slide-toggle>
              </div>
              <input type="text" formControlName="societa" class="form-control dea-form-input" placeholder="Società"
                [disabled]="!atletaEnable" />
              <input type="text" formControlName="disciplina" class="form-control dea-form-input"
                placeholder="Disciplina" [disabled]="!atletaEnable" />
            </div>
          </div>
          <!-- BISOGNI EDUCATIVI SPECIALI -->
          <div class="v-spacer"></div>
          <app-dea-label class="mb-2" [label]="''"></app-dea-label>
          <div class="m-0 p-0" formGroupName="bisogni_educativi_speciali"
            [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[0]}">
            <div class="row m-0 p-0 gap-3 mb-2">
              <div class="col m-0 p-0" style="max-width: 1200px;">
                <div class="hstack mb-1">
                  <app-dea-label [label]="'Bisogni Educativi Speciali:'"></app-dea-label>
                  <mat-slide-toggle formControlName="enable" class="ms-3" color="primary"
                    (change)="specialEducationalNeedsSwitchChanged()"></mat-slide-toggle>
                  <mat-checkbox class="ms-auto fast-action-btn mat-checkbox-small" color="primary"
                    labelPosition="before" formControlName="dsa">
                    Disturbi Specifici dell'Apprendimento
                  </mat-checkbox>
                </div>
                <div class="hstack m-0 p-0">
                  <input type="text" value="{{altri_bisogni_educativi_speciali}}"
                    class="me-4 form-control dea-form-input" placeholder="Altro..."
                    [disabled]="!specialEducationalNeedsEnable"
                    (input)="onChangeSpecialEducationalNeedsOther($event)" />
                  <mat-checkbox class="fast-action-btn mat-checkbox-small" color="primary"
                    [disabled]="!specialEducationalNeedsEnable"
                    [checked]="specialEducationalNeedsIsChecked('Discalculia')"
                    (change)="onChangeSpecialEducationalNeedsCheckbox('Discalculia')">
                    <span class="fs-075r">Discalculia</span>
                  </mat-checkbox>
                  <mat-checkbox class="ms-2 fast-action-btn mat-checkbox-small" color="primary"
                    [disabled]="!specialEducationalNeedsEnable"
                    [checked]="specialEducationalNeedsIsChecked('Disgrafia')"
                    (change)="onChangeSpecialEducationalNeedsCheckbox('Disgrafia')">
                    <span class="fs-075r">Disgrafia</span>
                  </mat-checkbox>
                  <mat-checkbox class="ms-2 fast-action-btn mat-checkbox-small" color="primary"
                    [disabled]="!specialEducationalNeedsEnable"
                    [checked]="specialEducationalNeedsIsChecked('Disortografia')"
                    (change)="onChangeSpecialEducationalNeedsCheckbox('Disortografia')">
                    <span class="fs-075r">Disortografia</span>
                  </mat-checkbox>
                  <mat-checkbox class="ms-2 fast-action-btn mat-checkbox-small" color="primary"
                    [disabled]="!specialEducationalNeedsEnable"
                    [checked]="specialEducationalNeedsIsChecked('Dislessia')"
                    (change)="onChangeSpecialEducationalNeedsCheckbox('Dislessia')">
                    <span class="fs-075r">Dislessia</span>
                  </mat-checkbox>
                  <mat-checkbox class="ms-2 fast-action-btn mat-checkbox-small" color="primary"
                    [disabled]="!specialEducationalNeedsEnable" [checked]="specialEducationalNeedsIsChecked('ADHD')"
                    (change)="onChangeSpecialEducationalNeedsCheckbox('ADHD')">
                    <span class="fs-075r">ADHD</span>
                  </mat-checkbox>
                  <mat-checkbox class="ms-2 fast-action-btn mat-checkbox-small" color="primary"
                    [disabled]="!specialEducationalNeedsEnable"
                    [checked]="specialEducationalNeedsIsChecked('Disturbi Misti')"
                    (change)="onChangeSpecialEducationalNeedsCheckbox('Disturbi Misti')">
                    <span class="fs-075r">Disturbi Misti</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3 mb-3">
              <div class="col m-0 p-0" style="max-width: 1200px;">
                <input formControlName="note" type="text" class="form-control dea-form-input" placeholder="Note" />
              </div>
            </div>
          </div>
          <!-- DOMICILIO -->
          <div class="v-spacer"></div>
          <div class="hstack">
            <app-dea-label class="mb-2" [label]="'Domicilio:'"></app-dea-label>
            <div class="ms-auto">
              <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Copia da \'Residenza\''"
                (click)="copyAddressFromTo('residenza','domicilio')">
              </app-dea-label>
            </div>
          </div>
          <div class="row m-0 p-0 gap-3" formGroupName="domicilio">
            <div class="col-4 m-0 p-0">
              <input formControlName="indirizzo" type="text" class="form-control dea-form-input" placeholder="Indirizzo"
                toTitleCase />
            </div>
            <div class="col-1 m-0 p-0">
              <input formControlName="civico" type="text" class="form-control dea-form-input" placeholder="Civico"
                toUpperCase />
            </div>
            <div class="col-1 m-0 p-0">
              <input formControlName="cap" type="text" class="form-control dea-form-input" placeholder="CAP" justInt />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="citta" type="text" class="form-control dea-form-input" placeholder="Città"
                toTitleCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="provincia" type="text" class="form-control dea-form-input" placeholder="Provincia"
                toUpperCase />
            </div>
          </div>
          <!-- RESIDENZA -->
          <div class="v-spacer"></div>
          <div class="hstack">
            <app-dea-label class="mb-2" [label]="'Residenza:'"></app-dea-label>
            <div class="ms-auto">
              <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Copia da \'Domicilio\''"
                (click)="copyAddressFromTo('domicilio','residenza')">
              </app-dea-label>
            </div>
          </div>
          <div class="row m-0 p-0 gap-3" formGroupName="residenza">
            <div class="col-4 m-0 p-0">
              <input formControlName="indirizzo" type="text" class="form-control dea-form-input" placeholder="Indirizzo"
                toTitleCase />
            </div>
            <div class="col-1 m-0 p-0">
              <input formControlName="civico" type="text" class="form-control dea-form-input" placeholder="Civico"
                toUpperCase />
            </div>
            <div class="col-1 m-0 p-0">
              <input formControlName="cap" type="text" class="form-control dea-form-input" placeholder="CAP" justInt />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="citta" type="text" class="form-control dea-form-input" placeholder="Città"
                toTitleCase />
            </div>
            <div class="col m-0 p-0">
              <input formControlName="provincia" type="text" class="form-control dea-form-input" placeholder="Provincia"
                toUpperCase />
            </div>
          </div>
          <!-- CONTATTI -->
          <div class="m-0 p-0" formGroupName="contatti">
            <div class="v-spacer"></div>
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Contatti:'"></app-dea-label>
            </div>
            <!-- E-MAIL -->
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'E-Mail:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Indirizzo E-Mail\''"
                  (click)="addContactFields('email')">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="email">
              <ng-container *ngFor="let item of userFormEmails.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="etichetta" type="text" class="form-control dea-form-input"
                        placeholder="Etichetta" toTitleCase />
                      <div class="hstack">
                        <app-dea-label class="ms-1 fs-075r" [label]="'Default:'"></app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'Scolastica'"
                          (click)="setLabelUserContactInAt('Scolastica','email',i)">
                        </app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'Personale'"
                          (click)="setLabelUserContactInAt('Personale','email',i)">
                        </app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'Lavoro'"
                          (click)="setLabelUserContactInAt('Lavoro','email',i)">
                        </app-dea-label>
                      </div>
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="indirizzo" type="text" class="form-control dea-form-input"
                        placeholder="Indirizzo E-Mail" toLowerCase />
                      <div class="hstack">
                        <app-dea-label class="ms-1 fs-075r" [label]="'Domini:'"></app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'@gmail.com'"
                          (click)="setDomainInEmailContactAt('gmail.com', i)">
                        </app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'@hotmail.com'"
                          (click)="setDomainInEmailContactAt('hotmail.com', i)">
                        </app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'@ideamicis.it'"
                          (click)="setDomainInEmailContactAt('ideamicis.it', i)">
                        </app-dea-label>
                        <app-dea-label class="ms-auto fast-action-btn fs-075r" [label]="'DeA-Mail'"
                          title="Genera E-Mail Scolastica" (click)="setDeaEmailContactAtIndex(i)">
                        </app-dea-label>
                      </div>
                    </div>
                    <div class="col-auto m-0 p-0">
                      <mat-checkbox color="primary" formControlName="enable" [checked]="true"
                        title="Utilizza contatto per le comunicazioni">
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina contatto E-Mail"
                    title="Elimina contatto E-Mail" (click)="deleteUserContactInAt('email',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
            <div class="v-spacer"></div>
            <!-- TELEFONO -->
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Telefono:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Numero di Telefono\''"
                  (click)="addContactFields('telefono')">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="telefono">
              <ng-container *ngFor="let item of userFormPhones.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="etichetta" type="text" class="form-control dea-form-input"
                        placeholder="Etichetta" toTitleCase />
                      <div class="hstack">
                        <app-dea-label class="ms-1 fs-075r" [label]="'Default:'"></app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'Cellulare'"
                          (click)="setLabelUserContactInAt('Cellulare','telefono',i)">
                        </app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'Lavoro'"
                          (click)="setLabelUserContactInAt('Lavoro','telefono',i)">
                        </app-dea-label>
                        <app-dea-label class="ms-2 fast-action-btn fs-075r" [label]="'Casa'"
                          (click)="setLabelUserContactInAt('Casa','telefono',i)">
                        </app-dea-label>
                      </div>
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="numero" type="text" class="form-control dea-form-input"
                        placeholder="Numero di Telefono" toLowerCase />
                    </div>
                    <div class="col-auto m-0 p-0">
                      <mat-checkbox color="primary" formControlName="enable" [checked]="true"
                        title="Utilizza contatto per le comunicazioni">
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina contatto telefonico"
                    title="Elimina contatto telefonico" (click)="deleteUserContactInAt('telefono',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- GENITORI -->
          <div class="m-0 p-0" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[0]}">
            <div class="v-spacer"></div>
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Genitori:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Genitore\''"
                  (click)="openUsersDialog('Parent', 'genitori')">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="genitori">
              <ng-container *ngFor="let item of userFormParents.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="cognome" type="text" class="form-control dea-form-input"
                        placeholder="Cognome" toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                        toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                        placeholder="Secondo Nome" toTitleCase />
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                    (click)="deleteItemInAt('genitori',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- FIGLI -->
          <div class="m-0 p-0" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[2]}">
            <div class="v-spacer"></div>
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Figli:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Figli\''"
                  (click)="openUsersDialog('Student', 'figli')">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="figli">
              <ng-container *ngFor="let item of userFormSons.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="cognome" type="text" class="form-control dea-form-input"
                        placeholder="Cognome" toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                        toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                        placeholder="Secondo Nome" toTitleCase />
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                    (click)="deleteItemInAt('figli',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- TUTORI -->
          <div class="m-0 p-0" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[0]}">
            <div class="v-spacer"></div>
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Tutori:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Tutore\''"
                  (click)="openUsersDialog('Tutor', 'tutori')">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="tutori">
              <ng-container *ngFor="let item of userFormTutors.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="cognome" type="text" class="form-control dea-form-input"
                        placeholder="Cognome" toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                        toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                        placeholder="Secondo Nome" toTitleCase />
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                    (click)="deleteItemInAt('tutori',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- TUTORATI -->
          <div class="m-0 p-0" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[4]}">
            <div class="v-spacer"></div>
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Tutorati:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Tutorato\''"
                  (click)="openUsersDialog('Student', 'tutorati')">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="tutorati">
              <ng-container *ngFor="let item of userFormTutored.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="cognome" type="text" class="form-control dea-form-input"
                        placeholder="Cognome" toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="nome" type="text" class="form-control dea-form-input" placeholder="Nome"
                        toTitleCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="secondo_nome" type="text" class="form-control dea-form-input"
                        placeholder="Secondo Nome" toTitleCase />
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                    (click)="deleteItemInAt('tutorati',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- MATERIE -->
          <div class="m-0 p-0" [ngClass]="{'d-none': selecteduserTypeUserForm !== userTypes[1]}">
            <div class="v-spacer"></div>
            <div class="hstack">
              <app-dea-label class="mb-2" [label]="'Materie:'"></app-dea-label>
              <div class="ms-auto">
                <app-dea-label class="mb-2 fast-action-btn fs-075r" [label]="'Aggiungi \'Materia\''"
                  (click)="openSubjectsDialog()">
                </app-dea-label>
              </div>
            </div>
            <div class="row m-0 p-0 gap-3" formArrayName="materie">
              <ng-container *ngFor="let item of subjectFormSubjects.controls; let i = index;" [formGroupName]="i">
                <div class="col-10 m-0 p-0">
                  <div class="row m-0 p-0 gap-3">
                    <div class="col m-0 p-0">
                      <input formControlName="dea_id" type="text" class="form-control dea-form-input"
                        placeholder="DEA-ID Materia" toUpperCase />
                    </div>
                    <div class="col m-0 p-0">
                      <input formControlName="nome" type="text" class="form-control dea-form-input"
                        placeholder="Nome Materia" toTitleCase />
                    </div>
                  </div>
                </div>
                <div class="col m-0 p-0 text-center">
                  <button class="m-0 ms-1 p-0" mat-icon-button color="primary" aria-label="Elimina" title="Elimina"
                    (click)="deleteItemInAt('materie',i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- SUBMIT -->
          <div class="v-spacer"></div>
          <div class="hstack">
            <div class="ms-auto">
              <button *ngIf="!posting" mat-flat-button color="secondary" class="fw-bold px-4 py-1 rounded-pill"
                (click)="backOnePage()">
                Annulla
              </button>
            </div>
            <div class="ms-2">
              <button *ngIf="!posting" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
                (click)="postData()">
                Salva
              </button>
              <button *ngIf="posting" mat-flat-button class="fw-bold px-4 py-1 rounded-pill" disabled>
                <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Salva
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>
