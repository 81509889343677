import { StatusGroup } from './status-group.model';

export class Status {
  icon?: string;
  title?: string;
  description?: string;
  footerText?: string;
  groupList?: StatusGroup[];
  date?: Date;

  constructor(json?: any) {
    if (!json) return;
    if (json['config']) this.icon = json['config']['icon'];
    if (json['config']) this.title = json['config']['title'];
    if (json['config']) this.description = json['config']['description'];
    if (json['config']) this.footerText = json['config']['footerText'];
    this.groupList = (json['publicGroupList'] || []).map(
      (g: any) => new StatusGroup(g)
    );
    this.date = json['date'] ? new Date(json['date']) : undefined;
  }
}
