import { EmailContact } from 'src/app/models/user/email-contact.model';
import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-email-box',
  templateUrl: './email-box.component.html',
  styleUrls: ['./email-box.component.scss'],
})
export class EmailBoxComponent implements OnInit {
  @Input() data?: EmailContact;

  label: string = 'E-Mail';
  enable: boolean = true;

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {
    if (this.data?.enable != undefined) this.enable = this.data.enable;
    if (this.data?.label) {
      this.label = ('E-Mail ' + this.data.label).toString().trim();
    }
  }

  copyInClipboard() {
    this.clipboard.copy(this.data?.address || '');
  }

  onDoubleClick() {
    if (this.data?.address) {
      const mailLink = document.createElement('a');
      mailLink.href = 'mailto:' + encodeURIComponent(this.data.address);
      mailLink.click();
    }
  }
}
