import { Tech } from './tech.model';

export class TechReturn extends Tech {
  items?: string[];
  status?: string;

  constructor(json?: any) {
    super(json);
    if (!json) return;
    this.items = json['items'];
    this.status = json['status'];
  }
}
