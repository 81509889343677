import { DeaNewVersionDialogComponent } from 'src/app/components/dea-pack/dea-new-version-dialog/dea-new-version-dialog.component';
import { ClientStorageService } from '../services/client-storage.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { VERSION } from 'src/environments/version';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';

@Injectable()
export class HashVersionInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}

  reloadPage(): void {
    window.location.reload();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clientStorageService = new ClientStorageService();
    const versionHash = clientStorageService.getVersionHash();
    if (environment.production.toString().trim() == 'false') {
      console.log(
        'Version Check => ',
        VERSION.hash === versionHash.toString().trim()
      );
    }
    if (VERSION.hash === versionHash.toString().trim()) return next.handle(req);
    else
      return this.dialog
        .open(DeaNewVersionDialogComponent, {
          maxWidth: '400px',
          minWidth: '400px',
          data: {},
        })
        .afterClosed()
        .pipe(() => next.handle(req));
  }
}
