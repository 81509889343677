import { UserDocumentType } from '../models/user/user-document/user-document-type.enum';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class UserDocumentsService {
  constructor(private http: HttpClient) {}

  getDocumentsListing(id?: string, documentsTypes?: UserDocumentType[]) {
    documentsTypes ??= [
      UserDocumentType.DOCUMENT,
      UserDocumentType.BES_ATTACHMENT,
      UserDocumentType.SPORT_ATTACHMENT,
      UserDocumentType.CERTIFICATE,
    ];
    return this.http.get<ApiResponse<any>>(
      API.userDocumentsListing(id, documentsTypes),
      {
        withCredentials: true,
      }
    );
  }

  getDocumentDetails(userId?: string, documentId?: string) {
    return this.http.get<ApiResponse<any>>(
      API.userDocumentDetails(userId, documentId),
      {
        withCredentials: true,
      }
    );
  }

  setDocumentCheckbox(userId?: string, documentId?: string, value?: boolean) {
    return this.http.patch<ApiResponse<any>>(
      API.setUserDocumentCheckbox(userId, documentId),
      {
        checkbox: value,
      },
      {
        withCredentials: true,
      }
    );
  }

  uploadDocument(formData: FormData, userId?: string) {
    return this.http.post(API.userDocumentUpload(userId), formData, {
      withCredentials: true,
      // reportProgress: true,
      // observe: 'events',
      // headers: headers,
    });
  }

  downloadDocument(userId?: string, documentId?: string) {
    return this.http.get(API.userDocumentDownload(userId, documentId), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  deleteDocument(userId?: string, documentId?: string) {
    return this.http.delete(API.userDocumentDelete(userId, documentId), {
      withCredentials: true,
    });
  }
}
