import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeaPdfDialogData } from 'src/app/constants/dialog-interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dea-pdf-dialog',
  templateUrl: './dea-pdf-dialog.component.html',
  styleUrls: ['./dea-pdf-dialog.component.scss'],
})
export class DeaPdfDialogComponent {
  refreshed: boolean = false;
  id: string = '';
  public pdfSCR = {
    withCredentials: true,
    url: '',
  };

  constructor(
    private _authService: AuthService,
    public dialogRef: MatDialogRef<DeaPdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeaPdfDialogData
  ) {
    if (data.id && data.url) {
      this.id = data.id;
      this.pdfSCR.url = data.url;
    }
  }

  onError(error: any) {
    if (error.status === 401 && !this.refreshed) {
      this.refreshed = true;
      this._authService
        .token()
        .subscribe()
        .add(() => {
          this.pdfSCR = {
            withCredentials: true,
            url: this.data.url,
          };
        });
    } else {
      console.error('Errore generale');
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
