import { UserDocumentType } from './user-document-type.enum';

export class UserDocument {
  id?: string;
  filename?: string;
  contentType?: string;
  length?: number;
  chunkSize?: number;
  uploadDate?: Date;

  mimetype?: string;
  originalname?: string;
  owner?: string;
  nome?: string;
  note?: string;
  checkbox?: boolean;
  type?: UserDocumentType;

  public static fromJson(json: any): UserDocument {
    const userDoc: UserDocument = new UserDocument();
    userDoc.id = json['_id'] || json['id'];
    userDoc.filename = json['filename'];
    userDoc.contentType = json['contentType'];
    userDoc.length = json['length'];
    userDoc.chunkSize = json['chunkSize'];
    userDoc.uploadDate = json['uploadDate']
      ? new Date(json['uploadDate'])
      : undefined;
    userDoc.mimetype = json['metadata']['mimetype'];
    userDoc.originalname = json['metadata']['originalname'];
    userDoc.owner = json['metadata']['owner'];
    userDoc.type = json['metadata']['type'];
    userDoc.nome = json['metadata']['nome'];
    userDoc.note = json['metadata']['note'];
    userDoc.checkbox =
      json['metadata']['checkbox'] === undefined
        ? undefined
        : (json['metadata']['checkbox'] + '')
            .toString()
            .toLowerCase()
            .trim() === 'true'
        ? true
        : false;
    return userDoc;
  }

  hasCheckbox(): boolean {
    return this.checkbox ? true : false;
  }

  isPDF(): boolean {
    return this.contentType?.includes('pdf') ? true : false;
  }

  getExt(): string {
    if (this.isPDF()) return 'pdf';
    if (this.contentType?.includes('png')) return 'png';
    if (this.contentType?.includes('jpg')) return 'jpg';
    if (this.contentType?.includes('jpeg')) return 'jpeg';
    return '';
  }
}
