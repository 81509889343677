import { ClientStorageService } from 'src/app/services/client-storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit {
  constructor(
    private _clientStorageService: ClientStorageService,
    private _authService: AuthService,
    private _router: Router
  ) {
    _clientStorageService.setVersionHash();
  }

  ngOnInit(): void {
    // ESEGUO IL CONTROLLO DELLA SESSIONE
    this._authService
      .session()
      .subscribe({
        next: () => this._clientStorageService.setSessionCheck(true),
        error: () => {
          this._clientStorageService.setSessionCheck(false);
          this._clientStorageService.setStatusWelcomeMessage(false);
        },
      })
      .add(() => {
        // IMPOSTO LA SESSIONE
        this.next();
      });
  }

  next() {
    const session = this._clientStorageService.getSessionCheck();
    setTimeout(() => {
      this._router.navigateByUrl(
        session.toString() === 'true' ? '' : AppPaths.LOGIN
      );
    }, 1500);
  }
}
