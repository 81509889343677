import { environment } from 'src/environments/environment';
import { Headquarter } from '../models/headquarter';
import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpParams,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
} from '@angular/common/http';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;
    req = req.clone({
      params: (req.params ? req.params : new HttpParams()).set(
        'h',
        localStorage.getItem('dea-headquarter') ?? Headquarter.MILANO.backend
      ),
    });

    return next.handle(req).pipe(
      tap({
        next: (event) =>
          (ok = event instanceof HttpResponse ? 'succeeded' : ''),
        error: (error) => (ok = 'failed'),
      }),
      finalize(() => {
        if (environment.production.toString().trim() == 'false') {
          console.log(
            'Logger Info: ',
            `${req.method} "${req.urlWithParams}" ${ok} in ${
              Date.now() - started
            } ms.`
          );
        }
      })
    );
  }
}
