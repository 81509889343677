<div class="container-dropdown mb-3">
  <input style="display:none;" id="{{dropdownMenuId}}" type="checkbox"
    [attr.disabled]="menuIsDisabled ? 'disabled' : null" />
  <div class="dropdown">
    <label class="dropbtn dea-radius" for="{{dropdownMenuId}}">
      <div class="d-flex align-items-center justify-content-between px-3" style="height: 100%; width: 100%;">
        <div class="text-capitalize">{{selectedItem}}</div>
        <div style="opacity: 0.8;">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
            class="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
          </svg>
        </div>
      </div>
    </label>
    <div class="dropdown-content dea-radius shadow">
      <p *ngFor="let item of items | keyvalue; let i = index" (click)="onClick(i)">
        <label class="text-capitalize" for="{{dropdownMenuId}}">{{item.value}}</label>
      </p>
    </div>
    <label for="{{dropdownMenuId}}" class="overlay"></label>
  </div>
</div>