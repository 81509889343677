import { TimetableReplacementsSummary } from 'src/app/models/calendar/replacement/timetable-replacements-summary';
import { BaseComponentService } from 'src/app/services/_app/base-component.service';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-timetable-replacements',
  templateUrl: './timetable-replacements.component.html',
  styleUrl: './timetable-replacements.component.scss',
})
export class TimetableReplacementsComponent implements OnInit {
  public summaries: TimetableReplacementsSummary[] = [];
  public pagination: ApiPagination = new ApiPagination();
  public loading: boolean = true;
  public error?: ApiError;

  private _baseComponentServices = inject(BaseComponentService);
  private _calendarService: CalendarService = inject(CalendarService);

  ngOnInit(): void {
    this.getData();
  }

  onPage(page: number) {
    this.pagination.page = page;
    this.getData();
  }

  async getData() {
    this.loading = true;
    this.error = undefined;
    this._calendarService
      .getTimetableReplacementsSummaryListing()
      .subscribe({
        next: (response) => {
          this.pagination = new ApiPagination(response.pagination);
          this.summaries = (response.data ?? []).map(
            (j: any) => new TimetableReplacementsSummary(j)
          );
        },
        error: (error) => {
          this.error = new ApiError(error);
          console.error('Replacements Summary Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  openSummaryPDF(id?: string): void {
    this._baseComponentServices.showPdfFromURL(
      API.getTimetableReplacementsSummaryPDF(id ?? 'ID-ERROR')
    );
  }

  async downloadSummaryPDF(id?: string): Promise<void> {
    this._baseComponentServices.downloadFile({
      url: API.getTimetableReplacementsSummaryPDF(id ?? 'ID-ERROR'),
      defaultFileName: 'Modulo Comunicazione Sostituzione Docenti.pdf',
    });
  }

  deleteSummary(id?: string) {
    this._baseComponentServices
      .confirmDeletion({
        message: 'Confermi di voler eliminare questo modulo?',
        details: id,
      })
      .subscribe((response) => {
        if (response && id) {
          this._calendarService.deleteReplacementsSummary(id).subscribe({
            next: (_) => this.getData(),
            error: (error) => {
              this._baseComponentServices.showErrorDialog({
                error: new ApiError(error),
              });
            },
          });
        }
      });
  }
}
