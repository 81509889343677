<app-dea-dialog-wrapper>
  <div class="fw-semibold text-center fs-4 mb-2">
    <app-dea-thumbnail-img size="64" url="{{avatarUrl}}"></app-dea-thumbnail-img>
  </div>
  <div class="text-center">{{data.message}}</div>
  <div class="text-center fw-lighter">{{data.details}}</div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">No</button>
    <button class="px-5 py-1" mat-flat-button color="primary" (click)="onConfirm()">Sì</button>
  </div>
</app-dea-dialog-wrapper>