<app-dea-widget-wrapper>
  <div class="d-flex justify-content-between">
    <div *ngIf="formDeaId" class="d-flex align-items-center">
      <div class="fw-semibold">{{formDeaId}}</div>
    </div>
    <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill" (click)="openFiltersDialog()">
      Filtri
    </button>
  </div>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <table class="table table-borderless m-0">
        <thead class="m-0">
          <tr class="dea-table-row-header m-0">
            <th scope="col">Proprietario</th>
            <th scope="col">Classe</th>
            <th scope="col">Materia</th>
            <th scope="col">Data Creazione</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let form of forms;" class="guest-attendance-listing-table-row dea-radius">
            <td class="col py-auto">
              <div *ngFor="let owner of form.getOwnersFullName()">
                <div>{{owner}}</div>
              </div>
            </td>
            <td class="col py-auto">
              <div *ngFor="let classe of form.getClasses()">
                <div>{{classe}}</div>
              </div>
            </td>
            <td class="col py-auto">
              <div *ngFor="let subject of form.getSubjects()">
                <div>{{subject}}</div>
              </div>
            </td>
            <td class="col py-auto">{{form.createdAt | date:'yyyy-MM-dd'}}</td>
            <td class="col py-auto">
              <mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="openPdfPreviewPDF(form.id)"
                title="Visualizza {{form?.title}}">
                visibility
              </mat-icon>
              <mat-icon class="ms-3 m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="documentFormPDF(form.id)"
                title="Download: {{form?.title}}">
                download
              </mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)">
      </app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>
