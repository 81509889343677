import { FiltersDialogConfigInterface } from '../components/ring-forms/filters-dialog/filters-dialog.component';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class RingFormsService {
  constructor(private http: HttpClient) {}

  getFormsSchemasListing() {
    return this.http.get<ApiResponse<any>>(API.formsSchemasListing(), {
      withCredentials: true,
    });
  }

  getFormsListingByType(p: {
    deaId: string;
    page: number;
    limit: number;
    filters?: FiltersDialogConfigInterface;
  }) {
    return this.http.get<ApiResponse<any>>(
      API.formsListingByType(p.deaId, p.page, p.limit, p.filters),
      {
        withCredentials: true,
      }
    );
  }

  getFormPDF(id: string) {
    return this.http.get(API.getFormPDF(id), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }
}
