<app-dea-widget-wrapper>
  <!-- LOADING / ERROR -->
  <div *ngIf="loading || isError" class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner *ngIf="loading && !isError" strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    <div *ngIf="!loading && isError">{{error?.message}}</div>
  </div>
  <!-- LOADING / ERROR -->
  <!-- DATA -->
  <ng-container *ngIf="!loading && !isError">
    <div class="hstack m-0 p-0">
      <div class="fw-semibold">Classe</div>
      <div class="ms-3 opacity-50">{{class?.dea_id}}</div>
      <button class="ms-auto d-flex align-items-center dea-icon-btn" mat-icon-button aria-label="Contatta per E-Mail"
        title="Contatta per E-Mail" color="secondary" (click)="emailLisitng()">
        <i class="bi bi-envelope-at"></i>
      </button>
      <button class="ms-2 d-flex align-items-center dea-icon-btn" mat-icon-button aria-label="Download classe in CSV"
        title="Download classe in CSV" color="secondary" (click)="downloadCSV(class?.id)">
        <i class="bi bi-filetype-csv"></i>
      </button>
      <button class="ms-2 d-flex align-items-center dea-icon-btn" mat-icon-button aria-label="Modifica classe"
        title="Modifica" routerLink="/modifica-classe/{{class?.id}}">
        <mat-icon class="mb-1">edit</mat-icon>
      </button>
      <button class="ms-2 d-flex align-items-center dea-icon-btn" mat-icon-button aria-label="Genera Classe Successiva"
        title="Genera Classe Successiva" routerLink="/classe/{{class?.id || 'ID-ERROR'}}/next-year-preview">
        <mat-icon class="mb-1">switch_access_shortcut_add</mat-icon>
      </button>
    </div>
    <div class="v-spacer"></div>
    <div class="hstack">
      <div class="fs-4 fw-semibold">{{class?.getName()}}</div>
    </div>
    <div class="hstack gap-3">
      <div>{{class?.anno_scolastico}}</div>
      <div class="vr"></div>
      <div>{{class?.sede?.frontend}}</div>
    </div>
    <div class="v-spacer"></div>
    <div class="row m-0 p-0">
      <div class="col m-0 p-0">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="100ms">
          <mat-tab label="Studenti">
            <div class="v-spacer"></div>
            <table class="table table-borderless m-0">
              <thead class="m-0">
                <tr class="dea-table-row-header m-0">
                  <th scope="col"></th>
                  <th scope="col-3">Cognome</th>
                  <th scope="col-3">Nome</th>
                  <th scope="col-auto">Ritardi</th>
                  <th scope="col-auto pe-3">Informazioni</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of (class?.studenti || []);" class="user-listing-table-row dea-radius">
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}" class="col">
                    <app-dea-thumbnail-img size="32" id="{{user.id}}"></app-dea-thumbnail-img>
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}"
                    class="col-3 py-auto {{(class?.rappresentanti || []).includes(user.id || '') ? 'fw-bold' : 'fw-normal'}}">
                    {{user.cognome}}
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}"
                    class="col-3 py-auto {{(class?.rappresentanti || []).includes(user.id || '') ? 'fw-bold' : 'fw-normal'}}">
                    {{user.nome}}
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}" class="col-auto py-auto">
                    {{user.totale_ritardi}}
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}" class="col-auto py-auto">
                    <div class="hstack gap-3 d-flex align-items-start">
                      <app-dea-chip-status [label]="'BES'"
                        [status]="user?.bisogni_educativi_speciali?.enable"></app-dea-chip-status>
                      <app-dea-chip-status [label]="'REL'" [status]="user?.religione"></app-dea-chip-status>
                      <app-dea-chip-status [label]="'ATL'" [status]="user?.atleta?.enable"></app-dea-chip-status>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Docenti">
            <div class="v-spacer"></div>
            <table class="table table-borderless m-0">
              <thead class="m-0">
                <tr class="dea-table-row-header m-0">
                  <th scope="col"></th>
                  <th scope="col-3">Cognome</th>
                  <th scope="col-3">Nome</th>
                  <th scope="col-auto">Materie</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of (class?.docenti || []) ;" class="user-listing-table-row dea-radius">
                  <td routerLink="/utente/{{user.docente?.id}}" title="{{user.docente?.getFullName()}}" class="col">
                    <app-dea-thumbnail-img size="32" id="{{user.docente?.id}}"></app-dea-thumbnail-img>
                  </td>
                  <td routerLink="/utente/{{user.docente?.id}}" title="{{user.docente?.getFullName()}}"
                    class="col-3 py-auto {{(class?.coordinatori || []).includes(user.docente?.id || '') ? 'fw-bold' : 'fw-normal'}}">
                    {{user.docente?.cognome}}
                  </td>
                  <td routerLink="/utente/{{user.docente?.id}}" title="{{user.docente?.getFullName()}}"
                    class="col-3 py-auto {{(class?.coordinatori || []).includes(user.docente?.id || '') ? 'fw-bold' : 'fw-normal'}}">
                    {{user.docente?.nome}}
                  </td>
                  <td routerLink="/utente/{{user.docente?.id}}" title="{{user.docente?.getFullName()}}"
                    class="col-auto py-auto gap-3 d-inline-flex">
                    {{getDisciplineListing(user.discipline)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Genitori / Tutori">
            <div class="v-spacer"></div>
            <table class="table table-borderless m-0">
              <thead class="m-0">
                <tr class="dea-table-row-header m-0">
                  <th scope="col"></th>
                  <th scope="col-3">Cognome</th>
                  <th scope="col-3">Nome</th>
                  <th scope="col-auto">Figli / Tutorati</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of (class?.genitori_tutori || []) ;" class="user-listing-table-row dea-radius">
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}" class="col">
                    <app-dea-thumbnail-img size="32" id="{{user.id}}"></app-dea-thumbnail-img>
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}" class="col-3 py-auto">
                    {{user.cognome}}
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}" class="col-3 py-auto">
                    {{user.nome}}
                  </td>
                  <td routerLink="/utente/{{user.id}}" title="{{user.getFullName()}}"
                    class="col-auto py-auto gap-3 d-inline-flex">
                    {{getFigliTutorati(user)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Programmazione">
            <div class="v-spacer"></div>
            <div class="col m-0 p-0 w-100">
              <div *ngFor="let file of (class?.files || [])" class="hstack m-0 p-0" style="height: 64px;">
                <div class="m-0 p-0 me-3">
                  <mat-icon *ngIf="file?.isPDF()" class="m-0 p-0 opacity-25">picture_as_pdf</mat-icon>
                  <mat-icon *ngIf="!file?.isPDF()" class="m-0 p-0 opacity-25">photo_library</mat-icon>
                </div>
                <div class="m-0 p-0">
                  <div class="fw-lighter opacity-75" style="font-size: 0.5rem;">
                    {{file?.uploadDate | date:'yyyy-MM-dd'}}
                  </div>
                  <div class="fw-normal">{{file?.originalname}}</div>
                </div>
                <div class="ms-auto fs-075r" (click)="onPreviewFile(file?.id, file?.isPDF())">
                  <mat-icon class="m-0 p-0 dea-icon-btn" title="Visualizza: {{file?.originalname}}">
                    visibility
                  </mat-icon>
                </div>
                <div class="ms-4 fs-075r" (click)="onDownloadFile(file?.id)">
                  <mat-icon class="m-0 p-0 dea-icon-btn" title="Download: {{file?.originalname}}">
                    download
                  </mat-icon>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Quadro Orario">
            <div class="v-spacer"></div>
            <div class="col m-0 p-0 w-100">
              <app-dea-timetable [classId]="classId" [startLoading]="true"></app-dea-timetable>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </ng-container>
  <!-- DATA -->
</app-dea-widget-wrapper>