<app-dea-dialog-wrapper>
  <div style="max-height: 480px; overflow-y: scroll;">
    <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
    <ng-template #loadingTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
      </div>
    </ng-template>
    <ng-template #loadingFalse>
      <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
      <ng-template #isErrorTrue>
        <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
          {{error?.message}}
        </div>
      </ng-template>
      <ng-template #isErrorFalse>
        <table class="table table-borderless m-0">
          <thead class="m-0">
            <tr class="dea-table-row-header m-0">
              <th scope="col-3">DEA-ID</th>
              <th scope="col-3">Anno</th>
              <th scope="col-3">Sezione</th>
              <th scope="col-3">Dipartimento</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let class of classes;" class="dea-table-row dea-table-row-hover-bg dea-radius">
              <td class="col-3 py-auto" title="{{class.getName()}}" (click)="onSelected(class.id)">
                {{class.dea_id}}
              </td>
              <td class="col-3 py-auto" title="{{class.getName()}}" (click)="onSelected(class.id)">
                {{class.anno}}
              </td>
              <td class="col-3 py-auto" title="{{class.getName()}}" (click)="onSelected(class.id)">
                {{class.sezione}}
              </td>
              <td class="col-3 py-auto" title="{{class.getName()}}" (click)="onSelected(class.id)">
                {{class.dipartimento}}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <app-listing-pagination-bar [paginationData]="pagination"
                (pageEmitter)="onPage($event)"></app-listing-pagination-bar>  -->
      </ng-template>
    </ng-template>
  </div>
</app-dea-dialog-wrapper>