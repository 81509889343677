import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent {
  private _router: Router = inject(Router);

  @Input() label: string = '';
  @Input() value: string = '0';
  @Input() link: string = '';

  get hasLinkAction(): boolean {
    return this.link != '';
  }

  onClick() {
    if (this.link != '') {
      this._router.navigateByUrl(this.link);
    }
  }
}
