import { Component, OnInit, HostListener, inject } from '@angular/core';
import { MainLoaderService } from './services/main-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _mainLoaderService = inject(MainLoaderService);
  public overMinSize: boolean = false;
  private _minWidth: number = 1274;
  private _minHeight: number = 679;

  get showMainLoader(): boolean {
    return this._mainLoaderService.show;
  }

  ngOnInit(): void {
    this.overMinSize =
      window.innerWidth > this._minWidth &&
      window.innerHeight > this._minHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    this.overMinSize =
      e.target.innerWidth > this._minWidth &&
      e.target.innerHeight > this._minHeight;
  }
}
