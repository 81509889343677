import { DeaConfirmDialogComponent } from '../dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { FeatureDialogComponent } from './feature-dialog/feature-dialog.component';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { FeatureService } from 'src/app/services/feature.service';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AuthService } from 'src/app/services/auth.service';
import { Feature } from 'src/app/models/feature.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 100;
  private _dialog: MatDialog = inject(MatDialog);
  private _authService: AuthService = inject(AuthService);
  private _featuresService: FeatureService = inject(FeatureService);
  public pagination?: ApiPagination;
  public isError: boolean = false;
  public loading: boolean = true;
  public features: Feature[] = [];
  public error?: ApiError;

  public selectedStatus: string = 'DONE';
  public statusTypes = [
    { frontend: 'Done', backend: 'DONE' },
    { frontend: 'In Progress', backend: 'IN PROGRESS' },
    { frontend: 'Pending', backend: 'PENDING' },
    // { frontend: 'Request', backend: 'REQUEST' },
  ];

  public createFeatures: boolean = false;
  public deleteFeatures: boolean = false;
  public editFeatures: boolean = false;
  public showBtn: boolean = false;

  ngOnInit(): void {
    this._authService.getSectionsAuth().subscribe({
      next: (data: any) => {
        this.createFeatures = data?.data?.createFeatures || false;
        this.deleteFeatures = data?.data?.deleteFeatures || false;
        this.editFeatures = data?.data?.editFeatures || false;
        this.showBtn = true;
      },
      error: (error) => console.error('Error:', error),
    });
    this.getData();
  }

  openDialog(feature?: Feature) {
    const dialogRef = this._dialog.open(FeatureDialogComponent, {
      maxWidth: '800px',
      minWidth: '800px',
      data: feature,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != undefined && result == true) this.getData();
    });
  }

  deleteFeature(feature?: Feature) {
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questa feature?',
        details: 'Attenzione: non sarà più possibile ripristinarla',
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult && feature?.id != undefined && feature.id != '') {
        this._featuresService.delete(feature.id).subscribe({
          next: (_: any) => this.getData(),
          error: (error: any) => console.error('Delete Feature Error: ', error),
        });
      }
    });
  }

  onSelectStatus(status: string) {
    this.selectedStatus = status;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._featuresService.page = this.page;
    this._featuresService.limit = this.limit;
    this._featuresService.status = this.selectedStatus;
    this._featuresService
      .getListing()
      .subscribe({
        next: (data: any) => {
          this.pagination = new ApiPagination(data?.pagination);
          this.features = data?.data?.map((json: any) => new Feature(json));
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Features Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
