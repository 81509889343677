<div class="hstack m-0 p-0" style="background-color: transparent; height: 64px;">
  <div class="m-0 p-0 me-3">
    <mat-icon *ngIf="document?.isPDF()" class="m-0 p-0 opacity-25">picture_as_pdf</mat-icon>
    <mat-icon *ngIf="!document?.isPDF()" class="m-0 p-0 opacity-25">photo_library</mat-icon>
  </div>
  <div class="m-0 p-0">
    <div class="fw-lighter opacity-75" style="font-size: 0.5rem;">
      {{document?.uploadDate | date:'yyyy-MM-dd'}}
    </div>
    <div class="fw-normal">{{document?.nome}}</div>
    <div class="fw-lighter fs-075r">{{document?.note}}</div>
  </div>
  <div class="ms-auto fs-075r">
    <mat-checkbox *ngIf="document?.type === 'DOCUMENT'" color="primary" [labelPosition]="'before'"
      [checked]="document?.checkbox" (change)="onChangeCheckbox($event)">
    </mat-checkbox>
  </div>
  <div class="ms-4 fs-075r">
    <mat-icon class="m-0 p-0 dea-icon-btn" (click)="onPreviewFile()" title="Visualizza: {{document?.nome}}">
      visibility
    </mat-icon>
  </div>
  <div class="ms-4 fs-075r">
    <mat-icon class="m-0 p-0 dea-icon-btn" (click)="onDownloadFile()" title="Download: {{document?.nome}}">
      download
    </mat-icon>
  </div>
  <div class="ms-4 fs-075r">
    <mat-icon class="m-0 p-0 dea-icon-btn" (click)="onDeleteDocument()" title="Elimina: {{document?.nome}}"
      color="warn">
      delete
    </mat-icon>
  </div>
</div>
