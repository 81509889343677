export enum DocumentFormItemType {
  'user-student',
  'user-parent',
  'user-teacher',
  'user-staff',
  'class',
  'subject',
  'text',
  'multiline-text',
  'select',
  'checkbox',
  'multi-checkbox',
  'radio',
  'goal',
  'object',
  'file',
  'static-text',
}

export class ItemDocumentForm {
  type?: DocumentFormItemType;
  input_type?: string;
  optional?: boolean;
  label?: string;
  description?: string;
  placeholder?: string;
  filetype?: string;
  step?: number;

  answer?: string;
  /*
   choices: [{
      value: { type: String, trim: true },
      label: { type: String, trim: true },
      description: { type: String, trim: true },
    }],
  */

  public static fromJson(json?: any): ItemDocumentForm {
    const newItem: ItemDocumentForm = new ItemDocumentForm();
    newItem.type = json['type'];
    newItem.input_type = json['input_type'];
    newItem.optional =
      json['optional'] === true || json['optional'] === false
        ? json['optional']
        : undefined;
    newItem.label = json['label'];
    newItem.description = json['description'];
    newItem.placeholder = json['placeholder'];
    newItem.filetype = json['filetype'];
    newItem.step = json['step'];

    newItem.answer = json['answer'];
    return newItem;
  }
}
