import { DeaSubjectsListingDialogComponent } from 'src/app/components/dea-pack/dea-subjects-listing-dialog/dea-subjects-listing-dialog.component';
import { DeaUserListingDialogComponent } from 'src/app/components/dea-pack/dea-user-listing-dialog/dea-user-listing-dialog.component';
import { DeaSingleBtnDialogComponent } from 'src/app/components/dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { Department, DepartmentUtils } from 'src/app/models/department.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { ToolService } from 'src/app/services/tool.service';
import { Component, Input, OnInit } from '@angular/core';
import { Headquarter } from 'src/app/models/headquarter';
import { UserType } from 'src/app/models/user/user-type';
import { Subject } from 'src/app/models/subject.model';
import { AppPaths } from 'src/app/constants/app-paths';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  DeaDropdownMenuEventData,
  DeaUserListingDialogComponentOutput,
  DeaSubjectsListingDialogComponentOutput,
} from 'src/app/constants/interfaces';

@Component({
  selector: 'app-user-personal-data-form',
  templateUrl: './user-personal-data-form.component.html',
  styleUrls: ['./user-personal-data-form.component.scss'],
})
export class UserPersonalDataFormComponent implements OnInit {
  public headquarters: string[] = Headquarter.getFrontendHeadqarters();
  public departments: string[] = DepartmentUtils.getDepartments();
  public userTypes: string[] = UserType.getFrontendUserTypes();
  public disableUserHeadquarterDropdownMenu: boolean = false;
  public disableUserTypeDropdownMenu: boolean = false;
  public selectedHeadquarterUserForm: string = '';
  public selectedDepartmentUserForm: string = '';
  public selecteduserTypeUserForm: string = '';

  public posting: boolean = false;
  public loading: boolean = false;
  public isError: boolean = false;
  public user: User = new User();
  public formTitle: string = '';
  public error?: ApiError;

  private allSpecialEducationalNeedsList = [
    'Discalculia',
    'Disgrafia',
    'Disortografia',
    'Dislessia',
    'ADHD',
    'Disturbi Misti',
  ];

  @Input() userId?: string;

  isEditingMode: boolean = false;

  userForm = new FormGroup({
    id: new FormControl(),
    nome: new FormControl(),
    secondo_nome: new FormControl(),
    cognome: new FormControl(),
    codice_fiscale: new FormControl(),
    nazionalita: new FormControl(),
    sesso: new FormControl(),
    data_di_nascita: new FormControl(),
    luogo_di_nascita: new FormControl(),
    provincia_di_nascita: new FormControl(),
    anno: new FormControl(),
    dipartimento: new FormControl(),
    religione: new FormControl(),
    atleta: new FormGroup({
      enable: new FormControl(),
      societa: new FormControl(),
      disciplina: new FormControl(),
    }),
    bisogni_educativi_speciali: new FormGroup({
      enable: new FormControl(),
      dsa: new FormControl(),
      diagnosi: new FormArray([]),
      note: new FormControl(),
    }),
    domicilio: new FormGroup({
      indirizzo: new FormControl(),
      civico: new FormControl(),
      cap: new FormControl(),
      citta: new FormControl(),
      provincia: new FormControl(),
    }),
    residenza: new FormGroup({
      indirizzo: new FormControl(),
      civico: new FormControl(),
      cap: new FormControl(),
      citta: new FormControl(),
      provincia: new FormControl(),
    }),
    contatti: new FormGroup({
      email: new FormArray([
        new FormGroup({
          etichetta: new FormControl(),
          indirizzo: new FormControl(),
          enable: new FormControl(),
        }),
      ]),
      telefono: new FormArray([
        new FormGroup({
          etichetta: new FormControl(),
          numero: new FormControl(),
          enable: new FormControl(),
        }),
      ]),
    }),
    genitori: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        cognome: new FormControl({ value: '', disabled: true }),
        secondo_nome: new FormControl({ value: '', disabled: true }),
      }),
    ]),
    figli: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        cognome: new FormControl({ value: '', disabled: true }),
        secondo_nome: new FormControl({ value: '', disabled: true }),
      }),
    ]),
    tutori: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        cognome: new FormControl({ value: '', disabled: true }),
        secondo_nome: new FormControl({ value: '', disabled: true }),
      }),
    ]),
    tutorati: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        cognome: new FormControl({ value: '', disabled: true }),
        secondo_nome: new FormControl({ value: '', disabled: true }),
      }),
    ]),
    materie: new FormArray([
      new FormGroup({
        _id: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        dea_id: new FormControl({ value: '', disabled: true }),
      }),
    ]),
  });

  public altri_bisogni_educativi_speciali: string = '';

  get atletaEnable(): boolean {
    return this.userForm.controls.atleta.get('enable')?.value || false;
  }

  get specialEducationalNeedsEnable(): boolean {
    return (
      this.userForm.controls.bisogni_educativi_speciali.get('enable')?.value ||
      false
    );
  }

  get userFormEmails() {
    return this.userForm.controls.contatti.controls.email as FormArray;
  }

  get userFormPhones() {
    return this.userForm.controls.contatti.controls.telefono as FormArray;
  }

  get userFormParents() {
    return this.userForm.controls.genitori as FormArray;
  }

  get userFormSons() {
    return this.userForm.controls.figli as FormArray;
  }

  get userFormTutors() {
    return this.userForm.controls.tutori as FormArray;
  }

  get userFormTutored() {
    return this.userForm.controls.tutorati as FormArray;
  }

  get subjectFormSubjects() {
    return this.userForm.controls.materie as FormArray;
  }

  onFormChange() {
    this.getUserFromForm();
  }

  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private location: Location,
    private _route: ActivatedRoute,
    public _toolService: ToolService,
    public _userService: UserService,
    public _clientStorageService: ClientStorageService
  ) {}

  ngOnInit(): void {
    this.resetFormArrays(true);
    this.user.sede = this._clientStorageService.getHeadquarter();
    this._route.queryParams.subscribe((queryParams) => {
      if (queryParams['type']) {
        this.user.type = UserType.fromString(queryParams['type']);
      }
    });
    this.setSelectedUserType();
    this.setSelectedUserHeadquarter();
    this._route.params.subscribe((params) => {
      if (params['id']) this.userId = params['id'];
      this.formTitle = this.userId
        ? 'Modifica Dati Personali:'
        : 'Nuovo Utente:';
      if (this.userId) {
        this.isEditingMode = true;
        this.getUserPersonalData();
      }
      this.setCfListener();
    });
  }

  getUserPersonalData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getDetail(this.userId)
      .subscribe({
        next: (data) => {
          if (data.data != undefined && (data.data as User)) {
            this.user = new User(data.data);
            this.userForm.get('id')?.setValue(this.user.id ?? undefined);
            this.disableUserHeadquarterDropdownMenu = true;
            this.disableUserTypeDropdownMenu = true;
            this.selecteduserTypeUserForm =
              this.user.type?.frontend || UserType.STUDENT.frontend;
            this.selectedHeadquarterUserForm =
              this.user.sede?.frontend || Headquarter.MILANO.frontend;
            this.setSelectedUserType();
            this.setSelectedUserHeadquarter();
            this.userForm
              .get('cognome')
              ?.setValue(this.user.cognome ?? undefined);
            this.userForm.get('nome')?.setValue(this.user.nome ?? undefined);
            this.userForm
              .get('secondo_nome')
              ?.setValue(this.user.secondo_nome ?? undefined);
            this.userForm
              .get('codice_fiscale')
              ?.setValue(this.user.codice_fiscale ?? undefined);
            this.userForm
              .get('nazionalita')
              ?.setValue(this.user.nazionalita ?? '');
            this.userForm.get('sesso')?.setValue(this.user.sesso ?? undefined);
            this.userForm
              .get('data_di_nascita')
              ?.setValue(
                this.user.data_di_nascita?.toISOString().slice(0, 10) ??
                  undefined
              );
            this.userForm
              .get('luogo_di_nascita')
              ?.setValue(this.user.luogo_di_nascita ?? undefined);
            this.userForm
              .get('provincia_di_nascita')
              ?.setValue(this.user.provincia_di_nascita ?? undefined);
            // STUDENT
            if (this.user.isStudent()) {
              this.userForm
                .get('anno')
                ?.setValue(this.user.anno?.toString() || undefined);
              this.selectedDepartmentUserForm =
                this.user.dipartimento || Department.SECONDARIA_DI_I_GRADO;

              this.userForm.controls.atleta
                .get('enable')
                ?.setValue(this.user.atleta?.enable ?? undefined);
              this.userForm.controls.atleta
                .get('societa')
                ?.setValue(this.user.atleta?.societa ?? undefined);
              this.userForm.controls.atleta
                .get('disciplina')
                ?.setValue(this.user.atleta?.disciplina ?? undefined);
              this.userForm
                .get('religione')
                ?.setValue(this.user.religione ?? false);
              if (
                this.user.bisogni_educativi_speciali &&
                this.user.bisogni_educativi_speciali?.enable?.toString() ==
                  'true'
              ) {
                this.userForm.controls.bisogni_educativi_speciali
                  .get('enable')
                  ?.setValue(
                    this.user.bisogni_educativi_speciali.enable ?? undefined
                  );
                this.userForm.controls.bisogni_educativi_speciali
                  .get('dsa')
                  ?.setValue(
                    this.user.bisogni_educativi_speciali.dsa ?? undefined
                  );
                if (
                  this.user.bisogni_educativi_speciali.diagnosi?.length != 0
                ) {
                  const diagnosiArray = <FormArray>(
                    this.userForm.controls.bisogni_educativi_speciali.get(
                      'diagnosi'
                    )
                  );
                  this.user.bisogni_educativi_speciali.diagnosi?.map((d) => {
                    diagnosiArray.push(new FormControl(d));
                  });
                  let diagnosi: string[] = [];
                  const allDisabilities =
                    this.allSpecialEducationalNeedsList.map((d) =>
                      d.toLowerCase()
                    );
                  diagnosiArray.getRawValue().map((d) => {
                    if (
                      !allDisabilities.includes(
                        d.toString().toLowerCase().trim()
                      )
                    ) {
                      diagnosi.push(d);
                    }
                  });
                  if (diagnosi.length != 0) {
                    this.altri_bisogni_educativi_speciali = diagnosi[0];
                  }
                }
                this.userForm.controls.bisogni_educativi_speciali
                  .get('note')
                  ?.setValue(
                    this.user.bisogni_educativi_speciali.note ?? undefined
                  );
              } else console.log('NO Bisogni Educativi Speciali');
              this.specialEducationalNeedsSwitchChanged();
            }
            // DOMICILIO
            this.userForm.controls.domicilio
              .get('indirizzo')
              ?.setValue(this.user.domicilio?.address ?? undefined);
            this.userForm.controls.domicilio
              .get('civico')
              ?.setValue(this.user.domicilio?.civic ?? undefined);
            this.userForm.controls.domicilio
              .get('cap')
              ?.setValue(this.user.domicilio?.cap ?? undefined);
            this.userForm.controls.domicilio
              .get('citta')
              ?.setValue(this.user.domicilio?.city ?? undefined);
            this.userForm.controls.domicilio
              .get('provincia')
              ?.setValue(this.user.domicilio?.province ?? undefined);
            // RESIDENZA
            this.userForm.controls.residenza
              .get('indirizzo')
              ?.setValue(this.user.residenza?.address ?? undefined);
            this.userForm.controls.residenza
              .get('civico')
              ?.setValue(this.user.residenza?.civic ?? undefined);
            this.userForm.controls.residenza
              .get('cap')
              ?.setValue(this.user.residenza?.cap ?? undefined);
            this.userForm.controls.residenza
              .get('citta')
              ?.setValue(this.user.residenza?.city ?? undefined);
            this.userForm.controls.residenza
              .get('provincia')
              ?.setValue(this.user.residenza?.province ?? undefined);
            // CONTACTS
            this.setItemsArrayInForm('email', this.user.contatti?.email || []);
            this.setItemsArrayInForm(
              'telefono',
              this.user.contatti?.phone || []
            );
            // PARENTS / SONS / TUTORS / TUTORED
            this.setItemsArrayInForm('genitori', this.user.genitori || []);
            this.setItemsArrayInForm('figli', this.user.figli || []);
            this.setItemsArrayInForm('tutori', this.user.tutori || []);
            this.setItemsArrayInForm('tutorati', this.user.tutorati || []);
            // TEACHERS
            this.setItemsArrayInForm('materie', this.user.materie || []);
          }
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Detail Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  setCfListener(): void {
    if (!this.isEditingMode) {
      this.userForm.get('codice_fiscale')?.valueChanges.subscribe((event) => {
        if (this.userForm.get('codice_fiscale')?.value.length == 16) {
          const codice_fiscale = this.userForm.get('codice_fiscale')?.value;
          this._toolService.getDataFromCF(codice_fiscale).subscribe({
            next: (data) => {
              try {
                if (data.data != undefined) {
                  let json: any = data.data;
                  json = json.dati;
                  this.userForm.get('nazionalita')?.setValue('Italiana');
                  this.userForm
                    .get('sesso')
                    ?.setValue(json.gender ?? undefined);
                  this.userForm
                    .get('data_di_nascita')
                    ?.setValue(
                      new Date(json.birthday)?.toISOString()?.slice(0, 10) ??
                        undefined
                    );
                  this.userForm
                    .get('luogo_di_nascita')
                    ?.setValue(json.birthplace ?? undefined);
                  this.userForm
                    .get('provincia_di_nascita')
                    ?.setValue(json.birthplaceProvincia ?? undefined);
                }
              } catch (e) {
                console.error('Data from CF Error: ', e);
              }
            },
            error: (error) => console.error('Data from CF Error: ', error),
          });
        }
      });
    }
  }

  setItemsArrayInForm(forArray: string, items: any[]) {
    if (['genitori', 'figli', 'tutori', 'tutorati'].includes(forArray)) {
      let formArray = <FormArray>this.userForm.get(forArray);
      if (formArray) {
        items.forEach((item) => {
          formArray.push(
            new FormGroup({
              _id: new FormControl({
                value: item.id,
                disabled: true,
              }),
              nome: new FormControl({
                value: item.nome,
                disabled: true,
              }),
              cognome: new FormControl({
                value: item.cognome,
                disabled: true,
              }),
              secondo_nome: new FormControl({
                value: item.secondo_nome,
                disabled: true,
              }),
            })
          );
        });
        formArray.value.forEach((item: any, i: number) => {
          if (item._id === undefined) formArray.removeAt(i);
        });
      }
    } else if (['email', 'telefono'].includes(forArray)) {
      let formArray = <FormArray>this.userForm.controls.contatti.get(forArray);
      items.forEach((item) => {
        formArray.push(
          forArray === 'email'
            ? new FormGroup({
                etichetta: new FormControl(item.label),
                indirizzo: new FormControl(item.address),
                enable: new FormControl(item.enable),
              })
            : new FormGroup({
                etichetta: new FormControl(item.label),
                numero: new FormControl(item.phone),
                enable: new FormControl(item.enable),
              })
        );
      });
    } else if (['materie'].includes(forArray)) {
      let formArray = <FormArray>this.userForm.get(forArray);
      items.forEach((item) => {
        formArray.push(
          new FormGroup({
            id: new FormControl({ value: item.id, disabled: true }),
            nome: new FormControl({ value: item.nome, disabled: true }),
            dea_id: new FormControl({ value: item.dea_id, disabled: true }),
          })
        );
      });
    } else console.log('Attenzione: chiave non valida!');
  }

  getUserFromForm(): User {
    let data: any = { ...this.userForm.value };
    data = Object.assign(data, {
      sede:
        Headquarter.fromString(this.selectedHeadquarterUserForm)?.backend ||
        Headquarter.MILANO.backend,
      __type:
        UserType.fromString(this.selecteduserTypeUserForm)?.backend ||
        UserType.STUDENT.backend,
    });
    if (data.__type === UserType.STUDENT.backend) {
      if (!data.atleta.enable) {
        delete data.atleta.societa;
        delete data.atleta.disciplina;
      }
      if (!data.bisogni_educativi_speciali.enable) {
        data.bisogni_educativi_speciali.tipo = '';
        data.bisogni_educativi_speciali.diagnosi = [];
        data.bisogni_educativi_speciali.note = '';
      }
      data = Object.assign(data, {
        dipartimento:
          DepartmentUtils.fromString(this.selectedDepartmentUserForm) ||
          DepartmentUtils.getDepartments()[0],
      });
    }
    const formUserData: User = new User(data);
    formUserData.genitori = this.userForm.controls['genitori'].value.map(
      (user: any) => new User(user)
    );
    formUserData.figli = this.userForm.controls['figli'].value.map(
      (user: any) => new User(user)
    );
    formUserData.tutori = this.userForm.controls['tutori'].value.map(
      (user: any) => new User(user)
    );
    formUserData.tutorati = this.userForm.controls['tutorati'].value.map(
      (user: any) => new User(user)
    );
    formUserData.materie = this.userForm.controls['materie'].value.map(
      (subject: any) => new Subject(subject)
    );
    if (this.userId) {
      formUserData.dea_id = this.user.dea_id;
      formUserData.archiviato = this.user.archiviato;
    }
    return formUserData;
  }

  postData() {
    this.posting = true;
    const newUserData: User = this.getUserFromForm();
    const method = this.userId
      ? this._userService.putUser(this.userId, newUserData.toJson(false))
      : this._userService.postUser(newUserData.toJson());
    method.subscribe({
      next: (data) => {
        this.user = new User(data?.data);
        this._router.navigateByUrl(AppPaths.UTENTE + '/' + this.user.id);
      },
      error: (error) => {
        this.error = new ApiError(error);
        const errorDialogRef = this.dialog.open(DeaSingleBtnDialogComponent, {
          maxWidth: '450px',
          minWidth: '450px',
          data: {
            error: this.error,
          },
          autoFocus: false,
          restoreFocus: false,
        });
        errorDialogRef.afterClosed().subscribe(() => (this.posting = false));
      },
    });
  }

  // FUNCS
  copyAddressFromTo(from: string, to: string) {
    const validStrings: string[] = ['domicilio', 'residenza'];
    if (validStrings.includes(from) && validStrings.includes(to)) {
      const labels = ['indirizzo', 'civico', 'cap', 'citta', 'provincia'];
      labels.forEach((label: string) => {
        this.userForm
          .get([to, label])
          ?.setValue(this.userForm.get([from, label])?.value);
      });
    } else console.log(`ERROR! | copyAddressFromTo(from: ${from}, to: ${to})`);
  }

  setSelectedUserType() {
    const t = this.user.type?.backend || this.userTypes[0];
    this.selecteduserTypeUserForm =
      UserType.fromString(t)?.frontend ||
      UserType.fromString(this.userTypes[0])!.frontend;
  }

  setSelectedUserHeadquarter() {
    const h = this.user.sede?.backend || this.headquarters[0];
    this.selectedHeadquarterUserForm =
      Headquarter.fromString(h)?.frontend ||
      Headquarter.fromString(this.headquarters[0])!.frontend;
  }

  setSelectedUserDepartment() {
    const d = this.user.dipartimento || this.departments[0];
    this.selectedDepartmentUserForm =
      DepartmentUtils.fromString(d) ||
      DepartmentUtils.fromString(this.departments[0])!;
  }

  onUserTypeChange(eventData: DeaDropdownMenuEventData) {
    if (eventData.id === 'userPersonalDataFormUserTypeDropdownMenu') {
      this.user.type = UserType.fromString(this.userTypes[eventData.index])!;
      this.setSelectedUserType();
      this.resetFormArrays();
    }
  }

  onUserHeadquarterChange(eventData: DeaDropdownMenuEventData) {
    if (eventData.id === 'userPersonalDataFormHeadquarterDropdownMenu') {
      this.user.sede = Headquarter.fromString(
        this.headquarters[eventData.index]
      )!;
      this.setSelectedUserHeadquarter();
    }
  }

  onUserDepartmentChange(eventData: DeaDropdownMenuEventData) {
    if (eventData.id === 'userPersonalDataFormDepartmentDropdownMenu') {
      this.user.dipartimento = DepartmentUtils.fromString(
        this.departments[eventData.index]
      )!;
      this.setSelectedUserDepartment();
    }
  }

  atletaSwitchChanged() {
    if (this.atletaEnable) {
      this.userForm.controls.atleta.get('societa')?.enable();
      this.userForm.controls.atleta.get('disciplina')?.enable();
    } else {
      this.userForm.controls.atleta.get('societa')?.disable();
      this.userForm.controls.atleta.get('disciplina')?.disable();
    }
  }

  specialEducationalNeedsSwitchChanged() {
    if (this.specialEducationalNeedsEnable) {
      this.userForm.controls.bisogni_educativi_speciali.get('dsa')?.enable();
      this.userForm.controls.bisogni_educativi_speciali.get('note')?.enable();
    } else {
      this.userForm.controls.bisogni_educativi_speciali.get('dsa')?.disable();
      this.userForm.controls.bisogni_educativi_speciali.get('note')?.disable();
    }
  }

  onChangeSpecialEducationalNeedsOther(event: any) {
    const value = event.target.value;
    const diagnosiArray = <FormArray>(
      this.userForm.controls.bisogni_educativi_speciali.get('diagnosi')
    ); // PRENDO L'ARRAY
    let diagnosi: string[] = [];
    const allDisabilities = this.allSpecialEducationalNeedsList.map((d) =>
      d.toLowerCase()
    );
    diagnosiArray.getRawValue().map((d) => {
      if (!allDisabilities.includes(d.toString().toLowerCase().trim())) {
        diagnosi.push(d);
      }
    });
    if (diagnosi.length == 0 && value.length != 0) {
      diagnosiArray.push(new FormControl(value)); // AGGIUNGO ALTRO
      this.altri_bisogni_educativi_speciali = value;
    } else {
      if (value.length == 0) {
        diagnosiArray.removeAt(
          diagnosiArray.getRawValue().indexOf(diagnosi[0])
        ); // ELIMINO ALTRO
        this.altri_bisogni_educativi_speciali = '';
      } else {
        diagnosiArray
          .at(diagnosiArray.getRawValue().indexOf(diagnosi[0]))
          .patchValue(value); // AGGIORNO ALTRO
        this.altri_bisogni_educativi_speciali = value;
      }
    }
  }

  specialEducationalNeedsIsChecked(item: string): boolean {
    if (this.allSpecialEducationalNeedsList.includes(item)) {
      const diagnosiArray = <FormArray>(
        this.userForm.controls.bisogni_educativi_speciali.get('diagnosi')
      );
      const diagnosiValues = diagnosiArray
        .getRawValue()
        .map((d: any) => d.toString().toLowerCase().trim());
      return diagnosiValues.includes(item.toString().toLowerCase().trim());
    } else {
      console.log('specialEducationalNeedsIsChecked() Error: wrong item!');
    }
    return false;
  }

  onChangeSpecialEducationalNeedsCheckbox(item: string) {
    if (this.allSpecialEducationalNeedsList.includes(item)) {
      let diagnosiArray = <FormArray>(
        this.userForm.controls.bisogni_educativi_speciali.get('diagnosi')
      );
      if (this.specialEducationalNeedsIsChecked(item)) {
        diagnosiArray.removeAt(diagnosiArray.getRawValue().indexOf(item));
      } else {
        diagnosiArray.push(new FormControl(item));
      }
    } else {
      console.log(
        'onChangeSpecialEducationalNeedsCheckbox() Error: wrong item!'
      );
    }
  }

  addContactFields(key: string) {
    if (['email', 'telefono'].includes(key)) {
      let formArray = <FormArray>this.userForm.controls.contatti.get(key);
      let formGroup;
      switch (key) {
        case 'email':
          formGroup = new FormGroup({
            etichetta: new FormControl(),
            indirizzo: new FormControl(),
            enable: new FormControl(true),
          });
          break;
        case 'telefono':
          formGroup = new FormGroup({
            etichetta: new FormControl(),
            numero: new FormControl(),
            enable: new FormControl(true),
          });
          break;
      }
      if (formGroup) formArray.push(formGroup);
    } else console.log('Attenzione: chiave non valida!');
  }

  deleteUserContactInAt(forArray: string, index: number) {
    if (['email', 'telefono'].includes(forArray)) {
      let formArray = <FormArray>this.userForm.controls.contatti.get(forArray);
      if (formArray) formArray.removeAt(index);
    } else console.log('Attenzione: chiave non valida!');
  }

  setLabelUserContactInAt(label: string, forArray: string, index: number) {
    if (
      ['Scolastica', 'Personale', 'Lavoro', 'Cellulare', 'Casa'].includes(label)
    ) {
      if (['email', 'telefono'].includes(forArray)) {
        let formArray = <FormArray>(
          this.userForm.controls.contatti.get(forArray)
        );
        let etichetta = formArray.at(index).get('etichetta');
        if (etichetta) {
          etichetta.setValue(label);
          if (label == 'Scolastica') this.setDeaEmailContactAtIndex(index);
        }
      } else console.log('Attenzione: chiave non valida!');
    } else console.log('Attenzione: etichetta non valida!');
  }

  setDomainInEmailContactAt(domain: string, index: number) {
    if (['ideamicis.it', 'gmail.com', 'hotmail.com'].includes(domain)) {
      let formArray = <FormArray>this.userForm.controls.contatti.get('email');
      let email = formArray.at(index).get('indirizzo');
      if (!(email?.value || '').includes('@')) {
        email?.setValue((email?.value || '') + '@' + domain);
      } else {
        email?.setValue((email?.value?.split('@')[0] || '') + '@' + domain);
      }
    } else console.log('Attenzione: dominio non valido!');
  }

  setDeaEmailContactAtIndex(index: number) {
    let lastName = this.userForm.controls.cognome;
    let firstName = this.userForm.controls.nome;
    if (
      lastName?.value ||
      firstName?.value ||
      lastName?.value?.length == 0 ||
      firstName?.value?.length == 0
    ) {
      let formArray = <FormArray>this.userForm.controls.contatti.get('email');
      let email = formArray.at(index).get('indirizzo');
      email?.setValue(
        `${lastName.value.toString().toLowerCase().trim()}.${firstName.value
          .toString()
          .toLowerCase()
          .trim()}@ideamicis.it`
      );
    } else {
      console.log(
        'Attenzione: mancano dei dati per generare la mail in automatico!'
      );
    }
  }

  openUsersDialog(type: string, forArray: string) {
    if (
      ['Parent', 'Student', 'Tutor'].includes(type) &&
      ['genitori', 'figli', 'tutori', 'tutorati'].includes(forArray)
    ) {
      const dialogSize = '1000px';
      const dialogRef = this.dialog.open(DeaUserListingDialogComponent, {
        maxWidth: dialogSize,
        minWidth: dialogSize,
        data: {
          type: type.toString().trim(),
        },
        autoFocus: false,
        restoreFocus: false,
      });
      dialogRef
        .afterClosed()
        .subscribe((userDialogResult: DeaUserListingDialogComponentOutput) => {
          if (userDialogResult) {
            let formArray;
            switch (forArray.toString().toLowerCase()) {
              case 'genitori':
                formArray = <FormArray>this.userForm.get('genitori');
                break;
              case 'figli':
                formArray = <FormArray>this.userForm.get('figli');
                break;
              case 'tutori':
                formArray = <FormArray>this.userForm.get('tutori');
                break;
              case 'tutorati':
                formArray = <FormArray>this.userForm.get('tutorati');
                break;
            }
            if (formArray) {
              formArray.push(
                new FormGroup({
                  _id: new FormControl({
                    value: userDialogResult.id,
                    disabled: true,
                  }),
                  nome: new FormControl({
                    value: userDialogResult.nome,
                    disabled: true,
                  }),
                  cognome: new FormControl({
                    value: userDialogResult.cognome,
                    disabled: true,
                  }),
                  secondo_nome: new FormControl({
                    value: userDialogResult.secondo_nome,
                    disabled: true,
                  }),
                })
              );
            }
          }
        });
    } else console.log('Attenzione: chiave non valida!');
  }

  openSubjectsDialog() {
    const dialogSize = '800px';
    const dialogRef = this.dialog.open(DeaSubjectsListingDialogComponent, {
      maxWidth: dialogSize,
      minWidth: dialogSize,
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef
      .afterClosed()
      .subscribe(
        (userDialogResult: DeaSubjectsListingDialogComponentOutput) => {
          if (userDialogResult) {
            let formArray = <FormArray>this.userForm.get('materie');
            if (formArray) {
              formArray.push(
                new FormGroup({
                  _id: new FormControl({
                    value: userDialogResult.id,
                    disabled: true,
                  }),
                  nome: new FormControl({
                    value: userDialogResult.nome,
                    disabled: true,
                  }),
                  dea_id: new FormControl({
                    value: userDialogResult.dea_id,
                    disabled: true,
                  }),
                })
              );
            }
          }
        }
      );
  }

  deleteItemInAt(forArray: string, index: number) {
    if (
      ['genitori', 'figli', 'tutori', 'tutorati', 'materie'].includes(forArray)
    ) {
      let formArray = <FormArray>this.userForm.get(forArray);
      if (formArray) formArray.removeAt(index);
    } else console.log('Attenzione: chiave non valida!');
  }

  resetFormArrays(complete: boolean = false) {
    const keys = ['genitori', 'figli', 'tutori', 'tutorati', 'materie'];
    keys.forEach((key) => {
      let formArray = <FormArray>this.userForm.get(key);
      const length = formArray.length;
      for (let i = length - 1; i >= 0; i--) formArray.removeAt(i);
    });
    if (complete) {
      const contactsKeys = ['email', 'telefono'];
      contactsKeys.forEach((key) => {
        let formArray = <FormArray>this.userForm.controls.contatti.get(key);
        const length = formArray.length;
        for (let i = length - 1; i >= 0; i--) formArray.removeAt(i);
      });
    }
  }

  backOnePage(): void {
    this.location.back();
  }

  formEnter(event: any): void {
    event.preventDefault();
  }
}
