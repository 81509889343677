import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-user-personal-data',
  templateUrl: './edit-user-personal-data.component.html',
  styleUrls: ['./edit-user-personal-data.component.scss'],
})
export class EditUserPersonalDataComponent implements OnInit {
  userId?: string;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.userId = params['id'];
    });
  }
}
