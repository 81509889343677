<app-dea-widget-wrapper>
  <div class="hstack">
    <div class="fw-semibold">{{userData?.type?.frontend}}</div>
    <div class="ms-3 opacity-50">{{userData?.dea_id}}</div>
    <div class="ms-auto"></div>
    <div class="ms-1">
      <button mat-icon-button aria-label="Crea credenziali temporanee" title="Crea credenziali temporanee"
        (click)="createTempCredentials()">
        <mat-icon class="mb-1">key</mat-icon>
      </button>
    </div>
    <div class="ms-2">
      <button mat-icon-button aria-label="Modifica utente" title="Modifica"
        routerLink="/modifica-anagrafica/{{userData?.id}}">
        <mat-icon class="mb-1">edit</mat-icon>
      </button>
    </div>
    <div class="ms-1">
      <button mat-icon-button aria-label="Elimina utente" title="Elimina" color="warn" disabled>
        <mat-icon class="mb-1">delete</mat-icon>
      </button>
    </div>
  </div>
  <br>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms"
    (selectedTabChange)="tabClick($event)">
    <mat-tab label="Anagrafica">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-user-thumbnail-and-controls [id]="userData?.id"></app-user-thumbnail-and-controls>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="true" [showTags]="true" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-master-data (userDataEmitter)="onUserData($event)"></app-user-master-data>
    </mat-tab>
    <mat-tab label="Ritardi" *ngIf="userData?.type?.backend == 'Student'">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="true" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-justifications-data [startLoading]="tabGroupStatus.ritardi" [userId]="userId" [user]="userData">
      </app-user-justifications-data>
    </mat-tab>
    <mat-tab label="Documenti">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="false" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-documents-data [startLoading]="tabGroupStatus.documenti" [userId]="userId"></app-user-documents-data>
    </mat-tab>
    <mat-tab label="Tech">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="false" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-tech-data [startLoading]="tabGroupStatus.tech" [userId]="userId"></app-user-tech-data>
    </mat-tab>
    <mat-tab label="Moduli">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="false" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-modules-data [startLoading]="tabGroupStatus.moduli" [userId]="userId"></app-user-modules-data>
    </mat-tab>
    <mat-tab label="PCTO" *ngIf="userData?.type?.backend == 'Student'">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="false" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-pcto-data [startLoading]="tabGroupStatus.pcto" [userId]="userId"
        [corso_sicurezza]="userData?.corso_sicurezza">
      </app-user-pcto-data>
    </mat-tab>
    <mat-tab label="Classi" *ngIf="userData?.type?.backend == 'Student' || userData?.type?.backend == 'Teacher'">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="true" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-classes-data [startLoading]="tabGroupStatus.classi" [userId]="userId"></app-user-classes-data>
    </mat-tab>
    <mat-tab label="Quadro Orario" *ngIf="userData?.type?.backend == 'Teacher'">
      <div class="v-spacer"></div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col-auto m-0 p-0">
          <app-dea-thumbnail-img size="64" id="{{userData?.id}}"></app-dea-thumbnail-img>
        </div>
        <div class="col m-0 p-0 ps-3">
          <app-user-base-data [showFullData]="true" [user]="userData"></app-user-base-data>
        </div>
      </div>
      <app-user-timetable [startLoading]="tabGroupStatus.timetable" [teacherId]="userData?.id"></app-user-timetable>
    </mat-tab>
  </mat-tab-group>
</app-dea-widget-wrapper>
