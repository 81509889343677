<ng-template *ngIf="address; then addressTrue else addressFalse"></ng-template>
<ng-template #addressTrue>
  <app-details-divider></app-details-divider>
  <div *ngIf="addressTopLabel" class="ms-3 hstack my-4 gap-3">
    <app-dea-label [label]="addressTopLabel"></app-dea-label>
  </div>
  <div class="ms-3 hstack mb-4 gap-3">
    <app-data-box [label]="'Città'" [data]="address?.city"></app-data-box>
    <app-data-box [label]="'Provincia'" [data]="address?.province"></app-data-box>
  </div>
  <div class="ms-3 hstack mb-4 gap-3">
    <app-data-box [label]="'Indirizzo'" [data]="address?.address"></app-data-box>
    <app-data-box [label]="'Civico'" [data]="address?.civic"></app-data-box>
    <app-data-box [label]="'CAP'" [data]="address?.cap"></app-data-box>
  </div>
</ng-template>
<ng-template #addressFalse></ng-template>