import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-status-icon',
  templateUrl: './dea-status-icon.component.html',
  styleUrls: ['./dea-status-icon.component.scss'],
})
export class DeaStatusIconComponent {
  @Input() enable?: boolean = undefined;
  @Input() enabledTitle: string = '';
  @Input() disabledTitle: string = '';
}
