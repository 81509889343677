import { CalendarTimetableEventBlockTypes } from '../models/calendar/calendar-timetable-event-block-types.enum';
import { CalendarEventTypes } from '../models/calendar/calendar-event-types.enum';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent } from '../models/calendar/calendar-event.model';
import { ApiResponse } from '../models/api/api-response.model';
import { Headquarter } from '../models/headquarter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { API } from '../constants/api';

export interface timezoneOption {
  name: string;
  value: string;
}

export interface CalendarColorsConfig {
  name: string;
  hex: string;
  rgb: number[];
}

export interface CalendarTimetableGridConfig {
  headquarter: string;
  time: { min: string; max: string; minutes: number };
  blocks: { id: string; from: string; to: string; departments: string[] }[];
}

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  public getDefaultTZ = (): string => moment.tz.guess(true);
  public defaultColor: string = '#32ADE6FF';
  public timeZones: timezoneOption[] = [];

  public colors: CalendarColorsConfig[] = [];
  public timetableGridConfigs?: CalendarTimetableGridConfig;

  constructor(private http: HttpClient) {
    this.timeZones = this._getTimeZones();
    if (this.colors.length === 0) {
      this.getColorsConfigs().subscribe({
        next: (response) => {
          this.colors = response.data;
        },
        error: (err) => {
          console.error('CalendarService getColorsConfigs Error:', err);
        },
      });
    }
    if (!this.timetableGridConfigs) this.setTimetableGridConfigs();
  }

  setTimetableGridConfigs(): void {
    this.getTimetableGridConfigs().subscribe({
      next: (response) => {
        this.timetableGridConfigs = response.data;
      },
      error: (err) => {
        console.error('CalendarService getTimetableGridConfigs Error:', err);
      },
    });
  }

  private _getTimeZones(): timezoneOption[] {
    return moment.tz.names().map((tz) => {
      return {
        name:
          tz.split('_').join(' ') + ' [GMT ' + moment.tz(tz).format('Z') + ']',
        value: tz,
      };
    });
  }

  createCalendarEventFormGroup(
    event?: CalendarEvent,
    forceEventType?: CalendarEventTypes | undefined
  ): FormGroup<any> {
    switch (forceEventType) {
      case CalendarEventTypes.TIMETABLE_EVENT:
        event ??= new CalendarEvent({
          __type: CalendarEventTypes.TIMETABLE_EVENT,
        });
        break;
      default:
        event ??= new CalendarEvent({
          __type: CalendarEventTypes.EVENT,
        });
        break;
    }

    let formGroup = new FormGroup({
      title: new FormControl(event.title, Validators.required),
      description: new FormControl(event.description),
      start: new FormControl(
        event.start
          ? moment.tz(event?.start, event.tz).format('YYYY-MM-DD HH:mm:ss')
          : moment().add(1, 'day').format('YYYY-MM-DD HH:') + '00:00',
        Validators.required
      ),
      end: new FormControl(
        event.end
          ? moment.tz(event?.end, event.tz).format('YYYY-MM-DD HH:mm:ss')
          : moment().add(1, 'day').add(1, 'hour').format('YYYY-MM-DD HH:') +
            '00:00',
        Validators.required
      ),
      tz: new FormControl(event.tz || this.getDefaultTZ(), Validators.required),
      location: new FormControl(event.location),
      room: new FormControl(event.room),
      meeting_link: new FormControl(event.meetingLink),
      owner: new FormControl(event.owner),
      color: new FormControl(event.color ?? this.defaultColor),
      note: new FormControl(event.note),
      __type: new FormControl(event.__type),
      isRecursiveEvent: new FormControl(event.recurrence !== undefined),
      recurrence: new FormGroup({
        frequency: new FormControl(event.recurrence?.frequency),
        interval: new FormControl(event.recurrence?.interval),
        endOption: new FormControl(
          event.recurrence?.endDate !== undefined
            ? 'date'
            : event.recurrence?.count !== undefined
            ? 'count'
            : 'never'
        ),
        endDate: new FormControl({
          value: event.recurrence?.endDate
            ? moment
                .tz(event.recurrence?.endDate, event.tz)
                .format('YYYY-MM-DD')
            : undefined,
          disabled: event.recurrence?.endDate === undefined,
        }),
        count: new FormControl({
          value: event.recurrence?.count,
          disabled: event.recurrence?.count === undefined,
        }),
        byDay: new FormArray(
          event.recurrence?.byDay
            ? (event.recurrence?.byDay ?? []).map(
                (value) => new FormControl(value)
              )
            : []
        ),
        // byMonthDay: new FormArray(
        //   event.recurrence?.byMonthDay
        //     ? (event.recurrence?.byMonthDay ?? []).map(
        //         (value) => new FormControl(value)
        //       )
        //     : []
        // ),
        // bySetPosition: new FormControl(event.recurrence?.bySetPosition),
      }),
      // TIMETABLE-EVENT
      headquarter: new FormControl(
        event.headquarter?.frontend ?? Headquarter.MILANO.frontend
      ),
      timetable_block: new FormControl(
        event.timetableBlock ?? CalendarTimetableEventBlockTypes.LEZIONE
      ),
      teachers: new FormArray(
        event.__type === CalendarEventTypes.TIMETABLE_EVENT && event.teachers
          ? (event.teachers ?? []).map((value) => {
              return new FormGroup({
                _id: new FormControl({ value: value.id, disabled: true }),
                nome: new FormControl({ value: value.nome, disabled: true }),
                secondo_nome: new FormControl({
                  value: value.secondo_nome,
                  disabled: true,
                }),
                cognome: new FormControl({
                  value: value.cognome,
                  disabled: true,
                }),
              });
            })
          : []
      ),
      subject: new FormGroup({
        _id: new FormControl({ value: event.subject?.id, disabled: true }),
        nome: new FormControl({ value: event.subject?.nome, disabled: true }),
        dea_id: new FormControl({
          value: event.subject?.dea_id,
          disabled: true,
        }),
      }),
      classes: new FormArray(
        event.__type === CalendarEventTypes.TIMETABLE_EVENT && event.teachers
          ? (event.classes ?? []).map(
              (value) =>
                new FormGroup({
                  _id: new FormControl({ value: value.id, disabled: true }),
                  department: new FormControl({
                    value: value.dipartimento,
                    disabled: true,
                  }),
                  section: new FormControl({
                    value: value.sezione,
                    disabled: true,
                  }),
                  year: new FormControl({
                    value: value.anno,
                    disabled: true,
                  }),
                })
            )
          : []
      ),
      out: new FormControl(event.out ?? false),
    });

    formGroup.get('isRecursiveEvent')?.valueChanges.subscribe((value: any) => {
      const byDayArray = <FormArray>formGroup.controls.recurrence.get('byDay');
      if (value === true) {
        formGroup.controls.recurrence.get('interval')?.setValue(1);
        formGroup.controls.recurrence.get('frequency')?.setValue('WEEKLY');
        byDayArray.push(new FormControl(this.getCurrentByDayValue()));
        formGroup.controls.recurrence
          .get('endOption')
          ?.setValue(
            event.recurrence?.endDate !== undefined
              ? 'date'
              : event.recurrence?.count !== undefined
              ? 'count'
              : 'never'
          );
        event.recurrence?.endDate === undefined
          ? formGroup.controls.recurrence.get('endDate')?.disable()
          : formGroup.controls.recurrence.get('endDate')?.enable();
        formGroup.controls.recurrence
          .get('endDate')
          ?.setValue(
            event.recurrence?.endDate
              ? moment
                  .tz(event.recurrence?.endDate, event.tz)
                  .format('YYYY-MM-DD')
              : undefined
          );
        event.recurrence?.count === undefined
          ? formGroup.controls.recurrence.get('count')?.disable()
          : formGroup.controls.recurrence.get('count')?.enable();
        formGroup.controls.recurrence
          .get('count')
          ?.setValue(
            event.recurrence?.count ? event.recurrence?.count : undefined
          );
      } else {
        formGroup.controls.recurrence.get('interval')?.setValue(undefined);
        formGroup.controls.recurrence.get('frequency')?.setValue(undefined);
        formGroup.controls.recurrence.get('endOption')?.setValue('never');
        byDayArray.clear();
      }
    });

    formGroup.controls.recurrence
      .get('endOption')
      ?.valueChanges.subscribe((value: any) => {
        switch (value) {
          case 'never':
            formGroup.controls.recurrence.get('count')?.setValue(undefined);
            formGroup.controls.recurrence.get('endDate')?.setValue(undefined);
            formGroup.controls.recurrence.get('count')?.disable();
            formGroup.controls.recurrence.get('endDate')?.disable();
            break;
          case 'date':
            formGroup.controls.recurrence.get('count')?.disable();
            formGroup.controls.recurrence.get('endDate')?.enable();
            formGroup.controls.recurrence.get('count')?.setValue(undefined);
            formGroup.controls.recurrence
              .get('endDate')
              ?.setValue(moment().add(1, 'month').format('YYYY-MM-DD'));
            break;
          case 'count':
            formGroup.controls.recurrence.get('count')?.enable();
            formGroup.controls.recurrence.get('endDate')?.disable();
            formGroup.controls.recurrence.get('count')?.setValue(1);
            formGroup.controls.recurrence.get('endDate')?.setValue(undefined);
            break;
        }
      });

    return formGroup;
  }

  getCurrentByDayValue(): string {
    const currentLocale = moment.locale();
    moment.locale('en');
    const currentByDayValue = moment().format('dd').toUpperCase();
    moment.locale(currentLocale);
    return currentByDayValue;
  }

  postEvent(data?: any) {
    return this.http.post<ApiResponse<any>>(API.calendarEvent(), data, {
      withCredentials: true,
    });
  }

  putEvent(eventId?: string, data?: any) {
    return this.http.put<ApiResponse<any>>(
      API.singleCalendarEvent(eventId) + '?test=test',
      data,
      { withCredentials: true }
    );
  }

  eventDetails(eventId?: string) {
    return this.http.get<ApiResponse<any>>(API.singleCalendarEvent(eventId), {
      withCredentials: true,
    });
  }

  deleteEvent(eventId?: string) {
    return this.http.delete<ApiResponse<any>>(
      API.singleCalendarEvent(eventId),
      { withCredentials: true }
    );
  }

  timetable(p: {
    classId: string | undefined;
    teacherId: string | undefined;
    from: moment.Moment | undefined;
    to: moment.Moment | undefined;
  }) {
    return this.http.get<ApiResponse<any>>(API.timetable(p), {
      withCredentials: true,
    });
  }

  getColorsConfigs() {
    return this.http.get<ApiResponse<any>>(API.calendarColorsConfigs(), {
      withCredentials: false,
    });
  }

  getTimetableGridConfigs() {
    return this.http.get<ApiResponse<any>>(API.calendarTimetableGridConfigs(), {
      withCredentials: false,
    });
  }

  getGeneralTimetableData() {
    const url = API.calendarGeneralTimetable({ pdf: false });
    return this.http.get<ApiResponse<any>>(url, {
      withCredentials: true,
    });
  }

  getGeneralTimetablePDF() {
    const url = API.calendarGeneralTimetable({ pdf: true });
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  getSlotIDFrom(event: CalendarEvent): string | undefined {
    if (event.__type != CalendarEventTypes.TIMETABLE_EVENT) return undefined;
    if (this.timetableGridConfigs == undefined) return undefined;
    const eventTime = moment.tz(event.start, event.tz).format('HH:mm');
    const result = this.timetableGridConfigs.blocks.find(
      (block) => eventTime >= block.from && eventTime < block.to
    );
    if (result == undefined) return undefined;
    return result.id;
  }

  getTimetableReplacementsSummaryListing() {
    return this.http.get<ApiResponse<any>>(API.timetableReplacementsSummary(), {
      withCredentials: true,
    });
  }

  getTimetableReplacementOptionsListing(p: {
    eventId: string | undefined;
    start: moment.Moment | undefined;
    end: moment.Moment | undefined;
    tz: string | undefined;
    exclude: string[] | undefined;
  }) {
    return this.http.get<ApiResponse<any>>(
      API.timetableReplacementOptionsListing(p),
      { withCredentials: true }
    );
  }

  getReplacementsSummary(summeryId?: string) {
    return this.http.get<ApiResponse<any>>(
      API.timetableReplacementsSummaryDetails(summeryId),
      { withCredentials: true }
    );
  }

  postReplacementsSummary(data: any) {
    return this.http.post<ApiResponse<any>>(
      API.timetableReplacementsSummary(),
      data,
      { withCredentials: true }
    );
  }

  patchReplacementsSummary(summeryId: string, data: any) {
    return this.http.patch<ApiResponse<any>>(
      API.timetableReplacementsSummaryDetails(summeryId),
      data,
      { withCredentials: true }
    );
  }

  deleteReplacementsSummary(summeryId: string) {
    return this.http.delete<ApiResponse<any>>(
      API.timetableReplacementsSummaryDetails(summeryId),
      { withCredentials: true }
    );
  }
}
