import { ClassTeacher } from '../models/class/class-teacher';
import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user/user.model';

@Pipe({
  name: 'fromClassTeachersToUsers',
})
export class FromClassTeachersToUsersPipe implements PipeTransform {
  transform(value: ClassTeacher[], ...args: unknown[]): User[] {
    let users: User[] = [];
    value.map((cT: ClassTeacher) => {
      if (cT.docente) users.push(cT.docente);
    });
    return users;
  }
}
