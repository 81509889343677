import { RingFormsService } from 'src/app/services/ring-forms.service';
import { RingForm } from 'src/app/models/ring-form/ring-form.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, OnInit, inject } from '@angular/core';
import { AppPaths } from 'src/app/constants/app-paths';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schemas-listing',
  templateUrl: './schemas-listing.component.html',
  styleUrls: ['./schemas-listing.component.scss'],
})
export class SchemasListingComponent implements OnInit {
  private _ringFormsService: RingFormsService = inject(RingFormsService);
  private _router: Router = inject(Router);
  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;

  public schemas: RingForm[] = [];

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._ringFormsService
      .getFormsSchemasListing()
      .subscribe({
        next: (data) => {
          this.schemas = (data.data ?? []).map(
            (json: any) => new RingForm(json)
          );
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('RingForms Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  goToModuloListing(index: number): void {
    const dea_id = this.schemas.at(index)?.dea_id;
    if (dea_id) {
      this._router.navigateByUrl(AppPaths.MODULO_LISTING + '/' + dea_id);
    }
  }

  async changeCreationStatus(id?: string, status?: boolean) {
    if (id && status !== undefined && status != null) {
      this._ringFormsService.setCreationStatus(id, status).subscribe({
        next: (response) => {
          const updatedSchema = new RingForm(response.data);
          const index = this.schemas.map((s) => s.id).indexOf(updatedSchema.id);
          if (index > -1) {
            this.schemas.at(index)!.allowed = updatedSchema.allowed;
          } else this.getData();
        },
        error: (err) => {
          console.error('SetCreationStatus - Error:', err);
        },
      });
    }
  }
}
