import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { LoggingInterceptor } from './web/logger-interceptor';

import { AppComponent } from './app.component';
import { ScreenSizeManagerComponent } from './components/screen-size-manager/screen-size-manager.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { SessionComponent } from './pages/session/session.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HomepageComponent } from './components/homepage/homepage.component';
import { StudentsComponent } from './components/students/students.component';
import { TeachersComponent } from './components/teachers/teachers.component';
import { ParentsComponent } from './components/parents/parents.component';
import { ClassesComponent } from './components/classes/classes.component';
import { UserComponent } from './components/user/user.component';
import { AuthInterceptor } from './web/auth-interceptor';
import { StatisticsComponent } from './components/homepage/statistics/statistics.component';
import { CounterComponent } from './components/homepage/statistics/counter/counter.component';
import { CountUpDirective } from './directives/count-up.directive';
import { ListingTopBarComponent } from './components/listing/_listing-top-bar/listing-top-bar.component';
import { AttendancesListingComponent } from './components/listing/attendances-listing/attendances-listing.component';
import { ClassesListingComponent } from './components/listing/classes-listing/classes-listing.component';
import { UsersListingComponent } from './components/listing/users-listing/users-listing.component';
import { ListingPaginationBarComponent } from './components/listing/_listing-pagination-bar/listing-pagination-bar.component';
import { EmailLabelComponent } from './components/label/email-label/email-label.component';
import { PhoneLabelComponent } from './components/label/phone-label/phone-label.component';
import { DataBoxComponent } from './components/label/data-box/data-box.component';
import { EmailBoxComponent } from './components/label/email-box/email-box.component';
import { PhoneBoxComponent } from './components/label/phone-box/phone-box.component';
import { UserAddressComponent } from './components/user/blocks/user-address/user-address.component';
import { UserBaseDataComponent } from './components/user/blocks/user-base-data/user-base-data.component';
import { UserBirthDataComponent } from './components/user/blocks/user-birth-data/user-birth-data.component';
import { DetailsDividerComponent } from './components/user/blocks/details-divider/details-divider.component';
import { UserRelationshipsComponent } from './components/user/blocks/user-relationships/user-relationships.component';
import { ClassCellComponent } from './components/listing/classes-listing/class-cell/class-cell.component';
import { EditUserPersonalDataComponent } from './components/user/user-form/edit-user-personal-data/edit-user-personal-data.component';
import { EditUserTechnologyDataComponent } from './components/user/user-form/edit-user-technology-data/edit-user-technology-data.component';
// PIPES
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { ShorteningSchoolYearPipe } from './pipes/shortening-school-year.pipe';
import { ThumbnailCachePipe } from './pipes/thumbnail-cache.pipe';
import { FilesizePipe } from './pipes/filesize.pipe';
import { GetMonthNamePipe } from './pipes/get-month-name.pipe';
//
import { UserPersonalDataFormComponent } from './components/user/user-form/user-personal-data-form/user-personal-data-form.component';
import { NewUserPersonalDataComponent } from './components/user/user-form/new-user-personal-data/new-user-personal-data.component';
import { DeaDropdownMenuComponent } from './components/dea-pack/dea-dropdown-menu/dea-dropdown-menu.component';
import { DeaLabelComponent } from './components/dea-pack/dea-label/dea-label.component';
import { InputToUpperCaseDirective } from './directives/input-to-upper-case.directive';
import { InputToLowerCaseDirective } from './directives/input-to-lower-case.directive';
import { InputToTitleCaseDirective } from './directives/input-to-title-case.directive';
import { AttendancesComponent } from './components/register/attendances/attendances.component';
import { AttendanceDetailsComponent } from './components/register/attendances/attendance-details/attendance-details.component';
import { GuestAttendanceDetailsComponent } from './components/register/guests-attendances/guest-attendance-details/guest-attendance-details.component';
import { GuestsAttendancesComponent } from './components/register/guests-attendances/guests-attendances.component';
import { GuestsAttendanceLisitngComponent } from './components/listing/guests-attendance-lisitng/guests-attendance-lisitng.component';
import { GuestCardComponent } from './components/register/guests-attendances/guest-card/guest-card.component';
import { AttendanceInOutComponent } from './components/register/attendance-in-out/attendance-in-out.component';
import { GuestAttendanceSignatureComponent } from './components/register/guests-attendances/guest-attendance-signature/guest-attendance-signature.component';
import { DeaConfirmDialogComponent } from './components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { DeaUserConfirmDialogComponent } from './components/dea-pack/dea-user-confirm-dialog/dea-user-confirm-dialog.component';
import { ClassDetailsComponent } from './components/classes/class-details/class-details.component';
import { ClassFormComponent } from './components/classes/class-form/class-form.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomUsersListingComponent } from './components/listing/custom-users-listing/custom-users-listing.component';
import { FromClassTeachersToUsersPipe } from './pipes/from-class-teachers-to-users.pipe';
import { DeaUserListingDialogComponent } from './components/dea-pack/dea-user-listing-dialog/dea-user-listing-dialog.component';
import { DeaSingleBtnDialogComponent } from './components/dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { UserMasterDataComponent } from './components/user/sections/user-master-data/user-master-data.component';
import { UserTechDataComponent } from './components/user/sections/user-tech-data/user-tech-data.component';
import { UserModulesDataComponent } from './components/user/sections/user-modules-data/user-modules-data.component';
import { UserPctoDataComponent } from './components/user/sections/user-pcto-data/user-pcto-data.component';
import { UserDocumentsDataComponent } from './components/user/sections/user-documents-data/user-documents-data.component';
import { UserDocumentRowComponent } from './components/user/blocks/user-document-row/user-document-row.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { UserDocumentUploadDialogComponent } from './components/user/sections/user-documents-data/user-document-upload-dialog/user-document-upload-dialog.component';
import { HashVersionInterceptor } from './web/hash-version-interceptor';
import { DeaNewVersionDialogComponent } from './components/dea-pack/dea-new-version-dialog/dea-new-version-dialog.component';
import { LimboComponent } from './components/limbo/limbo.component';
import { LimboStudentsListingComponent } from './components/listing/limbo-students-listing/limbo-students-listing.component';
import { DeaThumbnailImgComponent } from './components/dea-pack/dea-thumbnail-img/dea-thumbnail-img.component';
import { TemporaryCredentialsComponent } from './pages/tmp/temporary-credentials/temporary-credentials.component';
import { TemporaryLoginComponent } from './pages/tmp/temporary-login/temporary-login.component';
import { DeaWelcomeDialogComponent } from './components/dea-pack/dea-welcome-dialog/dea-welcome-dialog.component';
import { DocumentsFormsComponent } from './components/documents-forms/documents-forms.component';
import { DocumentFormCellComponent } from './components/listing/documents-forms-listing/document-form-cell/document-form-cell.component';
import { DocumentsFormsDeaIdListingComponent } from './components/listing/documents-forms-dea-id-listing/documents-forms-dea-id-listing.component';
import { DeaPdfDialogComponent } from './components/dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClassYearsListingComponent } from './components/listing/classes-listing/class-years-listing/class-years-listing.component';
import { ClassNextYearPreviewComponent } from './components/classes/class-details/class-next-year-preview/class-next-year-preview.component';
import { CsvSelectorDialogComponent } from './components/classes/class-details/dialogs/csv-selector-dialog.component';
import { SubjectsDialogComponent } from './components/classes/class-form/subjects-dialog/subjects-dialog.component';
import { SchoolbooksDialogComponent } from './components/classes/class-form/schoolbooks-dialog/schoolbooks-dialog.component';
import { TutorsComponent } from './components/tutors/tutors.component';
import { StaffsComponent } from './components/staffs/staffs.component';
import { JustificationsCounterComponent } from './components/justifications-cards/justifications-counter/justifications-counter.component';
import { UserJustificationsDataComponent } from './components/user/sections/user-justifications-data/user-justifications-data.component';
import { JustificationsCardsComponent } from './components/justifications-cards/justifications-cards.component';
import { DeaStatusIconComponent } from './components/dea-pack/dea-status-icon/dea-status-icon.component';
import { DeaChipStatusComponent } from './components/dea-pack/dea-chip-status/dea-chip-status.component';
import { UserPctoUploadDialogComponent } from './components/user/sections/user-pcto-data/user-pcto-upload-dialog/user-pcto-upload-dialog.component';
import { UserPctoRowComponent } from './components/user/blocks/user-pcto-row/user-pcto-row.component';
import { InputJustNumberDirective } from './directives/input-just-number.directive';
import { InputJustIntDirective } from './directives/input-just-int.directive';
import { DeaHomepageWrapperComponent } from './components/dea-pack/dea-homepage-wrapper/dea-homepage-wrapper.component';
import { DeaSubjectsListingDialogComponent } from './components/dea-pack/dea-subjects-listing-dialog/dea-subjects-listing-dialog.component';
import { UserPctoSicurezzaRowBlockComponent } from './components/user/blocks/user-pcto-sicurezza-row-block/user-pcto-sicurezza-row-block.component';
import { FeaturesComponent } from './components/features/features.component';
import { FeatureRowComponent } from './components/features/feature-row/feature-row.component';
import { DeaImgDialogComponent } from './components/dea-pack/dea-img-dialog/dea-img-dialog.component';
import { UtilityComponent } from './components/utility/utility.component';
import { DeaWidgetWrapperComponent } from './components/dea-pack/dea-widget-wrapper/dea-widget-wrapper.component';
import { UserDeviceBoxComponent } from './components/user/sections/user-tech-data/user-device-box/user-device-box.component';
import { ToTitleCasePipe } from './pipes/to-title-case.pipe';
import { UserTechBlockComponent } from './components/user/sections/user-tech-data/user-tech-block/user-tech-block.component';
import { ToItDateDdMmmmYyyyPipe } from './pipes/to-it-date-dd-mmmm-yyyy.pipe';
import { DeaDeviceListingDialogComponent } from './components/dea-pack/dea-device-listing-dialog/dea-device-listing-dialog.component';
import { UserTechInsuranceDialogComponent } from './components/user/sections/user-tech-data/user-tech-dialogs/user-tech-insurance-dialog.component';
import { UserThumbnailAndControlsComponent } from './components/user/blocks/user-thumbnail-and-controls/user-thumbnail-and-controls.component';
import { StatusComponent } from './components/status/status.component';
import { FeatureDialogComponent } from './components/features/feature-dialog/feature-dialog.component';
import { DeaDateStepperComponent } from './components/dea-pack/dea-date-stepper/dea-date-stepper.component';
import { DeaDialogWrapperComponent } from './components/dea-pack/dea-dialog-wrapper/dea-dialog-wrapper.component';
import { DeaBirthdaysListingDialogComponent } from './components/dea-pack/dea-birthdays-listing-dialog/dea-birthdays-listing-dialog.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourcesSectionComponent } from './components/resources/resources-section/resources-section.component';
import { CreateNewFolderDialogComponent } from './components/resources/create-new-folder-dialog/create-new-folder-dialog.component';
import { FilmsComponent } from './components/items/films/films.component';
import { DeaSearchBarComponent } from './components/dea-pack/dea-search-bar.component';
import { FilmDialogComponent } from './components/items/films/film-dialog/film-dialog.component';
import { UserTechBreakingDialogComponent } from './components/user/sections/user-tech-data/user-tech-dialogs/user-tech-breaking-dialog.component';
import { UserTechBlockSectionHeadComponent } from './components/user/sections/user-tech-data/user-tech-block/user-tech-block-section-head.component';
import { UserTechReturnDialogComponent } from './components/user/sections/user-tech-data/user-tech-dialogs/user-tech-return-dialog.component';
import { UserTechLossDialogComponent } from './components/user/sections/user-tech-data/user-tech-dialogs/user-tech-loss-dialog.component';
import { UserTechReleaseDialogComponent } from './components/user/sections/user-tech-data/user-tech-dialogs/user-tech-release-dialog.component';
import { EmailSelectorDialogComponent } from './components/classes/class-details/dialogs/email-selector-dialog.component';
import { DevicesComponent } from './components/devices/devices.component';
import { DeaJsonPreviewComponent } from './components/dea-pack/dea-json-preview.component';
import { EventsComponent } from './components/events/events.component';
import { EventRowComponent } from './components/events/event-row/event-row.component';
import { EventFormComponent } from './components/events/event-form/event-form.component';
import { CreateEventComponent } from './components/events/create-event/create-event.component';
import { EditEventComponent } from './components/events/edit-event/edit-event.component';
import { EventDetailsComponent } from './components/events/event-details/event-details.component';
import { DeaClassesListingDialogComponent } from './components/dea-pack/dea-classes-listing-dialog/dea-classes-listing-dialog.component';
import { SchemasListingComponent } from './components/ring-forms/schemas-listing/schemas-listing.component';
import { FormsListingComponent } from './components/ring-forms/forms-listing/forms-listing.component';
import { FiltersDialogComponent } from './components/ring-forms/filters-dialog/filters-dialog.component';
import { BesDetailsDialogComponent } from './components/user/blocks/user-base-data/bes-details-dialog/bes-details-dialog.component';
import { AtlDetailsDialogComponent } from './components/user/blocks/user-base-data/atl-details-dialog/atl-details-dialog.component';
import { UserClassesDataComponent } from './components/user/sections/user-classes-data/user-classes-data.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarMonthViewComponent } from './components/calendar/views/calendar-month-view/calendar-month-view.component';
import { UserTimetableComponent } from './components/user/sections/user-timetable/user-timetable.component';
import { CalendarEventFormDialogComponent } from './components/calendar/calendar-event-form-dialog/calendar-event-form-dialog.component';
import { DeaTimetableComponent } from './components/dea-pack/dea-timetable/dea-timetable.component';
import { DeaTmpCredentialsDialogComponent } from './components/dea-pack/dea-tmp-credentials-dialog/dea-tmp-credentials-dialog.component';
import { TimetableBlocksSlotsDialogComponent } from './components/calendar/timetable-blocks-slots-dialog/timetable-blocks-slots-dialog.component';
import { EnrollmentsComponent } from './components/enrollments/enrollments.component';
import { EnrollmentsStatisticsComponent } from './components/enrollments/enrollments-statistics/enrollments-statistics.component';
import { DeaFastActionBtnComponent } from './components/dea-pack/dea-fast-action-btn/dea-fast-action-btn.component';
import { EnrollmentFormComponent } from './components/enrollments/enrollment-form/enrollment-form.component';
import { EnrollmentUserFormComponent } from './components/enrollments/enrollment-form/enrollment-user-form/enrollment-user-form.component';
import { GeneralTimetableComponent } from './components/calendar/general-timetable/general-timetable.component';

@NgModule({
  declarations: [
    AppComponent,
    // DEA-PACK
    DeaDropdownMenuComponent,
    DeaLabelComponent,
    // DEA-PACK
    ScreenSizeManagerComponent,
    DashboardComponent,
    LoginComponent,
    SessionComponent,
    NotFoundComponent,
    LogoutComponent,
    HomepageComponent,
    StudentsComponent,
    TeachersComponent,
    ParentsComponent,
    ClassesComponent,
    UserComponent,
    StatisticsComponent,
    CounterComponent,
    CountUpDirective,
    ListingTopBarComponent,
    AttendancesListingComponent,
    ClassesListingComponent,
    UsersListingComponent,
    ListingPaginationBarComponent,
    EmailLabelComponent,
    PhoneLabelComponent,
    DataBoxComponent,
    EmailBoxComponent,
    PhoneBoxComponent,
    PhoneNumberPipe,
    UserAddressComponent,
    UserBaseDataComponent,
    UserBirthDataComponent,
    DetailsDividerComponent,
    UserRelationshipsComponent,
    ClassCellComponent,
    ShorteningSchoolYearPipe,
    EditUserPersonalDataComponent,
    EditUserTechnologyDataComponent,
    UserPersonalDataFormComponent,
    NewUserPersonalDataComponent,
    InputToUpperCaseDirective,
    InputToLowerCaseDirective,
    InputToTitleCaseDirective,
    AttendancesComponent,
    AttendanceDetailsComponent,
    GuestAttendanceDetailsComponent,
    GuestsAttendancesComponent,
    GuestsAttendanceLisitngComponent,
    GuestCardComponent,
    AttendanceInOutComponent,
    GuestAttendanceSignatureComponent,
    DeaConfirmDialogComponent,
    DeaUserConfirmDialogComponent,
    ClassDetailsComponent,
    ClassFormComponent,
    CustomUsersListingComponent,
    FromClassTeachersToUsersPipe,
    DeaUserListingDialogComponent,
    DeaSingleBtnDialogComponent,
    UserMasterDataComponent,
    UserTechDataComponent,
    UserModulesDataComponent,
    UserPctoDataComponent,
    UserDocumentsDataComponent,
    UserDocumentRowComponent,
    UserDocumentUploadDialogComponent,
    DeaNewVersionDialogComponent,
    LimboComponent,
    LimboStudentsListingComponent,
    DeaThumbnailImgComponent,
    TemporaryCredentialsComponent,
    TemporaryLoginComponent,
    DeaWelcomeDialogComponent,
    DocumentsFormsComponent,
    DocumentFormCellComponent,
    DocumentsFormsDeaIdListingComponent,
    DeaPdfDialogComponent,
    SafeUrlPipe,
    ClassYearsListingComponent,
    ClassNextYearPreviewComponent,
    CsvSelectorDialogComponent,
    SubjectsDialogComponent,
    SchoolbooksDialogComponent,
    TutorsComponent,
    StaffsComponent,
    JustificationsCounterComponent,
    UserJustificationsDataComponent,
    JustificationsCardsComponent,
    DeaStatusIconComponent,
    DeaChipStatusComponent,
    UserPctoUploadDialogComponent,
    UserPctoRowComponent,
    InputJustNumberDirective,
    InputJustIntDirective,
    DeaHomepageWrapperComponent,
    DeaSubjectsListingDialogComponent,
    UserPctoSicurezzaRowBlockComponent,
    FeaturesComponent,
    FeatureRowComponent,
    DeaImgDialogComponent,
    UtilityComponent,
    DeaWidgetWrapperComponent,
    UserDeviceBoxComponent,
    ToTitleCasePipe,
    UserTechBlockComponent,
    ToItDateDdMmmmYyyyPipe,
    DeaDeviceListingDialogComponent,
    UserTechInsuranceDialogComponent,
    UserThumbnailAndControlsComponent,
    StatusComponent,
    FeatureDialogComponent,
    DeaDateStepperComponent,
    DeaDialogWrapperComponent,
    DeaBirthdaysListingDialogComponent,
    ResourcesComponent,
    FilesizePipe,
    GetMonthNamePipe,
    ResourcesSectionComponent,
    CreateNewFolderDialogComponent,
    FilmsComponent,
    DeaSearchBarComponent,
    FilmDialogComponent,
    UserTechBreakingDialogComponent,
    UserTechBlockSectionHeadComponent,
    UserTechReturnDialogComponent,
    UserTechLossDialogComponent,
    UserTechReleaseDialogComponent,
    ThumbnailCachePipe,
    EmailSelectorDialogComponent,
    DevicesComponent,
    DeaJsonPreviewComponent,
    EventsComponent,
    EventRowComponent,
    EventFormComponent,
    CreateEventComponent,
    EditEventComponent,
    EventDetailsComponent,
    DeaClassesListingDialogComponent,
    SchemasListingComponent,
    FormsListingComponent,
    FiltersDialogComponent,
    BesDetailsDialogComponent,
    AtlDetailsDialogComponent,
    UserClassesDataComponent,
    CalendarComponent,
    CalendarMonthViewComponent,
    UserTimetableComponent,
    CalendarEventFormDialogComponent,
    DeaTimetableComponent,
    DeaTmpCredentialsDialogComponent,
    TimetableBlocksSlotsDialogComponent,
    EnrollmentsComponent,
    EnrollmentsStatisticsComponent,
    DeaFastActionBtnComponent,
    EnrollmentFormComponent,
    EnrollmentUserFormComponent,
    GeneralTimetableComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    PdfViewerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatTableModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatTabsModule,
    MatInputModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HashVersionInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
