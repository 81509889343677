<mat-card fxLayoutGap class="p-3">
  <div class="fw-semibold fs-4">Carica Documento PCTO</div>
  <div *ngIf="type == 'PCTO-SICUREZZA'">Corso Sicurezza</div>
  <div class="v-spacer mt-1"></div>
  <div class="row m-0 p-0">
    <form [formGroup]="userPctoForm" enctype="multipart/form-data">
      <app-dea-label [label]="'Anno Scolastico'"></app-dea-label>
      <mat-radio-group formControlName="anno_scolastico" color="primary">
        <div class="row row-cols-2">
          <mat-radio-button value="{{years[0]}}">{{years[0]}}</mat-radio-button>
          <mat-radio-button value="{{years[1]}}">{{years[1]}}</mat-radio-button>
        </div>
        <div class="row row-cols-2">
          <mat-radio-button value="{{years[2]}}" checked="true">{{years[2]}}</mat-radio-button>
          <mat-radio-button value="{{years[3]}}">{{years[3]}}</mat-radio-button>
        </div>
      </mat-radio-group>
      <div class="v-spacer"></div>
      <input *ngIf="type == 'PCTO'" type="text" formControlName="ore" class="form-control dea-form-input mb-3"
        placeholder="Numero di ore" justNumber />
      <input type="text" formControlName="ente" class="form-control dea-form-input mb-3"
        placeholder="Ente documento PCTO" toTitleCase />
      <input type="text" formControlName="note" class="form-control dea-form-input mb-3"
        placeholder="Note per documento PCTO" />
      <input type="file" formControlName="file" class="form-control dea-form-input mb-1" placeholder="Seleziona un file"
        accept="image/png,image/jpeg,.pdf" (change)="onFileChange($event)" />
      <div class="fw-lighter mb-3" style="font-size: 0.5rem;">
        Formati accettati: PDF - PNG/JPG/JPEG | Dimensione Masima: 48Mb x 1
      </div>
      <div class="mb-3 text-danger">{{error?.message || errorMessage}}</div>
    </form>
  </div>
  <div class="mt-3 gap-2" mat-dialog-actions align="center">
    <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">Annulla</button>
    <button *ngIf="!isUploading" class="px-5 py-1" mat-flat-button color="primary" (click)="onConfirm()">
      Carica
    </button>
    <button *ngIf="isUploading" class="px-5 py-1" mat-flat-button disabled>
      <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Caricamento
    </button>
  </div>
</mat-card>