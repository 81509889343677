import { CalendarEventTypes } from './calendar-event-types.enum';
import { Calendar } from './calendar.model';
import * as moment from 'moment-timezone';
import { User } from '../user/user.model';
import { isMongoId } from 'validator';

import { Schoolbook } from '../schoolbook.model';
import { Headquarter } from '../headquarter';
import { Class } from '../class/class.model';
import { Subject } from '../subject.model';

export const byDayOptions = [
  { title: 'Lunedì', name: 'LU', value: 'MO' },
  { title: 'Martedì', name: 'MA', value: 'TU' },
  { title: 'Mercoledì', name: 'ME', value: 'WE' },
  { title: 'Giovedì', name: 'GI', value: 'TH' },
  { title: 'Venerdì', name: 'VE', value: 'FR' },
  { title: 'Sabato', name: 'SA', value: 'SA' },
  { title: 'Domenica', name: 'DO', value: 'SU' },
];

export class CalendarEventRecurrence {
  frequency?: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY' | undefined;
  interval?: number;
  endDate?: moment.Moment;
  count?: number;
  byDay?: ('MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU')[];
  byMonthDay?: number[];
  bySetPosition?: number;

  constructor(json?: any) {
    if (!json) return;
    this.frequency = json['frequency'];
    this.interval = json['interval'];
    this.endDate = json['endDate'] ? moment(json['endDate']) : undefined;
    this.count = json['count'];
    this.byDay = (json['byDay'] || []).map((value: string) =>
      value.toString().toUpperCase().trim()
    );
    this.byMonthDay = json['byMonthDay'];
    this.bySetPosition = json['bySetPosition'];
  }
}

export class CalendarEvent {
  id?: string;
  title?: string;
  description?: string;
  location?: string;
  room?: string;
  meetingLink?: string;
  allDay?: boolean;
  start?: moment.Moment;
  end?: moment.Moment;
  tz: string = moment.tz.guess(true);
  // RECURSIVE EVENT
  recurrence?: CalendarEventRecurrence;
  // exceptions: {
  //     type: [{
  //         date: { type: Date, required: true },
  //         isCancelled: { type: Boolean, default: false },
  //         newDetails: {
  //             title: { type: String, required: false },
  //             description: { type: String, required: false },
  //             location: { type: String, required: false },
  //             room: { type: String, trim: true },
  //             meeting_link: { type: String, trim: true },
  //             all_day: { type: Boolean, default: false },
  //             start: { type: Date, required: false },
  //             end: { type: Date, required: false },
  //             tz: { type: String, trim: true, required: true, enum: moment.tz.names() },
  //         }
  //     }],
  //     required: false
  // },
  calendars?: Calendar[];
  owner?: User;
  // attachments: [{ type: mongoose.Schema.Types.ObjectId, ref: calendarEventDocumentModelName }],
  organizer?: { name: string; email: string }[];
  color?: string;
  note?: string;

  __type?: CalendarEventTypes;

  // TIMETABLE-EVENTS
  headquarter?: Headquarter;
  timetableBlock?:
    | 'LEZIONE'
    | 'RICEVIMENTO'
    | 'DISPOSIZIONE'
    | 'SORVEGLIANZA'
    | 'SOSTITUZIONE'
    | 'GITA'
    | undefined;
  teachers?: User[] = [];
  subject?: Subject;
  books?: Schoolbook[];
  classes?: Class[];
  out?: boolean;

  constructor(json?: any) {
    if (!json) return;
    if (isMongoId(json.toString().trim())) {
      this.id = json.toString().trim();
      return;
    }
    this.id = json['_id'] || json['id'];
    this.title = json['title'];
    this.description = json['description'];
    this.location = json['location'];
    this.room = json['room'];
    this.meetingLink = json['meeting_link'];
    this.allDay = json['all_day'];
    this.start = json['start'] ? moment(json['start']) : undefined;
    this.end = json['end'] ? moment(json['end']) : undefined;
    this.tz = json['tz'] || 'Europe/London';
    this.calendars = json['calendars']
      ? (json['calendars'] ?? []).map((cal: any) => new Calendar(cal))
      : undefined;
    this.owner = json['owner'] ? new User(json['owner']) : undefined;
    // organizer?: { name: string; email: string }[];
    this.color = json['color'];
    this.note = json['note'];
    this.__type = json['__type'];
    // RECURSIVE-EVENTS
    this.recurrence = json['recurrence']
      ? new CalendarEventRecurrence(json['recurrence'])
      : undefined;
    // TIMETABLE-EVENTS
    this.headquarter = json['headquarter']
      ? Headquarter.fromString(json['headquarter'])
      : undefined;
    this.timetableBlock = json['timetable_block'];
    this.teachers = (json['teachers'] || []).map((json: any) => new User(json));
    this.classes = (json['classes'] || []).map((json: any) => new Class(json));
    this.subject = json['subject'] ? new Subject(json['subject']) : undefined;
    this.books = (json['books'] || []).map((json: any) => new Schoolbook(json));
    this.out =
      json['out'] === undefined
        ? undefined
        : (json['out'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
  }

  isRecursiveEvent(): boolean {
    return this.recurrence !== undefined;
  }

  getOwnerFullName(): string | undefined {
    return this.owner?.getFullName();
  }

  isTimetableEvent(): boolean {
    return this.__type === CalendarEventTypes.TIMETABLE_EVENT;
  }

  getTeachersFullName(): string {
    return this.teachers?.map((tc) => tc.getFullName())?.join(', ') || '';
  }

  getClassesShortName(joinWidth: string = ' | '): string {
    return this.classes?.map((cl) => cl.getShortName())?.join(joinWidth) || '';
  }

  toJson(bool?: boolean): any {
    return {};
  }
}
