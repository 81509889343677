import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TechBreaking } from 'src/app/models/tech/tech-breaking.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { TechService } from 'src/app/services/tech.service';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-user-tech-breaking-dialog',
  template: `<app-dea-dialog-wrapper>
    <div class="fw-semibold fs-4">
      {{ this.data?.docId === undefined ? '' : 'Modifica ' }}Guasto / Rottura
    </div>
    <div class="v-spacer"></div>
    <div class="row m-0 p-0">
      <form
        [formGroup]="userBreakingForm"
        class="m-0 p-0 d-flex flex-column gap-3"
      >
        <app-dea-label [label]="'Tipo (Opzionale):'"></app-dea-label>
        <mat-radio-group
          formControlName="type"
          aria-label="Specifica un tipo"
          color="primary"
        >
          <mat-radio-button class="me-3" value="Batteria">
            Batteria
          </mat-radio-button>
          <mat-radio-button class="me-3" value="Schermo">
            Schermo
          </mat-radio-button>
          <mat-radio-button class="me-3" value="Pulsante Home">
            Pulsante Home
          </mat-radio-button>
          <mat-radio-button class="me-3" value="Altro">Altro</mat-radio-button>
        </mat-radio-group>
        <app-dea-label [label]="'Note (Opzionali):'"></app-dea-label>
        <textarea
          formControlName="note"
          class="form-control dea-form-input"
          placeholder="Note"
          rows="3"
        ></textarea>
        <div class="text-danger">{{ error?.message }}</div>
      </form>
    </div>
    <div class="m-0 p-0 mt-3 gap-2" mat-dialog-actions align="end">
      <button class="px-5 py-1" mat-stroked-button (click)="onDismiss()">
        Annulla
      </button>
      <button
        *ngIf="!loading"
        mat-flat-button
        color="primary"
        class="px-5 py-1"
        (click)="onConfirm()"
      >
        {{ this.data?.docId === undefined ? 'Crea' : 'Modifica' }}
      </button>
      <button *ngIf="loading" class="px-5 py-1" mat-flat-button disabled>
        <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon>
        {{ this.data?.docId === undefined ? 'Creazione' : 'Aggiornamento' }}
      </button>
    </div>
  </app-dea-dialog-wrapper>`,
  styles: [],
})
export class UserTechBreakingDialogComponent {
  public loading: boolean = false;
  public error?: ApiError;

  public userBreakingForm: FormGroup;

  constructor(
    private _dialogRef: MatDialogRef<UserTechBreakingDialogComponent>,
    private _techService: TechService,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      userId?: string;
      deviceId?: string;
      docId?: string;
    }
  ) {
    this.userBreakingForm = new FormGroup({
      owner: new FormControl(this.data?.userId, Validators.required),
      device: new FormControl(this.data?.deviceId, Validators.required),
      type: new FormControl(null),
      note: new FormControl(null),
    });
    if (this.data?.docId !== undefined) {
      this._techService.getTechBlockDetails(this.data.docId).subscribe({
        next: (response: any) => {
          const data: TechBreaking = new TechBreaking(response.data);
          this.userBreakingForm = new FormGroup({
            owner: new FormControl(data.owner?.id, Validators.required),
            device: new FormControl(data.device?.id, Validators.required),
            type: new FormControl(data.type),
            note: new FormControl(data.note),
          });
        },
        error: (error: any) => console.error('Get Tech Details Error: ', error),
      });
    }
  }

  onConfirm(): void {
    this.loading = true;
    this.error = undefined;
    const data = this.userBreakingForm.value;
    if (data.type === undefined || data.type === null) delete data.type;
    if (data.note === undefined || data.note === null) delete data.note;
    const subscription = {
      next: (_: any) => this._dialogRef.close(true),
      error: (error: any) => {
        this.error = new ApiError(error);
        console.error('User Post/Patch BreakingTechBlock Error: ', error);
      },
    };
    if (this.data?.docId) {
      this._techService
        .patchBreaking(this.data.docId, data)
        .subscribe(subscription)
        .add(() => (this.loading = false));
    } else {
      this._techService
        .postBreaking(data)
        .subscribe(subscription)
        .add(() => (this.loading = false));
    }
  }

  onDismiss(): void {
    this._dialogRef.close(false);
  }
}
