<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <div class="d-flex flex-column gap-4">
    <!-- YEAR SELECTOR -->
    <div class="d-flex justify-content-between w-100">
      <button class="pointer" (click)="setYear(-1)" title="Anno scolastico precedente" type="button" mat-stroked-button>
        <i class="bi bi-chevron-left"></i>
      </button>
      <div class="fw-bold" [title]="statistics?.informazioni?.anno_scolastico">
        {{statistics?.informazioni?.anno_scolastico}}
      </div>
      <button class="pointer" (click)="setYear(1)" title="Giorno seguente" type="button" mat-stroked-button>
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
    <!-- YEAR SELECTOR -->
    <!-- COUNTERS -->
    <div class="row row-cols-4 row-gap-4">
      <div class="col text-center">
        <app-counter label="Totale" value="{{statistics?.generale?.iscritti?.totale}}"></app-counter>
      </div>
      <div *ngFor="let data of statistics?.dipartimenti" class="col text-center">
        <app-counter label="{{data.dipartimento}}" value="{{data.iscritti.totale}}"></app-counter>
      </div>
    </div>
    <!-- COUNTERS -->
    <!-- COLUMNS -->
    <div class="row">
      <div class="col">
        <app-dea-widget-wrapper>
          <canvas id="graficoColonneDivisioneDipartimenti" style="width: 100%; max-height: 450px !important;">
            {{graficoColonneDivisioneDipartimenti}}
          </canvas>
        </app-dea-widget-wrapper>
      </div>
    </div>
    <!-- COLUMNS -->
    <!-- PIES -->
    <div class="row">
      <div class="col">
        <app-dea-widget-wrapper>
          <canvas id="graficoTortaDivisioneDipartimenti" style="height: 400px; width: 50%;">
            {{graficoTortaDivisioneDipartimenti}}
          </canvas>
        </app-dea-widget-wrapper>
      </div>
      <div class="col">
        <app-dea-widget-wrapper>
          <canvas id="graficoTortaDivisioneGenere" style="height: 400px; width: 50%;">
            {{graficoTortaDivisioneGenere}}
          </canvas>
        </app-dea-widget-wrapper>
      </div>
      <div class="col">
        <app-dea-widget-wrapper>
          <canvas id="graficoTortaDivisioneSede" style="height: 400px; width: 50%;">
            {{graficoTortaDivisioneSede}}
          </canvas>
        </app-dea-widget-wrapper>
      </div>
    </div>
    <!-- PIES -->
  </div>
</ng-template>
