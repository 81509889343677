import { GeneralStatistics } from 'src/app/models/general-statistics.model';
import { UtilityService } from 'src/app/services/utility.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  statistics: GeneralStatistics = new GeneralStatistics({
    students: 0,
    parents: 0,
    teachers: 0,
    documents: 0,
    justifications: 0,
  });

  constructor(private _utilityService: UtilityService) {}

  ngOnInit(): void {
    this._utilityService.getGeneralStatistics().subscribe({
      next: (data) => {
        this.statistics = new GeneralStatistics(data.data);
      },
      error: (error) => {
        console.error('Statistics Error: ', new ApiError(error));
      },
    });
  }
}
