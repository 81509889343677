export class EmailContact {
  label?: string;
  address?: string;
  enable?: boolean;

  constructor(label: string, address: string, enable: boolean) {
    this.label = label;
    this.address = address;
    this.enable = enable;
  }

  public static fromJson(json: any): EmailContact {
    return new EmailContact(
      json['etichetta'],
      json['indirizzo'],
      json['enable'] === undefined
        ? true
        : (json['enable'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false
    );
  }

  toJson(): any {
    return {
      etichetta: this.label,
      indirizzo: this.address,
      enable: this.enable,
    };
  }

  getEmailContactString(): string {
    return `${this.label} ${this.address}`.toString().trim();
  }
}
