import { ClientStorageService } from 'src/app/services/client-storage.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';
import { FilmService } from 'src/app/services/items/film.service';
import { Film } from 'src/app/models/items/film.model';
import { Component, Inject } from '@angular/core';

interface LanguageInterface {
  code: string;
  family: string;
  name: string;
  nativeName: string;
}

@Component({
  selector: 'app-film-dialog',
  templateUrl: './film-dialog.component.html',
  styleUrls: ['./film-dialog.component.scss'],
})
export class FilmDialogComponent {
  private _filmId?: string = undefined;
  public languages?: LanguageInterface[] = [];
  public filmForm: FormGroup = new FormGroup({
    sede: new FormControl(),
    year: new FormControl(),
    title: new FormArray([]),
    languages: new FormControl(),
    subtitles: new FormControl(),
    categories: new FormControl(),
    cover: new FormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { film: Film },
    public dialogRef: MatDialogRef<FilmDialogComponent>,
    private _clientStorage: ClientStorageService,
    private _utilityService: UtilityService,
    private _filmService: FilmService
  ) {
    this.getLanguages();
    if (this.data?.film) {
      this._filmId = this.data?.film?.id;
      const json = this.data.film.toJson();
      if (json.sede) this.filmForm.get('sede')?.setValue(json.sede);
      else {
        this.filmForm
          ?.get('sede')
          ?.setValue(this._clientStorage.getHeadquarter().backend);
      }
      if (json.year) this.filmForm.get('year')?.setValue(json.year);
      if (json.title) {
        Object.keys(json.title).map((key: string) => {
          (this.filmForm?.get('title') as FormArray)?.push(
            new FormGroup({
              key: new FormControl(key),
              value: new FormControl(json.title[key]),
            })
          );
        });
      }
      if (json.languages) {
        this.filmForm
          ?.get('languages')
          ?.setValue(json.languages.map((l: string) => l.toLowerCase()));
      }
      if (json.subtitles) {
        this.filmForm
          ?.get('subtitles')
          ?.setValue(json.subtitles.map((l: string) => l.toLowerCase()));
      }
      if (json.categories) {
        this.filmForm.get('categories')?.setValue(json?.categories?.join(', '));
      }
    } else {
      this.addTitle();
      this.filmForm
        ?.get('sede')
        ?.setValue(this._clientStorage.getHeadquarter().backend);
    }
  }

  getLanguages(): void {
    this._utilityService.getLanguages(false).subscribe({
      next: (data: any) => {
        this.languages = data?.data as LanguageInterface[];
      },
      error: (error: any) => console.error('Error:', error),
    });
  }

  cancelBtnAction(): void {
    this.dialogRef.close(false);
  }

  confirmBtnAction(): void {
    let json: any = this.filmForm.value;
    json.title.map((obj: any, index: number) => {
      if (index === 0) json.title = {};
      json['title'][obj.key] = obj.value;
    });
    json.categories = json.categories?.split(',') || [];
    json.categories.map((cat: string, index: number) => {
      if (index === 0) json.categories = [];
      if (cat.toString().trim() != '') {
        json.categories.push(cat.toString().trim());
      }
    });
    const film: Film = new Film(json);
    film.id = this._filmId;
    const sub = {
      next: () => this.dialogRef.close(true),
      error: (error: any) => console.error('Error: ', error),
    };
    if (this._filmId) this._filmService.putFilm(film).subscribe(sub);
    else this._filmService.postFilm(film).subscribe(sub);
  }

  addTitle(): void {
    (this.filmForm?.get('title') as FormArray)?.push(
      new FormGroup({
        key: new FormControl(),
        value: new FormControl(),
      })
    );
  }

  removeTitle(index: number): void {
    (this.filmForm?.get('title') as FormArray)?.removeAt(index);
  }
}
