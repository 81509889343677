export class UserSportInformation {
  enable?: boolean;
  societa?: String;
  disciplina?: String;

  constructor(json?: any) {
    if (!json) return;
    this.enable =
      json['enable'] === undefined
        ? undefined
        : (json['enable'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.societa = json['societa'];
    this.disciplina = json['disciplina'];
  }

  toJson(defaultUndefined: boolean = true): any {
    const value = defaultUndefined ? undefined : '';
    if (
      (this.enable == undefined || this.enable == false) &&
      !this.societa &&
      !this.disciplina
    ) {
      return undefined;
    }
    return {
      enable: this.enable || value,
      societa: this.societa || value,
      disciplina: this.disciplina || value,
    };
  }
}
