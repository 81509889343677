<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      {{error?.message}}
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <div class="row m-0 p-0">
      <div class="col-auto m-0 p-0">
        <div class="user-img-spacer"></div>
      </div>
      <div class="col m-0 p-0 ps-3">
        <!-- BIRTH DATA -->
        <app-user-birth-data [user]="user"></app-user-birth-data>
        <!-- ADDRESS (Domicilio) -->
        <app-user-address [addressTopLabel]="'Domicilio'" [address]="user?.domicilio"></app-user-address>
        <!-- ADDRESS (Residenza) -->
        <app-user-address [addressTopLabel]="'Residenza'" [address]="user?.residenza"></app-user-address>
        <!-- PARENTS -->
        <app-user-relationships [label]="'Genitori'" [users]="user?.genitori"></app-user-relationships>
        <!-- TUTORS -->
        <app-user-relationships [label]="'Tutori'" [users]="user?.tutori"></app-user-relationships>
        <!-- FIGLI -->
        <app-user-relationships [label]="'Figli'" [users]="user?.figli"></app-user-relationships>
        <!-- SIBLINGS -->
        <app-user-relationships [label]="'Fratelli / Sorelle'" [users]="user?.siblings"></app-user-relationships>
      </div>
    </div>
  </ng-template>
</ng-template>