import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-users-listing',
  templateUrl: './users-listing.component.html',
  styleUrls: ['./users-listing.component.scss'],
})
export class UsersListingComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 20;
  @Input() type?: string;
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() linkMainBtn: string = '';
  @Input() linkMainBtnQueryParams: object = {};

  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public users: User[] = [];
  public error?: ApiError;
  private text?: string;

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getListing({
        page: this.page,
        limit: this.limit,
        type: this.type,
        txt: this.text,
      })
      .subscribe({
        next: (data) => {
          this.users = data?.data ?? [];
          this.pagination = data?.pagination;
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
