import * as moment from 'moment-timezone';

export class ScholasticYear {
  constructor() {}
  public static getCurrent(): string {
    const month: number = 8;
    const oggi = new Date();
    const anno: number = oggi.getFullYear();
    const mese: number = oggi.getMonth();
    const inizio: number = mese < month ? anno - 1 : anno;
    const fine: number = mese < month ? anno : anno + 1;
    return `${inizio}-${fine}`;
  }

  public static getCurrentEndDate(tz?: string): moment.Moment {
    const defaultTZ = 'Europe/Rome';
    const endDate = moment
      .tz(`${this.getCurrent().split('-')[1]}-06-22`, tz ?? defaultTZ)
      .endOf('day');
    return endDate;
  }

  public static getFromCurrent(number: number): string {
    const scholasticYear: string = this.getCurrent();
    const years: string[] = scholasticYear.split('-');
    if (years.length != 2) return 'ERROR';
    return `${parseInt(years[0]) + number}-${parseInt(years[1]) + number}`;
  }
  // Shortcut
  public static getPrev(): string {
    return this.getFromCurrent(-1);
  }
  // Shortcut
  public static getNext(): string {
    return this.getFromCurrent(1);
  }
}
