import { EnrollmentFormService } from 'src/app/services/enrollment/enrollment-form.service';
import { Component, inject, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-enrollment-user-form',
  templateUrl: './enrollment-user-form.component.html',
  styleUrl: './enrollment-user-form.component.scss',
})
export class EnrollmentUserFormComponent {
  // private _toolService: ToolsService = inject(ToolsService);
  private _enrollmentFormService = inject(EnrollmentFormService);
  @Input() uuid!: string;

  get enrollmentFormService(): EnrollmentFormService {
    return this._enrollmentFormService;
  }

  get user() {
    return this._enrollmentFormService.getUserByUUID(this.uuid);
  }

  get userEmails() {
    return this.user.controls['contatti']?.get('email') as FormArray;
  }

  get userPhones() {
    return this.user.controls['contatti'].get('telefono') as FormArray;
  }
}
