<app-dea-dialog-wrapper [title]="'Seleziona uno slot predefinito'">
  <form [formGroup]="formGroup">
    <app-dea-label [label]="'Giorno della Settimana:'"></app-dea-label>
    <select class="form-select" formControlName="day">
      <option value="1">Lunedì</option>
      <option value="2">Martedì</option>
      <option value="3">Mercoledì</option>
      <option value="4">Giovedì</option>
      <option value="5">Venerdì</option>
      <option value="6">Sabato</option>
      <option value="0">Domenica</option>
    </select>
    <div class="v-spacer"></div>
    <app-dea-label [label]="'Slots:'"></app-dea-label>
    <select class="form-select" formControlName="slotComboId">
      @for(block of timetableConfigs?.blocks; track block) {
      <option value="{{block.id}}-{{block.from}}-{{block.to}}">
        {{block.id}}@if(block.id !== 'INT') {° Ora} | {{block.from}} - {{block.to}}
        @if(block.departments !== undefined && !block.departments.includes('*')) {
        ({{block.departments.join(', ')}})
        }
      </option>
      }
    </select>
  </form>
  <!-- SUBMIT -->
  <div class="v-spacer"></div>
  <div class="hstack">
    <div class="ms-auto">
      <button mat-flat-button color="secondary" class="fw-bold px-4 py-1 rounded-pill" (click)="cancel()">
        Annulla
      </button>
    </div>
    <div class="ms-2">
      <button mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill" (click)="next()">
        Avanti
      </button>
    </div>
  </div>
</app-dea-dialog-wrapper>