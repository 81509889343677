import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { DeaPdfDialogComponent } from 'src/app/components/dea-pack/dea-pdf-dialog/dea-pdf-dialog.component';
import { JustificationsCardService } from 'src/app/services/justifications-card.service';
import { JustificationsCard } from 'src/app/models/register/justifications-card.model';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-user-justifications-data',
  templateUrl: './user-justifications-data.component.html',
  styleUrls: ['./user-justifications-data.component.scss'],
})
export class UserJustificationsDataComponent implements OnChanges {
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() user?: User;
  @Input() userId?: string;
  @Input() startLoading: boolean = false;

  justificationsCards: JustificationsCard[] = [];

  constructor(
    private _dialog: MatDialog,
    private _userService: UserService,
    private _justificationsCardService: JustificationsCardService
  ) {}

  get canCreateNewCard(): boolean {
    return true; // Sempre abilitato su richiesta dei docenti
    return (
      this.justificationsCards.filter((card) => !card.tag?.includes('LICOS'))
        .length === 0
    );
  }

  get canCreateNewLICOSCard(): boolean {
    return true; // Sempre abilitato su richiesta dei docenti
    return (
      this.justificationsCards.filter((card) => card.tag?.includes('LICOS'))
        .length === 0
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading']?.currentValue &&
      !changes['startLoading']?.previousValue
    ) {
      this.getData();
    }
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getUserJustificationsCards(this.userId)
      .subscribe({
        next: (data: any) => {
          this.justificationsCards = data.data.map((card: any) => {
            return new JustificationsCard(card);
          });
        },
        error: (error: any) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Justifications Cards Error:', error);
        },
      })
      .add(() => (this.loading = false));
  }

  postJustificationCard(user_id?: string, type?: number, tag?: string) {
    this._justificationsCardService
      .postJustificationsCard(user_id, type, tag)
      .subscribe({
        next: (_: any) => this.getData(),
        error: (error: ApiError) => {
          console.error('POST Justifications Card Error:', error);
        },
      });
  }

  addJustificationToCard(cardId?: string, type?: number) {
    this._justificationsCardService
      .patchJustificationsCard(cardId, type)
      .subscribe({
        next: (data) => {
          let updatedCard = new JustificationsCard(data.data);
          if (updatedCard != undefined) {
            let index = this.justificationsCards.findIndex(
              (item: JustificationsCard) => item.id === updatedCard.id
            );
            if (index > -1) this.justificationsCards[index] = updatedCard;
          }
        },
        error: (error: ApiError) => {
          console.error('Add Justification To Card Error: ', error);
        },
      });
  }

  removeLastJustificationToCard(cardId?: string, type?: number) {
    const message =
      type == 1
        ? "Confermi di voler eliminare l'ultimo ingresso posticipato registrato in questa card?"
        : type == 2
        ? "Confermi di voler eliminare l'ultimo uscita anticipata registrata in questa card?"
        : "Confermi di voler eliminare l'ultimo elemento in questa card?";
    const details =
      type == 1
        ? "Attenzione: saranno eliminate anche data e ora dell'ultimo ingresso posticipato e non sarà più possibile ripristinarle"
        : type == 2
        ? "Attenzione: saranno eliminate anche data e ora dell'ultima uscita anticipata e non sarà più possibile ripristinarle"
        : "Attenzione: saranno eliminate anche data e ora dell'ultimo elemento e non sarà più possibile ripristinarle";
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message: message,
        details: details,
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this._justificationsCardService
          .removeLastJustificationToCard(cardId, type)
          .subscribe({
            next: (data) => {
              let updatedCard = new JustificationsCard(data.data);
              if (updatedCard != undefined) {
                let index = this.justificationsCards.findIndex(
                  (item: JustificationsCard) => item.id === updatedCard.id
                );
                if (index > -1) this.justificationsCards[index] = updatedCard;
              }
            },
            error: (error: ApiError) => {
              console.error('Remove Last Justification To Card Error: ', error);
            },
          });
      }
    });
  }

  openPdfPreviewPDF(id?: string): void {
    this._dialog.open(DeaPdfDialogComponent, {
      minHeight: '600px',
      minWidth: '1000px',
      data: {
        id: id || 'NO-ID',
        url: API.downloadJustificationsCardPDF(id || 'ID-ERROR'),
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  downloadPDF(id?: string): void {
    if (!id) return;
    this._justificationsCardService.downloadPDF(id).subscribe((res: any) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download =
        res.headers.get('originalname') ||
        `${new Date().toISOString()} - Modulo Ingressi Posticipati e Uscite Anticipate.pdf`;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }
}
