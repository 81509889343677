import { EventClass } from './event-class.model';

export class Event {
  id?: string;
  // owners?: User[];
  public?: boolean;
  // code?: string;
  title?: string;
  description?: string;
  location?: string;
  start?: Date;
  startDate?: Date;
  startTime?: string;
  end?: Date;
  cover?: string;
  classes?: EventClass[];
  /*
    organizers: [{
        entity: { type: String, trim: true },
        lastName: { type: String, trim: true, required: true },
        firstName: { type: String, trim: true, required: true },
        email: { type: String, trim: true, lowercase: true },
    }],
    number: { type: Number },
    attendees: [{
        entity: { type: String, trim: true },
        status: { type: Boolean, required: true, default: false },
        lastName: { type: String, trim: true, required: true },
        firstName: { type: String, trim: true, required: true },
        email: { type: String, trim: true, lowercase: true },
        phone: { type: String, trim: true, lowercase: true },
        metadata: { type: Object }
    }]
*/
  created?: Date;
  updated?: Date;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    //  owners?: User[];
    this.public =
      json['public'] === undefined
        ? undefined
        : (json['public'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    // this.code = json['code'];
    this.title = json['title'];
    this.description = json['description'];
    this.location = json['location'];
    this.start = json['start'] ? new Date(json['start']) : undefined;
    this.end = json['end'] ? new Date(json['end']) : undefined;
    this.cover = json['cover'];
    this.classes = (json['classes'] || []).map(
      (json: any) => new EventClass(json)
    );
    this.created = json['createdAt'] ? new Date(json['createdAt']) : undefined;
    this.updated = json['updatedAt'] ? new Date(json['updatedAt']) : undefined;
  }

  toJson(): any {
    return {
      _id: this.id,
      // owners?: User[];
      public: this.public,
      // code?: string;
      title: this.title,
      description: this.description,
      location: this.location,
      start: this.start ? this.start?.toISOString() : undefined,
      end: this.end ? this.end?.toISOString() : undefined,
      cover: this.cover,
      classes: this.classes?.map((cl) => cl.toJson()),
      createdAt: this.created,
      updatedAt: this.updated,
    };
  }
}
