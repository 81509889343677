<app-dea-dialog-wrapper>
  <app-dea-date-stepper (date)="newDate($event)"></app-dea-date-stepper>
  <div class="v-spacer"></div>
  <div *ngIf="loading" class="d-flex justify-content-center p-5">
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </div>
  <div *ngIf="!loading && users.length == 0" class="d-flex justify-content-center p-5">
    Nessun compleanno da mostrare
  </div>
  <div *ngIf="!loading && users.length != 0" class="d-flex flex-column justify-content-start">
    <table class="table table-borderless m-0">
      <thead class="m-0">
        <tr class="dea-table-row-header m-0">
          <th class="col-auto" style="width: 32px;"></th>
          <th class="col-4">Cognome</th>
          <th class="col-4">Nome</th>
          <th class="col-auto">Anni</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users;" class="user-listing-table-row dea-radius pointer">
          <td class="col-auto" style="width: 32px;" (click)="goToUser(user.id)" title="{{user.getFullName()}}">
            <app-dea-thumbnail-img size="32" id="{{user.id}}"></app-dea-thumbnail-img>
          </td>
          <td class="col py-auto" (click)="goToUser(user.id)" title="{{user.getFullName()}}">
            {{user.cognome}}
          </td>
          <td class="col py-auto" (click)="goToUser(user.id)" title="{{user.getFullName()}}">
            {{user.nome}}
          </td>
          <td class="col-auto py-auto" (click)="goToUser(user.id)" title="{{user.getFullName()}}">
            {{getAge(user.data_di_nascita)}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-dea-dialog-wrapper>