<div class="bg-w dea-radius dea-shadow dea-padding">
  <div class="fw-lighter opacity-75">{{formTitle}}</div>
  <div class="v-spacer"></div>
  <div class="v-spacer"></div>
  <form [formGroup]="timetableReplacementsResumeForm">
    <!-- CONFIGURAZIONE SCHEDA -->
    <div class="d-flex flex-column gap-2">
      <div class="fw-lighter opacity-75">Configurazione:</div>
      <div class="dea-border dea-radius p-3 d-flex flex-column gap-2">
        <div class="row m-0 p-0 gap-3">
          <div class="col m-0 p-0">
            <app-dea-label class="mb-2" [label]="'Sede:'"></app-dea-label>
            <input formControlName="headquarter" type="text" class="form-control dea-form-input"
              placeholder="Sede scheda sostituzioni" />
          </div>
          <div class="col m-0 p-0">
            <app-dea-label class="mb-2" [label]="'Data:'"></app-dea-label>
            <input formControlName="date" type="date" class="form-control dea-form-input"
              placeholder="Data scheda sostituzioni" />
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <div class="my-auto">
            <div class="fw-lighter opacity-75">Docenti:</div>
          </div>
          <app-dea-fast-action-btn class="ms-auto" (click)="openUsersDialog()">
            Aggiungi 'Docente'
          </app-dea-fast-action-btn>
        </div>
        <div class="d-flex flex-row flex-wrap gap-2">
          <app-dea-user-chip *ngFor="let user of resumeTeachers; let i = index;" [showThumbnail]="true"
            [showRemoveAction]="true" [user]="user" (onRemove)="newFormTeacherListingRemoveById($event)">
          </app-dea-user-chip>
        </div>
      </div>
    </div>
    <!-- CONFIGURAZIONE SCHEDA -->
    <div class="v-spacer"></div>
    <!-- VERSIONE CON FORM-GROUP -->
    <div class="d-flex flex-column gap-2">
      <app-dea-label [label]="'Sostituzioni:'"></app-dea-label>
      <div *ngFor="let slotID of slotIDs; let slotIdIndex = index;"
        class="dea-border dea-radius p-3 d-flex flex-column gap-2">
        <div class="d-flex m-0 p-0">
          <div class="bg-primary text-white fw-bold px-4 py-1 dea-radius-end" style="margin-left: -16px;">
            {{slotID}}
          </div>
        </div>
        <ng-container *ngFor="let block of getBlocksFormGroupWithSlot(slotID); let i = index;">
          <div class="dea-border dea-radius p-2 gap-2" [formGroup]="block">
            <div class=" d-flex flex-row gap-2">
              <div class="col d-flex flex-row gap-2">
                <div class="col text-wrap w-100">
                  <app-dea-label class="fs-075r" [label]="'Docenti Lezione:'"></app-dea-label>
                  <input type="text" class="form-control dea-form-input w-100"
                    [value]="getUserFullnameFromAbstractControl(block.get('teacher'))" disabled />
                </div>
                <div class="vr"></div>
                <div class="col col-auto h-100">
                  <app-dea-label class="fs-075r" [label]="'Classi:'"></app-dea-label>
                  <div class="row m-0 p-0" style="min-height: 38px;">
                    <div class="col m-0 p-0 col-auto align-self-center">
                      {{ getClassesShortNameFromAbstractControl(block.get('classes')) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="vr"></div>
              <div class="col">
                <app-dea-label class="fs-075r" [label]="'Modifica:'"></app-dea-label>
                <mat-radio-group formControlName="modification" class="d-flex flex-row gap-1 align-items-start"
                  color="primary">
                  <mat-radio-button value="substitute">Sostituzione con docente</mat-radio-button>
                  <mat-radio-button value="person">Sostituzione generica</mat-radio-button>
                  <mat-radio-button value="entry">Ingresso posticipato</mat-radio-button>
                  <mat-radio-button value="exit">Uscita anticipata</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="mt-2 d-flex flex-row gap-2">
              <div class="col" *ngIf="block.get('modification')?.value === 'substitute'">
                <app-dea-label class="fs-075r" [label]="'Sostituzione con docente:'"></app-dea-label>
                <div class="input-group flex-nowrap">
                  <input type="text" class="form-control dea-form-input w-100"
                    [value]="getUserFullnameFromAbstractControl(block.get('substitute'))"
                    placeholder="Seleziona un'opzione" disabled />
                  <button
                    class="dea-border dea-radius-end cursor-pointer d-flex align-items-center justify-content-center m-0 p-0"
                    aria-label="Seleziona Opzione" title="Seleziona Opzione"
                    style="width: 38px; background: transparent; border-left: none;"
                    (click)="showOptionsDialogForBlock(block)">
                    <i class="bi bi-pencil-fill dea-icon-btn"></i>
                  </button>
                </div>
              </div>
              <div class="col" *ngIf="block.get('modification')?.value === 'person'">
                <app-dea-label class="fs-075r" [label]="'Sostituzione generica:'"></app-dea-label>
                <div class="input-group flex-nowrap">
                  <input type="text" class="form-control dea-form-input w-100" formControlName="person"
                    [value]="getUserFullnameFromAbstractControl(block.get('person'))" placeholder="Cognome Nome" />
                </div>
              </div>
              <div class="col"
                *ngIf="block.get('modification')?.value !== 'substitute' && block.get('modification')?.value !== 'person'">
                <app-dea-label class="fs-075r"
                  [label]="block.get('modification')?.value == 'exit' ? 'Orario uscita anticipata:' : 'Orario ingresso posticipato'"></app-dea-label>
                <div class="row m-0 p-0">
                  <div class="col m-0 p-0">
                    <input type="time" formControlName="time" class="form-control dea-form-input w-100"
                      placeholder="Modifica orario scolastico" />
                  </div>
                </div>
              </div>
              <div class="vr"></div>
              <div class="col">
                <app-dea-label class="fs-075r" [label]="'Note:'"></app-dea-label>
                <input formControlName="note" type="text" class="form-control dea-form-input" placeholder="">
              </div>
            </div>
            <div class="mt-2 d-flex flex-row gap-2">
              <div class="fs-055r fw-lighter opacity-50">Event-ID: {{ block.get('slot.original')?.value }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- VERSIONE CON FORM-GROUP -->
  </form>
  <div class="mt-5 d-flex justify-content-end gap-2">
    <button *ngIf="!isPosting" mat-flat-button color="secondary" class="fw-bold px-3 py-1 rounded-pill"
      aria-label="Annulla" title="Annulla" (click)="backOnePage()">
      Annulla
    </button>
    <button type="button" mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill"
      (click)="postFormData()">
      Salva
    </button>
  </div>
</div>