export enum Department {
  SECONDARIA_DI_I_GRADO = 'Secondaria di I Grado',
  LICEO_DELLE_SCIENZE_UMANE = 'Liceo delle Scienze Umane',
  LICEO_DELLO_SPORT = 'Liceo dello Sport',
  LICEO_SCIENTIFICO = 'Liceo Scientifico',
  LICEO_LINGUISTICO = 'Liceo Linguistico',
  LICEO_CLASSICO = 'Liceo Classico',
  ITEM = 'ITEM',
}

export class DepartmentUtils {
  public static fromString(str?: string): Department | undefined {
    switch (str) {
      case Department.SECONDARIA_DI_I_GRADO:
        return Department.SECONDARIA_DI_I_GRADO;
      case Department.LICEO_DELLE_SCIENZE_UMANE:
        return Department.LICEO_DELLE_SCIENZE_UMANE;
      case Department.LICEO_DELLO_SPORT:
        return Department.LICEO_DELLO_SPORT;
      case Department.LICEO_SCIENTIFICO:
        return Department.LICEO_SCIENTIFICO;
      case Department.LICEO_LINGUISTICO:
        return Department.LICEO_LINGUISTICO;
      case Department.LICEO_CLASSICO:
        return Department.LICEO_CLASSICO;
      case Department.ITEM:
        return Department.ITEM;
      default:
        return undefined;
    }
  }

  public static getDepartmentsStrings(): string[] {
    return [
      Department.SECONDARIA_DI_I_GRADO,
      Department.LICEO_DELLE_SCIENZE_UMANE,
      Department.LICEO_DELLO_SPORT,
      Department.LICEO_SCIENTIFICO,
      Department.LICEO_LINGUISTICO,
      Department.LICEO_CLASSICO,
      Department.ITEM,
    ];
  }

  public static getDepartments(): Department[] {
    return [
      Department.SECONDARIA_DI_I_GRADO,
      Department.LICEO_DELLE_SCIENZE_UMANE,
      Department.LICEO_DELLO_SPORT,
      Department.LICEO_SCIENTIFICO,
      Department.LICEO_LINGUISTICO,
      Department.LICEO_CLASSICO,
      Department.ITEM,
    ];
  }

  public static getShortName(str?: string): string | undefined {
    switch (str) {
      case Department.SECONDARIA_DI_I_GRADO:
        return 'ME';
      case Department.LICEO_DELLE_SCIENZE_UMANE:
        return 'SU';
      case Department.LICEO_DELLO_SPORT:
        return 'SP';
      case Department.LICEO_SCIENTIFICO:
        return 'SC';
      case Department.LICEO_LINGUISTICO:
        return 'LL';
      case Department.LICEO_CLASSICO:
        return 'CL';
      case Department.ITEM:
        return 'IT';
      default:
        return undefined;
    }
  }
}
