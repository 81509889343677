import { DeaUserListingDialogComponentOutput } from 'src/app/constants/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarService } from 'src/app/services/calendar.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { User } from 'src/app/models/user/user.model';
import { Component, Inject } from '@angular/core';
import * as moment from 'moment-timezone';

export interface TimetableReplacementOptionsDialogComponentConfigs {
  start: moment.Moment | undefined;
  end: moment.Moment | undefined;
  tz: string | undefined;
  eventId: string | undefined;
  classId: string | undefined;
  exclude: string[] | undefined;
}

class TimetableReplacementOption {
  constructor(
    public user: User,
    public hasDisposizione: boolean | undefined,
    public hasClasse: boolean | undefined
  ) {}
}

@Component({
  selector: 'app-timetable-replacement-options-dialog',
  templateUrl: './timetable-replacement-options-dialog.component.html',
  styleUrl: './timetable-replacement-options-dialog.component.scss',
})
export class TimetableReplacementOptionsDialogComponent {
  public options: TimetableReplacementOption[] = [];
  public isError: boolean = false;
  public loading: boolean = true;
  public error?: ApiError;

  private _configs:
    | TimetableReplacementOptionsDialogComponentConfigs
    | undefined;

  constructor(
    private _calendarService: CalendarService,
    public dialogRef: MatDialogRef<TimetableReplacementOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: TimetableReplacementOptionsDialogComponentConfigs
  ) {
    this._configs = data;
  }

  ngOnInit(): void {
    this.getData();
  }

  onSelected(user?: User): void {
    this.dialogRef.close({
      id: user?.id || '',
      nome: user?.nome || '',
      cognome: user?.cognome || '',
      secondo_nome: user?.secondo_nome || '',
    } as DeaUserListingDialogComponentOutput);
  }

  async getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._calendarService
      .getTimetableReplacementOptionsListing({
        eventId: this._configs?.eventId,
        start: this._configs?.start,
        end: this._configs?.end,
        tz: this._configs?.tz,
        exclude: this._configs?.exclude,
      })
      .subscribe({
        next: (response) => {
          this.options = response.data.options.map(
            (json: any) =>
              new TimetableReplacementOption(
                new User(json),
                json.hasDisposizione as boolean,
                json.hasClasse as boolean
              )
          );
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error(
            'Timetable Replacement Options Dialog Listing Error: ',
            error
          );
        },
      })
      .add(() => (this.loading = false));
  }
}
