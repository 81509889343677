import { SchoolbookService } from 'src/app/services/schoolbook.service';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Schoolbook } from 'src/app/models/schoolbook.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'src/app/models/subject.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-schoolbooks-dialog',
  templateUrl: './schoolbooks-dialog.component.html',
  styleUrls: ['./schoolbooks-dialog.component.scss'],
})
export class SchoolbooksDialogComponent {
  @Input() linkMainBtnQueryParams: object = {};
  @Input() limit: number = 10;
  @Input() page: number = 0;
  @Input() type?: string;

  public loading: boolean = true;
  public isError: boolean = false;
  public pagination?: ApiPagination;
  public schoolbooks: Schoolbook[] = [];
  public error?: ApiError;
  private text?: string;

  constructor(
    private _schoolbookService: SchoolbookService,
    public dialogRef: MatDialogRef<SchoolbooksDialogComponent>
  ) {}

  onSelected(id?: string): void {
    const selectedSchoolbook: Schoolbook[] = id
      ? this.schoolbooks.filter((s) => s.id === id)
      : [];
    const dialogOutput: any = {
      id: selectedSchoolbook[0].id || '',
      dea_id: selectedSchoolbook[0].dea_id || '',
      titolo: selectedSchoolbook[0].titolo || '',
      isbn: selectedSchoolbook[0].isbn || '',
    };
    this.dialogRef.close(dialogOutput);
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._schoolbookService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
      })
      .subscribe({
        next: (data) => {
          this.pagination = new ApiPagination(data?.pagination);
          this.schoolbooks = data?.data?.map(
            (json: any) => new Schoolbook(json)
          );
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Schoolbooks Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
