import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { API } from 'src/app/constants/api';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-temporary-credentials',
  templateUrl: './temporary-credentials.component.html',
  styleUrls: ['./temporary-credentials.component.scss'],
})
export class TemporaryCredentialsComponent {
  public loading = false;

  public tmpCredentialsForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.email,
    ]),
  });

  get iconUrl(): string {
    return API.deAmicisRingIcon();
  }

  constructor(private _router: Router, private _authService: AuthService) {}

  getTmpCredentials() {
    this.loading = true;
    setTimeout(() => {
      if (!this.tmpCredentialsForm.valid) {
        this.tmpCredentialsForm?.reset();
        this.loading = false;
        return;
      }
      this._authService
        .tmpCredentials(
          this.tmpCredentialsForm?.value?.email?.toString().trim()
        )
        .subscribe({
          next: () => this._router.navigateByUrl(AppPaths.TEMP_LOGIN),
          error: (err) => {
            this.loading = false;
            console.error('Error:', err);
          },
        })
        .add(() => this.tmpCredentialsForm?.reset());
    }, 1500);
  }
}
