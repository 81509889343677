import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent {
  @Input() label: string = '';
  @Input() value: string = '0';
  @Input() link: string = '';

  constructor(private _router: Router) {}

  onClick() {
    if (this.link != '') {
      this._router.navigateByUrl(this.link);
    }
  }
}
