import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-create-new-folder-dialog',
  templateUrl: './create-new-folder-dialog.component.html',
  styleUrls: ['./create-new-folder-dialog.component.scss'],
})
export class CreateNewFolderDialogComponent {
  newFolderForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });

  constructor(
    public _dialogRef: MatDialogRef<CreateNewFolderDialogComponent>
  ) {}

  onConfirm(): void {
    if (this.newFolderForm.valid) {
      this._dialogRef.close(this.newFolderForm?.get('name')?.value);
    }
  }

  onDismiss(): void {
    this._dialogRef.close(undefined);
  }
}
