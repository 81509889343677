export enum UserTypeFrontendStr {
  STUDENT = 'Studente',
  TEACHER = 'Docente',
  PARENT = 'Genitore',
  STAFF = 'Staff',
  TUTOR = 'Tutore',
}

export enum UserTypeBackendStr {
  STUDENT = 'Student',
  TEACHER = 'Teacher',
  PARENT = 'Parent',
  STAFF = 'Staff',
  TUTOR = 'Tutor',
}

export class UserType {
  public static readonly STUDENT = new UserType(
    UserTypeFrontendStr.STUDENT,
    UserTypeBackendStr.STUDENT
  );
  public static readonly TEACHER = new UserType(
    UserTypeFrontendStr.TEACHER,
    UserTypeBackendStr.TEACHER
  );
  public static readonly PARENT = new UserType(
    UserTypeFrontendStr.PARENT,
    UserTypeBackendStr.PARENT
  );
  public static readonly STAFF = new UserType(
    UserTypeFrontendStr.STAFF,
    UserTypeBackendStr.STAFF
  );
  public static readonly TUTOR = new UserType(
    UserTypeFrontendStr.TUTOR,
    UserTypeBackendStr.TUTOR
  );

  private constructor(
    public readonly frontend: string,
    public readonly backend: string
  ) {}

  public static fromString(str?: string): UserType | undefined {
    switch (str) {
      case UserTypeBackendStr.STUDENT:
        return UserType.STUDENT;
      case UserTypeFrontendStr.STUDENT:
        return UserType.STUDENT;
      case UserTypeFrontendStr.TEACHER:
        return UserType.TEACHER;
      case UserTypeBackendStr.TEACHER:
        return UserType.TEACHER;
      case UserTypeFrontendStr.PARENT:
        return UserType.PARENT;
      case UserTypeBackendStr.PARENT:
        return UserType.PARENT;
      case UserTypeFrontendStr.STAFF:
        return UserType.STAFF;
      case UserTypeBackendStr.STAFF:
        return UserType.STAFF;
      case UserTypeFrontendStr.TUTOR:
        return UserType.TUTOR;
      case UserTypeBackendStr.TUTOR:
        return UserType.TUTOR;
      default:
        return undefined;
    }
  }

  public static getUserTypes(): UserType[] {
    return [
      UserType.STUDENT,
      UserType.TEACHER,
      UserType.PARENT,
      UserType.STAFF,
      UserType.TUTOR,
    ];
  }

  public static getFrontendUserTypes(): string[] {
    return [
      UserType.STUDENT.frontend,
      UserType.TEACHER.frontend,
      UserType.PARENT.frontend,
      UserType.STAFF.frontend,
      UserType.TUTOR.frontend,
    ];
  }

  public static getBackendUserTypes(): string[] {
    return [
      UserType.STUDENT.backend,
      UserType.TEACHER.backend,
      UserType.PARENT.backend,
      UserType.STAFF.backend,
      UserType.TUTOR.backend,
    ];
  }
}

// export enum UserType {
//   STUDENT = 'Studente',
//   TEACHER = 'Docente',
//   PARENT = 'Genitore',
//   STAFF = 'Staff',
//   TUTOR = 'Tutore',
// }

// export class UserTypeUtil {
//   public static fromString(str?: string): UserType | undefined {
//     switch (str) {
//       case 'Student':
//         return UserType.STUDENT;
//       case 'Teacher':
//         return UserType.TEACHER;
//       case 'Parent':
//         return UserType.PARENT;
//       case 'Staff':
//         return UserType.STAFF;
//       case 'Tutor':
//         return UserType.TUTOR;
//       default:
//         return undefined;
//     }
//   }

//   public static toBackend(type: any): string | undefined {
//     switch (type) {
//       case UserType.STUDENT:
//         return 'Student';
//       case UserType.TEACHER:
//         return 'Teacher';
//       case UserType.PARENT:
//         return 'Parent';
//       case UserType.STAFF:
//         return 'Staff';
//       case UserType.TUTOR:
//         return 'Tutor';
//       default:
//         return undefined;
//     }
//   }

//   public static getUserTypes(): UserType[] {
//     return [
//       UserType.STUDENT,
//       UserType.TEACHER,
//       UserType.PARENT,
//       UserType.STAFF,
//       UserType.TUTOR,
//     ];
//   }
// }
