import { Device } from 'src/app/models/tech/device.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-device-box',
  templateUrl: './user-device-box.component.html',
  styleUrls: ['./user-device-box.component.scss'],
})
export class UserDeviceBoxComponent implements OnInit {
  scr: string = 'https://static.deamicis.cloud/transparent.png';
  @Input() device?: Device;

  ngOnInit(): void {
    if (this.device) this.scr = this.device.getIconPath();
  }
}
