import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dea-tmp-credentials-dialog',
  templateUrl: './dea-tmp-credentials-dialog.component.html',
  styleUrl: './dea-tmp-credentials-dialog.component.scss',
})
export class DeaTmpCredentialsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeaTmpCredentialsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { email?: string; tmpPassword?: string }
  ) {}

  onDismiss(): void {
    this.dialogRef.close();
  }
}
