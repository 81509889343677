import { Component } from '@angular/core';

@Component({
  selector: 'app-screen-size-manager',
  templateUrl: './screen-size-manager.component.html',
  styleUrls: ['./screen-size-manager.component.scss']
})
export class ScreenSizeManagerComponent {

}
