import isMongoId from 'validator/es/lib/isMongoId';
import { Headquarter } from '../headquarter';
import { User } from '../user/user.model';

export class Calendar {
  id?: string;
  headquarters?: Headquarter[];
  owner?: User;
  name?: string;
  description?: string;
  color?: string;

  // shared_with: [{
  //     user: { type: mongoose.Schema.Types.ObjectId, ref: 'UserModel', required: true },
  //     permissions: { type: String, enum: ['read', 'write', 'admin'], required: true, default: 'read' }
  // }],
  // access_groups: [{
  //     group: { type: String, enum: userDiscriminatorKeysArray, required: true },
  //     permissions: { type: String, enum: ['read', 'write', 'admin'], required: true, default: 'read' }
  // }],
  // visibility: { type: String, enum: ['public', 'private'], default: 'private' },

  constructor(json?: any) {
    if (!json) return;
    if (isMongoId(json.toString().trim())) {
      this.id = json.toString().trim();
      return;
    }
    this.id = json['_id'] || json['id'];
    this.headquarters = json['headquarters']
      ? (json['headquarters'] ?? []).map((h: any) => Headquarter.fromString(h))
      : undefined;
    this.owner = json['owner'] ? new User(json['owner']) : undefined;
    this.name = json['name'];
    this.description = json['description'];
    this.color = json['color'];
  }

  getOwnerFullName(): string | undefined {
    return this.owner?.getFullName();
  }
}
