import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Resource } from 'src/app/models/resource.model';

@Component({
  selector: 'app-resources-section',
  templateUrl: './resources-section.component.html',
  styleUrls: ['./resources-section.component.scss'],
})
export class ResourcesSectionComponent {
  @Output() pushPathEmitter = new EventEmitter<string>();
  @Output() downloadEmitter = new EventEmitter<string>();
  @Output() deleteEmitter = new EventEmitter<string>();
  @Input() downloadingId: string = '';
  @Input() resources: Resource[] = [];
  @Input() label: string = '';

  onClick(index: number) {
    if (this.resources[index].isFolder()) {
      this.pushPathEmitter.emit(
        `${this.resources[index].id},${this.resources[index].name}`
      );
    } else this.downloadEmitter.emit(this.resources[index].id);
  }

  onDelete(id?: string) {
    if (!id) return;
    this.deleteEmitter.emit(id);
  }
}
