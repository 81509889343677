export class AppJsonDecoder {
  static optBool(jsonValue: any): undefined | boolean {
    return jsonValue === undefined
      ? undefined
      : jsonValue.toString().toLowerCase().trim() === 'true';
  }

  static optDate(jsonValue: any): undefined | Date {
    return jsonValue === undefined ? undefined : new Date(jsonValue);
  }
}
