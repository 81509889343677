<div class="m-0 p-0" [ngStyle]="{'display': overMinSize ? 'block' : 'none'}">
  <router-outlet></router-outlet>
</div>
<div class="m-0 p-0" [ngStyle]="{'display': overMinSize ? 'none' : 'block'}">
  <app-screen-size-manager></app-screen-size-manager>
</div>
<!-- MAIN-LOADER -->
<div class="justify-content-center align-items-center" [ngStyle]="{'display': showMainLoader ? 'flex' : 'none'}"
  style="position: fixed; top: 0; left: 0; width: 100vw;  height: 100vh; background-color: rgba(0, 0, 0, 0.5); z-index: 1050; cursor: progress;">
  <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
</div>
<!-- MAIN-LOADER -->