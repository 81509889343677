<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex flex-column justify-content-center align-items-center gap-3" style="height: 96px;">
      <div>{{error?.message}}</div>
      <button class="mx-auto" mat-stroked-button color="primary" (click)="postAssignment()">
        Assegna Dispositivo
      </button>
      <!-- <button class="mx-auto" mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
        Crea blocco Tech
      </button>
      <mat-menu #menu="matMenu" class="w-100">
        <button mat-menu-item>Assegnazione</button>
        <button mat-menu-item>Assicurazione</button>
        <button mat-menu-item disabled>Furto / Smarrimento</button>
        <button mat-menu-item disabled>Guasto / Rottura</button>
        <button mat-menu-item disabled>Restituzione</button>
        <button mat-menu-item disabled>Riscatto</button>
      </mat-menu> -->
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <div class="row m-0 p-0">
      <div class="col-auto m-0 p-0">
        <div class="user-img-spacer"></div>
      </div>
      <div class="col m-0 p-0 ps-3">
        <div class="hstack m-0 p-0">
          <button class="ms-auto" mat-stroked-button color="primary" (click)="postAssignment()">
            Assegna Dispositivo
          </button>
          <!-- <mat-menu #menu="matMenu" class="w-100" xPosition="before" yPosition="below">
            <button mat-menu-item>Assegnazione</button>
            <button mat-menu-item>Assicurazione</button>
            <button mat-menu-item disabled>Furto / Smarrimento</button>
            <button mat-menu-item disabled>Guasto / Rottura</button>
            <button mat-menu-item disabled>Restituzione</button>
            <button mat-menu-item disabled>Riscatto</button>
          </mat-menu> -->
        </div>
        <div class="row m-0 mt-3 p-0" *ngFor="let aggregatedTech of aggregatedTechs;">
          <app-user-tech-block [aggregatedTech]="aggregatedTech" [userId]="userId"
            (shouldReloadTechData)="getTechData()" style="padding-right: 0;">
          </app-user-tech-block>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>