import { AppTools } from '../utils/tools';

export class CfData {
  codice_fiscale?: string;
  name?: string;
  surname?: string;
  gender?: string;
  day?: number;
  year?: number;
  month?: number;
  birthday?: string;
  birthplace?: string;
  birthplaceProvincia?: string;

  constructor(json?: any) {
    if (!json) return;
    this.codice_fiscale = json?.codice_fiscale?.toUpperCase();
    this.name = json?.dati?.name;
    this.surname = json?.dati?.surname;
    this.gender = json?.dati?.gender?.toUpperCase();
    this.day = json?.dati?.day;
    this.month = json?.dati?.month;
    this.year = json?.dati?.year;
    this.birthday = json?.dati?.birthday;
    this.birthplace = json?.dati?.birthplace
      ? AppTools.capitalizeFirstLetterOfEachWord(json.dati.birthplace)
      : undefined;
    this.birthplaceProvincia = json?.dati?.birthplaceProvincia?.toUpperCase();
  }
}
