<ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
<ng-template #loadingTrue>
  <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
    <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #loadingFalse>
  <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
  <ng-template #isErrorTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <div class="text-center">
        <div>{{error?.message}}</div>
        <button class="mx-auto mt-3" mat-stroked-button color="primary" (click)="openUploadDialog()">
          Carica Documento
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #isErrorFalse>
    <div class="row m-0 p-0">
      <div class="col-auto m-0 p-0">
        <div class="user-img-spacer"></div>
      </div>
      <div class="col m-0 p-0 ps-3">
        <div class="hstack">
          <button class="ms-auto" mat-stroked-button color="primary" (click)="openUploadDialog()">
            Carica Documento
          </button>
        </div>
        <div class="mt-4 d-flex flex-column gap-4">
          <div *ngIf="groupedDocument.documents.length != 0" class="w-100 border rounded m-0 p-0 p-3">
            <div class="d-flex flex-row gap-3">
              <mat-icon class="pe-1 mb-1 opacity-25" aria-hidden=" true" fontIcon="task"></mat-icon>
              <div class="text-decoration-underline fw-medium">Documenti</div>
            </div>
            <div *ngFor="let doc of groupedDocument.documents; let first = first;">
              <app-details-divider></app-details-divider>
              <app-user-document-row [document]="doc" (userDocRowEmitter)="getDocumentsData()"></app-user-document-row>
            </div>
          </div>
          <div *ngIf="groupedDocument.besAttachments.length != 0" class="w-100 border rounded m-0 p-0 p-3">
            <div class="d-flex flex-row gap-3">
              <mat-icon class="pe-1 mb-1 opacity-25" aria-hidden=" true" fontIcon="diagnosis"></mat-icon>
              <div class="text-decoration-underline fw-medium">Allegati BES</div>
            </div>
            <div *ngFor="let doc of groupedDocument.besAttachments;">
              <app-details-divider></app-details-divider>
              <app-user-document-row [document]="doc" (userDocRowEmitter)="getDocumentsData()"></app-user-document-row>
            </div>
          </div>
          <div *ngIf="groupedDocument.atlAttachments.length != 0" class="w-100 border rounded m-0 p-0 p-3">
            <div class="d-flex flex-row gap-3">
              <mat-icon class="pe-1 mb-1 opacity-25" aria-hidden=" true" fontIcon="sports_basketball"></mat-icon>
              <div class="text-decoration-underline fw-medium">Allegati ATL</div>
            </div>
            <div *ngFor="let doc of groupedDocument.atlAttachments;">
              <app-details-divider></app-details-divider>
              <app-user-document-row [document]="doc" (userDocRowEmitter)="getDocumentsData()"></app-user-document-row>
            </div>
          </div>
          <div *ngIf="groupedDocument.certificates.length != 0" class="w-100 border rounded m-0 p-0 p-3">
            <div class="d-flex flex-row gap-3">
              <mat-icon class="pe-1 mb-1 opacity-25" aria-hidden=" true" fontIcon="verified"></mat-icon>
              <div class="text-decoration-underline fw-medium">Attestati</div>
            </div>
            <div *ngFor="let doc of groupedDocument.certificates;">
              <app-details-divider></app-details-divider>
              <app-user-document-row [document]="doc" (userDocRowEmitter)="getDocumentsData()"></app-user-document-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
