import { DeaSingleBtnDialogComponent } from 'src/app/components/dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { VERSION } from 'src/environments/version';
import { API } from 'src/app/constants/api';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import isEmail from 'validator/es/lib/isEmail';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  private _ref?: MatDialogRef<DeaSingleBtnDialogComponent>;
  public loading = false;

  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  get iconUrl(): string {
    return API.deAmicisRingIcon();
  }

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _clientStorageService: ClientStorageService
  ) {
    console.info('Application version hash:', VERSION.hash);
  }

  onUsernameBlur() {
    const regex = new RegExp('^[a-zA-Z]{2}[0-9]{4}$', 'gm');
    let username: string = this.loginForm?.value?.username?.toString().trim();
    if (regex.test(username)) username = username.toUpperCase();
    else if (isEmail(username)) username = username.toLowerCase();
    this.loginForm?.get('username')?.setValue(username);
  }

  doLogin() {
    this.loading = true;
    if (this._ref) return;
    if (!this.loginForm.valid) {
      return this._openErrorDialog(
        new ApiError({
          title: 'Attenzione',
          message: 'Verifica le credenziali inserite',
        })
      );
    }
    this._authService
      .login(
        this.loginForm?.value?.username?.toString().trim(),
        this.loginForm?.value?.password?.toString()
      )
      .subscribe({
        next: () => this._clientStorageService.setSessionCheck(true),
        error: (error) => {
          this._clientStorageService.setSessionCheck(false);
          this._openErrorDialog(new ApiError(error));
        },
      })
      .add(() => {
        this.loading = false;
        this.loginForm?.reset();
        if (this._clientStorageService.getSessionCheck()) {
          this._router.navigateByUrl(AppPaths.DASHBOARD);
        }
      });
  }

  private _openErrorDialog(error: ApiError): void {
    this._ref = this._dialog.open(DeaSingleBtnDialogComponent, {
      data: { error: error },
      maxWidth: '450px',
      minWidth: '450px',
      autoFocus: false,
      restoreFocus: false,
    });
    this._ref
      .afterClosed()
      .subscribe()
      .add(() => delete this._ref);
  }
}
