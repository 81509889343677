<app-dea-widget-wrapper>
  <div class="d-flex align-items-center gap-2">
    <div class="w-100">
      <app-listing-top-bar (textEmitter)="onText($event)"></app-listing-top-bar>
      <div class="v-spacer"></div>
      <app-dea-date-stepper (date)="onNewDate($event)" [todayRight]="true"></app-dea-date-stepper>
    </div>
  </div>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="table-responsive">
        <table class="table table-borderless m-0" style="overflow-y: hidden;">
          <thead class="m-0">
            <tr class="dea-table-row-header m-0">
              <th scope="col-auto" style="width: 32px;"></th>
              <th scope="col-auto" style="min-width: 120px;">Cognome</th>
              <th scope="col-auto" style="min-width: 120px;">Nome</th>
              <th scope="col-auto" style="min-width: 120px;">Classe</th>
              <th scope="col-auto" style="min-width: 120px;">Telefono</th>
              <th scope="col-auto" style="min-width: 120px;">Ritardi</th>
              <th scope="col-auto" style="min-width: 120px;">Azioni</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let justificationsCard of justificationsCards;" class="dea-radius pointer">
              <td class="col-auto" style="width: 48px;" routerLink="/utente/{{justificationsCard.user?.id}}"
                title="{{justificationsCard.user?.getFullName()}}">
                <app-dea-thumbnail-img size="32" id="{{justificationsCard.user?.id}}"></app-dea-thumbnail-img>
              </td>
              <td class="col-auto" routerLink="/utente/{{justificationsCard.user?.id}}"
                title="{{justificationsCard.user?.getFullName()}}">
                {{justificationsCard.cognome}}
              </td>
              <td class="col-auto py-auto" routerLink="/utente/{{justificationsCard.user?.id}}"
                title="{{justificationsCard.user?.getFullName()}}">
                {{justificationsCard.nome}}
              </td>
              <td class="col-auto py-auto" routerLink="/utente/{{justificationsCard.user?.id}}"
                title="{{justificationsCard.user?.getFullName()}}">
                {{justificationsCard.class?.getShortName()}}
              </td>
              <td class="col-auto py-auto">
                <app-phone-label
                  [phone]="justificationsCard.user?.contatti?.getFirstPhoneNumber() ?? ''"></app-phone-label>
              </td>
              <td class="col-auto py-auto" routerLink="/utente/{{justificationsCard.user?.id}}">
                <app-justifications-counter *ngIf="justificationsCard.type === 0 || justificationsCard.type === 1"
                  [slots]="justificationsCard.entry_slots" [cards_counter]="justificationsCard.entry_cards_counter"
                  [slots_counter]="justificationsCard.entry_slots_counter">
                </app-justifications-counter>
                <app-justifications-counter *ngIf="justificationsCard.type === 0 || justificationsCard.type === 2"
                  [slots]="justificationsCard.exit_slots" [cards_counter]="justificationsCard.exit_cards_counter"
                  [slots_counter]="justificationsCard.exit_slots_counter">
                </app-justifications-counter>
              </td>
              <td class="col py-auto">
                <mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="openPdfPreviewPDF(justificationsCard.id)"
                  title="Visualizza anteprima">
                  visibility
                </mat-icon>
                <mat-icon class="ms-3 m-0 p-0 opacity-25 dea-mat-icon-btn" (click)="downloadPDF(justificationsCard.id)"
                  title="Download Modulo PDF">
                  download
                </mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)">
      </app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>
