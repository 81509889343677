import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value?: string | null): unknown {
    if (value) {
      return parsePhoneNumberFromString(value, 'IT')?.number ?? value;
    }
    return value;
  }
}
