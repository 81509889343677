import { Component } from '@angular/core';

@Component({
  selector: 'app-documents-forms',
  templateUrl: './documents-forms.component.html',
  styleUrls: ['./documents-forms.component.scss']
})
export class DocumentsFormsComponent {

}
