<app-dea-widget-wrapper>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="hstack">
        <div class="ms-auto">
          <button *ngIf="!isPosting" mat-flat-button color="secondary" class="fw-bold px-3 py-1 rounded-pill"
            aria-label="Annulla" title="Annulla" (click)="backOnePage()">
            Annulla
          </button>
        </div>
      </div>
      <div class="v-spacer"></div>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="hstack">
        <div class="fw-semibold">Classe</div>
        <div class="ms-3 opacity-50">{{class?.dea_id}}</div>
        <div class="ms-auto">
          <button *ngIf="!isPosting" mat-flat-button color="secondary" class="fw-bold px-3 py-1 rounded-pill"
            aria-label="Annulla" title="Annulla" (click)="backOnePage()">
            Annulla
          </button>
        </div>
        <div class="ms-2">
          <button *ngIf="!isPosting" mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill"
            aria-label="Conferma Liste e Crea Classe" title="Conferma Liste e Crea Classe" (click)="postData()">
            Conferma Liste e Crea Classe
          </button>
          <button *ngIf="isPosting" mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill"
            aria-label="Conferma Liste e Crea Classe" title="Conferma Liste e Crea Classe" disabled>
            <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon> Conferma Liste e Crea Classe
          </button>
        </div>
      </div>
      <div class="v-spacer"></div>
      <div class="hstack">
        <div class="fs-4 fw-semibold">{{class?.getName()}}</div>
      </div>
      <div class="hstack gap-3">
        <div class="">{{class?.anno_scolastico}}</div>
        <div class="vr"></div>
        <div class="">{{class?.sede?.frontend}}</div>
      </div>
      <div class="v-spacer"></div>
      <div class="row m-0 p-0">
        <div class="col m-0 p-0">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="100ms">
            <mat-tab label="Studenti">
              <div class="v-spacer"></div>
              <div class="dea-fake-table-row-header row m-0 p-0 row-cols-4">
                <div scope="col m-0 p-0" style="width: 48px !important;"></div>
                <div scope="col">Cognome</div>
                <div scope="col">Nome</div>
                <div scope="col">Secondo Nome</div>
              </div>
              <div *ngFor="let user of (class?.studenti || []);"
                class="user-listing-table-row dea-radius row m-0 p-0 row-cols-4">
                <div class="col m-0 d-flex align-items-center" style="width: 48px !important;"
                  title="{{user?.getFullName()}}">
                  <mat-checkbox color="primary" [labelPosition]="'before'" [checked]="isChecked(user?.id)"
                    (change)="onChangeCheckbox(user?.id)"></mat-checkbox>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.getFullName()}}">
                  <div>{{user?.cognome}}</div>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.getFullName()}}">
                  <div>{{user?.nome}}</div>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.getFullName()}}">
                  <div>{{user?.secondo_nome}}</div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Docenti">
              <div class="v-spacer"></div>
              <div class="dea-fake-table-row-header row m-0 p-0 row-cols-5">
                <div scope="col m-0 p-0" style="width: 48px !important;"></div>
                <div scope="col">Cognome</div>
                <div scope="col">Nome</div>
                <div scope="col">Secondo Nome</div>
                <div scope="col">Discipline</div>
              </div>
              <div *ngFor="let user of (class?.docenti || []);"
                class="user-listing-table-row dea-radius row m-0 p-0 row-cols-5">
                <div class="col m-0 d-flex align-items-center" style="width: 48px !important;"
                  title="{{user?.docente?.getFullName()}}">
                  <mat-checkbox color="primary" [labelPosition]="'before'" [checked]="isChecked(user?.docente?.id)"
                    (change)="onChangeCheckbox(user?.docente?.id)"></mat-checkbox>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.docente?.getFullName()}}">
                  <div>{{user?.docente?.cognome}}</div>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.docente?.getFullName()}}">
                  <div>{{user?.docente?.nome}}</div>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.docente?.getFullName()}}">
                  <div>{{user?.docente?.secondo_nome}}</div>
                </div>
                <div class="col m-0 d-flex align-items-center" title="{{user?.docente?.getFullName()}}">
                  <!-- <div>{{user?.discipline}}</div> -->
                  *** discipline? ***
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>