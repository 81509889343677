<app-dea-widget-wrapper>
  <!-- RICERCA -->
  <div class="row m-0 p-0" style="max-width: calc(100vw - var(--dea-side-drawer-w) - (var(--dea-spacer) * 4));">
    <div class="d-flex flex-row-reverse m-0 p-0">
      <!-- <button mat-stroked-button color="primary" class="fw-bold px-3 py-1 rounded-pill me-2"
            [routerLink]="'replacements'">
            Statistiche
          </button> -->
      <button mat-flat-button color="primary" class="fw-bold px-4 py-1 rounded-pill" [routerLink]="'form'">
        Crea nuovo modulo
      </button>
    </div>
  </div>
  <!-- RICERCA -->
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="error !== undefined; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="d-flex flex-column gap-3 mb-3">
        <div *ngFor="let summary of summaries;" class="border rounded p-3 d-flex flex-column gap-2">
          <div class="d-flex flex-row justify-content-between align-items-start">
            <div class="fs-065r opacity-50 d-flex align-items-center py-1">ID: {{summary.id}}</div>
            <div>
              <mat-icon class="ms-3 p-0 opacity-25 dea-mat-icon-btn" title="Visualizza Modulo PDF"
                (click)="openSummaryPDF(summary.id)">
                visibility
              </mat-icon>
              <mat-icon class="ms-3 m-0 p-0 opacity-25 dea-mat-icon-btn" title="Download Modulo PDF"
                (click)="downloadSummaryPDF(summary.id)">
                download
              </mat-icon>
            </div>
          </div>
          <div class="d-flex flex-row gap-2">
            <div>
              <div class="border rounded w-auto h-auto">
                <div class="w-100 text-center border-bottom px-2">{{summary?.date | getMonthName}}</div>
                <div class="w-100 text-center fs-3">{{summary?.date | date:'dd'}}</div>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap gap-2 flex-grow-1">
              <app-dea-label [label]="'Docenti Assenti:'"></app-dea-label>
              <div *ngFor="let teacher of summary.teachers;">{{teacher.getFullName()}}</div>
            </div>
            <div class="d-flex flex-row flex-wrap gap-2" style="min-width: 280px;">
              <app-dea-label [label]="'Totale Blocchi:'"></app-dea-label>
              <div>{{summary.blocks?.length ?? 0}}</div>
            </div>
          </div>
          <div *ngIf="summary.canEdit" class="d-flex justify-content-end align-items-center gap-2">
            <button type="button" title="Elimina Modulo" (click)="deleteSummary(summary.id)" mat-stroked-button
              class="d-flex justify-content-center align-items-center gap-2 dea-mat-icon-btn">
              <div>Elimina</div><mat-icon mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn">
                delete
              </mat-icon>
            </button>
            <button type="button" title="Modifica Modulo"
              [routerLink]="'/timetable-calendar/replacements/form/'+summary.id" mat-stroked-button
              class="d-flex justify-content-center align-items-center gap-2 dea-mat-icon-btn">
              <div>Modifica</div><mat-icon mat-icon class="m-0 p-0 opacity-25 dea-mat-icon-btn">
                edit
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
      <app-listing-pagination-bar [paginationData]="pagination"
        (pageEmitter)="onPage($event)"></app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>