import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getMonthName',
})
export class GetMonthNamePipe implements PipeTransform {
  transform(value?: unknown, ...args: unknown[]): unknown {
    if (value) {
      let date: Date = new Date(value.toString());
      switch (date.getMonth()) {
        case 0:
          return 'Gennaio';
        case 1:
          return 'Febbraio';
        case 2:
          return 'Marzo';
        case 3:
          return 'Aprile';
        case 4:
          return 'Maggio';
        case 5:
          return 'Giugno';
        case 6:
          return 'Luglio';
        case 7:
          return 'Agosto';
        case 8:
          return 'Settembre';
        case 9:
          return 'Ottobre';
        case 10:
          return 'Novembre';
        case 11:
          return 'Dicembre';
        default:
          return '---';
      }
    }
    return null;
  }
}
