import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-email-label',
  templateUrl: './email-label.component.html',
  styleUrls: ['./email-label.component.scss'],
})
export class EmailLabelComponent implements OnInit, OnChanges {
  @Input() email?: string;
  public emailEncoded?: string;

  ngOnInit(): void {
    this.encodeEmail();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.encodeEmail();
  }

  private encodeEmail(): void {
    if (this.email) this.emailEncoded = encodeURIComponent(this.email);
  }
}
