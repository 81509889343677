<div class="d-flex flex-column h-100 w-100" style="height: 100% !important; background-color:azure;">
  <!-- Header -->
  <div class="row row-cols-7 m-0 p-0 w-100">
    @for (day of weekDays; track day) {
    <div class="col m-0 p-0 py-1 text-center border border-025">
      <span class="opacity-50">{{ day.toUpperCase() }}</span>
    </div>
    }
  </div>
  <!-- Body -->
  <div class="row row-cols-7 m-0 p-0 h-100 w-100">
    @for (date of monthDates; track date) {
    <div class="col m-0 p-0 text-center border border-025 d-flex flex-column">
      <div>
        <span class="rounded-circle p-1 fs-065r"
          [ngClass]="date.isSame(mainDate, 'day') && date.isSame(today, 'day') ? 'bg-primary text-white': !date.isSame(mainDate, 'month') ? 'opacity-50':''">
          {{ date.date() }}
        </span>
      </div>
      <div class="h-100 w-100 d-flex align-items-center justify-content-center opacity-50">
        {{ getNumberOfEvents(date) }}
      </div>
    </div>
    }
  </div>
</div>
