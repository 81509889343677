export class GuestCard {
  id?: string;
  dea_id?: string;
  cognome?: string;
  nome?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.dea_id = json['dea_id'];
    this.nome = json['nome'];
    this.cognome = json['cognome'];
  }

  getFullName(): string {
    return `${this.cognome} ${this.nome}`.replaceAll('undefined', '').trim();
  }
}
