import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-data-box',
  templateUrl: './data-box.component.html',
  styleUrls: ['./data-box.component.scss'],
})
export class DataBoxComponent {
  @Output() doubleClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() boldData: boolean = false;
  @Input() label?: string;
  @Input() title?: string;
  @Input() data?: any;

  constructor(private _clipboard: Clipboard) {}

  copyInClipboard(): void {
    this._clipboard.copy(this.data);
  }

  emitDoubleClick(value?: any): void {
    this.doubleClick.emit(value);
  }
}
