export class PhoneContact {
  label?: string;
  phone?: string;
  enable?: boolean;

  constructor(label: string, phone: string, enable: boolean) {
    this.label = label;
    this.phone = phone;
    this.enable = enable;
  }

  public static fromJson(json: any): PhoneContact {
    return new PhoneContact(
      json['etichetta'],
      json['numero'],
      json['enable'] === undefined
        ? true
        : (json['enable'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false
    );
  }

  toJson(): any {
    return { etichetta: this.label, numero: this.phone, enable: this.enable };
  }

  getPhoneContactString(): string {
    return `${this.label} ${this.phone}`.toString().trim();
  }
}
