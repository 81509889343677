import { CreateNewFolderDialogComponent } from './create-new-folder-dialog/create-new-folder-dialog.component';
import { DeaConfirmDialogComponent } from '../dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { ResourceService } from 'src/app/services/resource.service';
import { Resource } from 'src/app/models/resource.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  loading: boolean = true;
  isCreating: boolean = false;
  isUploading: boolean = false;
  downloadingId: string = '';
  folders: Resource[] = [];
  files: Resource[] = [];

  paths: string[] = [];
  namepaths: string[] = ['Risorse'];

  constructor(
    private _dialog: MatDialog,
    private _resouceService: ResourceService
  ) {}

  get systemFolders(): Resource[] {
    return this.folders.filter((folder) => folder.isSystemFolder());
  }

  get baseFolders(): Resource[] {
    return this.folders.filter((folder) => !folder.isSystemFolder());
  }

  ngOnInit(): void {
    this.getData();
  }

  pushPath(id?: string, name?: string) {
    if (!id) return;
    this.namepaths.push(name || '');
    this.paths.push(id);
    this.getData();
  }

  popPath() {
    if (this.paths.length == 0) return;
    this.namepaths.pop();
    this.paths.pop();
    this.getData();
  }

  backToResources() {
    this.namepaths = ['Risorse'];
    this.paths = [];
    this.getData();
  }

  getData() {
    this.loading = true;
    this._resouceService
      .getListing(
        this.paths.length != 0 ? this.paths[this.paths.length - 1] : undefined
      )
      .subscribe({
        next: (data) => {
          this.files = (data?.data?.files || []).map(
            (f: any) => new Resource(f)
          );
          this.folders = (data?.data?.folders || []).map(
            (f: any) => new Resource(f)
          );
        },
        error: (error) => {
          console.error('Get Resources Listing Error:', error);
        },
      })
      .add(() => (this.loading = false));
  }

  triggerFileUpload() {
    const btnId: string = 'resourceFileInput';
    (document.getElementById(btnId) as HTMLButtonElement)?.click();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.uploadResource(event.target.files[0]);
    }
  }

  uploadResource(file?: any) {
    if (!file) return;
    this.isUploading = true;
    const formData = new FormData();
    formData.append(
      'file',
      file,
      encodeURIComponent(file.name || new Date().toISOString().slice(0, 10))
    );
    const subscription = {
      next: (_: any) => setTimeout(() => this.getData(), 500),
      error: (error: any) => {
        console.error('Post Resource Error: ', error);
      },
    };
    this._resouceService
      .upload(
        formData,
        this.paths.length != 0 ? this.paths[this.paths.length - 1] : undefined
      )
      .subscribe(subscription)
      .add(() => (this.isUploading = false));
  }

  downloadResource(id?: string) {
    if (!id) return;
    this.downloadingId = id;
    this._resouceService
      .download(id)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let a = document.createElement('a');
        a.download = decodeURIComponent(
          res.headers.get('originalname') ||
            `${new Date().toISOString()} - De Amicis Ring Resource`
        );
        a.href = window.URL.createObjectURL(blob);
        a.click();
      })
      .add(() => (this.downloadingId = ''));
  }

  deleteResource(id?: string) {
    if (!id) return;
    const deleteConfirmDialogRef = this._dialog.open(
      DeaConfirmDialogComponent,
      {
        maxWidth: '480px',
        minWidth: '480px',
        data: {
          destructive: true,
          title: 'Attenzione!',
          message: 'Sei sicuro di voler eliminare questa risorsa?',
          details: "(L'operazione non sarà reversibile)",
          falseBtn: 'No, annulla',
          trueBtn: 'Sì, elimina',
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );
    deleteConfirmDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === true)
        this._resouceService.delete(id).subscribe({
          next: (_: any) => setTimeout(() => this.getData(), 500),
          error: (error: any) => console.error('Delete Resource Error:', error),
        });
    });
  }

  createNewFolder() {
    const newFolderDialogRef = this._dialog.open(
      CreateNewFolderDialogComponent,
      {
        maxWidth: '480px',
        minWidth: '480px',
        data: {
          destructive: true,
          title: 'Attenzione!',
          message: 'Sei sicuro di voler eliminare questa risorsa?',
          details: "(L'operazione non sarà reversibile)",
          falseBtn: 'No, annulla',
          trueBtn: 'Sì, elimina',
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );
    newFolderDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult !== undefined && dialogResult !== '') {
        this.isCreating = true;
        this._resouceService
          .createFolder(
            dialogResult.toString().trim(),
            this.paths.length != 0
              ? this.paths[this.paths.length - 1]
              : undefined
          )
          .subscribe({
            next: (_: any) => {
              this.loading = true;
              setTimeout(() => this.getData(), 500);
            },
            error: (error: any) => {
              console.error('Create Resource Folder Error:', error);
            },
          })
          .add(() => (this.isCreating = false));
      }
    });
  }
}
