import { ThumbnailCacheService } from 'src/app/services/thumbnail-cache.service';
import { ClientStorageService } from 'src/app/services/client-storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _thumbnailCacheService: ThumbnailCacheService
  ) {}

  ngOnInit(): void {
    this._authService
      .logout()
      .subscribe()
      .add(() => {
        new ClientStorageService().setSessionCheck(false);
        this._thumbnailCacheService.clearAll();
        this._router.navigateByUrl(AppPaths.SESSION);
      });
  }
}
