import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserSportInformation } from 'src/app/models/user/user-sport-information.model';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-atl-details-dialog',
  templateUrl: './atl-details-dialog.component.html',
  styleUrls: ['./atl-details-dialog.component.scss'],
})
export class AtlDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AtlDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserSportInformation
  ) {}

  onDismiss(): void {
    this.dialogRef.close();
  }
}
