import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toItDateDdMmmmYyyy',
})
export class ToItDateDdMmmmYyyyPipe implements PipeTransform {
  transform(value?: unknown, ...args: unknown[]): unknown {
    if (value) {
      let date: Date = new Date(value.toString());
      let dateStr = date.getDate() + '';
      switch (date.getMonth()) {
        case 0:
          dateStr += ' Gennaio';
          break;
        case 1:
          dateStr += ' Febbraio';
          break;
        case 2:
          dateStr += ' Marzo';
          break;
        case 3:
          dateStr += ' Aprile';
          break;
        case 4:
          dateStr += ' Maggio';
          break;
        case 5:
          dateStr += ' Giugno';
          break;
        case 6:
          dateStr += ' Luglio';
          break;
        case 7:
          dateStr += ' Agosto';
          break;
        case 8:
          dateStr += ' Settembre';
          break;
        case 9:
          dateStr += ' Ottobre';
          break;
        case 10:
          dateStr += ' Novembre';
          break;
        case 11:
          dateStr += ' Dicembre';
          break;
        default:
          dateStr += '---';
          break;
      }
      dateStr += ` ${date.getFullYear()}`;
      return dateStr;
    }
    return null;
  }
}
