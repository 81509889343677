import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { UserTechInsuranceDialogComponent } from '../user-tech-dialogs/user-tech-insurance-dialog.component';
import { UserTechBreakingDialogComponent } from '../user-tech-dialogs/user-tech-breaking-dialog.component';
import { UserTechReleaseDialogComponent } from '../user-tech-dialogs/user-tech-release-dialog.component';
import { UserTechReturnDialogComponent } from '../user-tech-dialogs/user-tech-return-dialog.component';
import { UserTechLossDialogComponent } from '../user-tech-dialogs/user-tech-loss-dialog.component';
import { Tech, TechDiscriminatorKey } from 'src/app/models/tech/tech.model';
import { AggregatedTech } from 'src/app/models/tech/aggregated-tech.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TechService } from 'src/app/services/tech.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-tech-block',
  templateUrl: './user-tech-block.component.html',
  styleUrls: ['./user-tech-block.component.scss'],
})
export class UserTechBlockComponent {
  @Output() shouldReloadTechData: EventEmitter<any> = new EventEmitter();
  @Input() aggregatedTech?: AggregatedTech;
  @Input() deviceId?: string;
  @Input() userId?: string;

  constructor(private _techService: TechService, private _dialog: MatDialog) {}

  showTechBlockDialog(tech?: Tech, type?: TechDiscriminatorKey) {
    let component: any = undefined;
    switch (tech?.__type || type) {
      case 'insurance':
        component = UserTechInsuranceDialogComponent;
        break;
      case 'loss':
        component = UserTechLossDialogComponent;
        break;
      case 'breaking':
        component = UserTechBreakingDialogComponent;
        break;
      case 'return':
        component = UserTechReturnDialogComponent;
        break;
      case 'release':
        component = UserTechReleaseDialogComponent;
        break;
    }
    if (component === undefined || component === null) return;
    const dialogRef = this._dialog.open(component, {
      autoFocus: false,
      restoreFocus: false,
      minWidth: '480px',
      data: {
        userId: this.userId,
        deviceId: this.aggregatedTech?.device?.id,
        docId: tech ? tech.id : undefined,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.shouldReloadTechData.emit();
    });
  }

  downloadDoc(docId?: string): void {
    if (!docId) return;
    this._techService.getDocument(docId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download =
        res.headers.get('originalname') ||
        `${new Date().toISOString()} - Tech Document (De Amicis Ring)`;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }

  deleteDoc(docId?: string) {
    if (!docId) return;
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questo allegato?',
        details: "Attenzione: l'operazione non sarà in alcun modo reversibile",
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
    });
    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this._techService.deleteDocument(docId).subscribe({
          next: () => this.shouldReloadTechData.emit(),
          error: (error: any) => {
            console.error('Delete Tech Document Error: ', error);
          },
        });
      }
    });
  }

  onDelete(id?: string) {
    if (!id) return;
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        destructive: true,
        message: 'Confermi di voler eliminare questo elemento?',
        details:
          "Attenzione: saranno eliminati anche gli eventuali documenti allegati a questo elemento e l'operazione non sarà in alcun modo reversibile",
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
    });
    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this._techService.deleteTechBlock(id).subscribe({
          next: () => this.shouldReloadTechData.emit(),
          error: (error: any) => {
            console.error('Delete Tech Item Error: ', error);
          },
        });
      }
    });
  }
}
