import { ApiError } from 'src/app/models/api/api-error.model';
import { UserService } from 'src/app/services/user.service';
import { Class } from 'src/app/models/class/class.model';
import {
  Input,
  inject,
  OnChanges,
  Component,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-user-classes-data',
  templateUrl: './user-classes-data.component.html',
  styleUrls: ['./user-classes-data.component.scss'],
})
export class UserClassesDataComponent implements OnChanges {
  private _userService: UserService = inject(UserService);
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() userId?: string;
  @Input() startLoading: boolean = false;

  public classes: Class[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading']?.currentValue &&
      !changes['startLoading']?.previousValue
    ) {
      this.getUserClasses();
    }
  }

  async getUserClasses() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userService
      .getClasses(this.userId)
      .subscribe({
        next: (data) => {
          this.classes = data?.data?.map((json: any) => new Class(json));
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Classes Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
