import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-justifications-counter',
  templateUrl: './justifications-counter.component.html',
  styleUrls: ['./justifications-counter.component.scss'],
})
export class JustificationsCounterComponent {
  @Input() cards_counter?: number = 0;
  @Input() slots_counter?: number = 0;
  @Input() slots?: Date[] = [];

  public icons: string[] = [
    'bi bi-1-circle',
    'bi bi-2-circle',
    'bi bi-3-circle',
    'bi bi-4-circle',
    'bi bi-5-circle',
    'bi bi-6-circle',
    'bi bi-7-circle',
    'bi bi-8-circle',
    'bi bi-9-circle',
    'bi bi-exclamation-circle',
  ];

  get getSlots(): any[] {
    return Array(this.slots_counter)
      .fill(0)
      .map((_, i) => i);
  }

  existSlot(index: number): boolean {
    if (this.slots_counter == 0) return false;
    return index <= (this.slots_counter || 1) - 1;
  }

  getDate(index: number): Date {
    index = (this.cards_counter || 0) * 10 + index;
    return (this.slots || [])[index] ? (this.slots || [])[index] : new Date();
  }
}
