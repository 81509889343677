export class StatusHeartbeat {
  status?: number;
  time?: Date;
  msg?: string;
  ping?: number;

  constructor(json?: any) {
    if (!json) return;
    this.status = json['status'];
    this.time = json['time'] ? new Date(json['time']) : undefined;
    this.msg = json['msg'];
    this.ping = json['ping'];
  }
}
