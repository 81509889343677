import { User } from '../user/user.model';

export class RingFormConfigs {
  uuid?: string;
  password?: string;
  completed?: boolean;
  signed?: boolean;
  owners?: User[];
  editors?: User[];
  viewers?: User[];

  constructor(json?: any) {
    if (!json) return;
    this.uuid = json['uuid'];
    this.password = json['password'];
    this.completed =
      json['completed'] === undefined
        ? undefined
        : (json['completed'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.signed =
      json['signed'] === undefined
        ? undefined
        : (json['signed'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    this.owners = (json['owners'] || []).map((json: any) => new User(json));
    this.editors = (json['editors'] || []).map((json: any) => new User(json));
    this.viewers = (json['viewers'] || []).map((json: any) => new User(json));
  }
}
