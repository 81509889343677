import { RingFormConfigs } from './ring-form-configs.model';
import { RingFormCell } from './ring-form-cell.model';

export class RingForm {
  id?: string;
  dea_id?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  information?: string;
  cells?: RingFormCell[];
  configs?: RingFormConfigs;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.dea_id = json['dea_id'];
    this.title = json['title'];
    this.subtitle = json['subtitle'];
    this.description = json['description'];
    this.information = json['information'];
    this.configs = new RingFormConfigs(json['configs']);
    this.cells = (json['cells'] || []).map(
      (json: any) => new RingFormCell(json)
    );
    this.createdAt = json['createdAt']
      ? new Date(json['createdAt'])
      : undefined;
    this.updatedAt = json['updatedAt']
      ? new Date(json['updatedAt'])
      : undefined;
  }

  getHeaderCells(): RingFormCell[] {
    return (this.cells ?? []).filter((cell) => cell.header == true);
  }

  getOwnersFullName(): string[] {
    let ownersFullName: string[] = [];
    for (let i = 0; i < (this.configs?.owners || []).length; i++) {
      let ownerFullName = this.configs?.owners?.at(i)?.getFullName() || '';
      if (ownerFullName.length != 0) ownersFullName.push(ownerFullName);
    }
    return ownersFullName;
  }

  getClasses(): string[] {
    let classes: string[] = [];
    try {
      const headerCells = this.getHeaderCells();
      for (let i = 0; i < (headerCells || []).length; i++) {
        if (this.cells?.at(i)?.type?.toString() === 'classes') {
          for (let a = 0; a < (this.cells?.at(i)?.answers || []).length; a++) {
            if (this.cells?.at(i)?.answers?.at(a)?.answer != undefined) {
              classes.push(this.cells?.at(i)?.answers?.at(a)?.answer || '');
            }
          }
        }
      }
    } catch (error) {
      console.error('getClasses() ERROR => ', error);
    }
    return classes;
  }

  getSubjects(): string[] {
    let subjects: string[] = [];
    try {
      const headerCells = this.getHeaderCells();
      for (let i = 0; i < (headerCells || []).length; i++) {
        if (this.cells?.at(i)?.type?.toString() === 'subjects') {
          for (let a = 0; a < (this.cells?.at(i)?.answers || []).length; a++) {
            if (this.cells?.at(i)?.answers?.at(a)?.answer != undefined) {
              subjects.push(this.cells?.at(i)?.answers?.at(a)?.answer || '');
            }
          }
        }
      }
    } catch (error) {
      console.error('getSubjects() ERROR => ', error);
    }
    return subjects;
  }
}
