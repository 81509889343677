import { DocumentsFormsListingByDeaId } from '../constants/interfaces';
import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class DocumentsFormsService {
  constructor(private http: HttpClient) {}

  getListing() {
    return this.http.get<ApiResponse<any>>(API.documentsFormsListing(), {
      withCredentials: true,
    });
  }

  getListingByDeaId(p: DocumentsFormsListingByDeaId) {
    return this.http.get<ApiResponse<any>>(
      API.documentsFormsListingByDeaId(p),
      {
        withCredentials: true,
      }
    );
  }

  downloadDocumentFormPDF(id: string) {
    return this.http.get(API.downloadDocumentFormPDF(id), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }
}
