<div class="my-auto data-box">
  <div class="hstack">
    <app-dea-label [label]="label"></app-dea-label>
  </div>
  <ng-template *ngIf="data?.address; then dataTrue else dataFalse"></ng-template>
  <ng-template #dataTrue>
    <div title="Click: copia {{data?.address}} • Doppio click: componi e-mail" (click)="copyInClipboard()"
      (dblclick)="onDoubleClick()" class="hstack gap-1 pointer">
      <app-dea-status-icon [enabledTitle]="'Abilitato per le comunicazioni'"
        [disabledTitle]="'Disabilitato per le comunicazioni'" [enable]="enable">
      </app-dea-status-icon>
      {{data?.address}}
    </div>
  </ng-template>
  <ng-template #dataFalse>
    <div class="opacity-50 fs-075r">No E-Mail</div>
  </ng-template>
</div>