import { FiltersDialogConfigInterface } from '../components/ring-forms/filters-dialog/filters-dialog.component';
import { UserDocumentType } from '../models/user/user-document/user-document-type.enum';
import { TechDiscriminatorKey } from '../models/tech/tech.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import {
  PageLimitText,
  UserListingParameters,
  ClassListingParameters,
  LimboListingParameters,
  SubjectListingParameters,
  GuestsAttendancesParameters,
  DocumentsFormsListingByDeaId,
  SchoolbookListingParameters,
  FeaturesListingParameters,
  ExportUtilityParameters,
  DeviceListingParameters,
  EventsListingParameters,
  EnrollmentsListingParameters,
} from './interfaces';

export class API {
  // AUTH
  static login(): string {
    return `${environment.authUrl}/api/login`;
  }
  static logout(): string {
    return `${environment.authUrl}/api/logout`;
  }
  static token(): string {
    return `${environment.authUrl}/api/token`;
  }
  static session(): string {
    return `${environment.authUrl}/api/session`;
  }
  static tmpCred(email?: string): string {
    return `${environment.authUrl}/api/credential?email=${email || 'NO-EMAIL'}`;
  }
  static credentials(): string {
    return `${environment.authUrl}/api/credential`;
  }
  static dashboardSections(): string {
    return `${environment.apiUrl}/v1/dashboard/sections`;
  }
  // STATIC
  static deAmicisRingIcon(): string {
    return `${environment.staticUrl}/ring/ring-icon-b.png`;
  }
  static defaultThumbnail(): string {
    return `${environment.staticUrl}/user/thumbnail.png`;
  }
  static deaLogo(): string {
    return `${environment.staticUrl}/logo/dea.webp`;
  }
  static transparent(): string {
    return `${environment.staticUrl}/transparent.png`;
  }
  // FEATURES
  static featuresListing(p: FeaturesListingParameters): string {
    let url = `${environment.apiUrl}/v1/features?page=${p.page}&limit=${p.limit}`;
    if (p.status) url += `&status=${p.status}`;
    return url;
  }
  static feature(id?: string): string {
    return `${environment.apiUrl}/v1/${id ? 'feature/' + id : 'features'}`;
  }
  // USER
  static userListing(p: UserListingParameters): string {
    let url = `${environment.apiUrl}/v1/user/listing?page=${p.page}&limit=${p.limit}`;
    url += `${p.type ? `&type=${p.type}` : ''}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    url += `${p.aggregate ? `&aggregate=${p.aggregate}` : '&aggregate=true'}`;
    return url;
  }
  static userDetail(id?: string): string {
    return `${environment.apiUrl}/v1/user${
      id ? `/${id.toString().trim()}/details` : ''
    }`;
  }
  static newUser(): string {
    return `${environment.apiUrl}/v1/user`;
  }
  static editUser(id?: string): string {
    return `${environment.apiUrl}/v1/user/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }`;
  }
  static patchCorsoSicurezza(id?: string) {
    return `${environment.apiUrl}/v1/user/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }/corso-sicurezza`;
  }
  static userClasses(id?: string) {
    return `${environment.apiUrl}/v1/user/${
      id?.toString()?.trim() ?? 'ID-ERROR'
    }/classes`;
  }
  // USER-DOCUMENTS
  static userDocumentsListing(
    id?: string,
    userDocumentTypes?: UserDocumentType[]
  ): string {
    userDocumentTypes ??= [
      UserDocumentType.DOCUMENT,
      UserDocumentType.BES_ATTACHMENT,
      UserDocumentType.SPORT_ATTACHMENT,
      UserDocumentType.CERTIFICATE,
    ];
    return `${environment.apiUrl}/v1/user${
      id ? `/${id.toString().trim()}/documents` : '/documents'
    }?types=${userDocumentTypes.join(',')}`;
  }
  static userDocumentDetails(userId?: string, documentId?: string): string {
    userId = (userId ? userId : 'ID-ERROR').toString().trim();
    documentId = (documentId ? documentId : 'ID-ERROR').toString().trim();
    return `${environment.apiUrl}/v1/user/${userId}/document/${documentId}`;
  }
  static setUserDocumentCheckbox(userId?: string, documentId?: string): string {
    userId = (userId ? userId : 'ID-ERROR').toString().trim();
    documentId = (documentId ? documentId : 'ID-ERROR').toString().trim();
    return `${environment.apiUrl}/v1/user/${userId}/document/${documentId}/checkbox`;
  }
  static userDocumentUpload(userId?: string): string {
    userId = (userId ? '/' + userId : '').toString().trim();
    return `${environment.apiUrl}/v1/user${userId}/document`;
  }
  static userDocumentDownload(userId?: string, documentId?: string): string {
    userId = (userId ? userId : 'ID-ERROR').toString().trim();
    documentId = (documentId ? documentId : 'ID-ERROR').toString().trim();
    return `${environment.apiUrl}/v1/user/${userId}/document/${documentId}/download`;
  }
  static userDocumentDelete(userId?: string, documentId?: string): string {
    userId = (userId ? '/' + userId : '').toString().trim();
    documentId = (documentId ? documentId : 'ID-ERROR').toString().trim();
    return `${environment.apiUrl}/v1/user${userId}/document/${documentId}`;
  }
  static userJustificationsCards(userId?: string): string {
    return `${environment.apiUrl}/v1/user/${userId || 'ID-ERROR'}/cards`;
  }
  // USER-TECH
  static techListing(id?: string): string {
    if (id) {
      return `${environment.apiUrl}/v1/user/${
        id ? `${id.toString().trim()}` : 'ID-ERROR'
      }/tech`;
    }
    return `${environment.apiUrl}/v1/tech`;
  }
  static techAggregateListing(id?: string): string {
    if (id) {
      return `${environment.apiUrl}/v1/user/${
        id ? `${id.toString().trim()}` : 'ID-ERROR'
      }/tech?aggregate=true`;
    }
    return `${environment.apiUrl}/v1/tech?aggregate=true`;
  }
  // USER-FORMS
  static userForms(id?: string): string {
    return `${environment.apiUrl}/v1/user/${
      id ? `${id?.toString().trim()}` : 'ID-ERROR'
    }/forms`;
  }
  // USER-PCTO (Student)
  static userPctoListing(id?: string, type?: string): string {
    switch (type) {
      case 'PCTO-SICUREZZA':
        return `${environment.apiUrl}/v1/user/${
          id ? `${id.toString().trim()}` : 'ID-ERROR'
        }/pcto-corso-sicurezza`;
      default:
        return `${environment.apiUrl}/v1/user/${
          id ? `${id.toString().trim()}` : 'ID-ERROR'
        }/pcto`;
    }
  }
  static pctoUpload(id?: string, type?: string): string {
    switch (type) {
      case 'PCTO-SICUREZZA':
        return `${environment.apiUrl}/v1/user/${
          id ? `${id.toString().trim()}` : 'ID-ERROR'
        }/pcto-corso-sicurezza`;
      default:
        return `${environment.apiUrl}/v1/user/${
          id ? `${id.toString().trim()}` : 'ID-ERROR'
        }/pcto`;
    }
  }
  static pctoDownload(userId?: string, pctoId?: string): string {
    return `${environment.apiUrl}/v1/user/${userId || 'USER-ID-ERROR'}/pcto/${
      pctoId || 'PCTO-ID-ERROR'
    }/download`;
  }
  static pctoDelete(userId?: string, pctoId?: string): string {
    return `${environment.apiUrl}/v1/user/${userId || 'USER-ID-ERROR'}/pcto/${
      pctoId || 'PCTO-ID-ERROR'
    }`;
  }
  static birthdaysListing(p?: { types?: string; date?: string }) {
    return `${environment.apiUrl}/v1/users/birthdays?types=${
      p?.types || 'ST,TC,SF'
    }${p?.date ? `&date=${p?.date}` : ''}`;
  }
  // THUMBNAIL
  static thumbnail(defaultThumbnail: any = 1): string {
    return `${environment.apiUrl}/v1/user/thumbnail?default=${defaultThumbnail}`;
  }
  static userThumbnail(userId?: string, defaultThumbnail = true): string {
    return `${environment.apiUrl}/v1/user/${
      userId || 'NO-ID'
    }/thumbnail?default=${defaultThumbnail}`;
  }
  // SUBJECT
  static subjectListing(p: SubjectListingParameters): string {
    let url = `${environment.apiUrl}/v1/subject/listing?`;
    url += `${p.limit ? `&limit=${p.limit}` : ''}`;
    url += `${p.page ? `&page=${p.page}` : ''}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }
  // SCHOOLBOOK
  static schoolbookListing(p: SchoolbookListingParameters): string {
    let url = `${environment.apiUrl}/v1/schoolbook/listing?`;
    url += `${p.limit ? `&limit=${p.limit}` : ''}`;
    url += `${p.page ? `&page=${p.page}` : ''}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }
  // CLASSES
  static classesListing(p: ClassListingParameters): string {
    let url = `${environment.apiUrl}/${
      p.page !== undefined ? 'v1' : 'v2'
    }/classes/listing?`;
    if (p.page !== undefined) url += `&page=${p.page}`;
    url += `${p.year ? `&y=${p.year}` : ''}`;
    url += `${p.department ? `&department=${p.department}` : ''}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }
  static classDetail(id?: string): string {
    return `${environment.apiUrl}/v1/class/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }/details?files=1`;
  }
  static classFile(classId?: string, fileId?: string): string {
    return `${environment.apiUrl}/v1/class/${(
      classId || 'ID-ERROR'
    ).trim()}/file/${(fileId || 'ID-ERROR').trim()}`;
  }
  static scholasticYears(): string {
    return `${environment.apiUrl}/v1/class/scholastic-years`;
  }
  static newClass(): string {
    return `${environment.apiUrl}/v1/class`;
  }
  static editClass(id?: string): string {
    return `${environment.apiUrl}/v1/class${
      id ? `/${id.toString().trim()}` : 'ID-ERROR'
    }`;
  }
  static classNextYear(id?: string): string {
    return `${environment.apiUrl}/v1/class${
      id ? `/${id.toString().trim()}/next-year` : 'ID-ERROR'
    }`;
  }
  static classDownloadCSV(
    id?: string,
    types?: string[],
    options?: string[]
  ): string {
    let url = `${environment.apiUrl}/v1/class${
      id ? `/${id.toString().trim()}/csv` : 'ID-ERROR'
    }`;
    url += `${
      types ? `?type=${types.join(',')}` : 'student,teacher,parent,tutor'
    }`;
    url += `${options ? `&options=${options.join(',')}` : ''}`;
    return url;
  }
  // GUEST-ATTENDANCES
  static guestAttendanceListing(p: GuestsAttendancesParameters): string {
    let url = `${environment.apiUrl}/v1/register/guest/attendances?page=${p.page}&limit=${p.limit}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }
  static guestAttendance(id?: string): string {
    return `${environment.apiUrl}/v1/register/guest/attendance${
      id ? `/${id.toString().trim()}` : ''
    }`;
  }
  // JUSTIFICATIONS-CARDS
  static getJustificationsCards(
    p: PageLimitText,
    tag?: string,
    date?: string
  ): string {
    let url = `${environment.apiUrl}/v1/register/justifications-cards?page=${p.page}&limit=${p.limit}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    url += `${date ? `&date=${date}` : ''}`;
    url += `${tag ? `&tag=${tag}` : ''}`;
    return url;
  }
  static createJustificationsCard(): string {
    return `${environment.apiUrl}/v1/register/justifications-card`;
  }
  static patchJustificationsCard(cardId?: string, type?: number): string {
    return `${environment.apiUrl}/v1/register/justifications-card/${
      cardId ? `${cardId.toString().trim()}` : 'ID-ERROR'
    }${type != undefined ? `?type=${type}` : ''}`;
  }
  static downloadJustificationsCardPDF(id: string): string {
    let url = `${environment.apiUrl}/v1/register/justifications-cards/${id}/pdf`;
    return url;
  }
  // LIMBO
  static limboListing(p: LimboListingParameters): string {
    let url = `${environment.apiUrl}/v1/user/limbo-listing?page=${p.page}&limit=${p.limit}&set=${p.set}`;
    if (p.year && p.year !== '') url += `&year=${p.year}`;
    if (p.txt && p.txt !== '') url += `&txt=${p.txt}`;
    return url;
  }
  // RING-FORMS
  static formsSchemasListing(page: number = 0, limit: number = 100): string {
    let url = `${environment.apiUrl}/v1/forms/schemes?page=${page}&limit=${limit}`;
    return url;
  }
  static formsListingByType(
    deaId: string,
    page: number = 0,
    limit: number = 20,
    filters?: FiltersDialogConfigInterface
  ): string {
    let url = `${environment.apiUrl}/v1/forms/?deaId=${deaId}&page=${page}&limit=${limit}`;
    if (filters?.scholasticYear) url += `&year=${filters?.scholasticYear}`;
    if (filters?.classes) url += `&classId=${filters?.classes?.at(0)?.id}`;
    if (filters?.subjects) url += `&subjectId=${filters?.subjects?.at(0)?.id}`;
    if (filters?.owners) url += `&ownerId=${filters?.owners?.at(0)?.id}`;
    return url;
  }
  static getFormPDF(id: string): string {
    let url = `${environment.apiUrl}/v1/form/${id ?? 'ID-ERROR'}/pdf`;
    return url;
  }
  static setCreationStatus(id: string): string {
    return `${environment.apiUrl}/v1/form/${id ?? 'ID-ERROR'}/creation-status`;
  }
  // DOCUMENTS-FORMS
  static documentsFormsListing(): string {
    const p = { page: 0, limit: 10 };
    let url = `${environment.apiUrl}/v1/documents/listing?page=${p.page}&limit=${p.limit}`;
    // url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }
  // DOCUMENTS-FORMS-BY-DEA-ID
  static documentsFormsListingByDeaId(p: DocumentsFormsListingByDeaId): string {
    let url = `${environment.apiUrl}/v1/documents/${p.dea_id}?page=${p.page}&limit=${p.limit}`;
    if (p.txt) url += `&txt=${p.txt}`;
    return url;
  }
  // DOCUMENT-FORM PDF
  static downloadDocumentFormPDF(id: string): string {
    let url = `${environment.apiUrl}/v1/document/${id}/pdf`;
    return url;
  }
  // DEVICE - TECH
  static deviceListing(p: DeviceListingParameters): string {
    let url = `${environment.apiUrl}/v1/tech/devices?page=${p.page}&limit=${p.limit}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }
  static devicesJSON(): string {
    return `${environment.apiUrl}/v1/tech/devices`;
  }
  static techBlock(id?: string): string {
    return `${environment.apiUrl}/v1/tech/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }`;
  }
  static techDocument(id?: string): string {
    return `${environment.apiUrl}/v1/tech/doc/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }`;
  }
  static techUserPost(
    userId?: string,
    blockType?: TechDiscriminatorKey
  ): string {
    return `${environment.apiUrl}/v1/user/${
      userId ? `${userId.toString().trim()}` : 'ID-ERROR'
    }/tech/${blockType ? `${blockType.toString().trim()}` : 'TYPE-ERROR'}`;
  }
  static techPatch(blockId?: string, blockType?: TechDiscriminatorKey): string {
    return `${environment.apiUrl}/v1/tech/${
      blockType ? `${blockType.toString().trim()}` : 'TYPE-ERROR'
    }/${blockId ? `${blockId.toString().trim()}` : 'ID-ERROR'}`;
  }
  // RESOURCES
  static resourcesListing(p?: { folderId?: string; split?: boolean }): string {
    let url = `${environment.apiUrl}/v1/resources`;
    if (p?.folderId) url += `/${p.folderId}`;
    if (p?.split) url += `?split=${p.split}`;
    return url;
  }
  static downloadResource(id?: string): string {
    return `${environment.apiUrl}/v1/resources/file/${id || 'ID-ERROR'}`;
  }
  static uploadResource(folderId?: string): string {
    let url = `${environment.apiUrl}/v1/resources`;
    if (folderId) url += `?folder=${folderId.trim()}`;
    return url;
  }
  static deleteResource(id?: string): string {
    return `${environment.apiUrl}/v1/resources/file/${id || 'ID-ERROR'}`;
  }
  static createFolder(): string {
    return `${environment.apiUrl}/v1/resources/folder`;
  }
  // UTILITIES
  static generalStatistics(): string {
    let url = `${environment.apiUrl}/v1/utility/statistics`;
    return url;
  }
  static utilityCSV(p: ExportUtilityParameters): string {
    let url = `${environment.apiUrl}/v1/utility?y=${p.y}`;
    if (p.type) url += `&type=${p.type}`;
    if (p.years) url += `&years=${p.years}`;
    if (p.sections) url += `&sections=${p.sections}`;
    if (p.departments) url += `&departments=${p.departments}`;
    if (p.features) url += `&features=${p.features}`;
    if (p.options) url += `&options=${p.options}`;
    if (p.text) url += `&text=${p.text}`;
    if (p.fields) url += `&fields=${p.fields}`;
    return url;
  }
  static utilityNumberOfItems(p: ExportUtilityParameters): string {
    let url = `${environment.apiUrl}/v1/utility/count?y=${p.y}`;
    if (p.type) url += `&type=${p.type}`;
    if (p.years) url += `&years=${p.years}`;
    if (p.sections) url += `&sections=${p.sections}`;
    if (p.departments) url += `&departments=${p.departments}`;
    if (p.features) url += `&features=${p.features}`;
    if (p.options) url += `&options=${p.options}`;
    if (p.text) url += `&text=${p.text}`;
    if (p.fields) url += `&fields=${p.fields}`;
    return url;
  }
  static utilityStatus(): string {
    return `${environment.apiUrl}/v1/utility/status`;
  }
  static languagesListing(code: boolean = true): string {
    return `${environment.apiUrl}/v1/utility/languages?code=${code}`;
  }
  // TOOLS
  static dataFromCF(cf: string): string {
    let url = `${environment.apiUrl}/v1/tools/cf/data?cf=${cf}`;
    return url;
  }
  // ITEMS
  static filmsLisitng(txt?: string): string {
    let url = `${environment.apiUrl}/v1/items/films${
      txt ? '?txt=' + txt.trim() : ''
    }`;
    return url;
  }
  static postFilm(): string {
    return `${environment.apiUrl}/v1/items/film`;
  }
  static putFilm(id?: string): string {
    return `${environment.apiUrl}/v1/items/film/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }`;
  }
  static deleteFilm(id?: string): string {
    return `${environment.apiUrl}/v1/items/film/${
      id ? `${id.toString().trim()}` : 'ID-ERROR'
    }`;
  }
  // EVENTS
  static eventsListing(p: EventsListingParameters): string {
    let url = `${environment.apiUrl}/v1/events/listing?page=${p.page}&limit=${p.limit}`;
    url += `${p.txt ? `&txt=${p.txt}` : ''}`;
    return url;
  }

  static event(id?: string): string {
    return `${environment.apiUrl}/v1/event${id ? '/' + id : ''}`;
  }

  // CALENDAR
  static calendarColorsConfigs() {
    return `${environment.apiUrl}/v1/calendar/configs/colors`;
  }

  static calendarTimetableGridConfigs() {
    return `${environment.apiUrl}/v1/calendar/configs/timetable-grid`;
  }

  static timetable(p: {
    classId: string | undefined;
    teacherId: string | undefined;
    from: moment.Moment | undefined;
    to: moment.Moment | undefined;
  }): string {
    p.from ??= moment().startOf('week').add(1, 'day');
    p.to ??= moment().endOf('week').add(1, 'day');
    console.info('TODAY:', moment(), '- FROM:', p.from, '- TO:', p.to);
    let url = `${environment.apiUrl}/v1/calendar/timetable?from=${
      p.from.utc().toISOString().split('T')[0]
    }&to=${p.to.utc().toISOString().split('T')[0]}`;
    if (p.classId !== undefined) {
      url += `&classId=${p.classId.toString().trim()}`;
    }
    if (p.teacherId !== undefined) {
      url += `&teacherId=${p.teacherId.toString().trim()}`;
    }
    return url;
  }

  static calendarEvent() {
    return `${environment.apiUrl}/v1/calendar/event`;
  }

  static calendarEvents() {
    return `${environment.apiUrl}/v1/calendar/events`;
  }

  static singleCalendarEvent(eventId?: string) {
    return `${environment.apiUrl}/v1/calendar/event/${eventId || 'ID-ERROR'}`;
  }

  static calendarGeneralTimetable(p: {
    h?: string;
    y?: string;
    pdf?: boolean;
  }) {
    let url = `${environment.apiUrl}/v1/calendar/general-timetable`;
    if (p.pdf === true) url += '/pdf';
    if (p.h) {
      url += (url.includes('?') ? '&' : '?') + `h=${p.h.trim().toUpperCase()}`;
    }
    if (p.y) {
      url += (url.includes('?') ? '&' : '?') + `y=${p.y.trim()}`;
    }
    return url;
  }

  static timetableReplacementOptionsListing(p: {
    eventId: string | undefined;
    start: moment.Moment | undefined;
    end: moment.Moment | undefined;
    tz: string | undefined;
    exclude: string[] | undefined;
  }) {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
    const end: string = moment.isMoment(p.end)
      ? p.end.format(dateFormat)
      : 'NO-VALID-DATE';
    const start: string = moment.isMoment(p.start)
      ? p.start.format(dateFormat)
      : 'NO-VALID-DATE';
    let url: string = `${environment.apiUrl}/v1/calendar/timetable/${
      p.eventId ?? 'ID-ERROR'
    }/replacement?start=${start}&end=${end}&tz=${p.tz ?? 'Europe/Rome'}`;
    if (p.exclude) {
      url += `&exclude=${p.exclude.join(',')}`;
    }
    return url;
  }

  static timetableReplacementsSummary(): string {
    return `${environment.apiUrl}/v1/calendar/timetable/replacements-summary`;
  }

  static timetableReplacementsSummaryDetails(summaryId?: string): string {
    return `${environment.apiUrl}/v1/calendar/timetable/replacement-summary/${
      summaryId ?? 'ID-ERROR'
    }`;
  }

  static getTimetableReplacementsSummaryPDF(summaryId?: string): string {
    return `${environment.apiUrl}/v1/calendar/timetable/replacement-summary/${
      summaryId ?? 'ID-ERROR'
    }/pdf`;
  }

  static replacementPDFtmp(): string {
    return `${environment.apiUrl}/v1/calendar/timetable/replacements/pdf`;
  }

  // ENROLLMENT
  static enrollmentYearsListing(): string {
    return `${environment.apiUrl}/v1/enrollments/years`;
  }

  static enrollmentListing(p: EnrollmentsListingParameters): string {
    let url = `${environment.apiUrl}/v1/enrollments?page=${p.page}&limit=${p.limit}`;
    if (p.txt) url += `&txt=${p.txt}`;
    return url;
  }

  static enrollmentDetails(id?: string): string {
    return `${environment.apiUrl}/v1/enrollment/${id ?? 'ID-ERROR'}/details`;
  }

  static enrollmentCheckBox(id?: string): string {
    return `${environment.apiUrl}/v1/enrollment/${id ?? 'ID-ERROR'}/check-box`;
  }

  static enrollment(id?: string): string {
    return `${environment.apiUrl}/v1/enrollment/${id ?? 'ID-ERROR'}`;
  }

  static getEnrollmentPDF(id: string) {
    return `${environment.apiUrl}/v1/enrollment/${id}/pdf`;
  }

  static convertEnrollment(id: string) {
    return `${environment.apiUrl}/v1/enrollment/${id}/convert`;
  }

  static enrollmentStatistics(y: string) {
    return `${environment.apiUrl}/v1/enrollments/statistics?y=` + y;
  }
}
