<div class="hstack m-0 p-0" style="background-color: transparent; min-height: 64px;">
  <div class="m-0 p-0 me-3">
    <mat-icon *ngIf="pctoDoc?.isPDF()" class="m-0 p-0 opacity-25">picture_as_pdf</mat-icon>
    <mat-icon *ngIf="!pctoDoc?.isPDF()" class="m-0 p-0 opacity-25">photo_library</mat-icon>
  </div>
  <div class="m-0 p-0 hstack w-100">
    <div>
      <div class="fw-lighter opacity-75" style="font-size: 0.5rem;">
        {{pctoDoc?.uploadDate | date:'yyyy-MM-dd'}}
      </div>
      <div class="fw-normal"><strong>{{pctoDoc?.anno_scolastico}}</strong> | {{pctoDoc?.ente}}</div>
      <div class="fw-lighter fs-075r">
        {{pctoDoc?.note}}
      </div>
    </div>
    <div *ngIf="showHours" class="ms-auto me-5 ps-3">
      <strong style="font-size: 1.25rem; white-space: nowrap;">{{pctoDoc?.ore}}</strong>
    </div>
  </div>
  <div class="ms-auto fs-075r">
    <mat-icon class="m-0 p-0 dea-icon-btn" (click)="onPreviewFile()" title="Visualizza: {{pctoDoc?.ente}}">
      visibility
    </mat-icon>
  </div>
  <div class="ms-4 fs-075r">
    <mat-icon class="m-0 p-0 dea-icon-btn" (click)="onDownloadFile()" title="Download: {{pctoDoc?.ente}}">
      download
    </mat-icon>
  </div>
  <div class="ms-4 fs-075r">
    <mat-icon class="m-0 p-0 dea-icon-btn" (click)="onDeleteDocument()" title="Elimina: {{pctoDoc?.ente}}" color="warn">
      delete
    </mat-icon>
  </div>
</div>