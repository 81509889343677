import { DeaConfirmDialogComponent } from 'src/app/components/dea-pack/dea-confirm-dialog/dea-confirm-dialog.component';
import { ThumbnailCacheService } from 'src/app/services/thumbnail-cache.service';
import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { API } from 'src/app/constants/api';

@Component({
  selector: 'app-user-thumbnail-and-controls',
  templateUrl: './user-thumbnail-and-controls.component.html',
  styleUrls: ['./user-thumbnail-and-controls.component.scss'],
})
export class UserThumbnailAndControlsComponent {
  private _dialog: MatDialog = inject(MatDialog);
  private _userService: UserService = inject(UserService);
  private _thumbnailCacheService = inject(ThumbnailCacheService);
  @ViewChild('thumbnailInput') fileInput?: ElementRef;
  @Input() id?: string;

  formData: FormData = new FormData();
  loading: boolean = false;

  onClick() {
    this.fileInput?.nativeElement.click();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0 && !this.loading) {
      this.loading = true;
      const file = event.target.files[0];
      this.formData.append(
        'thumbnail',
        file,
        file.name || new Date().toISOString().slice(0, 10)
      );
      if (this.id != undefined && this.id != '') {
        this._userService
          .postThumbnail(this.id, this.formData)
          .subscribe({
            next: (_: any) => this.reloadImage(),
            error: (error: any) => {
              console.error('Post User Thumbnail Error: ', error);
            },
          })
          .add(() => (this.loading = false));
      }
    }
  }

  deleteThumbnail(): void {
    this.loading = true;
    const confirmDialogRef = this._dialog.open(DeaConfirmDialogComponent, {
      maxWidth: '450px',
      minWidth: '450px',
      data: {
        destructive: true,
        message:
          'Confermi di voler eliminare la foto profilo di questo utente?',
        details: '',
        falseBtn: 'No, annulla',
        trueBtn: 'Sì, elimina',
      },
      autoFocus: false,
      restoreFocus: false,
    });
    confirmDialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      if (dialogResult && this.id != undefined && this.id != '') {
        this._userService
          .deleteThumbnail(this.id)
          .subscribe({
            next: (_: any) => this.reloadImage(),
            error: (error: any) => {
              console.error('Delete User Thumbnail Error: ', error);
            },
          })
          .add(() => (this.loading = false));
      } else {
        this.loading = false;
      }
    });
  }

  reloadImage(): void {
    this._thumbnailCacheService.clear(API.userThumbnail(this.id, true));
    const tmpID = this.id;
    this.id = undefined;
    setTimeout(() => (this.id = tmpID), 200);
  }
}
