<app-dea-homepage-wrapper [showBackHomeBtn]="true">
  <div class="mb-3">
    Inserisci l'indirizzo e-mail asociato alle tue credenziali di accesso, premi invio ed attendi il
    messaggio con le credenziali temporanee.
    <div class="fs-055r opacity-50 mt-1">
      Attenzione: potrebbero essere necessario attendere alcuni minuti prima di ricevere il messaggio
    </div>
  </div>
  <form [formGroup]="tmpCredentialsForm" class="w-100">
    <div class="input-group input-group-lg mb-3 form-control p-0 input-wrapper">
      <input auth-input type="email" class="form-control pe-0 border-0" placeholder="Indirizzo E-Mail"
        spellcheck="false" autocomplete="off" name="email" formControlName="email" (keydown.enter)="getTmpCredentials()"
        required>
      <div class="my-auto px-2">
        <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
        <ng-template #loadingTrue>
          <mat-spinner strokeWidth="1" color="primary" diameter="22"></mat-spinner>
        </ng-template>
        <ng-template #loadingFalse>
          <div (click)="getTmpCredentials()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
              class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </div>
        </ng-template>
      </div>
    </div>
  </form>
  <div class="ms-3 fw-lighter text-decoration-underline fs-075r dea-icon-btn" routerLink="/tmp/login">
    Hai già una Password temporanea?
  </div>
</app-dea-homepage-wrapper>