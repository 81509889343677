export enum HeadquarterFrontendStr {
  MILANO = 'Milano',
  GORGONZOLA = 'Gorgonzola',
}

export enum HeadquarterBackendStr {
  MILANO = 'MILANO',
  GORGONZOLA = 'GORGONZOLA',
}

export class Headquarter {
  public static readonly MILANO: Headquarter = new Headquarter(
    HeadquarterFrontendStr.MILANO,
    HeadquarterBackendStr.MILANO
  );
  public static readonly GORGONZOLA: Headquarter = new Headquarter(
    HeadquarterFrontendStr.GORGONZOLA,
    HeadquarterBackendStr.GORGONZOLA
  );

  private constructor(
    public readonly frontend: string,
    public readonly backend: string
  ) {}

  public static fromString(str?: string): Headquarter | undefined {
    switch (str) {
      case HeadquarterBackendStr.MILANO:
        return Headquarter.MILANO;
      case HeadquarterFrontendStr.MILANO:
        return Headquarter.MILANO;
      case HeadquarterBackendStr.GORGONZOLA:
        return Headquarter.GORGONZOLA;
      case HeadquarterFrontendStr.GORGONZOLA:
        return Headquarter.GORGONZOLA;
      default:
        return undefined;
    }
  }

  public static getHeadqarters(): Headquarter[] {
    return [Headquarter.MILANO, Headquarter.GORGONZOLA];
  }

  public static getFrontendHeadqarters(): string[] {
    return [Headquarter.MILANO.frontend, Headquarter.GORGONZOLA.frontend];
  }

  public static getBackendHeadqarters(): string[] {
    return [Headquarter.MILANO.backend, Headquarter.GORGONZOLA.backend];
  }
}
