import { Component } from '@angular/core';

@Component({
  selector: 'app-new-user-personal-data',
  templateUrl: './new-user-personal-data.component.html',
  styleUrls: ['./new-user-personal-data.component.scss']
})
export class NewUserPersonalDataComponent {

}
