import { EnrollmentFormService } from 'src/app/services/enrollment/enrollment-form.service';
import { EnrollmentService } from 'src/app/services/enrollment/enrollment.service';
import { Enrollment } from 'src/app/models/enrollment/enrollment.model';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, inject, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DeaSingleBtnDialogComponent } from '../../dea-pack/dea-single-btn-dialog/dea-single-btn-dialog.component';

@Component({
  selector: 'app-enrollment-form',
  templateUrl: './enrollment-form.component.html',
  styleUrl: './enrollment-form.component.scss',
})
export class EnrollmentFormComponent implements OnDestroy {
  private _enrollmentService: EnrollmentService = inject(EnrollmentService);
  private _enrollmentFormService = inject(EnrollmentFormService);
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _location: Location = inject(Location);
  private _dialog: MatDialog = inject(MatDialog);
  public enrollment?: Enrollment;
  public enrollmentId?: string;

  public posting: boolean = false;

  public loading: boolean = true;
  public error?: ApiError;

  get enrollmentFormService() {
    return this._enrollmentFormService;
  }

  ngOnDestroy(): void {
    this._enrollmentFormService.initializeEnrollmentFormSession();
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.enrollmentId = params['id'];
        if (this.enrollmentId) this.getDetails();
      }
    });
  }

  async getDetails() {
    this.loading = true;
    this.error = undefined;
    this._enrollmentService
      .getDetails(this.enrollmentId)
      .subscribe({
        next: (response) => {
          this.enrollment = new Enrollment(response.data);
          this._enrollmentFormService.initializeEnrollmentFormSession(
            this.enrollment
          );
        },
        error: (error) => {
          this.error = new ApiError(error);
          console.error('Error:', error);
        },
      })
      .add(() => (this.loading = false));
  }

  checkLanguage(language: 'Spagnolo' | 'Tedesco' | 'Francese'): boolean {
    if (
      this._enrollmentFormService.enrollmentForm?.get('prima_lingua')?.value ===
        language ||
      this._enrollmentFormService.enrollmentForm?.get('seconda_lingua')
        ?.value === language ||
      this._enrollmentFormService.enrollmentForm?.get('terza_lingua')?.value ===
        language
    ) {
      return true;
    }
    return false;
  }

  languageChanged(language: 'Spagnolo' | 'Tedesco' | 'Francese'): void {
    switch (language) {
      case 'Spagnolo':
        if (this.checkLanguage('Spagnolo')) {
          if (
            this._enrollmentFormService.enrollmentForm?.get('seconda_lingua')
              ?.value === 'Spagnolo'
          ) {
            this._enrollmentFormService.enrollmentForm
              ?.get('seconda_lingua')
              ?.setValue('');
          } else {
            this._enrollmentFormService.enrollmentForm
              ?.get('terza_lingua')
              ?.setValue('');
          }
        } else {
          let label = 'seconda_lingua';
          if (this.checkLanguage('Tedesco') || this.checkLanguage('Francese')) {
            label = 'terza_lingua';
          }
          this._enrollmentFormService.enrollmentForm
            ?.get(label)
            ?.setValue('Spagnolo');
        }
        break;
      case 'Tedesco':
        if (this.checkLanguage('Francese')) {
          this._enrollmentFormService.enrollmentForm
            ?.get('seconda_lingua')
            ?.setValue(language);
        } else if (this.checkLanguage(language)) {
          if (this.checkLanguage('Spagnolo')) {
            this._enrollmentFormService.enrollmentForm
              ?.get('terza_lingua')
              ?.setValue('');
            this._enrollmentFormService.enrollmentForm
              ?.get('seconda_lingua')
              ?.setValue('Spagnolo');
          } else {
            this._enrollmentFormService.enrollmentForm
              ?.get('seconda_lingua')
              ?.setValue('');
          }
        } else {
          if (this.checkLanguage('Spagnolo')) {
            this._enrollmentFormService.enrollmentForm
              ?.get('terza_lingua')
              ?.setValue('Spagnolo');
          }
          this._enrollmentFormService.enrollmentForm
            ?.get('seconda_lingua')
            ?.setValue(language);
        }
        break;
      case 'Francese':
        if (this.checkLanguage('Tedesco')) {
          this._enrollmentFormService.enrollmentForm
            ?.get('seconda_lingua')
            ?.setValue(language);
        } else if (this.checkLanguage(language)) {
          if (this.checkLanguage('Spagnolo')) {
            this._enrollmentFormService.enrollmentForm
              ?.get('terza_lingua')
              ?.setValue('');
            this._enrollmentFormService.enrollmentForm
              ?.get('seconda_lingua')
              ?.setValue('Spagnolo');
          } else {
            this._enrollmentFormService.enrollmentForm
              ?.get('seconda_lingua')
              ?.setValue('');
          }
        } else {
          if (this.checkLanguage('Spagnolo')) {
            this._enrollmentFormService.enrollmentForm
              ?.get('terza_lingua')
              ?.setValue('Spagnolo');
          }
          this._enrollmentFormService.enrollmentForm
            ?.get('seconda_lingua')
            ?.setValue(language);
        }
        break;
    }
  }

  async postData() {
    this.posting = true;
    const json = this.enrollmentFormService.enrollmentForm.getRawValue();
    this._enrollmentService
      .patchEnrollment(this.enrollmentId ?? 'ID-ERROR', json)
      .subscribe({
        next: () => this.backOnePage(),
        error: (error) => {
          console.error('Enrollment Patch Error:', error);
          const errorDialogRef = this._dialog.open(
            DeaSingleBtnDialogComponent,
            {
              maxWidth: '450px',
              minWidth: '450px',
              data: {
                error: new ApiError(error),
              },
              autoFocus: false,
              restoreFocus: false,
            }
          );
          errorDialogRef.afterClosed().subscribe(() => (this.posting = false));
        },
      });
  }

  async backOnePage() {
    this._location.back();
  }
}
