export class File {
  id?: string;
  filename?: string;
  contentType?: string;
  length?: number;
  chunkSize?: number;
  uploadDate?: Date;
  // METADATA
  mimetype?: string;
  originalname?: string;
  owner?: string;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.filename = json['filename'];
    this.contentType = json['contentType'];
    this.length = json['length'];
    this.chunkSize = json['chunkSize'];
    this.uploadDate = json['uploadDate']
      ? new Date(json['uploadDate'])
      : undefined;
    this.mimetype = json?.metadata?.mimetype;
    this.originalname = json?.metadata?.originalname;
    this.owner = json?.metadata?.owner;
  }

  getFileNameFromDate(): string {
    const date = this.uploadDate || new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${date.getFullYear()}${month}${day} - ${this.id}.${this.getExt()}`;
  }

  getExt(): string {
    if (this.isPDF()) return 'pdf';
    if (this.contentType?.includes('png')) return 'png';
    if (this.contentType?.includes('jpg')) return 'jpg';
    if (this.contentType?.includes('jpeg')) return 'jpeg';
    if (this.contentType?.includes('mp4')) return 'mp4';
    if (this.contentType?.includes('quicktime')) return 'mov';
    if (this.contentType?.includes('zip')) return 'zip';
    return 'error';
  }

  isPDF(): boolean {
    return this.contentType?.includes('pdf') ? true : false;
  }
}
