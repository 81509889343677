import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { ScholasticYear } from 'src/app/models/scholastic-year.model';
import { ClassesService } from 'src/app/services/classes.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Class } from 'src/app/models/class/class.model';
import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-dea-classes-listing-dialog',
  templateUrl: './dea-classes-listing-dialog.component.html',
  styleUrls: ['./dea-classes-listing-dialog.component.scss'],
})
export class DeaClassesListingDialogComponent {
  @Input() limit: number = 10;
  @Input() page: number = 0;

  private _year?: string = ScholasticYear.getCurrent();
  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public classes: Class[] = [];
  public error?: ApiError;

  constructor(
    private _classesService: ClassesService,
    public dialogRef: MatDialogRef<DeaClassesListingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public yearData: string
  ) {
    if (yearData != null && yearData.length != 0) this._year = yearData;
  }

  onSelected(id?: string): void {
    const selectedClass: Class[] = id
      ? this.classes.filter((cl) => cl.id === id)
      : [];
    this.dialogRef.close(selectedClass[0]);
  }

  ngOnInit(): void {
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._classesService
      .listing({ year: this._year })
      .subscribe({
        next: (data) => {
          this.classes = data?.data?.map((cl: any) => new Class(cl)) ?? [];
          this.pagination = data?.pagination;
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('Classes Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
