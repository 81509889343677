<div *ngIf="user" class="dea-border dea-radius p-2" [title]="user.getFullName()">
  <div class="d-flex flex-row flex-wrap align-items-center gap-2">
    <div *ngIf="showThumbnail">
      <app-dea-thumbnail-img size="24" id="{{user.id}}"></app-dea-thumbnail-img>
    </div>
    <div>{{user.getFullName()}}</div>
    <div *ngIf="showRemoveAction">
      <div class="d-flex flex-row justify-content-center align-items-center m-0 mb-1 p-0"
        style="height: 18px; width: 18px;">
        <div class="text-center cursor-pointer opacity-50" title="Rimuovi elemento" (click)="onRemoveAction()">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-x-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>