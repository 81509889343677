import { GuestAttendanceDetailsComponent } from './components/register/guests-attendances/guest-attendance-details/guest-attendance-details.component';
import { ClassNextYearPreviewComponent } from './components/classes/class-details/class-next-year-preview/class-next-year-preview.component';
import { EditUserPersonalDataComponent } from './components/user/user-form/edit-user-personal-data/edit-user-personal-data.component';
import { NewUserPersonalDataComponent } from './components/user/user-form/new-user-personal-data/new-user-personal-data.component';
import { GuestsAttendancesComponent } from './components/register/guests-attendances/guests-attendances.component';
import { JustificationsCardsComponent } from './components/justifications-cards/justifications-cards.component';
import { ClassDetailsComponent } from './components/classes/class-details/class-details.component';
import { DocumentsFormsComponent } from './components/documents-forms/documents-forms.component';
import { ClassFormComponent } from './components/classes/class-form/class-form.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { StudentsComponent } from './components/students/students.component';
import { TeachersComponent } from './components/teachers/teachers.component';
import { FeaturesComponent } from './components/features/features.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ClassesComponent } from './components/classes/classes.component';
import { ParentsComponent } from './components/parents/parents.component';
import { UtilityComponent } from './components/utility/utility.component';
import { TutorsComponent } from './components/tutors/tutors.component';
import { StaffsComponent } from './components/staffs/staffs.component';
import { StatusComponent } from './components/status/status.component';
import { SessionComponent } from './pages/session/session.component';
import { LimboComponent } from './components/limbo/limbo.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { UserComponent } from './components/user/user.component';
import { LoginComponent } from './pages/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AppPaths } from './constants/app-paths';
import { NgModule } from '@angular/core';
// CREDENTIALS
import { TemporaryLoginComponent } from './pages/tmp/temporary-login/temporary-login.component';
import { TemporaryCredentialsComponent } from './pages/tmp/temporary-credentials/temporary-credentials.component';
import { FilmsComponent } from './components/items/films/films.component';
import { DevicesComponent } from './components/devices/devices.component';
import { EventsComponent } from './components/events/events.component';
import { EditEventComponent } from './components/events/edit-event/edit-event.component';
import { CreateEventComponent } from './components/events/create-event/create-event.component';
import { EventDetailsComponent } from './components/events/event-details/event-details.component';
import { FormsListingComponent } from './components/ring-forms/forms-listing/forms-listing.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { EnrollmentsComponent } from './components/enrollments/enrollments.component';
import { EnrollmentFormComponent } from './components/enrollments/enrollment-form/enrollment-form.component';
import { GeneralTimetableComponent } from './components/calendar/general-timetable/general-timetable.component';
import { EnrollmentsStatisticsComponent } from './components/enrollments/enrollments-statistics/enrollments-statistics.component';
import { TimetableReplacementsComponent } from './components/calendar/general-timetable/timetable-replacements/timetable-replacements.component';
import { TimetableReplacementFormComponent } from './components/calendar/general-timetable/timetable-replacement-form/timetable-replacement-form.component';

const baseTitle = 'De Amicis Ring';

const routes: Routes = [
  {
    path: AppPaths.DASHBOARD,
    title: 'Dashboard - ' + baseTitle,
    component: DashboardComponent,
    children: [
      {
        path: AppPaths.UTENTE,
        component: UserComponent,
        title: 'Profilo Personale - ' + baseTitle,
      },
      {
        path: AppPaths.UTENTE + '/:id',
        component: UserComponent,
        title: 'Dettagli Utente - ' + baseTitle,
      },
      {
        path: AppPaths.MODIFICA_UTENTE + '/:id',
        component: EditUserPersonalDataComponent,
        title: 'Modifica Utente - ' + baseTitle,
      },
      {
        path: AppPaths.NUOVO_UTENTE,
        component: NewUserPersonalDataComponent,
        title: 'Nuovo Utente - ' + baseTitle,
      },
      {
        path: AppPaths.STUDENTI,
        component: StudentsComponent,
        title: 'Studenti - ' + baseTitle,
      },
      {
        path: AppPaths.GENITORI,
        component: ParentsComponent,
        title: 'Genitori - ' + baseTitle,
      },
      {
        path: AppPaths.DOCENTI,
        component: TeachersComponent,
        title: 'Docenti - ' + baseTitle,
      },
      {
        path: 'insegnanti', // REDIRECT //
        redirectTo: AppPaths.DOCENTI,
        title: 'Docenti - ' + baseTitle,
      },
      {
        path: AppPaths.TUTORI,
        component: TutorsComponent,
        title: 'Tutori - ' + baseTitle,
      },
      {
        path: AppPaths.STAFF,
        component: StaffsComponent,
        title: 'Staff - ' + baseTitle,
      },
      {
        path: AppPaths.CLASSI,
        component: ClassesComponent,
        title: 'Classi - ' + baseTitle,
      },
      {
        path: AppPaths.CLASSE + '/:id',
        component: ClassDetailsComponent,
        title: 'Classe - ' + baseTitle,
      },
      {
        path: AppPaths.NUOVA_CLASSE,
        component: ClassFormComponent,
        title: 'Nuova Classe - ' + baseTitle,
      },
      {
        path: AppPaths.MODIFICA_CLASSE + '/:id',
        component: ClassFormComponent,
        title: 'Modifica Classe - ' + baseTitle,
      },
      {
        path: AppPaths.CLASSE_PREVIEW1 + '/:id/' + AppPaths.CLASSE_PREVIEW2,
        component: ClassNextYearPreviewComponent,
        title: 'Classe - ' + baseTitle,
      },
      {
        path: AppPaths.MODULI,
        component: DocumentsFormsComponent,
        title: 'Moduli - ' + baseTitle,
      },
      {
        path: AppPaths.MODULO_LISTING + '/:id',
        component: FormsListingComponent,
        title: 'Moduli - ' + baseTitle,
      },
      {
        path: AppPaths.RESOURCES,
        component: ResourcesComponent,
        title: 'Risorse - ' + baseTitle,
      },
      {
        path: AppPaths.INGRESSI_OSPITI,
        component: GuestsAttendancesComponent,
        title: 'Ingressi Ospiti - ' + baseTitle,
      },
      {
        path: AppPaths.INGRESSO_OSPITE + '/:id',
        component: GuestAttendanceDetailsComponent,
        title: 'Ingresso Ospite - ' + baseTitle,
      },
      {
        path: AppPaths.RITARDI,
        component: JustificationsCardsComponent,
        title: 'Ritardi - ' + baseTitle,
      },
      {
        path: AppPaths.RITARDI_LICOS,
        component: JustificationsCardsComponent,
        title: 'Ritardi LICOS - ' + baseTitle,
      },
      {
        path: AppPaths.ENROLLMENTS,
        component: EnrollmentsComponent,
        title: 'Iscrizioni - ' + baseTitle,
      },
      {
        path: AppPaths.EDIT_ENROLLMENT + '/:id',
        component: EnrollmentFormComponent,
        title: 'Modifica Iscrizione - ' + baseTitle,
      },
      {
        path: AppPaths.ENROLLMENTS_STATISTICS,
        component: EnrollmentsStatisticsComponent,
        title: 'Statistiche Iscrizioni - ' + baseTitle,
      },
      {
        path: AppPaths.LIMBO,
        component: LimboComponent,
        title: 'Limbo - ' + baseTitle,
      },
      {
        path: AppPaths.UTILITA,
        component: UtilityComponent,
        title: 'Utilità - ' + baseTitle,
      },
      {
        path: AppPaths.FILMS,
        component: FilmsComponent,
        title: 'Films - ' + baseTitle,
      },
      {
        path: AppPaths.DEVICES,
        component: DevicesComponent,
        title: 'Devices - ' + baseTitle,
      },
      {
        path: AppPaths.EVENTS,
        component: EventsComponent,
        title: 'Events - ' + baseTitle,
        pathMatch: 'full',
      },
      {
        path: AppPaths.CREATE_EVENT,
        component: CreateEventComponent,
        title: 'Nuovo Evento - ' + baseTitle,
        pathMatch: 'full',
      },
      {
        path: AppPaths.EDIT_EVENT + '/:id',
        component: EditEventComponent,
        title: 'Modifica Evento - ' + baseTitle,
        pathMatch: 'full',
      },
      {
        path: AppPaths.EVENTS + '/:id',
        component: EventDetailsComponent,
        title: 'Dettagli Evento - ' + baseTitle,
      },
      {
        path: AppPaths.FEATURES,
        component: FeaturesComponent,
        title: 'Features - ' + baseTitle,
      },
      {
        path: AppPaths.STATUS,
        component: StatusComponent,
        title: 'Status - ' + baseTitle,
      },
      {
        path: AppPaths.CALENDAR,
        component: CalendarComponent,
        title: 'Calendario - ' + baseTitle,
      },
      {
        path: AppPaths.TIMETABLE_CALENDAR,
        component: GeneralTimetableComponent,
        title: 'Quadro Orario Generale - ' + baseTitle,
      },
      {
        path: AppPaths.TIMETABLE_CALENDAR_REPLACEMENTS,
        component: TimetableReplacementsComponent,
        title: 'Schede Sostituzioni - ' + baseTitle,
      },
      {
        path: AppPaths.TIMETABLE_CALENDAR_REPLACEMENT_FORM,
        component: TimetableReplacementFormComponent,
        title: 'Crea Scheda Sostituzioni - ' + baseTitle,
      },
      {
        path: AppPaths.TIMETABLE_CALENDAR_REPLACEMENT_FORM + '/:id',
        component: TimetableReplacementFormComponent,
        title: 'Modifica Scheda Sostituzioni - ' + baseTitle,
      },
      {
        path: AppPaths.DASHBOARD,
        pathMatch: 'full',
        component: HomepageComponent,
        title: 'Dashboard - ' + baseTitle,
      },
    ],
  },
  {
    path: AppPaths.SESSION,
    component: SessionComponent,
    title: baseTitle,
  },
  {
    path: AppPaths.LOGIN,
    component: LoginComponent,
    title: 'Login - ' + baseTitle,
  },
  {
    path: AppPaths.LOGOUT,
    component: LogoutComponent,
    title: 'Logout - ' + baseTitle,
  },
  {
    path: AppPaths.TEMP_CREDENTIALS,
    component: TemporaryCredentialsComponent,
    title: 'Temporary Credentials - ' + baseTitle,
  },
  {
    path: AppPaths.TEMP_LOGIN,
    component: TemporaryLoginComponent,
    title: 'Temporary Login - ' + baseTitle,
  },
  {
    path: AppPaths.NOT_FOUND,
    component: NotFoundComponent,
    title: '404 - ' + baseTitle,
  },
  { path: AppPaths.ALL, redirectTo: AppPaths.NOT_FOUND },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
