import { AtlDetailsDialogComponent } from './atl-details-dialog/atl-details-dialog.component';
import { BesDetailsDialogComponent } from './bes-details-dialog/bes-details-dialog.component';
import { Component, inject, Input } from '@angular/core';
import { User } from 'src/app/models/user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-base-data',
  templateUrl: './user-base-data.component.html',
  styleUrls: ['./user-base-data.component.scss'],
})
export class UserBaseDataComponent {
  private _dialog: MatDialog = inject(MatDialog);
  private _router: Router = inject(Router);

  @Input() showFullData?: boolean = false;
  @Input() showArrow?: boolean = false;
  @Input() showTags?: boolean = false;
  @Input() user?: User;

  isRappresentanteDiClasse(): boolean {
    return (
      this.user?.classe?.rappresentanti?.includes(this.user?.id || 'NO-ID') ||
      false
    );
  }

  goToClass(classId?: string) {
    if (classId) this._router.navigateByUrl('/classe/' + classId);
  }

  openBesDetailsDialog = () => {
    this._dialog.open(BesDetailsDialogComponent, {
      maxWidth: '480px',
      minWidth: '480px',
      data: this.user?.bisogni_educativi_speciali,
      autoFocus: false,
      restoreFocus: false,
    });
  };

  openAtlDetailsDialog = () => {
    this._dialog.open(AtlDetailsDialogComponent, {
      maxWidth: '480px',
      minWidth: '480px',
      data: this.user?.atleta,
      autoFocus: false,
      restoreFocus: false,
    });
  };
}
