import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dea-search-bar',
  template: `<div class="input-group">
    <input
      type="text"
      class="form-control rounded-pill px-3 pe-5 py-1"
      (keyup)="onChange($event)"
      placeholder="Cerca"
      aria-label="Cerca"
    />
    <span
      class="ps-2"
      style="position: absolute; right: 12px; top: 3px; z-index: 10;"
    >
      <svg
        width="16"
        height="16"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-search"
        viewBox="0 0 16 16"
      >
        <path
          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
        />
      </svg>
    </span>
  </div>`,
  styles: [],
})
export class DeaSearchBarComponent {
  @Output() text = new EventEmitter<string>();
  private lastTxtEmitted: string = '';
  private txt: string = '';

  private autoEmitTimer?: any;

  emitEvent(): void {
    if (this.txt != this.lastTxtEmitted) {
      this.lastTxtEmitted = this.txt;
      this.text.emit(this.txt);
    }
  }

  onChange(event: any): void {
    if (this.autoEmitTimer) clearTimeout(this.autoEmitTimer);
    if (event.key == 'Enter') return this.emitEvent();
    this.txt = event.target.value;
    this.autoEmitTimer = setTimeout(() => this.emitEvent(), 600);
  }
}
