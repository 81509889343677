import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import {
  SimpleChanges,
  EventEmitter,
  Component,
  OnChanges,
  Output,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-listing-pagination-bar',
  templateUrl: './listing-pagination-bar.component.html',
  styleUrls: ['./listing-pagination-bar.component.scss'],
})
export class ListingPaginationBarComponent implements OnChanges {
  @Input() paginationData?: ApiPagination;
  @Output() pageEmitter = new EventEmitter<number>();

  public enablePrev: boolean = this.paginationData
    ? this.paginationData.canGetPrevPage()
    : false;
  public enableNext: boolean = this.paginationData
    ? this.paginationData.canGetNextPage()
    : false;

  ngOnChanges(_: SimpleChanges): void {
    this.setStyleAndAction();
  }

  onClickPrev() {
    if (this.enablePrev && this.paginationData != undefined) {
      this.pageEmitter.emit(this.paginationData.getPrevPage());
    }
  }

  onClickNext() {
    if (this.enableNext && this.paginationData != undefined) {
      this.pageEmitter.emit(this.paginationData.getNextPage());
    }
  }

  setStyleAndAction() {
    if (this.paginationData != undefined) {
      this.enablePrev = this.paginationData
        ? this.paginationData.canGetPrevPage()
        : false;
      this.enableNext = this.paginationData
        ? this.paginationData.canGetNextPage()
        : false;
    }
  }
}
