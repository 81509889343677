export class AppTools {
  static capitalizeFirstLetterOfEachWord(string: string): string {
    let capitalizedArray: string[] = [];
    string.split(' ').forEach((word) => {
      capitalizedArray.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    });
    return capitalizedArray.join(' ');
  }

  static removeNullAndReplaceWithUndefined = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(AppTools.removeNullAndReplaceWithUndefined);
    } else if (obj !== null && typeof obj === 'object') {
      const newObj: any = {};
      for (const key in obj) {
        if (obj[key] === null) {
          newObj[key] = undefined;
        } else {
          newObj[key] = AppTools.removeNullAndReplaceWithUndefined(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  };
}
