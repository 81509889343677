<div class="calendar-container" style="height: calc(100% - 40px - var(--dea-spacer));">
  <div class="calendar-controls d-flex justify-content-between align-items-center">
    <div class="hstack gap-2">
      <button class="pointer" (click)="goBack()" title="Precedente" type="button" mat-stroked-button>
        <i class="bi bi-chevron-left"></i>
      </button>
      <button class="pointer" (click)="goToToday()" title="Oggi" type="button" mat-stroked-button>
        Oggi
      </button>
      <button class="pointer" (click)="goNext()" title="Seguente" type="button" mat-stroked-button>
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
    <div>
      {{ mainDate.format('YYYY-MM-DD') }} | {{ today.format('YYYY-MM-DD') }}
    </div>
    <div class="text-capitalize">
      {{mainDate.format(today.format('YYYY-MM-DD') === mainDate.format('YYYY-MM-DD') ? 'DD MMMM YYYY' : 'MMMM YYYY')}}
    </div>
    <!-- <div class="btn-group">
      <button (click)="changeView('month')" class="btn btn-outline-primary set-view-btn"
        [class.active]="currentView === 'month'">
        Month
      </button>
      <button (click)="changeView('week')" class="btn btn-outline-primary set-view-btn"
        [class.active]="currentView === 'week'">
        Week
      </button>
      <button (click)="changeView('day')" class="btn btn-outline-primary set-view-btn"
        [class.active]="currentView === 'day'">
        Day
      </button>
    </div> -->
  </div>
  <div class="v-spacer"></div>
  <!-- CALENDAR -->
  <div class="bg-white d-flex flex-column w-100 h-100 dea-radius border border-025 overflow-hidden">
    <!-- Vista Mese -->
    @if (currentView === 'month') {
    <div class="month-view d-flex flex-column h-100 w-100 align-content-stretch">
      <div class="row row-cols-7 m-0 p-0 w-100">
        @for (day of weekDays; track day) {
        <div class="col m-0 p-0 py-1 text-center border border-025">
          <span class="opacity-50">{{ day.toUpperCase() }}</span>
        </div>
        }
      </div>
      <div class="row row-cols-7 m-0 p-0 h-100" #calendarBody>
        @for (date of monthDates; track date) {
        <div class="col m-0 p-0 text-center border border-025 d-flex flex-column">
          <div>
            <span class="rounded-circle p-1 fs-065r"
              [ngClass]="date.isSame(mainDate, 'day') && date.isSame(today, 'day') ? 'bg-primary text-white': !date.isSame(mainDate, 'month') ? 'opacity-50':''">
              {{ date.date() }}
            </span>
          </div>
          <div class="h-100 w-100 d-flex align-items-center justify-content-center opacity-50">
            {{ getNumberOfEvents(date) }}
          </div>
        </div>
        }
      </div>
    </div>
    }

    <!-- Vista Settimana -->
    @if (currentView === 'week') {
    <div>
      <div class=" calendar-grid week-view">
        <div class="calendar-header time-header"></div>
        @for (day of weekDays; track day) {
        <div class="calendar-header">{{ day }}</div>
        }
        @for(time of dayHours; track time) {
        <div class="calendar-cell time-cell">{{ time }}</div>
        }
        @for (date of weekDates; track date) {
        <div class="calendar-cell"></div>
        }
      </div>
    </div>
    }
    <!-- Vista Giorno -->
    @if (currentView === 'day') {
    <div>
      <div class="calendar-grid day-view">
        <div class="calendar-header">{{ mainDate.format('dddd, MMMM D, YYYY') }}</div>
        @for(time of dayHours; track time) {
        <div class="calendar-cell time-cell">{{ time }}</div>
        }
        <div class="calendar-cell">cell</div>
      </div>
    </div>
    }
  </div>
</div>
