import { Component } from '@angular/core';

@Component({
  selector: 'app-dea-widget-wrapper',
  templateUrl: './dea-widget-wrapper.component.html',
  styleUrls: ['./dea-widget-wrapper.component.scss']
})
export class DeaWidgetWrapperComponent {

}
