<app-dea-homepage-wrapper>
  <form [formGroup]="loginForm" class="w-100">
    <div class="d-flex flex-column input-group-lg">
      <input auth-input type="text" class="form-control rounded-0 rounded-top" placeholder="DEA-ID / E-Mail / UUID"
        spellcheck="false" autocomplete="off" style="border-bottom-width: 0.00625em;" name="username"
        formControlName="username" autofocus required (blur)="onUsernameBlur()" style="border-bottom-width: 0.00625em;">
    </div>
    <div class="input-group input-group-lg mb-3 form-control p-0 rounded-0 rounded-bottom input-wrapper"
      style="border-top-width: 0.00625em;">
      <input auth-input type="password" class="form-control pe-0 border-0" placeholder="Password" spellcheck="false"
        autocomplete="off" name="password" formControlName="password" (keydown.enter)="doLogin()" required>
      <div class="my-auto px-2">
        <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
        <ng-template #loadingTrue>
          <mat-spinner strokeWidth="1" color="primary" diameter="22"></mat-spinner>
        </ng-template>
        <ng-template #loadingFalse>
          <div (click)="doLogin()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
              class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </div>
        </ng-template>
      </div>
    </div>
  </form>
  <div class="ms-3 fw-lighter text-decoration-underline fs-075r dea-icon-btn" routerLink="/tmp/credentials">
    Hai dimenticato la Password?
  </div>
</app-dea-homepage-wrapper>