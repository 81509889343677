export class ApiPagination {
  page?: number;
  limit?: number;
  last_page?: number;
  prev?: boolean;
  next?: boolean;
  items?: number;

  constructor(json?: any) {
    if (!json) return;
    this.page = json?.page ?? 0;
    this.limit = json?.limit ?? 0;
    this.last_page = json?.last_page ?? 0;
    this.prev = json?.prev ?? false;
    this.next = json?.next ?? false;
    this.items = json?.items ?? 0;
  }

  canGetPrevPage(): boolean {
    if (this.page != undefined) return this.page - 1 >= 0;
    return false;
  }

  canGetNextPage(): boolean {
    if (this.page != undefined && this.last_page != undefined) {
      return this.page + 1 <= this.last_page;
    }
    return false;
  }

  getPrevPage(): number {
    if (this.canGetPrevPage() && this.page != undefined) return this.page - 1;
    return 0;
  }

  getNextPage(): number {
    if (this.canGetNextPage() && this.page != undefined) return this.page + 1;
    return 0;
  }
}
