import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toTitleCase',
})
export class ToTitleCasePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) {
      let strArr: string[] = (value + '').split(' ');
      strArr = strArr.map((word) => {
        let newWord = '';
        for (let i = 0; i < word.length; i++) {
          if (i == 0) newWord += word[i].toUpperCase();
          else newWord += word[i].toLowerCase();
        }
        return newWord;
      });
      return strArr.join(' ');
    }
    return value;
  }
}
