<app-dea-widget-wrapper>
  <div class="hstack">
    <mat-chip-listbox aria-label="Tipo di Features">
      <mat-chip-option *ngFor="let status of statusTypes" (click)="onSelectStatus(status.backend)"
        [ngClass]="status.backend == selectedStatus ? 'text-light fw-bold dea-mat-chip-option selected' : ''"
        [selected]="status.backend == selectedStatus">
        {{status.frontend}}
      </mat-chip-option>
      <div *ngIf="statusTypes.length == 0" class="h-100 d-flex align-content-center"
        style="margin-top: 0.7rem; min-height: 29px;">
        <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon>
      </div>
    </mat-chip-listbox>
    <div *ngIf="showBtn && createFeatures" class="ms-auto">
      <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill" (click)="openDialog()">
        Aggiungi Feature
      </button>
    </div>
    <div *ngIf="showBtn && !createFeatures" class="ms-auto">
      <a title="Contatta lo Sviluppatore"
        href="mailto:gabrielebellani98@gmail.com?subject=Richiesta da Dashboard De Amicis Ring&body=Ciao Gabriele,">
        <button mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill">
          <i class="bi bi-envelope-at me-2"></i>Contatta lo Sviluppatore
        </button>
      </a>
    </div>
  </div>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="d-flex flex-column gap-3 mb-3">
        <app-feature-row *ngFor="let feature of features;" [feature]="feature">
          <div *ngIf="showBtn && editFeatures" class="ms-3" (click)="openDialog(feature)">
            <i class="bi bi-pencil-square dea-icon-btn" title="Modifica Feature"></i>
          </div>
          <div *ngIf="showBtn && deleteFeatures" class="ms-3" (click)="deleteFeature(feature)">
            <i class="bi bi-trash3 dea-icon-btn text-danger" title="Elimina Feature"></i>
          </div>
        </app-feature-row>
      </div>
      <app-listing-pagination-bar [paginationData]="pagination"
        (pageEmitter)="onPage($event)"></app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>