import { AppJsonDecoder } from 'src/app/utils/app-json-decoder';
import { Headquarter } from '../../headquarter';
import { Class } from '../../class/class.model';
import { User } from '../../user/user.model';

export class TimetableReplacementsSummaryBlock {
  block?: string;
  start?: Date;
  end?: Date;
  tz?: string;
  classes?: Class[];
  teacher?: User;
  modification?: 'substitute' | 'person' | 'entry' | 'exit';
  substitute?: User;
  person?: string;
  time?: string;
  note?: string;
  slot?: {
    original?: string;
    substitution?: string;
  };

  constructor(json?: any) {
    if (!json) return;
    this.block = json.block.toString().trim().toUpperCase();
    this.start = AppJsonDecoder.optDate(json.start);
    this.end = AppJsonDecoder.optDate(json.end);
    this.tz = json.tz;
    this.classes = (json.classes ?? []).map((cl: any) => new Class(cl));
    this.teacher = new User(json.teacher);
    this.modification = json.modification?.toString()?.trim()?.toLowerCase();
    this.substitute = json.substitute ? new User(json.substitute) : undefined;
    this.person = json.person;
    this.time = json.time;
    this.note = json.note;
    this.slot = {};
    this.slot.original = json.slot?.original;
    this.slot.substitution = json.slot?.substitution;
  }
}

export class TimetableReplacementsSummary {
  id?: string;
  headquarter?: Headquarter;
  date?: Date;
  owner?: User;
  teachers?: User[];
  converted?: boolean;
  note?: string;
  blocks?: TimetableReplacementsSummaryBlock[];
  canEdit?: boolean;

  constructor(json?: any) {
    if (!json) return;
    this.id = json['_id'] || json['id'];
    this.headquarter = json['headquarter']
      ? Headquarter.fromString(json['headquarter'])
      : undefined;
    this.date = AppJsonDecoder.optDate(json['date']);
    this.owner = json['owner'] ? new User(json['owner']) : undefined;
    this.teachers = (json['teachers'] ?? []).map((tc: any) => new User(tc));
    this.converted = AppJsonDecoder.optBool(json['converted']);
    this.note = json['note'];
    this.blocks = (json['blocks'] ?? []).map(
      (block: any) => new TimetableReplacementsSummaryBlock(block)
    );
    this.canEdit = this.date
      ? new Date().setHours(0, 0, 0, 0) <
        new Date(this.date).setHours(23, 59, 59, 999)
      : false;
  }
}
